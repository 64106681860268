import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import {
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Box,
  Typography,
  SelectChangeEvent,
  styled,
} from "@mui/material";

// Define interface for props
interface StyledMenuItemProps {
  first?: boolean;
}

// Define a styled component for the hint text
const HintText = styled(Typography)(({ theme }) => ({
  padding: '4px 26px 4px 15px',
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  backgroundColor: theme.palette.background.paper,
  zIndex: 1,
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

// Define a styled component for the dropdown menu item with optional margin
const StyledMenuItem = styled(MenuItem)<StyledMenuItemProps>(({ theme, first }) => ({
  ...(first && {
    marginTop: '10px',
  }),
}));

interface DynamicBarData {
  name: string;
  color: string;
  values: number[];
  postfix?: string;
}

interface DynamicBarChartProps {
  data: DynamicBarData[];
  xAxisCategories: string[];
  yAxisName?: string;
  xAxisName?: string;
  maxVisibleBars?: number; // Maximum number of bars to display
}

const DynamicBarChart: React.FC<DynamicBarChartProps> = ({
  data,
  xAxisCategories,
  yAxisName,
  xAxisName,
  maxVisibleBars = 3,
}) => {
  const initialSelection = data.slice(0, maxVisibleBars).map((d) => d.name);
  const [visibleData, setVisibleData] = useState<string[]>(initialSelection);

  // Handle the change in selected items
  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const selectedValues = event.target.value as string[];
    if (selectedValues.length <= maxVisibleBars) {
      setVisibleData(selectedValues);
    }
  };

  // Filter data based on visibleData
  const filteredData = data.filter((d) => visibleData.includes(d.name));

  const options: ApexOptions = {
    chart: {
      type: "bar",
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "80%",
      },
    },
    dataLabels: {
      enabled: false,
      offsetY: -20,
      formatter: function (value: any) {
        const numericValue =
          typeof value === "number" ? value : parseFloat(value);
        return numericValue.toFixed(2);
      },
    },
    xaxis: {
      categories: xAxisCategories,
      title: {
        text: xAxisName,
      },
      labels: {
        formatter: function (value) {
          if (typeof value === "string" && value.includes(" ")) {
            return value.split(" ")[1];
          }
          return value;
        },
      },
    },
    yaxis: {
      title: {
        text: yAxisName || "",
      },
      labels: {
        formatter: function (value) {
          return value.toFixed(0);
        },
      },
    },
    tooltip: {
      enabled: true,
      y: {
        formatter: function (
          value: number,
          { seriesIndex }: { seriesIndex: number }
        ) {
          const postfix = filteredData[seriesIndex]?.postfix;
          return `${value} ${postfix ? postfix : ""}`;
        },
      },
    },
    colors: filteredData.map((bar) => bar.color),
    series: filteredData.map((bar) => ({ name: bar.name, data: bar.values })),
    grid: {
      show: false,
    },
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
      floating: false,
      fontSize: "14px",
      offsetX: 0,
      offsetY: 0,
    },
  };

  return (
    <Box position="relative">
      <FormControl variant="outlined" sx={{ position: 'relative', width: 250 }}>
        <Select
          multiple
          value={visibleData}
          onChange={handleChange}
          renderValue={(selected) => selected.join(", ")}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 224,
                width: 250,
              },
            },
          }}
        >
          <HintText variant="caption">
            Maximum {maxVisibleBars} can be selected
          </HintText>
          {data.map((bar, index) => (
            <StyledMenuItem key={bar.name} value={bar.name} first={index === 0}>
              <Checkbox checked={visibleData.includes(bar.name)} />
              <ListItemText primary={bar.name} />
            </StyledMenuItem>
          ))}
        </Select>
      </FormControl>
      <ReactApexChart
        options={options}
        series={options.series}
        type="bar"
        height={350}
      />
    </Box>
  );
};

export default DynamicBarChart;
