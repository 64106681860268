import React from "react";

interface CardThreeProps {
  title?: string;
  amount?: string;
  background?: string;
}

const CardThree: React.FC<CardThreeProps> = ({
  title,
  amount,
  background,
}) => {
  return (
    <div
      className="rounded-sm border border-stroke p-6 bg-white"
      style={{ background, borderRadius: "12px", overflow: "hidden"}}
    >
      <p style={{fontSize: "16px"}}>{title}</p>
      <div className="mt-2">
        <h4 className="text-title-md text-black text-[21px] font-bold">{amount}</h4>
      </div>
    </div>
  );
};

export default CardThree;
