import React, { useEffect, useRef, useState } from "react";
import CardTwo from "../../components/dashBoardComponents/CardTwo";
import axios from "axios";
import tds from "../../images/tds1.png";
import temp from "../../images/temp.png";

import LineChart from "../../components/apex-chart/LineChart";
import {
  formatTimestamp,
  generateCSV1,
  reportDataWithGraph,
} from "../../common-function/CommonFunction";
import MobileTowerIcon from "../../components/apex-chart/HeatMapChart";
import LongMenu from "../../utils/longMenu/LongMenu";

const baseUrl = process.env.REACT_APP_BASE_URL;

const DashboardWaterQuality5 = ({ selectedDevice, devices }: any) => {
  const [value, setValue] = useState(0);
  const accessToken = localStorage.getItem("token");
  const [apiData, setApiData] = useState<any>([]);
  const chartRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    fetchData();
    const intervalId = setInterval(fetchData, 120000);
    return () => clearInterval(intervalId);
  }, [selectedDevice]);

  const fetchData = async () => {
    let response: any = null;
    try {
      response = await axios.get(baseUrl + `/device-data-log?last24=last24`, {
        params: {
          deviceId: selectedDevice,
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }

    const _waterQualityDO_Data = response.data
      .map((d: any, i: any) => {
        return {
          timestamp: formatTimestamp(d.timeStamp),
          tdsvalue: d["TDS"],
          temperature: d["TEMP"],
          rssi: d["rssi"],
        };
      })
      .filter((d: any) => {
        return !Object.values(d).some(
          (val: any) => typeof val === "string" && val.toLowerCase() === "error"
        );
      });
    setApiData(_waterQualityDO_Data);
  };

  const currentDate = new Date();
  const last24HoursData = apiData.filter((entry: any) => {
    const entryDate = new Date(entry.timestamp);
    const timeDifference = currentDate.getTime() - entryDate.getTime();
    return timeDifference <= 24 * 60 * 60 * 1000; // Include entries up to the current timestamp
  });
  const tds1 =
    last24HoursData.length > 0
      ? last24HoursData.map(({ timestamp, tdsvalue }: any) => ({
          timestamp,
          value: tdsvalue,
        }))
      : [];

  const tdsGraph: any = [
    {
      name: "TDS",
      color: "#3cbd2b",
      data: tds1.reverse(),
      postfix: " (ppm)",
    },
  ];
  const tempGraph =
    last24HoursData.length > 0
      ? last24HoursData.map(({ timestamp, temperature }: any) => ({
          timestamp,
          value: temperature,
        }))
      : [];

  const tmpGraph: any = [
    {
      name: "TDS",
      color: "#3cbd2b",
      data: tempGraph.reverse(),
      postfix: " (°C)",
    },
  ];

  const handleExportBarChart = async (exportOption: string) => {
    let lineChartData =
      value === 0
        ? tds1?.map(({ timestamp, value }: any) => {
            return {
              TIMESTAMP: timestamp,
              TDS: value,
              UNIT: " ppm",
            };
          })
        : tempGraph?.map(({ timestamp, value }: any) => {
            return {
              TIMESTAMP: timestamp,
              TEMPERATURE: value,
              UNIT: " °C",
            };
          });

    switch (exportOption) {
      case "Download as CSV":
        generateCSV1(lineChartData);
        break;
      case "Download as XLS":
        break;
      case "Download as PDF":
        if (lineChartData && chartRef) {
          reportDataWithGraph(
            chartRef,
            lineChartData,
            devices?.deviceName,
            devices?.Customer?.name,
            "Water Quality Summary",
            last24HoursData.length > 0
              ? last24HoursData[last24HoursData.length - 1].timestamp +
                  " - " +
                  last24HoursData[0].timestamp
              : ""
          );
        } else {
        }
        return;
      default:
        return; // Unknown export option
    }
  };
  return (
    <div>
      <p className=" flex  p-[10px] mb-[20px]">
        <MobileTowerIcon levels={apiData[0]?.rssi || 0} time={apiData[0]?.timestamp}/>
        <p className="ml-2">
          {"    Last Data Received at  " + apiData[0]?.timestamp ||
            "  YYYY-MM-DD HH:MM:SS"}
        </p>
      </p>
      <div className="border border-gray-400 rounded-md p-[10px]  grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5">
        <CardTwo
          title="TDS"
          amount={`${apiData[0]?.tdsvalue || 0} ppm`}
          icon={tds}
        />
        <CardTwo
          title="Temperature"
          amount={`${apiData[0]?.temperature || 0} \u2103`}
          icon={temp}
        />
      </div>
      <div className="border border-gray-400 rounded-md mt-[5px]  p-[20px]">
        <div className="relative">
          <p className="mb-[20px]">
            {value === 0 ? "TDS " : "Temperature "} data variation with time
            (between{" "}
            {last24HoursData.length > 0
              ? last24HoursData[last24HoursData.length - 1].timestamp +
                " to " +
                last24HoursData[0].timestamp
              : " "}
            )
          </p>
          <div className="absolute top-0 right-0">
            <LongMenu
              options={[
                "Download as CSV",
                "Download as PDF",
              ]}
              onOptionClick={handleExportBarChart}
              id={1}
            />
          </div>
        </div>
       
        <ul className="text-sm font-medium text-center text-gray-500 rounded-lg sm:flex">
          <li className="focus-within:z-10">
            <button
              className={`inline-block border border-gray-300 px-5 py-2 text-[1rem] text-gray-900 rounded-s-lg  transition-colors duration-300`}
              aria-current="page"
              style={value === 0 ? { backgroundColor: "#dadada" } : {}}
              onClick={() => setValue(0)}
            >
              TDS
            </button>
          </li>

          <li className="focus-within:z-10">
            <button
              className={`inline-block border border-gray-300 px-4 py-2 text-[1rem] text-gray-900 rounded-e-lg transition-colors duration-300`}
              style={value === 1 ? { backgroundColor: "#dadada" } : {}}
              onClick={() => setValue(1)}
            >
              Temperature
            </button>
          </li>
        </ul>

        <div ref={chartRef}>
          {value === 0 && (
            <LineChart
              stroke={true}
              yAxisName="TDS (ppm)"
              xAxisName="Time"
              data={tdsGraph}
            />
          )}
          {value === 1 && (
            <LineChart
              stroke={true}
              yAxisName="Temperature (°C)"
              xAxisName="Time"
              data={tmpGraph}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default DashboardWaterQuality5;
