import { useState, useEffect, useMemo } from "react";
import Table from "@mui/material/Table";
import Box from "@mui/material/Box";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import LongMenu from "../../../utils/longMenu/LongMenu";
import { ConfirmModal } from "../../../utils/alertui/ConfirmModal";
import EnhancedTableToolbar from "./EnhancedTableToolBar";
import EnhancedTableHead from "./EnhancedTableHead";
import {
  ApiResponseTagsGroupAttributes,
  FetchedTagsGroupData,
  TagGroupApiResponse,
} from "../../../types/TagGroup";
import { useTagGroupState } from "../../../app-context/taggroup-context/TagGroupState";
import { http } from "../../../helper/http";
import { toast } from "react-toastify";
import {
  getTagGroupPageClick,
  setTagGroup,
} from "../../../app-context/taggroup-context/TagGroupAction";
import AddTagGroupForm from "./AddTagGroupForm";
import {
  fetchAllTags,
  fetchAllTemplate,
} from "../../../common-function/CommonFunction";
import { setAllTags } from "../../../app-context/tags-context/TagAction";
import { setAllTemplate } from "../../../app-context/template-context/TemplateAction";
import { useTemplateState } from "../../../app-context/template-context/TemplateState";
import { useTagState } from "../../../app-context/tags-context/TagState";

const baseUrl = process.env.REACT_APP_BASE_URL;
export default function TagGroup() {
  const [page] = useState(0);
  const [editModal, setEditModal] = useState(false);
  const [rowsPerPage] = useState(10);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [updatedRows, setUpdatedRows] = useState<
    ApiResponseTagsGroupAttributes[]
  >([]);
  const [tagGroupData, setTagGroupData] = useState<FetchedTagsGroupData[]>([]);
  const { tagsGroups, dispatch } = useTagGroupState();
  const [deleteId, setDeleteId] = useState<number | null>(null);
  const { templates, dispatch: templateDispatch } = useTemplateState();
  const { dispatch: tagDispatch } = useTagState();

  useEffect(() => {
    setUpdatedRows(tagsGroups.tagGroups);
  }, [tagsGroups, tagsGroups.tagGroups]);

  const toggleTagForm = () => {
    setEditModal((prevVal) => !prevVal);
  };

  const handleMenuOptionClick = async (option: string, id: number) => {
    try {
      switch (option) {
        case "Delete":
          setShowConfirmModal(true);
          setDeleteId(id);
          break;
        case "Edit":
          const respTags = (await fetchAllTags()) as any;
          setAllTags(tagDispatch, respTags.tags);
          const respTemplate = (await fetchAllTemplate()) as any;
          setAllTemplate(templateDispatch, respTemplate.template);
          const editResp = (await http(
            baseUrl + `/tag-groups?id=${id}`
          )) as FetchedTagsGroupData;
          setTagGroupData([editResp]);
          toggleTagForm();
          break;
        case "View":
          break;
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleConfirmDelete = async () => {
    if (deleteId !== null) {
      try {
        const deleteResp = (await http(
          baseUrl + `/tag-groups?id=${deleteId}`,
          "DELETE"
        )) as TagGroupApiResponse;
        toast.success(deleteResp.message);
        if (tagsGroups.tagGroups.length === 1) {
          setTagGroup(dispatch, 1);
        } else {
          setTagGroup(dispatch, tagsGroups.currentPage);
        }
      } catch (err) {
        console.error(err);
      }
      setShowConfirmModal(false);
    }
  };

  // const toggleTagGroupEDit = () => {
  //   setEditModal((prevVal) => !prevVal);
  // };

  const handlePageChange = async (newPage: number) => {
    const direction =
      newPage > tagsGroups.currentPage - 1 ? "next" : "previous";
    const page = +tagsGroups.currentPage;
    try {
      const resp = (await http(
        baseUrl +
          `/tag-groups?page=${direction === "next" ? page + 1 : page - 1}`
      )) as TagGroupApiResponse;
      getTagGroupPageClick(dispatch, resp.tagsGroup);
    } catch (err) {
      console.error(err);
    }
  };

  const emptyRows = 10 - updatedRows.length;
  const visibleRows = useMemo(
    () =>
      updatedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [page, rowsPerPage, updatedRows]
  );
  // console.log("visible rows", visibleRows);
   useEffect(() => {
    if(tagsGroups.tagGroups.length===0){
      setTagGroup(dispatch, 1);
    }
  }, []);
  return (
    <>
      <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Paper sx={{ width: "99%" }}>
          <EnhancedTableToolbar onFilter={() => {}} />
          <div className="h-[72vh] overflow-y-auto">
            <TableContainer>
              {showConfirmModal && (
                <ConfirmModal
                  title="Confirmation"
                  message="Are you sure you want to delete?"
                  onConfirm={handleConfirmDelete}
                  onCancel={() => {
                    setShowConfirmModal(false);
                  }}
                  isVisible={showConfirmModal}
                />
              )}
              <Table
                className="min-w-750 bg-white"
                aria-labelledby="tableTitle"
                size="medium"
              >
                {editModal && (
                  <AddTagGroupForm
                    uniqueId="EDIT"
                    data={tagGroupData}
                    toggleForm={editModal}
                    toggleFormFun={toggleTagForm}
                  />
                )}

                <EnhancedTableHead />
                <TableBody>
                  {visibleRows.map((row: ApiResponseTagsGroupAttributes) => {
                    return (
                      <TableRow
                        hover
                        className="cursor-pointer transition-all hover:bg-gray-100"
                        key={row.id}
                      >
                        <TableCell
                          align="center"
                          style={{ textAlign: "center" }}
                        >
                          {row?.template?.templatename || "NA"}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ textAlign: "center" }}
                        >
                          {row?.tag?.tagname || "NA"}
                        </TableCell>
                        <TableCell
                          className="text-center"
                          style={{ textAlign: "center" }}
                        >
                          <LongMenu
                            options={["Edit", "Delete"]}
                            onOptionClick={handleMenuOptionClick}
                            id={row.id}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: 53 * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={tagsGroups?.totalItems || 0}
            rowsPerPage={10}
            page={Math.max(
              0,
              Math.min(tagsGroups?.currentPage - 1, tagsGroups?.totalPages - 1)
            )}
            onPageChange={(event, newPage) => handlePageChange(newPage)}
            className="bg-gray-100"
          />
        </Paper>
      </Box>
    </>
  );
}
