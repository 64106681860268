import React from 'react'
import PropTypes from 'prop-types';

interface DynamicCardProps {
    cardTitle?: string;
    cardValue?: number;
    width?: string;
    height?: string;
    unit?: string;
    textColor?: string;
    customComponent?: React.ReactNode;
}

const DynamicCard: React.FC<DynamicCardProps> = ({ cardTitle, cardValue, width = '300px', height = '150px', unit, textColor, customComponent }) => {


    return (
        <div className='px-5 py-4 ' style={{ width, height, boxShadow: "rgba(0, 0, 0, 0.14) 1px 1px 11px 0px" }}>
            {
                customComponent ? (customComponent) : (
                    <>
                        {unit ?
                            (<div className={`flex justify-center items-center text-4xl font-thin h-full ${textColor}`}>{unit}</div>)
                            : (
                                <>
                                    <p className={`text-xl mb-2 ${textColor}`}>{cardTitle}</p>
                                    <p className={`text-6xl font-thin ${textColor}`}>{cardValue?.toString() === "ERROR" ? 0 : cardValue}</p>
                                </>
                            )}
                    </>
                )
            }

        </div>
    )
}

DynamicCard.propTypes = {
    cardTitle: PropTypes.string,
    cardValue: PropTypes.number,
    width: PropTypes.string,
    height: PropTypes.string,
    unit: PropTypes.string,
    textColor: PropTypes.string,
    customComponent: PropTypes.node
};

export default DynamicCard
