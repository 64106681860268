import { useEffect, useRef, useState } from "react";
import { http } from "../../helper/http";
import {
  filterDataFromEachHourLast24HoursTotalizer,
  formatTimestamp,
  generateCSV,
  generateCSV1,
  processConsumptionDataTotalizer,
  processHourlyDataTotalizer,
  reportDataWithGraph,
} from "../../common-function/CommonFunction";
import CustomBarChart from "../../components/dashBoardComponents/BarChart";
import LongMenu from "../../utils/longMenu/LongMenu";
import DynamicCard from "../../components/waterDashboardComponents/DynamicCard";
import SignalStrength from "../../components/waterDashboardComponents/SignalStrength";
import { IconButton } from "@mui/material";
import { ImportExport } from "@mui/icons-material";
import TabularComponents from "../../components/deviceComponents/TabularComponents";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LineChart from "../../components/apex-chart/LineChart";
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import { Dialog, DialogContent, DialogTitle,  TextField } from "@mui/material";
import CheckBoxFilter from "../../components/CheckboxField";
import FilterListIcon from "@mui/icons-material/FilterList";
const baseUrl = process.env.REACT_APP_BASE_URL;

const column = ["TIMESTAMP", "FLOW", "FLOW_UNIT", "TOTALIZER", "UNIT"];
const DashboardWaterTotalizerJoin = ({ devices, selectedDevice }: any) => {
  const [apiData, setApiData] = useState<any[]>([]);
  const [dashboardSts, setDashboardSts] = useState<any>([]);
  const chartRef = useRef<HTMLDivElement>(null);
  const chartRef1 = useRef<HTMLDivElement>(null);
  const [filterTable, setFilterTable] = useState(false);
  const [selectedHeaders, setSelectedHeaders] = useState<string[]>(column);
  const fetchData = async () => {
    let response: any = null;
    try {
      response = await http(
        baseUrl + `/device-data-log?deviceId=${selectedDevice}?last24=last24`
      );

      const _data = response
      .map((d: any) => ({
        timestamp: formatTimestamp(d.timeStamp),
        rssi: Math.round((+d.rssi / 35) * 110),
        flow: d["Inst_flow_rate"],
        flow_unit: "m³/hr",
        totalizer: `${+d["Pos_Cumu_flow_Int"] + +d["Pos_Cumu_flow_Dec"]}`,
        unit: "m³",
      }))
      .filter((d: any) => {
        // Function to check if any value contains "error" (case insensitive)
        const containsError = (value: any) =>
          typeof value === "string" &&
          value.toLowerCase().includes("error");
    
        // Check if any value in the object contains "error" (case insensitive)
        return !Object.values(d).some(containsError);
      });

      setApiData(_data);
      setDashboardSts(_data.length ? _data[0] : "");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  

  const toggleFilterList = () => {
    setFilterTable(!filterTable);
  };

  const handleSelectedDataChange = (selectedData: string[]) => {
    setSelectedHeaders(selectedData);
  };

  useEffect(() => {
    fetchData();
    const intervalId = setInterval(fetchData, 120000);
    return () => clearInterval(intervalId);
  }, [selectedDevice]);

  const currentDate = new Date();
  const last24HoursData = apiData.filter((entry) => {
    const entryDate = new Date(entry.timestamp);
    const timeDifference = currentDate.getTime() - entryDate.getTime();
    return timeDifference <= 24 * 60 * 60 * 1000; // Include entries up to the current timestamp
  });

  let consumtionData: any[] | undefined;
  const filterLast24hourDataOfEachHOur =
    filterDataFromEachHourLast24HoursTotalizer(apiData);

  if (filterLast24hourDataOfEachHOur.length > 0) {
    const hourData = processHourlyDataTotalizer(filterLast24hourDataOfEachHOur);
    consumtionData = processConsumptionDataTotalizer(hourData);
  }
  const handleExportBarChart = async (exportOption: string) => {
    switch (exportOption) {
      case "Download as CSV":
        let columns = [
          "Hour",
          "First Timestamp",
          "Last Timestamp",
          "Consumption",
        ];
        let u: any = consumtionData?.map(
          ({ hour, firstTimeStamp, consumption, lastTimeStamp }: any) => {
            return {
              Hour: hour + ":00",
              "First Timestamp": firstTimeStamp,
              "Last Timestamp": lastTimeStamp,
              Consumption: consumption.toFixed(3),
            };
          }
        );
        generateCSV(columns, u);

        break;
      case "Download as XLS":
       
        break;

      case "Download as PDF":
        let updatedConsumption = consumtionData?.map(
          ({ hour, firstTimeStamp, consumption, lastTimeStamp }: any) => {
            return {
              Hour: hour + ":00",
              "First Timestamp": firstTimeStamp,
              "Last Timestamp": lastTimeStamp,
              Consumption: consumption.toFixed(3),
            };
          }
        );

        if (updatedConsumption && chartRef) {
          reportDataWithGraph(
            chartRef,
            updatedConsumption,
            devices?.deviceName,
            devices?.Customer?.name,
            "Consumption Summary Report",
            "Realtime - Last 24 Hour | IST"
          );
        } else {
        }
        return;
      default:
        return; // Unknown export option
    }
  };

  const flowrate =
    last24HoursData.length > 0
      ? last24HoursData.reverse().map(({ timestamp, flow }: any) => ({
          timestamp,
          value: flow,
        }))
      : [];
  const linechartflow: any = [
    {
      name: "Flow",
      color: "#1f79a7",
      data: flowrate,
    },
  ];

  const handleExportCsv = () => {
    generateCSV(
      ["Timestamp", "Flow", "Flow_Unit", "Totalizer", "Unit"],
      last24HoursData
    );
  };

  const handleExportBarChart1 = async (exportOption: string) => {
    switch (exportOption) {
      case "Download as CSV":
        generateCSV1(flowrate);
        break;
      
      case "Download as PDF":
        if (flowrate && chartRef1) {
          reportDataWithGraph(
            chartRef1,
            flowrate,
            devices?.deviceName,
            devices?.Customer?.name,
            "Water Quality Summary",
            "Last 24 Hours Consumption Data"
          );
        } else {
        }
        return;
      default:
        return; // Unknown export option
    }
  };

  return (
    <div className="flex flex-col md:flex-row">
      <div className="w-full md:w-1/2 h-full p-2">
        <div className="h-1/2 overflow-auto  flex border border-solid border-gray-300 rounded p-4">
          <div className="w-1/5 h-[302px]">
            <DynamicCard
              customComponent={
                <SignalStrength percentage={dashboardSts.rssi} />
              }
              width="100%"
              height="100%"
            />
          </div>
          <div className="flex-1 ml-2">
            <div className="flex">
              <DynamicCard
                cardTitle="Flow"
                cardValue={dashboardSts.flow as any}
                textColor="text-blue-400"
                width="70%"
              />
              <DynamicCard unit="m³/hr" textColor="text-blue-400" width="30%" />
            </div>
            <div className="flex">
              <DynamicCard
                cardTitle="Totalizer"
                cardValue={dashboardSts.totalizer}
                textColor="text-purple-400"
                width="70%"
              />
              <DynamicCard unit="m³" textColor="text-purple-400" width="30%" />
            </div>
          </div>
        </div>
        <div className="h-2/6 overflow-auto shadow-xl mt-4 z-11 border border-solid border-gray-300 rounded p-4">
          <div className="flex items-center mb-4">
            <p className="font-semibold text-lg text-slate-600">
              Last 24 Hours Data Table
            </p>
            <div className="ml-auto">
            <IconButton onClick={toggleFilterList}>
                  <FilterListIcon />
                </IconButton>
                <Dialog
                  open={filterTable}
                  onClose={() => {
                    setFilterTable(false);
                  }}
                >
                  <CheckBoxFilter
                    data={column}
                    onSelectedDataChange={handleSelectedDataChange}
                  />
                </Dialog>
              <IconButton onClick={handleExportCsv}>
                <ArrowCircleDownIcon />
              </IconButton>
            </div>
          </div>
          <TabularComponents
           headers={selectedHeaders}
            rows={last24HoursData}
            pagination={true}
            sortableColumns={["TIMESTAMP"]} 
          />
        </div>
      </div>

      <div className="w-full md:w-1/2 p-2">
        <div
          className="overflow-hidden border border-solid border-gray-300 rounded p-4"
          style={{
            boxShadow: "0 0 11px rgba(0, 0, 0, 0.1)",
            maxHeight: "600px",
            minHeight: "500px",
          }}
        >
          <div className="relative">
            {/* <h1 className="relative p-[0.2rem] h-fit w-[15rem]"> */}
            <p className="font-semibold text-lg text-slate-600">
              Last 24 Hours Consumption Data{" "}
            </p>

            {/* </h1> */}
            <div className="absolute top-0 right-0">
              {/* <IconButton> */}
                <LongMenu
                  options={[
                    "Download as CSV",
                    "Download as PDF",
                  ]}
                  onOptionClick={handleExportBarChart}
                  id={1}
                />
              {/* </IconButton> */}
            </div>
          </div>
          <div ref={chartRef}>
            <CustomBarChart data={consumtionData} />
          </div>
        </div>

        <div
          className=" overflow-auto mt-2 shadow-xl  border border-solid border-gray-300 rounded p-4"
          style={{ boxShadow: "0 0 11px rgba(0, 0, 0, 0.1)" }}
        >
          <div className="relative">
            <h1 className="relative p-[0.2rem] h-fit w-[15rem]">
              <p className="font-semibold text-lg text-slate-600">
                Last 24 Hours Flow Data{" "}
              </p>
            </h1>
            <div className="absolute top-0 right-0">
              <IconButton>
                <LongMenu
                  options={[
                    "Download as CSV",
                    "Download as PDF",
                  ]}
                  onOptionClick={handleExportBarChart1}
                  id={1}
                />
              </IconButton>
            </div>
          </div>
          <div ref={chartRef1} className="mt-7 mb-11">
            <LineChart yAxisName="Flow meter (m³)" data={linechartflow} stroke={true} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardWaterTotalizerJoin;
