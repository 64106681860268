import { useEffect, useState, useMemo } from "react";
import Table from "@mui/material/Table";
import Box from "@mui/material/Box";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import LongMenu from "../../../utils/longMenu/LongMenu";
import { useDeviceState } from "../../../app-context/device-context/DeviceState";
import { EnhancedTableToolbar } from "./EnhancedTableToolbar";
import { EnhancedTableHead } from "./EnhancedTableHeader";
import {
  getDevicePageClick,
  setDevice,
} from "../../../app-context/device-context/DeviceAction";
import { Device, DeviceApiResponse } from "../../../types/DeviceTypes";
import AddDeviceFormModal from "./AddDeviceFormModal";
import { http } from "../../../helper/http";
import { ConfirmModal } from "../../../utils/alertui/ConfirmModal";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ProtectedComponent from "../../../utils/protected-route/ProtectedComponent";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Switch,
  TextField,
} from "@mui/material";
import MqttPublish from "./MqttPublish";
import { useUserState } from "../../../app-context/auth-context/AuthState";
import HwraDetails from "./HwraDetails";
import { setAllCustomer } from "../../../app-context/customer-context/CustomerAction";
import { useCustomerState } from "../../../app-context/customer-context/CustomerState";
import {
  fetchAllCustomer,
  fetchAllTemplate,
} from "../../../common-function/CommonFunction";
import { setAllTemplate } from "../../../app-context/template-context/TemplateAction";
import { useTemplateState } from "../../../app-context/template-context/TemplateState";
import DeviceTable1 from "./device-table1/DeviceTable1";
import Rader from "./Rader";

const baseUrl = process.env.REACT_APP_BASE_URL;
export const Devices = (search: any) => {
  const { devices, dispatch } = useDeviceState();
  const [page] = useState(0);
  const [updatedRows, setUpdatedRows] = useState<Device[]>([]);
  const [editModal, setEditModal] = useState(false);
  const [deviceData, setDeviceData] = useState<Device[]>([]);
  const [rowsPerPage] = useState(10);
  const [viewModal, setViewModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [deleteId, setDeleteId] = useState<number | null>(null);
  const [publishDeviceId, setPublishDeviceId]: any = useState(null);
  const { users }: any = useUserState();
  const [hwraDeviceId, setHwraDeviceId]: any = useState(null);
  const [showRader, setShowRader]: any = useState(null);
  const [deviceIdRader, setDeviceIdRader]: any = useState(null);
  const [combinedDevices, setCombinedDevices] = useState<any[]>([]);
  const [show420ConfigModal, setShow420ConfigModal] = useState(false);
  const [showConfigBattery, setShowConfigBattery] = useState(false);
  const [configData, setConfigData] = useState<any>({});
  const [configBatteyData, setConfigBatteryData] = useState<any>({});
  const [search1, setSearch1] = useState("");
  const [raderDeviceId, setRaderDeviceId] = useState<any>(null);

  const navigate = useNavigate();
  const { dispatch: customerDispatch } = useCustomerState();
  const { dispatch: templateDispatch } = useTemplateState();

  const [assignDevice, setAssignDevice] = useState<Device[]>([]);

  const toggleDeviceForm = () => {
    setEditModal((prevVal) => !prevVal);
  };

  const toggleViewForm = () => {
    setViewModal(!viewModal);
  };

  useEffect(() => {
    setUpdatedRows(devices.devices);

    fetchData();
  }, [devices, devices.devices]);

  const fetchData = async () => {
    try {
      const resp: any = await http(
        `${baseUrl}/assign-device?userId=${users.user.id}`
      );

      setAssignDevice(resp.devices.devices);
      const fetchedDevices = resp.devices.devices.map(
        (item: any) => item.Device
      );
      setUpdatedRows([...devices.devices, ...fetchedDevices]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleConfigModalSave = async () => {
    // Handle save action for 420 Config modal
    try {
      const res = (await http(
        baseUrl + `/config-420?id=${configData.id}`,
        "PUT",
        configData
      )) as any;
      setShow420ConfigModal(false);
      toast.success("Updated successfully");
    } catch (error) {
      toast.error("Unexpected error update");
    }
  };

  const handleConfigBatteryModalSave = async () => {
    // Handle save action for Config Battery modal
    try {
      const res = (await http(
        baseUrl + `/config-battery?id=${configBatteyData.id}`,
        "PUT",
        configBatteyData
      )) as any;
      setShowConfigBattery(false);
      toast.success("Updated successfully");
    } catch (err) {
      toast.error("Update failed");
    }
  };

  const handleMenuOptionClick = async (option: string, id: number) => {
    const device = devices.devices.find((device: any) => device.id === id);
    // console.log("id", id);

    try {
      let _device: any;
      switch (option) {
        case "Delete":
          setShowConfirmModal(true);
          setDeleteId(id);
          break;
        case "Edit":
          const resp = (await fetchAllCustomer()) as any;
          setAllCustomer(customerDispatch, resp.customers);
          const respTemplate = (await fetchAllTemplate()) as any;
          setAllTemplate(templateDispatch, respTemplate.template);
          const editResp = (await http(
            baseUrl + `/device?id=${id}`
          )) as DeviceApiResponse;
          setDeviceData([editResp.devices]);
          toggleDeviceForm();
          break;
        case "View":
          const viewResp = (await http(
            baseUrl + `/device?id=${id}`
          )) as DeviceApiResponse;
          setDeviceData([viewResp.devices]);
          toggleViewForm();
          break;
        case "Data":
          navigate("/data", { state: { deviceId: id, deviceState: device } });
          break;
        case "ModbusConfig":
          navigate("/modbusConfig", { state: { deviceId: id } });
          break;
        case "Publish":
          _device = devices.devices.find((d: any) => d.id === id);
          setPublishDeviceId(_device.deviceId);
          break;
        case "HWRA":
          setHwraDeviceId(id);
          break;
        case "CSV Upload":
          navigate("csv-upload/" + id);
          break;
        case "420 Config":
          setShow420ConfigModal(true);
          const res = (await http(
            baseUrl + `/config-420?deviceId=${id}`
          )) as any;
          setConfigData(res.config420);
          break;
        case "Config Battery":
          setShowConfigBattery(true);
          const response = (await http(
            baseUrl + `/config-battery?deviceId=${id}`
          )) as any;
          setConfigBatteryData(response.configBattery);
          break;
        case "Edit CustomTag":
          const template: any = devices.devices.filter((d: any) => d.id === id);

          navigate("/edit-tags/" + id + "/" + template[0].template);
          break;
        case "Radar":
          openRaderDialog(id)
          break;
        default:
          break;
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleConfirmDelete = async () => {
    if (deleteId !== null) {
      try {
        const deleteResp = (await http(
          baseUrl + `/device?id=${deleteId}`,
          "DELETE"
        )) as DeviceApiResponse;
        toast.success(deleteResp.message);
        if (devices.devices.length === 1) {
          setDevice(dispatch, 1);
        } else {
          setDevice(dispatch, devices.currentPage);
        }
      } catch (err) {
        console.error(err);
      }
      setShowConfirmModal(false);
    }
  };

  const openRaderDialog = (id:any) => {
    setRaderDeviceId(id);
    setShowRader(true);
  };

  const closeRaderDialog = () => {
    setShowRader(false);
    setRaderDeviceId(null);
  };

  const handlePageChange = async (newPage: number) => {
    const direction = newPage > devices.currentPage - 1 ? "next" : "previous";
    const page = +devices.currentPage;
    try {
      const d = direction === "next" ? page + 1 : page - 1;
      // const resp = (await http(
      //   baseUrl + `/device?page=${direction === "next" ? page + 1 : page - 1}`
      // )) as DeviceApiResponse;
      // getDevicePageClick(dispatch, resp.devices);
      setDevice(dispatch, d, search1);
    } catch (err) {
      console.error(err);
    }
  };

  //   useEffect(() => {
  //     if(devices.devices.length ===0){
  //       setDevice(dispatch, 1);

  //     }
  // }, []);


  const emptyRows = 10 - updatedRows.length;
  const visibleRows = useMemo(
    () =>
      updatedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [page, rowsPerPage, updatedRows]
  );

  // console.log("visiible rows", visibleRows);
  

  // console.log("visiblke rowa", visibleRows);

  // console.log("device ID from device", raderDeviceId);

  useEffect(() => {
    if (search1 === "") {
      setDevice(dispatch, 1);
    }
    let timer = setTimeout((): void => {
      if (search1 === "") return;
      setDevice(dispatch, 1, search1);
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [search1]);

  return (
    <>
      <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Paper sx={{ width: "99%" }}>
          <ProtectedComponent>
            {/* <EnhancedTableToolbar /> */}
            <EnhancedTableToolbar search={search1} setSearch={setSearch1} />
            <div className="h-[60vh] overflow-y-auto">
              <TableContainer>
                <ProtectedComponent>
                  {showConfirmModal && (
                    <ConfirmModal
                      title="Confirmation"
                      message="Are you sure you want to delete?"
                      onConfirm={handleConfirmDelete}
                      onCancel={() => {
                        setShowConfirmModal(false);
                      }}
                      isVisible={showConfirmModal}
                    />
                  )}
                </ProtectedComponent>

                <ProtectedComponent>
                  {editModal && (
                    <AddDeviceFormModal
                      uniqueId="EDIT"
                      data={deviceData}
                      toggleForm={editModal}
                      toggleFormFun={toggleDeviceForm}
                    />
                  )}
                </ProtectedComponent>

                <Table
                  className="min-w-750 bg-white"
                  aria-labelledby="tableTitle"
                  size="medium"
                >
                  <EnhancedTableHead />

                  <TableBody>
                    {visibleRows.map((row: Device) => (
                      <TableRow
                        key={row?.id || null}
                        // hover
                        // className="cursor-pointer transition-all hover:bg-gray-100"
                      >
                        {row && (
                          <>
                            {/* {console.log("row", row)} */}
                            <TableCell
                              className="text-center"
                              style={{ textAlign: "center", padding: "10px" }}
                              // onClick={ () => navigate("/data", { state: { deviceId: row.id } }) }
                            >
                              {row.deviceId}
                            </TableCell>

                            <TableCell
                              className="text-center"
                              style={{ textAlign: "center" }}
                            >
                              {row.deviceName}
                            </TableCell>

                            <TableCell
                              className="text-center"
                              style={{ textAlign: "center" }}
                            >
                              {row.deviceAlias}
                            </TableCell>

                            <ProtectedComponent>
                              <TableCell
                                className="text-center"
                                style={{ textAlign: "center" }}
                              >
                                {/* {row?.customerName || ""} */}
                                {row?.Customer?.name || "NA"}
                              </TableCell>
                            </ProtectedComponent>

                            <TableCell
                              className="text-center"
                              style={{ textAlign: "center" }}
                            >
                              {row.latitude},{row.longitude}
                            </TableCell>

                            <TableCell
                              className="text-center"
                              style={{ textAlign: "center" }}
                            >
                              <span
                                className={`text-${
                                  row.status ? "green-600" : "red-600"
                                } 
                            ${row.status ? "bg-green-200" : "bg-red-100"} 
                            rounded-md 
                            ${row.status ? "px-4 py-2" : "px-3 py-2"} `}
                              >
                                {row.status ? "Active" : "Inactive"}
                              </span>
                            </TableCell>
                            <ProtectedComponent>
                              <TableCell
                                className="text-center"
                                style={{ textAlign: "center" }}
                              >
                                <LongMenu
                                  options={[
                                    "Edit",
                                    "Delete",
                                    row.customTag ? "Edit CustomTag" : "",
                                    row.template === 32 ||
                                    row.template === 35 ||
                                    row.template === 37 ||
                                    row.template === 47
                                      ? "420 Config"
                                      : "",
                                    row.batteryConfigStatus === true
                                      ? "Config Battery"
                                      : "",
                                    "Data",
                                    "Publish",
                                    "HWRA",
                                    "CSV Upload",
                                    "ModbusConfig",
                                    row.template === 35 || row.template === 29
                                      ? "Radar"
                                      : "",
                                  ]}
                                  onOptionClick={handleMenuOptionClick}
                                  id={row.id}
                                />
                              </TableCell>
                            </ProtectedComponent>

                            <ProtectedComponent roleids={[2]}>
                              <TableCell
                                className="text-center"
                                style={{ textAlign: "center" }}
                              >
                                <LongMenu
                                  // options={
                                  //   users.user.id == 17
                                  //     ? ["Data", "Publish"]
                                  //     : ["Data" ,"ModbusConfig"]
                                  // }

                                  options={[
                                    "Data",
                                    row.modbusConfig === false
                                      ? ""
                                      : "ModbusConfig",
                                  ]}
                                  onOptionClick={handleMenuOptionClick}
                                  id={row.id}
                                />
                              </TableCell>
                            </ProtectedComponent>
                          </>
                        )}
                      </TableRow>
                    ))}

                    {/* {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: 45 * emptyRows,
                      }}
                    >
                      <TableCell colSpan={4} />
                    </TableRow>
                  )} */}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <TablePagination
              component="div"
              rowsPerPageOptions={[]}
              count={devices?.totalItems || 0}
              rowsPerPage={10}
              page={Math.max(
                0,
                Math.min(devices?.currentPage - 1, devices?.totalPages - 1)
              )}
              onPageChange={(event, newPage) => handlePageChange(newPage)}
              className="bg-gray-100"
            />
          </ProtectedComponent>
        </Paper>
      </Box>
      <ProtectedComponent roleids={[2]}>
        <DeviceTable1 />
      </ProtectedComponent>

      <Dialog open={!!publishDeviceId} onClose={() => setPublishDeviceId(null)}>
        <DialogTitle>Publish Message</DialogTitle>
        <DialogContent>
          <MqttPublish deviceId={publishDeviceId} />
        </DialogContent>
      </Dialog>

      <Dialog open={hwraDeviceId} onClose={() => setHwraDeviceId(null)}>
        <DialogTitle>HWRA Details</DialogTitle>
        <DialogContent>
          <HwraDetails
            deviceId={hwraDeviceId}
            onClose={() => setHwraDeviceId(null)}
          />
        </DialogContent>
      </Dialog>

      <Dialog open={showRader} onClose={closeRaderDialog}>
        <DialogTitle>Radar Details</DialogTitle>
        <DialogContent>
          <Rader deviceId={raderDeviceId} onClose={closeRaderDialog} />
        </DialogContent>
      </Dialog>

      <Dialog
        open={show420ConfigModal}
        onClose={() => setShow420ConfigModal(false)}
      >
        <DialogTitle>420 Config</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="minRange"
            label="Min Range"
            type="text"
            fullWidth
            variant="standard"
            value={configData.minRange}
            onChange={(e) =>
              setConfigData({ ...configData, minRange: e.target.value })
            }
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            margin="dense"
            name="maxRange"
            label="Max Range"
            type="text"
            fullWidth
            variant="standard"
            value={configData.maxRange}
            onChange={(e) =>
              setConfigData({ ...configData, maxRange: e.target.value })
            }
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            margin="dense"
            name="startReading"
            label="Start Reading"
            type="text"
            fullWidth
            variant="standard"
            value={configData.startReading}
            onChange={(e) =>
              setConfigData({ ...configData, startReading: e.target.value })
            }
            InputLabelProps={{ shrink: true }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShow420ConfigModal(false)}>Cancel</Button>
          <Button onClick={() => handleConfigModalSave()}>Update</Button>
        </DialogActions>
      </Dialog>
      {/* Config Battery popup */}
      <Dialog
        open={showConfigBattery}
        onClose={() => setShowConfigBattery(false)}
      >
        <DialogTitle>Config Battery Range</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="minRange"
            label="Min Range"
            type="text"
            fullWidth
            variant="standard"
            value={configBatteyData.minRangeBatteryValue}
            onChange={(e) =>
              setConfigBatteryData({
                ...configBatteyData,
                minRangeBatteryValue: e.target.value,
              })
            }
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            margin="dense"
            name="maxRange"
            label="Max Range"
            type="text"
            fullWidth
            variant="standard"
            value={configBatteyData.maxRangeBatteryValue}
            onChange={(e) =>
              setConfigBatteryData({
                ...configBatteyData,
                maxRangeBatteryValue: e.target.value,
              })
            }
            InputLabelProps={{ shrink: true }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowConfigBattery(false)}>Cancel</Button>
          <Button onClick={() => handleConfigBatteryModalSave()}>Update</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
