import React from "react";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

interface Props {
  data: number[];
  title: string;
}

const ApexChart: React.FC<Props> = ({ data, title }) => {
  // Constructing series with dynamic x-axis values
  const series = [
    {
      data: data.map((value, index) => ({ x: index + 1, y: value })),
    },
  ];

  const options: ApexOptions = {
    chart: {
      id: "chart2",
      type: "line",
      height: 230,
      toolbar: {
        autoSelected: "pan",
        show: true,
      },
    },
    title: {
      text: title,
      align: "center",
      margin: 20,
      style: {
        fontSize: "15px",
        fontWeight: "bold",
      },
    },
    colors: ["#4d6fd1"],
    stroke: {
      width: 3,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      opacity: 1,
    },
    markers: {
      size: 0,
    },
    xaxis: {
      labels: {
        show: false, // Hide x-axis labels
      },
      axisBorder: {
        show: false, // Hide x-axis border
      },
      axisTicks: {
        show: false, // Hide x-axis ticks
      },
    },
    yaxis: {
      labels: {
        formatter: (value: number) => value.toString(), // Restrict to 2 decimal places
      },
    },
  };

  return (
    <div id="wrapper">
      <div id="chart-line2">
        <ReactApexChart
          options={options}
          series={series}
          type="line"
          height={230}
        />
      </div>
    </div>
  );
};

export default ApexChart;
