import React from "react";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

interface BarData {
  name: string;
  color: string;
  values: number[];
  postfix?: string;
}

interface BarChartProps {
  data: BarData[];
  xAxisCategories: string[];
  yAxisName?: string;
  xAxisName?: string;
}

const BarChart: React.FC<BarChartProps> = ({
  data,
  xAxisCategories,
  yAxisName,
  xAxisName,
}) => {

  const options: ApexOptions = {
    chart: {
      type: "bar",
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "80%",
      },
    },
    dataLabels: {
      enabled: false,
      offsetY: -20,
      formatter: function (value: any) {
        const numericValue =
          typeof value === "number" ? value : parseFloat(value);
        return numericValue.toFixed(2);
      },
    },
    xaxis: {
      categories: xAxisCategories,
      title: {
        text: xAxisName,
      },
      labels: {
        formatter: function(value) {
          if (typeof value === 'string' && value.includes(' ')) {
            // Extract only the time part from the datetime string
            return value.split(' ')[1];
          }
          // If it's not a string or doesn't contain a space, return the original value
          return value;
        }
      }
    },
    yaxis: {
      title: {
        text: yAxisName || "",
      },
      labels: {
        formatter: function (value) {
          return value.toFixed(0);
        },
      },
    },
    tooltip: {
      enabled: true,
      y: {
        formatter: function (
          value: number,
          { seriesIndex }: { seriesIndex: number }
        ) {
          const postfix = data[seriesIndex].postfix;
          return `${value.toFixed(3)} ${postfix ? postfix : ''}`;
        },
      },
    },
    colors: data.map((bar) => bar.color),
    series: data.map((bar) => ({ name: bar.name, data: bar.values })),
    grid: {
      show: false,
    },
  };

  return (
    <ReactApexChart
      options={options}
      series={options.series}
      type="bar"
      height={350}
    />
  );
};

export default BarChart;

// const data = [
//   {
//     timestamp: "2024-05-05T08:00:00",
//     value1: 10,
//     value2: 20,
//   },
//   {
//     timestamp: "2024-05-05T09:00:00",
//     value1: 20,
//     value2: 25,
//   },
//   {
//     timestamp: "2024-05-05T10:00:00",
//     value1: 15,
//     value2: 30,
//   },
//   {
//     timestamp: "2024-05-05T11:00:00",
//     value1: 30,
//     value2: 35,
//   },
// ];

// // Transform data into BarData format
// const barData = [
//   {
//     name: "Bar 1",
//     color: "#2979FF",
//     values: data.map((item) => item.value1),
//   },
//   {
//     name: "Bar 2",
//     color: "#FFC107",
//     values: data.map((item) => item.value2),
//   },
// ];

// Extract x-axis categories from timestamps
// const xAxisCategories = data.map((item) => item.timestamp);

//   <BarChart
//   data={barData}
//   xAxisCategories={xAxisCategories}
//   yAxisName="Value"
//   xAxisName="Timestamp"
// />
