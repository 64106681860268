import React, { useEffect, useState } from 'react'
import { http } from '../../../helper/http';
import MobileTowerIcon from '../../../components/apex-chart/HeatMapChart';
import { formatTimestamp } from '../../../common-function/CommonFunction';
const baseUrl = process.env.REACT_APP_BASE_URL;

// Define the type for the data object
type DeviceData = {
  [key: string]: string | undefined;
};

const Testakshay = ({ deviceId, devices, selectedDevice}: any) => {
  const [data, setData] = useState<DeviceData>({});
  const [dashboardSts, setDashboardSts] = useState<any>([]);
  // console.log('dashboardSts',dashboardSts)
  useEffect(()=>{
        fetchData();
    },[selectedDevice])

    console.log("data",data);

    const fetchData = async () => {
        let response: any = null;
        try {
          response = await http(
            baseUrl + `/device-data-log?deviceId=${selectedDevice}?last24=last24`
          );
    console.log("response",response);
          const _data = response
            .map((d: any) => {
              return {
                timestamp: formatTimestamp(d.timeStamp),
                "FT-101": d["FT-101"],
                "TDS-101": d["TDS-101"],
                "PH-101": d["PH-101"],
                "PT-101": d["PT-101"],
                "PT-103":  d["PT-103"],
                "PH-103": d["PH-103"],
                "FT-102": d["FT-102"],
                "TDS 102": d["TDS 102"],
                "FT-104": d["FT-104"],
                "TDS 104": d["TDS 104"],
                "PH-102": d["PH-102"],
                "PT-102": d["PT-102"],
                "FT-103": d["FT-103"],
                "TDS 103": d["TDS 103"],
                "FTKL-101": d["FTKL-101"],
                "FTKL-102": d["FTKL-102"],
                "FTKL-104": d["FTKL-104"],
                "FTKL-103": d["FTKL-103"],
                
                // rssi : d["rssi"],
              };
            })
            setDashboardSts(_data.length ? _data[0] : "");
            setData(_data[0]);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
  return (
    <>
      <section>
        <p className=" flex ">
          <MobileTowerIcon levels={dashboardSts.rssi || 0} time={dashboardSts?.timestamp} />
            {"    Last Data Received at  " + dashboardSts?.timestamp ||
                "  YYYY-MM-DD HH:MM:SS"}
            </p>
          </section>
    <div className="mt-4 bg-white shadow-md rounded-md p-6 w-full max-w-[100%] h-auto ">
      
      <div className='w-[100%]  '>
      <div className='flex '>
       <div>
        <h2 className="bg-gray-600 text-white px-2 rounded-md font-inter font-medium text-sm leading-[29.05px] text-center w-[58px] h-[29px] mt-20 border">
        ETP
        </h2>
       </div>
      
      <div className="w-[99%] ml-12 container  ">
    {/* Horizontal Line */}
    <div className="relative h-2 bg-green-700 w-[91.7%] ml-5">
      <div className="absolute top-[2px] inset-0 border-t-2 border-dashed border-white"></div>
    </div>

       <div className="flex">
      {/* First Vertical Line */}
      <div className=' w-[90%]' >
        <div className="w-2 bg-green-700 h-[101px] ml-5">
          <div className="h-full border-l-2 border-dashed border-white ml-[3px]"></div>
        </div>
        

        {/*1st line  */}
        <div className='flex'>
            <div className=''>
              <img
                src="https://s3-alpha-sig.figma.com/img/7256/33af/4f896415d39c4d6f943d86c30b934ac6?Expires=1734307200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=JS882LPNvmdg-z8qY4SvLbAgiD2XZzhWAb0aR92iHedLq-Jwz8P6dg5OYVRbPZR9WhpVAOzGAAjZnnbaCw2mh8Xl4mThFH0pVzdJ5PGJzIWz52xYLWR~91rolahaBRZcFaQE-RSygpCmf1CClMFHz~tZXZDOUOITq5O5YDtBF24okzN30ro7Q15XD43LH5qzSwUJMcjuDWRSJoQoT4ue4EwOhclgqMKS7PzchsM6nl6WLNTSh~2AYUqqaGoI2qQDWaRL~VHp7QPRNWI3kF11AJDNJakoOdFi6eHoEBwH5qmCOmvGU8shM~eUFteJOI8XpBUKTUHP2yxikGdj1w99kQ__"
                alt="Tank Type"
                className="h-16 imgs"
              />
              <div
              className="border mt-2 h-[35px] "
              style={{ borderRadius: "10px" }}>
                <h1 className="text-[8px] text-center leading-[9.68px] mt-1">T-101</h1>
                <h1 className="text-[8px] text-center leading-[9.68px] ">NT Feed Tank</h1>
              </div>
          </div>

            {/* Horizontal Line */}
          <div className=" relative h-2 bg-green-700  w-[25px] mt-[50px]">
            <div className="absolute top-[2px] inset-0 border-t-2 border-dashed border-white"></div>
          </div>
          {/* feed pump */}
            <div className=''>
            <img
              src="https://s3-alpha-sig.figma.com/img/41c7/0e25/da7f6924961e22d2841c6658d6ee179a?Expires=1734307200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=lzegHo~q540N2uiwuUgbE5xtpaobbscjje69j0TL4rrH4RsUzq8w5if-cw~VJie1ZuIZdL54nUUfnv3G6iDtAIsqRO~rN9ubH1f46zOrRFMl1AxKErMNsQ7TUQEWKLocCNb9RV6fX~L3M0I6t0Oaxp-WiTUd1TY-5jjaTeIzKhP1zeRGa6Zq2s~Y9EcMOSMzmBU8Qnqi4RZnQbhe8Pi6gvIXQDCCjGoWz-IONuXbpOWmPuADPMl4CLJPQi7tdKOJrCMkBjb7Oufm2FdtsnqGyGkpZ-el-TlXFmjITX3iqqXtEix3dqAEETBhIopYsffJ8Qh1lc8-drVOdyHCb81sxw__"
                alt="Tank Type"
                className="h-20 mt-[12px] imgs"
            />
            <div
              className="border h-[35px]"
              style={{ borderRadius: "10px"}}>
                <h1 className="text-[8px] text-center leading-[9.68px] mt-1">P-101 NT1</h1>
                <h1 className="text-[8px] text-center leading-[9.68px]">Feed Pump</h1>
              </div>
            </div>
            <div className='flex'>
              {/* Horizontal Line */}
            <div className=" relative h-2 bg-green-700  w-[20px] mt-[50px]">
              <div className="absolute top-[2px] inset-0 border-t-2 border-dashed border-white"></div>
            </div>

            {/* Vertical Line */}
            <div className="w-2 bg-green-700  h-[66px] mt-[-8px]">
            <div className="h-full border-l-2 border-dashed border-white ml-[3px] "></div>
            </div>

            {/* Horizontal Line */}
            <div className='mt-[-1%] '>  
            <div className='flex'>
            <div>
            <div className=" relative h-2 bg-green-700  w-[26px]">
              <div className="absolute top-[2px] inset-0 border-t-2 border-dashed border-white"></div>
            </div>
            </div>
            {/* flow meter */}
            <div className=' mt-[-63px]'>
                <div
                  className="h-[35px] border"
                  style={{ borderRadius: "10px", backgroundColor:"#008000"}}>
                  <h1 className="text-[8px] text-white text-center leading-[9.68px] mt-1">{data["FT-101"]} kl/hr </h1>
                  <h1 className="text-[8px] text-white text-center leading-[9.68px] ml-1">{data["FTKL-101"]} KL</h1>
                </div>
                <img
                src="https://s3-alpha-sig.figma.com/img/3f9a/6e99/793566f48b989e7204cd294a1176cecf?Expires=1734307200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=g7FUElvVavb9v41IeLyLVNVK4hPhPcAbDwyFU65LP4iClJ4bY8eHDbAA9MdN~rWBHww0g0W7q92xFHL~WYoMfSjSEYPQQ58QccaGYN~tyW6LbptlYZB4kei50EPFk2hz85AsODrmArv-S6ELrNq-reK4t7cSgkjxjie-LgJA56YxiyjJHyenOwATkeGOP9Fa9~3Zk5Baesc5pGkeuUvBq972at-OjqIH1eRaG8R-sRjTX6~ZabAGKxy5sPgpccGcQDNNq4PsGh3LZRA4FSsWtvqzUS8oNwxc0H-uxuLZKygFVnycRhWjddmCpGaWGQul4sJkOEq8sOnhh3sC6Zxh6g__"
                alt="Tank Type"
                className="h-16  imgs"
                />
                <div
                className="h-[35px]"
                style={{ borderRadius: "10px"}}>
                <h1 className="text-[8px] text-center leading-[9.68px] mt-1">FT-101
                </h1>
                <h1 className="text-[8px] text-center leading-[9.68px]">Flow Meter</h1>
                </div>

            </div>

            <div>
              {/* Horizontal Line */}
              <div className=" relative h-2 bg-green-700  w-[80px]">
              <div className="absolute top-[2px] inset-0 border-t-2 border-dashed border-white"></div>
              </div>
          
              {/* Vertical Line */}
              <div>
              <div className="w-2 bg-green-700  h-[59px] m-auto">
                <div className="h-full border-l-2 border-dashed border-white ml-[3px] "></div>
              </div>
              <div className="w-[70px] h-[40px] m-auto"
                  style={{backgroundColor:"#D9D9D9", borderRadius:"10px"}}>
                  <h1 className="text-[10px] text-center pt-1">
                    AS Dosing +</h1>
                    <h1 className="text-[10px] text-center leading-[8px]">HCL Dosing</h1>
                  </div>
              </div>
            </div> 
            {/* micron meter */}
            <div className='mt-[-60px]'>
              <div
                className="  h-[30px] border"
                style={{ borderRadius: "10px"}}>
                <h1 className="text-[8px] text-center leading-[9px] mt-1">MF-101 </h1>
                <h1 className="text-[8px] text-center leading-[9px]">5 Micron Filter</h1>
              </div>

              <img
                  src="https://s3-alpha-sig.figma.com/img/be7e/7ce8/f67bf3b1ecc10637358adc5535c29aca?Expires=1734307200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=esdQWbzU~KQN9Ys5VDVQOt1dL9FTrzBLSSyj46LANpXGvAzvmUZZMDAUvhu-i55Gj~XKbS22ppqFsgyCKPX4h5ohSXjCM1sCcj7eb5gxVDNkFTdUDWHmKc7cLm1lgw6OqN3cw1rAbf9KmIalLtZosquOCca2UMT~jKaXa5LrpD7kaEPCfoIVIZy6XSwMax2NIrEQ6G3j6mUmWB4US9iEFJnF28kTYikytjHdSmyxXDpNpPVXGzvh63VLexE-dMlu6QASF2w-kKAfV1wfR1Bxpsrg48cEBlM8bP8XMH7lFdaurE8pda-prjP~nnYp5QgJOnxTMUjZ7vAX0VLLn12Dyw__"
                  alt="Tank Type"
                  className="h-16 imgs mt-2"
            />
            </div>

          {/* Horizontal Line */}
            <div>
            <div className=" relative h-2 bg-green-700  w-[40px]">
              <div className="absolute top-[2px] inset-0 border-t-2 border-dashed border-white"></div>
            </div>
            </div>
            {/* tds */}
            <div className='mt-[-64px]'>
              <div
                  className="h-[20px] border"
                  style={{ borderRadius: "5px", backgroundColor:"#008000"}}>
                  <h1 className="text-[10px] text-white text-center leading-[9.68px] mt-1">{data["TDS-101"]} mg/L</h1>
              </div>
              <div className="relative inline-block my-2">
  <img
    src="https://s3-alpha-sig.figma.com/img/27bd/d695/6ec0b351cd508c8027f29d4795b6d5c1?Expires=1734307200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=gAEhkcDtcKghR1RLvWXzJ1yxBQUDyILfNTa98AgpLAN0Upmfdh1ZIY~3V08fhgIh4sCOD~Cmsw8xeUXB4FPmha6itySYwQHc7tgT4iJRp2v4v0Cx3hHZ3IQ-lI7yjcTCe9qizoaeF3K1qWN2P-gIQcbofXEXXoYbntCSEIFfY~ZRbfecVBwN7~QHNRrI3sRjyvZT3rXFlwbbh5ZWcIW4if5zziCjSVS64p6CqhF6lPcizSGSaNrYMTC8zy7zzaLth2PW1vmkKSLWAdZ-~OwzHGfyTf1f1l-JdIeI2psCbPuzEbZzPo5pKGNr3X1~2qMJYdVzFFCVoJGaWtKOrUBlJA__"
    alt="Tank Type"
    className="h-16 imgs"
  />
  <span className="absolute bottom-[27px]  left-[13px] transform -translate-x-1/2 bg-red-400 text-[9px] font-semibold text-black shadow">
    TDS
  </span>
</div>

              <div
                  className=" h-[20px] border "
                  style={{ borderRadius: "5px"}}>
                  <h1 className="text-[10px] text-center leading-[9.68px] mt-1">TDS 101</h1>
                </div>
            </div>

              {/* Horizontal Line */}
              <div>
            <div className=" relative h-2 bg-green-700  w-[50px]">
              <div className="absolute top-[2px] inset-0 border-t-2 border-dashed border-white"></div>
            </div>
            </div>
            {/* ph sensor */}
            <div className='mt-[-64px] imgs'>
              <div
                  className="h-[20px] border"
                  style={{ borderRadius: "5px", backgroundColor:"#008000"}}>
                  <h1 className="text-[10px] text-white text-center leading-[9.68px] mt-1">PH {data["PH-101"]}</h1>
              </div>
              <img
                  src="https://s3-alpha-sig.figma.com/img/27bd/d695/6ec0b351cd508c8027f29d4795b6d5c1?Expires=1734307200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=gAEhkcDtcKghR1RLvWXzJ1yxBQUDyILfNTa98AgpLAN0Upmfdh1ZIY~3V08fhgIh4sCOD~Cmsw8xeUXB4FPmha6itySYwQHc7tgT4iJRp2v4v0Cx3hHZ3IQ-lI7yjcTCe9qizoaeF3K1qWN2P-gIQcbofXEXXoYbntCSEIFfY~ZRbfecVBwN7~QHNRrI3sRjyvZT3rXFlwbbh5ZWcIW4if5zziCjSVS64p6CqhF6lPcizSGSaNrYMTC8zy7zzaLth2PW1vmkKSLWAdZ-~OwzHGfyTf1f1l-JdIeI2psCbPuzEbZzPo5pKGNr3X1~2qMJYdVzFFCVoJGaWtKOrUBlJA__"
                  alt="Tank Type"
                  className="h-16 imgs my-2"
              />
              <div
                  className=" h-[40px] border "
                  style={{ borderRadius: "5px"}}>
                  <h1 className="text-[10px] text-center leading-[9.68px] mt-1">PH-101 </h1>
                  <h1 className="text-[10px] text-center leading-[9.68px] mt-1">PH Sensor</h1>
                </div>
            </div>


              {/* Horizontal Line */}
              <div>
            <div className=" relative h-2 bg-green-700  w-[40px]">
              <div className="absolute top-[2px] inset-0 border-t-2 border-dashed border-white"></div>
            </div>
            </div>
            {/* p-102 sensor */}
            <div className='mt-[-23px] w-[64px]'>
            <div className="h-auto py-1"
            style={{backgroundColor:"#D9D9D9", borderRadius:"10px"}}>
              <h1 className="text-[12px] text-center mt-1">P-102 </h1>
              <h1 className="text-[12px] text-center">NT 1 HPP</h1>
            </div>
            
              
            </div>

              {/* Horizontal Line */}
              <div>
            <div className=" relative h-2 bg-green-700  w-[40px]">
              <div className="absolute top-[2px] inset-0 border-t-2 border-dashed border-white"></div>
            </div>
            </div>
            {/* pressure meter */}
            <div className='mt-[-75px] imgs'>
              <div
                  className="h-[20px] border"
                  style={{ borderRadius: "5px", backgroundColor:"#008000"}}>
                  <h1 className="text-[10px] text-white text-center leading-[9.68px] mt-1">{data["PT-101"]} Bar</h1>
              </div>
              <img
                  src="https://s3-alpha-sig.figma.com/img/e86b/cbe8/e797e51e639d09e4baac933b2b91ea2b?Expires=1734307200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=MR2x1SFFZ286l0JXhH32GsUle7BY4DW6joYl9tg3CjU3SfQ1xsnjtBwtD8Giv7AdkeMyteOhZDhPrRGbkMsCTInYYLZxzuB1Q-q6kx3lLcr0k33y6X2JWYqOSVQl6dpSOiVT7Dd~51f8ideuBEB2XCY0nXNlt3~eNLIHJjy4S2GXlUBOi9Lynp-Rs-nFh3uocRVeCUHccgrDdZVMseyxVhoV2jrznpUMPjHR2mZ61brx2204Ka05e~Cj3M1-rjgrXUwtkf2ZTTGx~~dPGHcPeqm~zHqYk3jZSjMmbjvI1Ks5aUnXZqjbpBNNsc-O4QOPH~10vEkDSBg2pCWx220SAw__"
                  alt="Tank Type"
                  className="h-16 imgs my-2"
              />
              <div
                  className="h-[40px] border "
                  style={{ borderRadius: "5px"}}>
                  <h1 className="text-[8px] text-center leading-[9.68px] mt-1">Pt-101 </h1>
                  <h1 className="text-[8px] text-center leading-[9.68px] mt-1">Pressure meter</h1>
                </div>
            </div>

              {/* Horizontal Line */}
              <div>
            <div className=" relative h-2 bg-green-700  w-[50px]">
              <div className="absolute top-[2px] inset-0 border-t-2 border-dashed border-white"></div>
            </div>
            </div>
            {/* nf-102 sensor */}
            <div className='mt-[-20px] imgs '>
            <div className="h-auto py-1"
            style={{backgroundColor:"#D9D9D9", borderRadius:"10px"}}>
              <h1 className="text-[10px] text-center mt-1">NF-102 </h1>
              <h1 className="text-[10px] text-center">NT system</h1>
            </div>

              {/* Vertical Line tds */}
              <div className='' >
         
              <div className="w-2 h-[122px] m-auto" style={{backgroundColor:"#1D81CE"}}>
                <div className="h-full border-l-2 border-dashed border-white ml-[3px] "></div>
              </div>
             
             
              </div>
              
            </div>
            
            <div className='flex'>
              {/* Horizontal Line */}
            <div className=" relative h-2  w-[30px] " style={{backgroundColor:"#F97316"}}>
              <div className="absolute top-[2px] inset-0 border-t-2 border-dashed border-white"></div>
            </div >
                {/* Vertical Line */}
                {/* <div className=" w-2 h-[652px]" style={{backgroundColor:"#F97316"}}>
                  <div className="  h-full border-l-2 border-dashed border-white ml-[3px] "></div>
                </div> */}
            </div> 
            
      
            </div> 
            </div>
            </div>


        </div> 

          {/* 2nd line start */}
       <div className='flex w-[100%] ml-[-3%]'>
    
    <div className=' w-[100%]'>
      {/*feed Tanks and Systems */}
      <div className='flex '>
       {/* Vertical Line */}
       <div className="w-3 bg-blue-500 mt-[8.8%] h-[130px] vertical_green_pipe2_line">
        <div className="h-full border-l-2 border-dashed border-white ml-[3px] "></div>
       </div>
        {/* Horizontal Line */}
      <div className=" relative h-2 bg-blue-500  w-[30px] mt-[77px]">
       <div className="absolute top-[2px] inset-0 border-t-2 border-dashed border-white"></div>
      </div>
       {/* pressure meter */}
       <div className='imgs'>
         <div
             className="h-[20px] border"
             style={{ borderRadius: "5px", backgroundColor:"#008000"}}>
              <h1 className="text-[10px] text-white text-center leading-[9.68px] mt-1">{data["PT-103"]} Bar</h1>
         </div>
         <img
              src="https://s3-alpha-sig.figma.com/img/e86b/cbe8/e797e51e639d09e4baac933b2b91ea2b?Expires=1734307200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=MR2x1SFFZ286l0JXhH32GsUle7BY4DW6joYl9tg3CjU3SfQ1xsnjtBwtD8Giv7AdkeMyteOhZDhPrRGbkMsCTInYYLZxzuB1Q-q6kx3lLcr0k33y6X2JWYqOSVQl6dpSOiVT7Dd~51f8ideuBEB2XCY0nXNlt3~eNLIHJjy4S2GXlUBOi9Lynp-Rs-nFh3uocRVeCUHccgrDdZVMseyxVhoV2jrznpUMPjHR2mZ61brx2204Ka05e~Cj3M1-rjgrXUwtkf2ZTTGx~~dPGHcPeqm~zHqYk3jZSjMmbjvI1Ks5aUnXZqjbpBNNsc-O4QOPH~10vEkDSBg2pCWx220SAw__"
              alt="Tank Type"
              className="h-16 imgs my-2"
         />
          <div
             className="h-[40px] border w-[60px]"
             style={{ borderRadius: "5px"}}>
              <h1 className="text-[8px] text-center leading-[9.68px] mt-1">PT/HPS-103
              
              </h1>
              <h1 className="text-[8px] text-center leading-[9.68px] mt-1">Pressure meter</h1>
           </div>
        </div>

       {/* Horizontal Line */}
      <div className=" relative h-2 bg-blue-500  w-[50px] mt-[77px]">
       <div className="absolute top-[2px] inset-0 border-t-2 border-dashed border-white"></div>
      </div>
      {/* P-106 RO2HPP */}
      <div
          className="h-[50px] border mt-[58px] w-[60px]"
          style={{ borderRadius: "10px" ,backgroundColor:"#D9D9D9"}}>
           <h1 className="text-[10px] text-center leading-[9.68px] mt-2">P-106</h1>
           <h1 className="text-[10px] text-center leading-[20px]">RO2HPP</h1>
     </div>
        
       <div className='flex'>
         {/* Horizontal Line */}
       <div className=" relative h-2 bg-blue-500  w-[50px] mt-[77px]">
         <div className="absolute top-[2px] inset-0 border-t-2 border-dashed border-white"></div>
       </div>

     

       {/* Horizontal Line */}
       <div className='mt-[77px] '>  
        <div className='flex'>
        
       {/* ph meter */}
       <div className=' imgs mt-[-64px]'>
           <div
             className="mb-[4px] w-[60px] border"
             style={{ borderRadius: "10px", backgroundColor:"#008000"}}>
              <h1 className="text-[10px] text-white text-center leading-[9.68px] mt-1">PH {data["PH-103"]}</h1>
            </div>
           <img
           src='https://s3-alpha-sig.figma.com/img/27bd/d695/6ec0b351cd508c8027f29d4795b6d5c1?Expires=1734307200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=gAEhkcDtcKghR1RLvWXzJ1yxBQUDyILfNTa98AgpLAN0Upmfdh1ZIY~3V08fhgIh4sCOD~Cmsw8xeUXB4FPmha6itySYwQHc7tgT4iJRp2v4v0Cx3hHZ3IQ-lI7yjcTCe9qizoaeF3K1qWN2P-gIQcbofXEXXoYbntCSEIFfY~ZRbfecVBwN7~QHNRrI3sRjyvZT3rXFlwbbh5ZWcIW4if5zziCjSVS64p6CqhF6lPcizSGSaNrYMTC8zy7zzaLth2PW1vmkKSLWAdZ-~OwzHGfyTf1f1l-JdIeI2psCbPuzEbZzPo5pKGNr3X1~2qMJYdVzFFCVoJGaWtKOrUBlJA__'
           alt="Tank Type"
           className="h-16  imgs"
           />
            <div
            className="h-[35px] border mt-2"
            style={{ borderRadius: "10px"}}>
           <h1 className="text-[10px] text-center leading-[9.68px] mt-1">P-103</h1>
           <h1 className="text-[10px] text-center leading-[9.68px]">PH Senser</h1>
           </div>

       </div>

       <div>
          {/* Horizontal Line */}
         <div className=" relative h-2 bg-blue-500  w-[50px]">
         <div className="absolute top-[2px] inset-0 border-t-2 border-dashed border-white"></div>
         </div>
     
        
       </div> 
       {/* micron meter */}
       <div className='mt-[-63px] w-[60px]'>
         <div
            className="  h-[35px] border"
            style={{ borderRadius: "10px"}}>
           <h1 className="text-[8px] text-center leading-[9px] mt-1">MF-103 </h1>
           <h1 className="text-[8px] text-center leading-[9px]">5 Micron Filter</h1>
         </div>

         <img
             src="https://s3-alpha-sig.figma.com/img/be7e/7ce8/f67bf3b1ecc10637358adc5535c29aca?Expires=1734307200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=esdQWbzU~KQN9Ys5VDVQOt1dL9FTrzBLSSyj46LANpXGvAzvmUZZMDAUvhu-i55Gj~XKbS22ppqFsgyCKPX4h5ohSXjCM1sCcj7eb5gxVDNkFTdUDWHmKc7cLm1lgw6OqN3cw1rAbf9KmIalLtZosquOCca2UMT~jKaXa5LrpD7kaEPCfoIVIZy6XSwMax2NIrEQ6G3j6mUmWB4US9iEFJnF28kTYikytjHdSmyxXDpNpPVXGzvh63VLexE-dMlu6QASF2w-kKAfV1wfR1Bxpsrg48cEBlM8bP8XMH7lFdaurE8pda-prjP~nnYp5QgJOnxTMUjZ7vAX0VLLn12Dyw__"
             alt="Tank Type"
             className="h-16  mt-2"
        />
       </div>

      {/* Horizontal Line */}
        <div>
        <div className=" relative h-2 bg-blue-500  w-[50px]">
         <div className="absolute top-[2px] inset-0 border-t-2 border-dashed border-white"></div>
        </div>
        </div>
       {/*A/S + PH Dosing*/}
       <div
          className="h-[50px] border mt-[-20px] w-[60px]"
          style={{ borderRadius: "10px" ,backgroundColor:"#D9D9D9"}}>
           <h1 className="text-[10px] text-center leading-[9.68px] mt-2">A/S + PH </h1>
           <h1 className="text-[10px] text-center leading-[20px]"> Dosing</h1>
       </div>

         {/* Horizontal Line */}
         <div>
        <div className=" relative h-2 bg-blue-500  w-[50px]">
         <div className="absolute top-[2px] inset-0 border-t-2 border-dashed border-white"></div>
        </div>
        </div>
           {/* feed pump */}
        <div className='mt-[-38px] '>
        
        <img
         src="https://s3-alpha-sig.figma.com/img/41c7/0e25/da7f6924961e22d2841c6658d6ee179a?Expires=1734307200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=lzegHo~q540N2uiwuUgbE5xtpaobbscjje69j0TL4rrH4RsUzq8w5if-cw~VJie1ZuIZdL54nUUfnv3G6iDtAIsqRO~rN9ubH1f46zOrRFMl1AxKErMNsQ7TUQEWKLocCNb9RV6fX~L3M0I6t0Oaxp-WiTUd1TY-5jjaTeIzKhP1zeRGa6Zq2s~Y9EcMOSMzmBU8Qnqi4RZnQbhe8Pi6gvIXQDCCjGoWz-IONuXbpOWmPuADPMl4CLJPQi7tdKOJrCMkBjb7Oufm2FdtsnqGyGkpZ-el-TlXFmjITX3iqqXtEix3dqAEETBhIopYsffJ8Qh1lc8-drVOdyHCb81sxw__"
           alt="Tank Type"
           className="h-20 imgs "
       />
          <div
             className="w-[56px]  h-[40px] border "
             style={{ borderRadius: "5px"}}>
              <h1 className="text-[8px] text-center leading-[9.68px] mt-1">P-105 RO2</h1>
              <h1 className="text-[8px] text-center leading-[9.68px] mt-1">Feed Pump</h1>
           </div>
        </div>


         {/* Horizontal Line */}
         <div>
        <div className=" relative h-2 bg-blue-500  w-[50px]">
         <div className="absolute top-[2px] inset-0 border-t-2 border-dashed border-white"></div>
        </div>
        </div>
        {/* T-104 RO2 */}
        <div className='mt-[-40px] imgs'>
        <img
           src="https://s3-alpha-sig.figma.com/img/7256/33af/4f896415d39c4d6f943d86c30b934ac6?Expires=1734307200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=JS882LPNvmdg-z8qY4SvLbAgiD2XZzhWAb0aR92iHedLq-Jwz8P6dg5OYVRbPZR9WhpVAOzGAAjZnnbaCw2mh8Xl4mThFH0pVzdJ5PGJzIWz52xYLWR~91rolahaBRZcFaQE-RSygpCmf1CClMFHz~tZXZDOUOITq5O5YDtBF24okzN30ro7Q15XD43LH5qzSwUJMcjuDWRSJoQoT4ue4EwOhclgqMKS7PzchsM6nl6WLNTSh~2AYUqqaGoI2qQDWaRL~VHp7QPRNWI3kF11AJDNJakoOdFi6eHoEBwH5qmCOmvGU8shM~eUFteJOI8XpBUKTUHP2yxikGdj1w99kQ__"
           alt="Tank Type"
           className="h-16 imgs"
         />
          <div
          className=" mt-2 h-[35px] border"
          style={{ borderRadius: "10px" }}>
           <h1 className="text-[9px] text-center leading-[9.68px] mt-1">T-104 RO2</h1>
           <h1 className="text-[9px] text-center leading-[9.68px]">Feed Tank</h1>
         </div>
          
        </div>

         {/* Horizontal Line */}
         <div>
        <div className=" relative h-2 bg-blue-500  w-[40px]">
         <div className="absolute top-[2px] inset-0 border-t-2 border-dashed border-white"></div>
        </div>
        </div>
        {/*FT-102 flow meter */}
        <div className='mt-[-78px] imgs'>
         <div
             className="h-[40px] border"
             style={{ borderRadius: "5px", backgroundColor:"#008000"}}>
              <h1 className="text-[8px] text-white text-center leading-[9.68px] mt-1">{data["FT-102"]} kl/hr </h1>
              <h1 className="text-[8px] text-white text-center leading-[9.68px] mt-1">{data["FTKL-102"]} KL</h1>
         </div>
         <img
              src='https://s3-alpha-sig.figma.com/img/3f9a/6e99/793566f48b989e7204cd294a1176cecf?Expires=1734307200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=g7FUElvVavb9v41IeLyLVNVK4hPhPcAbDwyFU65LP4iClJ4bY8eHDbAA9MdN~rWBHww0g0W7q92xFHL~WYoMfSjSEYPQQ58QccaGYN~tyW6LbptlYZB4kei50EPFk2hz85AsODrmArv-S6ELrNq-reK4t7cSgkjxjie-LgJA56YxiyjJHyenOwATkeGOP9Fa9~3Zk5Baesc5pGkeuUvBq972at-OjqIH1eRaG8R-sRjTX6~ZabAGKxy5sPgpccGcQDNNq4PsGh3LZRA4FSsWtvqzUS8oNwxc0H-uxuLZKygFVnycRhWjddmCpGaWGQul4sJkOEq8sOnhh3sC6Zxh6g__'
              alt="Tank Type"
              className="h-16 my-2 imgs"
         />
          <div
             className="h-[40px] border "
             style={{ borderRadius: "5px"}}>
              <h1 className="text-[8px] text-center leading-[9.68px] mt-1">FT-102</h1>
              <h1 className="text-[8px] text-center leading-[9.68px] mt-1">Flow meter</h1>
           </div>
        </div>

         {/* Horizontal Line */}
         <div>
        <div className=" relative h-2 bg-blue-500  w-[40px]">
         <div className="absolute top-[2px] inset-0 border-t-2 border-dashed border-white"></div>
        </div>
        </div>
        {/*TDS 102 */}
        <div className='mt-[-77px] imgs '>
        <div
             className=" w-[70px] imgs h-[30px] border"
             style={{ borderRadius: "5px", backgroundColor:"#008000"}}>
              <h1 className="text-[10px] text-white text-center leading-[9.68px] mt-2">{data["TDS 102"]} mg/L</h1>
         </div>
         <div className='relative inline-block my-2'>
         <img
              src="https://s3-alpha-sig.figma.com/img/27bd/d695/6ec0b351cd508c8027f29d4795b6d5c1?Expires=1734307200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=gAEhkcDtcKghR1RLvWXzJ1yxBQUDyILfNTa98AgpLAN0Upmfdh1ZIY~3V08fhgIh4sCOD~Cmsw8xeUXB4FPmha6itySYwQHc7tgT4iJRp2v4v0Cx3hHZ3IQ-lI7yjcTCe9qizoaeF3K1qWN2P-gIQcbofXEXXoYbntCSEIFfY~ZRbfecVBwN7~QHNRrI3sRjyvZT3rXFlwbbh5ZWcIW4if5zziCjSVS64p6CqhF6lPcizSGSaNrYMTC8zy7zzaLth2PW1vmkKSLWAdZ-~OwzHGfyTf1f1l-JdIeI2psCbPuzEbZzPo5pKGNr3X1~2qMJYdVzFFCVoJGaWtKOrUBlJA__"
              alt="Tank Type"
              className="h-16 imgs my-2"
         />
         <span className="absolute bottom-[35px]  left-[14.5px] transform -translate-x-1/2 bg-red-400 text-[9px] font-semibold text-black shadow">
    TDS
  </span>
         </div>
         
          <div
             className=" h-[20px] border "
             style={{ borderRadius: "5px"}}>
              <h1 className="text-[10px] text-center leading-[9.68px] mt-1">TDS 102</h1>
           </div>
        
        </div> 

        </div> 
         
       
       </div>
       </div>
       
      </div>   
    </div>
    
       </div>

         {/* 3rd line start */}
         <div className='flex w-[98%] mt-5 ml-[-80px]  mx-auto '>
    
    <div className=' w-[100%] ml-7'>
      {/*feed Tanks and Systems */}
      <div className='flex '>

      {/* Ro-101 RO System */}
      <div>
      <div
          className="h-[40px] border mt-[-22px] w-[60px]"
          style={{ borderRadius: "10px" ,backgroundColor:"#D9D9D9"}}>
           <h1 className="text-[10px] text-center leading-[9.68px] mt-2">Ro-101 </h1>
           <h1 className="text-[10px] text-center leading-[12px]">RO System</h1>
      </div>
       {/* Vertical Line */}
       <div className="w-2 h-[45px] ml-6" style={{backgroundColor:"#F97316"}}>
           <div className="h-full border-l-2 border-dashed border-white ml-[3px] "></div>
        </div>
          {/* Horizontal Line */}
      <div className=" relative h-2 w-[40px] ml-[-7px]" style={{backgroundColor:"#F97316"}}>
       <div className="absolute top-[2px] inset-0 border-t-2 border-dashed border-white"></div>
      </div>
        {/* Vertical Line */}
       <div className="w-2 h-[60px] ml-[-12%]" style={{backgroundColor:"#F97316"}}>
           <div className="h-full border-l-2 border-dashed border-white ml-[3px] "></div>
        </div>
       
      </div>
      
        
       <div className='flex'>
         {/* Horizontal Line */}
       <div className=" relative h-2  w-[52px]" style={{backgroundColor:"#A7CDEA"}}>
         <div className="absolute top-[2px] inset-0 border-t-2 border-dashed border-white"></div>
       </div>


       {/* Horizontal Line */}
       <div className=''>  
        <div className='flex'>
        
       {/*FT-104 Flow meter*/}
       <div className=' mt-[-65px]  imgs'>
            <div
                className="h-[40px] border"
                style={{ borderRadius: "5px", backgroundColor:"#008000"}}>
                 <h1 className="text-[8px] text-white text-center leading-[9.68px] mt-1">{data["FT-104"]} kl/hr </h1>
                 <h1 className="text-[8px] text-white text-center leading-[9.68px] mt-1">{data["FTKL-104"]} KL</h1>
            </div>
           <img
           src="https://s3-alpha-sig.figma.com/img/3f9a/6e99/793566f48b989e7204cd294a1176cecf?Expires=1734307200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=g7FUElvVavb9v41IeLyLVNVK4hPhPcAbDwyFU65LP4iClJ4bY8eHDbAA9MdN~rWBHww0g0W7q92xFHL~WYoMfSjSEYPQQ58QccaGYN~tyW6LbptlYZB4kei50EPFk2hz85AsODrmArv-S6ELrNq-reK4t7cSgkjxjie-LgJA56YxiyjJHyenOwATkeGOP9Fa9~3Zk5Baesc5pGkeuUvBq972at-OjqIH1eRaG8R-sRjTX6~ZabAGKxy5sPgpccGcQDNNq4PsGh3LZRA4FSsWtvqzUS8oNwxc0H-uxuLZKygFVnycRhWjddmCpGaWGQul4sJkOEq8sOnhh3sC6Zxh6g__"
           alt="Tank Type"
           className="h-16 imgs"
           />
            <div
            className="h-[35px] border mt-2"
            style={{ borderRadius: "10px"}}>
           <h1 className="text-[8px] text-center leading-[9.68px] mt-1">FT-104 </h1>
           <h1 className="text-[8px] text-center leading-[9.68px]">Flow meter</h1>
           </div>

       </div>

       <div>
          {/* Horizontal Line */}
         <div className=" relative h-2 w-[50px]" style={{backgroundColor:"#A7CDEA"}}>
         <div className="absolute top-[2px] inset-0 border-t-2 border-dashed border-white"></div>
         </div>
     
        
       </div> 
       {/* TDS 104 */}
       <div className='mt-[-84px] imgs'>
       <div
             className=" w-[50px]  h-[30px] border"
             style={{ borderRadius: "5px", backgroundColor:"#008000"}}>
              <h1 className="text-[8px] text-white text-center leading-[9.68px] mt-2">{data["TDS 104"]} mg/L</h1>
        </div>
        <div className='relative inline-block my-2'>
        <img
              src="https://s3-alpha-sig.figma.com/img/27bd/d695/6ec0b351cd508c8027f29d4795b6d5c1?Expires=1734307200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=gAEhkcDtcKghR1RLvWXzJ1yxBQUDyILfNTa98AgpLAN0Upmfdh1ZIY~3V08fhgIh4sCOD~Cmsw8xeUXB4FPmha6itySYwQHc7tgT4iJRp2v4v0Cx3hHZ3IQ-lI7yjcTCe9qizoaeF3K1qWN2P-gIQcbofXEXXoYbntCSEIFfY~ZRbfecVBwN7~QHNRrI3sRjyvZT3rXFlwbbh5ZWcIW4if5zziCjSVS64p6CqhF6lPcizSGSaNrYMTC8zy7zzaLth2PW1vmkKSLWAdZ-~OwzHGfyTf1f1l-JdIeI2psCbPuzEbZzPo5pKGNr3X1~2qMJYdVzFFCVoJGaWtKOrUBlJA__"
              alt="Tank Type"
              className="h-16 imgs my-2"
         />
         <span className="absolute bottom-[35px]  left-[14.2px] transform -translate-x-1/2 bg-red-400 text-[9px] font-semibold text-black shadow">
    TDS
  </span>
        </div>

          <div
             className="w-[70px]  h-[20px] border "
             style={{ borderRadius: "5px"}}>
              <h1 className="text-[8px] text-center leading-[9.68px] mt-1">TDS 104</h1>
           </div>
       </div>

      {/* Horizontal Line */}
        <div>
        <div className=" relative h-2  w-[50px]" style={{backgroundColor:"#A7CDEA"}}>
         <div className="absolute top-[2px] inset-0 border-t-2 border-dashed border-white"></div>
        </div>
        </div>
   
       {/* T-105 Ro permeate Storage Tank*/}
    
       <div className='mt-[-40px] imgs'>
           <img
              src="https://s3-alpha-sig.figma.com/img/7256/33af/4f896415d39c4d6f943d86c30b934ac6?Expires=1734307200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=JS882LPNvmdg-z8qY4SvLbAgiD2XZzhWAb0aR92iHedLq-Jwz8P6dg5OYVRbPZR9WhpVAOzGAAjZnnbaCw2mh8Xl4mThFH0pVzdJ5PGJzIWz52xYLWR~91rolahaBRZcFaQE-RSygpCmf1CClMFHz~tZXZDOUOITq5O5YDtBF24okzN30ro7Q15XD43LH5qzSwUJMcjuDWRSJoQoT4ue4EwOhclgqMKS7PzchsM6nl6WLNTSh~2AYUqqaGoI2qQDWaRL~VHp7QPRNWI3kF11AJDNJakoOdFi6eHoEBwH5qmCOmvGU8shM~eUFteJOI8XpBUKTUHP2yxikGdj1w99kQ__"
              alt="Tank Type"
              className="h-16 imgs"
            />
             <div
             className=" mt-2  h-[35px] border"
             style={{ borderRadius: "10px" }}>
              <h1 className="text-[8px] text-center leading-[9.68px] mt-1">T-105 Ro permeate </h1>
              <h1 className="text-[8px] text-center leading-[9.68px]">Storage Tank</h1>
            </div>
       </div>

         {/* Horizontal Line */}
         <div>
        <div className=" relative h-2   w-[50px]" style={{backgroundColor:"#A7CDEA"}}>
         <div className="absolute top-[2px] inset-0 border-t-2 border-dashed border-white"></div>
        </div>
        </div>
           {/* P-107 SFT Feed Pump */}
        <div className='mt-[-38px] imgs'>
        
        <img
         src="https://s3-alpha-sig.figma.com/img/41c7/0e25/da7f6924961e22d2841c6658d6ee179a?Expires=1734307200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=lzegHo~q540N2uiwuUgbE5xtpaobbscjje69j0TL4rrH4RsUzq8w5if-cw~VJie1ZuIZdL54nUUfnv3G6iDtAIsqRO~rN9ubH1f46zOrRFMl1AxKErMNsQ7TUQEWKLocCNb9RV6fX~L3M0I6t0Oaxp-WiTUd1TY-5jjaTeIzKhP1zeRGa6Zq2s~Y9EcMOSMzmBU8Qnqi4RZnQbhe8Pi6gvIXQDCCjGoWz-IONuXbpOWmPuADPMl4CLJPQi7tdKOJrCMkBjb7Oufm2FdtsnqGyGkpZ-el-TlXFmjITX3iqqXtEix3dqAEETBhIopYsffJ8Qh1lc8-drVOdyHCb81sxw__"
           alt="Tank Type"
           className="h-20 "
       />
          <div
             className="w-[60px]  h-[30px] border "
             style={{ borderRadius: "5px"}}>
              <h1 className="text-[8px] text-center leading-[9.68px] mt-1">P-107 SFT</h1>
              <h1 className="text-[8px] text-center leading-[9.68px] mt-1">Feed Pump</h1>
           </div>
        </div>


         {/* Horizontal Line */}
        
        <div className=" relative h-2  w-[50px]" style={{backgroundColor:"#A7CDEA"}}>
         <div className="absolute top-[2px] inset-0 border-t-2 border-dashed border-white"></div>
        </div>
      

        <div
          className="h-[40px] border mt-[-22px] w-[60px]"
          style={{ borderRadius: "10px" ,backgroundColor:"#D9D9D9"}}>
           <h1 className="text-[10px] text-center leading-[9.68px] mt-2">SFT-107</h1>
           <h1 className="text-[10px] text-center leading-[9.68px]"> Soft....</h1>
      </div>
       {/* Horizontal Line */}
      <div className=" relative h-2 w-[50px]" style={{backgroundColor:"#A7CDEA"}}>
         <div className="absolute top-[2px] inset-0 border-t-2 border-dashed border-white"></div>
        </div>
        {/* T-107 SFT Storage Tank */}
        <div className='mt-[-40px] imgs'>
        <img
           src="https://s3-alpha-sig.figma.com/img/7256/33af/4f896415d39c4d6f943d86c30b934ac6?Expires=1734307200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=JS882LPNvmdg-z8qY4SvLbAgiD2XZzhWAb0aR92iHedLq-Jwz8P6dg5OYVRbPZR9WhpVAOzGAAjZnnbaCw2mh8Xl4mThFH0pVzdJ5PGJzIWz52xYLWR~91rolahaBRZcFaQE-RSygpCmf1CClMFHz~tZXZDOUOITq5O5YDtBF24okzN30ro7Q15XD43LH5qzSwUJMcjuDWRSJoQoT4ue4EwOhclgqMKS7PzchsM6nl6WLNTSh~2AYUqqaGoI2qQDWaRL~VHp7QPRNWI3kF11AJDNJakoOdFi6eHoEBwH5qmCOmvGU8shM~eUFteJOI8XpBUKTUHP2yxikGdj1w99kQ__"
           alt="Tank Type"
           className="h-16 "
         />
          <div
          className=" mt-2 w-[60px]  h-[35px] border"
          style={{ borderRadius: "10px" }}>
           <h1 className="text-[8px] text-center leading-[9.68px] mt-1">T-107 SFT</h1>
           <h1 className="text-[8px] text-center leading-[12px]"> Storage Tank</h1>
         </div>
          
        </div>
        {/* Horizontal Line */}
        <div className=" relative h-2 mt-[9%] w-[100px] ml-[-1.8%]" style={{backgroundColor:"#FF0404"}}>
         <div className="absolute top-[2px] inset-0 border-t-2 border-dashed border-white"></div>
        </div>
        
        {/* T-107 SFT Storage Tank */}
        <div className='mt-[-11px]'>
        {/* Vertical Line */}
        <div className="w-2 h-[110px] ml-[-108%] mt-[-16px]" style={{backgroundColor:"#FF0404"}}>
        <div className="h-full border-l-2 border-dashed border-white ml-[3px] "></div>
       </div>

        </div>
        {/* Horizontal Line */}
        <div className=" relative h-2 mt-[-4%] ml-[-2%] w-[60px]" style={{backgroundColor:"#FF0404"}}>
         <div className="absolute top-[2px] inset-0 border-t-2 border-dashed border-white"></div>
        </div>
         {/* T-107 SFT Storage Tank */}
         <div className='mt-[-80px] imgs'>
        <img
           src="https://s3-alpha-sig.figma.com/img/7256/33af/4f896415d39c4d6f943d86c30b934ac6?Expires=1734307200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=JS882LPNvmdg-z8qY4SvLbAgiD2XZzhWAb0aR92iHedLq-Jwz8P6dg5OYVRbPZR9WhpVAOzGAAjZnnbaCw2mh8Xl4mThFH0pVzdJ5PGJzIWz52xYLWR~91rolahaBRZcFaQE-RSygpCmf1CClMFHz~tZXZDOUOITq5O5YDtBF24okzN30ro7Q15XD43LH5qzSwUJMcjuDWRSJoQoT4ue4EwOhclgqMKS7PzchsM6nl6WLNTSh~2AYUqqaGoI2qQDWaRL~VHp7QPRNWI3kF11AJDNJakoOdFi6eHoEBwH5qmCOmvGU8shM~eUFteJOI8XpBUKTUHP2yxikGdj1w99kQ__"
           alt="Tank Type"
           className="h-16 w-[65px]"
         />
          <div
          className=" mt-2 w-[70px]  h-[35px] border"
          style={{ borderRadius: "10px" }}>
           <h1 className="text-[8px] text-center leading-[9.68px] mt-1">T-103 NF2
           </h1>
           <h1 className="text-[8px] text-center leading-[12px]">Rejected Tank</h1>
         </div>
          
        </div>
       </div> 
         
       
       </div>
       </div>
       
      </div>   
    </div>
    
        </div>

         {/* 4th line start */}
         <div className='flex w-[115%] ml-[-5%] '>
         <div className=' w-[100%]'>

         <div className='flex '>
           {/* Vertical Line */}
       <div className="w-2 h-[110px] ml-[-6%] mt-[3.8%] 2xl:mt-[3.4%]" style={{backgroundColor:"#F97316"}}>
        <div className="h-full border-l-2 border-dashed border-white ml-[3px] "></div>
       </div>
       
        {/* Horizontal Line */}
      <div className=" relative h-2 w-[25px] mt-[40px] ml-[-4px]" style={{backgroundColor:"#F97316"}}>
       <div className="absolute top-[2px] inset-0 border-t-2 border-dashed border-white"></div>
      </div>

       {/*T-102 NF2 Feed Tank */}
       <div className='mt-[-5px] imgs'>
        <img
              src="https://s3-alpha-sig.figma.com/img/7256/33af/4f896415d39c4d6f943d86c30b934ac6?Expires=1734307200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=JS882LPNvmdg-z8qY4SvLbAgiD2XZzhWAb0aR92iHedLq-Jwz8P6dg5OYVRbPZR9WhpVAOzGAAjZnnbaCw2mh8Xl4mThFH0pVzdJ5PGJzIWz52xYLWR~91rolahaBRZcFaQE-RSygpCmf1CClMFHz~tZXZDOUOITq5O5YDtBF24okzN30ro7Q15XD43LH5qzSwUJMcjuDWRSJoQoT4ue4EwOhclgqMKS7PzchsM6nl6WLNTSh~2AYUqqaGoI2qQDWaRL~VHp7QPRNWI3kF11AJDNJakoOdFi6eHoEBwH5qmCOmvGU8shM~eUFteJOI8XpBUKTUHP2yxikGdj1w99kQ__"
              alt="Tank Type"
              className="h-16 imgs"
        />
          <div
             className="h-[40px] border mt-2"
             style={{ borderRadius: "5px"}}>
              <h1 className="text-[8px] text-center leading-[9.68px] mt-1">T-102 NF2</h1>
              <h1 className="text-[8px] text-center leading-[9.68px] mt-1"> Feed Tank</h1>
           </div>
        </div>

        {/* Horizontal Line */}
      <div className=" relative h-2 w-[40px] mt-[40px] horizontal_green_pipes4_line" style={{backgroundColor:"#F97316"}}>
       <div className="absolute top-[2px] inset-0 border-t-2 border-dashed border-white"></div>
      </div>

       {/* feed pump */}
       <div className='mt-[3px] imgs'>
        
        <img
         src="https://s3-alpha-sig.figma.com/img/41c7/0e25/da7f6924961e22d2841c6658d6ee179a?Expires=1734307200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=lzegHo~q540N2uiwuUgbE5xtpaobbscjje69j0TL4rrH4RsUzq8w5if-cw~VJie1ZuIZdL54nUUfnv3G6iDtAIsqRO~rN9ubH1f46zOrRFMl1AxKErMNsQ7TUQEWKLocCNb9RV6fX~L3M0I6t0Oaxp-WiTUd1TY-5jjaTeIzKhP1zeRGa6Zq2s~Y9EcMOSMzmBU8Qnqi4RZnQbhe8Pi6gvIXQDCCjGoWz-IONuXbpOWmPuADPMl4CLJPQi7tdKOJrCMkBjb7Oufm2FdtsnqGyGkpZ-el-TlXFmjITX3iqqXtEix3dqAEETBhIopYsffJ8Qh1lc8-drVOdyHCb81sxw__"
           alt="Tank Type"
           className="h-20 imgs"
       />
          <div
             className="h-[40px] border "
             style={{ borderRadius: "5px"}}>
              <h1 className="text-[8px] text-center leading-[9.68px] mt-1">P-103 NF2 
              </h1>
              <h1 className="text-[8px] text-center leading-[9.68px] mt-1">Feed Pump</h1>
           </div>
        </div>
        <div className='flex'>
        {/* Horizontal Line */}
      <div className=" relative h-2 w-[40px] mt-[40px] horizontal_green_pipes4_line" style={{backgroundColor:"#F97316"}}>
       <div className="absolute top-[2px] inset-0 border-t-2 border-dashed border-white"></div>
      </div>

       {/*A/S + PH Dosing*/}
     <div
          className="h-[50px] border mt-[20px] w-[60px]"
          style={{ borderRadius: "10px" ,backgroundColor:"#D9D9D9"}}>
           <h1 className="text-[10px] text-center leading-[9.68px] mt-2">A/S + HCL</h1>
           <h1 className="text-[10px] text-center leading-[20px]"> Dosing</h1>
       </div>

       <div>
          {/* Horizontal Line */}
         <div className=" relative h-2 w-[40px]  mt-[40px] horizontal_green_pipes4_line" style={{backgroundColor:"#F97316"}}>
         <div className="absolute top-[2px] inset-0 border-t-2 border-dashed border-white"></div>
         </div>
       </div> 
        {/* 5micron meter */}
        <div className='mt-[-25px] imgs'>
         <div
            className="  h-[35px] border"
            style={{ borderRadius: "10px"}}>
           <h1 className="text-[8px] text-center leading-[9px] mt-1">MF-102</h1>
           <h1 className="text-[8px] text-center leading-[9px]">5 Micron Filter</h1>
         </div>

         <img
             src="https://s3-alpha-sig.figma.com/img/be7e/7ce8/f67bf3b1ecc10637358adc5535c29aca?Expires=1734307200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=esdQWbzU~KQN9Ys5VDVQOt1dL9FTrzBLSSyj46LANpXGvAzvmUZZMDAUvhu-i55Gj~XKbS22ppqFsgyCKPX4h5ohSXjCM1sCcj7eb5gxVDNkFTdUDWHmKc7cLm1lgw6OqN3cw1rAbf9KmIalLtZosquOCca2UMT~jKaXa5LrpD7kaEPCfoIVIZy6XSwMax2NIrEQ6G3j6mUmWB4US9iEFJnF28kTYikytjHdSmyxXDpNpPVXGzvh63VLexE-dMlu6QASF2w-kKAfV1wfR1Bxpsrg48cEBlM8bP8XMH7lFdaurE8pda-prjP~nnYp5QgJOnxTMUjZ7vAX0VLLn12Dyw__"
             alt="Tank Type"
             className="h-16 imgs mt-2"
        />
       </div>

        {/* Horizontal Line */}
       
        <div className=" relative h-2 w-[40px] mt-[40px] horizontal_green_pipes4_line" style={{backgroundColor:"#F97316"}}>
         <div className="absolute top-[2px] inset-0 border-t-2 border-dashed border-white"></div>
        </div>

          {/*PH-102 PH Sensor*/}
          <div className='mt-[-35px] '>
        <div
             className="  h-[30px] border"
             style={{ borderRadius: "5px", backgroundColor:"#008000"}}>
              <h1 className="text-[10px] text-white text-center leading-[9.68px] mt-2 ">{data["PH-102"]} mg/L</h1>
         </div>
         <img
              src="https://s3-alpha-sig.figma.com/img/27bd/d695/6ec0b351cd508c8027f29d4795b6d5c1?Expires=1734307200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=gAEhkcDtcKghR1RLvWXzJ1yxBQUDyILfNTa98AgpLAN0Upmfdh1ZIY~3V08fhgIh4sCOD~Cmsw8xeUXB4FPmha6itySYwQHc7tgT4iJRp2v4v0Cx3hHZ3IQ-lI7yjcTCe9qizoaeF3K1qWN2P-gIQcbofXEXXoYbntCSEIFfY~ZRbfecVBwN7~QHNRrI3sRjyvZT3rXFlwbbh5ZWcIW4if5zziCjSVS64p6CqhF6lPcizSGSaNrYMTC8zy7zzaLth2PW1vmkKSLWAdZ-~OwzHGfyTf1f1l-JdIeI2psCbPuzEbZzPo5pKGNr3X1~2qMJYdVzFFCVoJGaWtKOrUBlJA__"
              alt="Tank Type"
              className="h-16  my-2"
         />
          <div className="  h-[40px] border "
             style={{ borderRadius: "5px"}}>
              <h1 className="text-[8px] text-center leading-[9.68px] mt-1">PH-102</h1>
              <h1 className="text-[8px] text-center leading-[9.68px] mt-1">PH Sensor</h1>
           </div>
        
          </div> 
         {/* Horizontal Line */}
         
        <div className=" relative h-2 w-[40px] mt-[40px] horizontal_green_pipes4_line" style={{backgroundColor:"#F97316"}}>
         <div className="absolute top-[2px] inset-0 border-t-2 border-dashed border-white"></div>
        </div>

        {/*NF-102 NF System 2*/}
       <div
          className="h-[50px] border mt-[20px] w-[65px]"
          style={{ borderRadius: "10px" ,backgroundColor:"#D9D9D9"}}>
           <h1 className="text-[10px] text-center leading-[9.68px] mt-2">P-104</h1>
           <h1 className="text-[10px] text-center leading-[20px]">NF2 HPP</h1>
       </div>

         {/* Horizontal Line */}
        <div className=" relative h-2 w-[40px] mt-[40px] horizontal_green_pipes4_line" style={{backgroundColor:"#F97316"}}>
         <div className="absolute top-[2px] inset-0 border-t-2 border-dashed border-white"></div>
        </div>

         {/* pressure meter */}
         <div className='imgs mt-[-38px]'>
            <div
                className="h-[20px] border"
                style={{ borderRadius: "5px", backgroundColor:"#008000"}}>
                 <h1 className="text-[10px] text-white text-center leading-[9.68px] mt-1">{data["PT-103"]} Bar</h1>
            </div>
            <img
                 src="https://s3-alpha-sig.figma.com/img/e86b/cbe8/e797e51e639d09e4baac933b2b91ea2b?Expires=1734307200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=MR2x1SFFZ286l0JXhH32GsUle7BY4DW6joYl9tg3CjU3SfQ1xsnjtBwtD8Giv7AdkeMyteOhZDhPrRGbkMsCTInYYLZxzuB1Q-q6kx3lLcr0k33y6X2JWYqOSVQl6dpSOiVT7Dd~51f8ideuBEB2XCY0nXNlt3~eNLIHJjy4S2GXlUBOi9Lynp-Rs-nFh3uocRVeCUHccgrDdZVMseyxVhoV2jrznpUMPjHR2mZ61brx2204Ka05e~Cj3M1-rjgrXUwtkf2ZTTGx~~dPGHcPeqm~zHqYk3jZSjMmbjvI1Ks5aUnXZqjbpBNNsc-O4QOPH~10vEkDSBg2pCWx220SAw__"
                 alt="Tank Type"
                 className="h-16  my-2"
            />
             <div
                className="h-[40px] border "
                style={{ borderRadius: "5px"}}>
                 <h1 className="text-[8px] text-center leading-[9.68px] mt-1">PT/HPS-102
                 </h1>
                 <h1 className="text-[8px] text-center leading-[9.68px] mt-1">Pressure meter</h1>
              </div>
          </div>
           {/* Horizontal Line */}
        <div className=" relative h-2 w-[40px] mt-[40px] horizontal_green_pipes4_line" style={{backgroundColor:"#F97316"}}>
         <div className="absolute top-[2px] inset-0 border-t-2 border-dashed border-white"></div>
        </div>

        {/* Vertical Line */}
        <div className='mt-[-50px]'>
       
        <div className="w-2 h-[70px] ml-7" style={{backgroundColor:"#FF0404"}}>
           <div className="h-full border-l-2 border-dashed border-white ml-[3px] "></div>
        </div>
        {/*NF-102 NF System 2*/}
       <div
          className="h-[50px] border  w-[65px]"
          style={{ borderRadius: "10px" ,backgroundColor:"#D9D9D9"}}>
           <h1 className="text-[10px] text-center leading-[9.68px] mt-2">NF-102</h1>
           <h1 className="text-[10px] text-center leading-[20px]"> NF System 2</h1>
       </div>

       
       </div>

       <div className=" relative h-2 w-[50px] mt-[40px] horizontal_green_pipes4_line" style={{backgroundColor:"#F97316"}}>
         <div className="absolute top-[2px] inset-0 border-t-2 border-dashed border-white"></div>
        </div>
        
        {/*FT-102 flow meter */}
        <div className='mt-[-33px] imgs'>
         <div
             className="h-[40px] border"
             style={{ borderRadius: "5px", backgroundColor:"#008000"}}>
              <h1 className="text-[8px] text-white text-center leading-[9.68px] mt-1">{data["FT-102"]} kl/hr </h1>
              <h1 className="text-[8px] text-white text-center leading-[9.68px] mt-1">{data["FTKL-103"]} KL</h1>
         </div>
         <img
              src='https://s3-alpha-sig.figma.com/img/3f9a/6e99/793566f48b989e7204cd294a1176cecf?Expires=1734307200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=g7FUElvVavb9v41IeLyLVNVK4hPhPcAbDwyFU65LP4iClJ4bY8eHDbAA9MdN~rWBHww0g0W7q92xFHL~WYoMfSjSEYPQQ58QccaGYN~tyW6LbptlYZB4kei50EPFk2hz85AsODrmArv-S6ELrNq-reK4t7cSgkjxjie-LgJA56YxiyjJHyenOwATkeGOP9Fa9~3Zk5Baesc5pGkeuUvBq972at-OjqIH1eRaG8R-sRjTX6~ZabAGKxy5sPgpccGcQDNNq4PsGh3LZRA4FSsWtvqzUS8oNwxc0H-uxuLZKygFVnycRhWjddmCpGaWGQul4sJkOEq8sOnhh3sC6Zxh6g__'
              alt="Tank Type"
              className="h-16  my-1"
         />
          <div
             className="h-[40px] border "
             style={{ borderRadius: "5px"}}>
              <h1 className="text-[8px] text-center leading-[9.68px] mt-1">FT-103
              </h1>
              <h1 className="text-[8px] text-center leading-[9.68px] mt-1">Flow meter</h1>
           </div>
        </div>
       
        <div className=" relative h-2 bg-green-700  w-[40px] mt-[40px] horizontal_green_pipes4_line">
         <div className="absolute top-[2px] inset-0 border-t-2 border-dashed border-white"></div>
        </div>
        
        {/*TDS 102 */}
        <div className='mt-[-40px] imgs'>
        <div
             className="h-[30px] border"
             style={{ borderRadius: "5px", backgroundColor:"#008000"}}>
              <h1 className="text-[8px] text-white text-center leading-[9.68px] mt-2">{data["TDS 103"]} mg/L</h1>
         </div>
         <div className="relative">
         <img
              src="https://s3-alpha-sig.figma.com/img/27bd/d695/6ec0b351cd508c8027f29d4795b6d5c1?Expires=1734307200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=gAEhkcDtcKghR1RLvWXzJ1yxBQUDyILfNTa98AgpLAN0Upmfdh1ZIY~3V08fhgIh4sCOD~Cmsw8xeUXB4FPmha6itySYwQHc7tgT4iJRp2v4v0Cx3hHZ3IQ-lI7yjcTCe9qizoaeF3K1qWN2P-gIQcbofXEXXoYbntCSEIFfY~ZRbfecVBwN7~QHNRrI3sRjyvZT3rXFlwbbh5ZWcIW4if5zziCjSVS64p6CqhF6lPcizSGSaNrYMTC8zy7zzaLth2PW1vmkKSLWAdZ-~OwzHGfyTf1f1l-JdIeI2psCbPuzEbZzPo5pKGNr3X1~2qMJYdVzFFCVoJGaWtKOrUBlJA__"
              alt="Tank Type"
              className="h-16 my-2 "
         />
         <span className="absolute bottom-[27px]  left-[14.2px] transform -translate-x-1/2 bg-red-400 text-[9px] font-semibold text-black shadow">
    TDS
  </span>
         </div>

          <div
             className=" h-[20px] border "
             style={{ borderRadius: "5px"}}>
              <h1 className="text-[8px] text-center leading-[9.68px] mt-1">TDS 103</h1>
           </div>
        
        </div> 
        {/* Horizontal Line */}
        <div className=" relative h-2 bg-green-700  w-[59px] mt-[40px] horizontal_green_pipe4_line">
         <div className="absolute top-[2px] inset-0 border-t-2 border-dashed border-white"></div>
        </div>
        </div>
        
         </div>
     
         </div>
        
         </div> 
        

           
      </div>
       {/* Vertical Line at the End for orange */}
      <div className="mt-[9.5%] w-2 h-[655px] vertical_orange_pipe" style={{backgroundColor:"#F97316"}}>
        <div className=" h-full border-l-2 border-dashed border-white ml-[3px] "></div>
      </div>

      <div className="flex-grow ">
        {/* Second Vertical Line at the End for green */}
      <div className="bg-green-700 w-2 h-[645px] self-end ml-5 border vertical_green_pipe">
        <div className="h-full border-l-2 border-dashed border-white ml-[3px]"></div>
      </div>
      </div> 
      </div>
      </div>


      </div>
        {/* Horizontal Line at the End for orange*/}
        <div className=" relative h-2 mt-[-0.7%] w-[99.6%] self-start horizontal_orange_pipe" style={{backgroundColor:"#F97316"}}>
         <div className="absolute top-[2px] inset-0 border-t-2 border-dashed border-white"></div>
        </div>
     
    </div>
    </div>

  </>
  )
}

export default Testakshay