import HandleHttp from "../utils/axiso-interceptor/axios.interceptor";


const loadTime = 0;

export const http = async (url: string, method: string = "GET", reqData :  Record<string, any> = {}) => {
    const baseUrl = process.env.REACT_APP_BASE_URL || '';
    const getToken = () => localStorage.getItem("token") || '';
    const httpInstance = HandleHttp({ baseUrl, token: getToken() });

    if(!getToken()){
        window.localStorage.removeItem("token")
        window.location.href = "/#/login";
    }




    try {
        const response = await httpInstance.request({
            url,
            method,
            data: reqData,
        });
        

        return new Promise((resolve) =>
            setTimeout(() => resolve(response.data), loadTime)
        );
    } catch (err: any) {
        const { status, data } = err.response || {};

        if (method === "GET" && status === 401) {
            // logout(dispatch);
            window.localStorage.removeItem("token")
            window.location.href = "/#/login";
        }

        return new Promise((resolve, reject) =>
            setTimeout(() => reject(data?.message || ""), loadTime)
        );
    }
};
