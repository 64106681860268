import React, { useCallback, useEffect, useRef, useState } from "react";

// import "../../App.css";
import {
  formatDate,
  formatTimestamp,
  generateCSV1,
  reportDataWithGraph,
} from "../../../common-function/CommonFunction";

import LineChart from "../../../components/apex-chart/LineChart";
import CardThree from "../../../components/dashBoardComponents/CardThree";
import LongMenu from "../../../utils/longMenu/LongMenu";
import MobileTowerIcon from "../../../components/apex-chart/HeatMapChart";
import { http } from "../../../helper/http";
import {
  fillMissingDays,
  fillMissingHours,
  filterDataFromEachDayEnergy,
  filterDataFromEachHourEnergy,
  processConsumptionDataEnergy,
  processHourlyDataEnergy,
  processHourlyDataEnergyDaily,
  processMonthlyConsumptionEnergy,
} from "./common-functions";
import CircularProgress from "@mui/material/CircularProgress";
import DynamicBarChart from "../../../components/apex-chart/CommonBarChart2";

const baseUrl = process.env.REACT_APP_BASE_URL;
const DashboardEnergy2 = ({ devices, selectedDevice }: any) => {
  //material tabs
  const [value, setValue] = React.useState(0);
  const [valueAnalysis, setvalueAnalysis] = React.useState(0);
  //instantenious
  const [valueInst, setInst] = React.useState(0);
  const [valueInstPower, setvalueInstPower] = React.useState(0);
  const [valueInstDemand, setvalueInstDemand] = React.useState(0);
  const [valueInstTDH, setvalueInstTDH] = React.useState(0);
  const [valueInstVolt, setValueInstVolt] = React.useState(0);

  const [apiData, setApiData] = useState<any>([]); //setTemperature
  const barchartref1 = useRef<HTMLDivElement>(null);
  const barchartref2 = useRef<HTMLDivElement>(null);
  const chartRef = useRef<HTMLDivElement>(null);
  const chartRef1 = useRef<HTMLDivElement>(null);
  const chartRef4 = useRef<HTMLDivElement>(null);
  const chartRef5 = useRef<HTMLDivElement>(null);
  const chartRef6 = useRef<HTMLDivElement>(null);
  const chartRef7 = useRef<HTMLDivElement>(null);
  const chartRef8 = useRef<HTMLDivElement>(null);
  const [dashboardSts, setDashboardSts] = useState<any>([]);
  const [loader, setLoader] = React.useState(false);
  const [customTagData, setCustomTagData] = useState<any>([]);
  // console.log("custom tag data", customTagData);

  const fetchData = async () => {
    let dof = new Date();

    let response: any = null;
    try {
      const formattedDate = formatDate(dof);
      let queryParam = "";
      if (value === 0) {
        queryParam =
          valueAnalysis === 0
            ? // ? `day=${formattedDate.slice(0, 10)}`
              `last24=${formattedDate.slice(0, 10)}`
            : valueAnalysis === 1
            ? `month=${formattedDate.slice(5, 7)}`
            : "";
      } else if (value === 1) {
        queryParam = `last24=${formattedDate.slice(0, 10)}`;
      }
      response = await http(
        `${baseUrl}/device-data-log?deviceId=${selectedDevice}&${queryParam}`
      );
      const _data = response
        .map((d: any, i: any) => {
          return {
            timestamp: formatTimestamp(d.timeStamp),

            // analysis tab
            activeenergy: d["t_acte"],
            reactiveenergy: d["t_re"],
            apparentenergy: d["t_apte"],
            importactenrgy: d["imp_ae"],
            importreactenrgy: d["imp_re"],
            exportactenrgy: d["exp_ae"],
            exportreactenrgy: d["exp_re"],

            //Voltage tab
            avgneuvolt: d["av_ln"],
            rphvtg: d["v1n"],
            yphvtg: d["v2n"],
            bphvtg: d["v3n"],

            avglinevolt: d["av_ll"],
            ryphvtg: d["v12"],
            ybpgvtg: d["v23"],
            rbphvtg: d["v31"],

            //current Tab
            avgcrt: d["av_c"],
            rphcrt: d["cl1"],
            yphcrt: d["cl2"],
            bphcrt: d["cl3"],

            //Power Tab
            totalactpo: d["t_kw"],
            rphactpo: d["kw1"],
            yphactpo: d["kw2"],
            bphactpo: d["kw3"],

            totalreactpo: d["t_kvar"],
            rphreactpo: d["kvar1"],
            yphreactpo: d["kvar2"],
            bphreactpo: d["kvar3"],

            totalapppo: d["t_kva"],
            rphapppo: d["kva1"],
            yphapppo: d["kva2"],
            bphapppo: d["kva3"],

            //Power Factor Tab
            powerfactor: d["av_pf"],
            rphpf: d["pf1"],
            yphpf: d["pf2"],
            bphpf: d["pf3"],

            //Frequency Tab
            frequency: d["freq"],

            //Treshold Tab
            rvtgphthd: d["thd_v1n"],
            yvtgphthd: d["thd_v2n"],
            bvtgphthd: d["thd_v3n"],

            ryvtgphthd: d["thd_v12"],
            ybvtgphthd: d["thd_v23"],
            rbvtgphthd: d["thd_v31"],

            rcrtphthd: d["thd_ci1"],
            ycrtphthd: d["thd_ci2"],
            bcrtphthd: d["thd_ci3"],

            //Demand Tab
            mxactpower: d["ap_maxd"],
            minactpower: d["ap_mind"],
            mxreactpower: d["rp_maxd"],
            minreactpower: d["rp_mind"],
            mxapppopower: d["apr_maxd"],

            //Others Tab
            runhours: d["r_hour"],
            currintrrpt: d["a_intrpt"],

            rphvoltage: d["max_iv1n"],
            yphvoltage: d["max_iv2n"],
            bphvoltage: d["max_iv3n"],

            ryphvoltage: d["max_iv12"],
            ybphvoltage: d["max_iv23"],
            rbphvoltage: d["max_iv31"],

            rphcuurent: d["max_ici1"],
            yphcurrent: d["max_ici2"],
            bphcurrent: d["max_ici3"],
            //RSSID
            rssi: d["rssi"],
          };
        })
        .filter((d: any) => {
          return !Object.values(d).some(
            (val: any) =>
              typeof val === "string" && val.toLowerCase() === "error"
          );
        });

      setDashboardSts(_data.length ? _data[0] : "");
      const sortedData = _data.sort((a: any, b: any) => {
        return (
          new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
        );
      });
      setApiData(sortedData);
      setLoader(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // console.log("device ddetails", customTagData);

  useEffect(() => {
    fetchData();
    if (devices?.DeviceTemplate?.tagJson) {
      const transformedTagJson = devices.DeviceTemplate.tagJson.reduce(
        (acc: any, tag: any) => {
          acc[tag.tagName] = tag;
          return acc;
        },
        {}
      );
      setCustomTagData(transformedTagJson);
    }
    const intervalId = setInterval(fetchData, 120000);
    return () => clearInterval(intervalId);
  }, [selectedDevice, valueAnalysis, value]);

  let energy_data_pdfData: any = [];
  let active_energy_last24hour: any = [];
  let reactive_energy_last24hour: any = [];
  let apparent_energy_last24hour: any = [];
  let import_active_last24hour: any = [];
  let export_active_last24hour: any = [];
  let import_reactive_last24hour: any = [];
  let export_reactive_last24hour: any = [];

  let avarage_neutral_data_of_current_month: any = [];
  let r_phase_Voltage_of_current_month: any = [];
  let y_phase_Voltage_of_current_month: any = [];
  let b_phase_Voltage_of_current_month: any = [];
  let avarage_L_L_data_of_current_month: any = [];
  let ry_phase_Voltage_of_current_month: any = [];
  let yb_phase_Voltage_of_current_month: any = [];
  let rb_phase_Voltage_of_current_month: any = [];

  let total_Current_of_current_month: any = [];
  let r_phase_Current_of_current_month: any = [];
  let y_phase_Current_of_current_month: any = [];
  let b_phase_Current_of_current_month: any = [];
  let total_Active_power_of_current_month: any = [];
  let r_phase_Active_power_of_current_month: any = [];
  let y_phase_Active_power_of_current_month: any = [];
  let b_phase_Active_power_of_current_month: any = [];
  let total_Reactive_power_of_current_month: any = [];
  let r_phase_Reactive_power_of_current_month: any = [];
  let y_phase_Reactive_power_of_current_month: any = [];
  let b_phase_Reactive_power_of_current_month: any = [];
  let total_Apparent_power_of_current_month: any = [];
  let r_phase_Apparent_power_of_current_month: any = [];
  let y_phase_Apparent_power_of_current_month: any = [];
  let b_phase_Apparent_power_of_current_month: any = [];
  let total_Power_Factor_of_current_month: any = [];
  let r_phase_Power_Factor_of_current_month: any = [];
  let y_phase_Power_Factor_of_current_month: any = [];
  let b_phase_Power_Factor_of_current_month: any = [];
  let frequency_for_pdf: any = [];
  let frequency_of_current_month: any = [];

  let max_active_demand_of_current_month: any = [];
  let max_reactive_demand_of_current_month: any = [];
  let max_apparend_demand_of_current_month: any = [];

  let min_active_demand_of_current_month: any = [];
  let min_reactive_demand_of_current_month: any = [];

  let r_phase_THD_Voltage_of_current_month: any = [];
  let y_phase_THD_Voltage_of_current_month: any = [];
  let b_phase_THD_Voltage_of_current_month: any = [];
  let ry_phase_THD_Voltage_of_current_month: any = [];
  let yb_phase_THD_Voltage_of_current_month: any = [];
  let rb_phase_THD_Voltage_of_current_month: any = [];
  let r_phase_THD_Current_of_current_month: any = [];
  let y_phase_THD_Current_of_current_month: any = [];
  let b_phase_THD_Current_of_current_month: any = [];

  let intLNVoltPdfData: any = [];
  let intLLVoltPdfData: any = [];
  let intCurrPdfData: any = [];
  let getPowerFactorData: any = [];
  let getTDHDataToExport: any = [];

  if (value === 0) {
    if (apiData.length > 0) {
      apiData.forEach(
        ({
          timestamp,
          activeenergy,
          reactiveenergy,
          apparentenergy,
          importactenrgy,
          exportactenrgy,
          importreactenrgy,
          exportreactenrgy,
        }: any) => {
          energy_data_pdfData.push({
            timestamp,
            // Active Energy
            ...(customTagData?.["t_acte"]?.sensorStatus
              ? {
                  [`${customTagData["t_acte"]?.sensorAlias} ${
                    customTagData["t_acte"]?.sensorUnit || "kWh"
                  }`]: activeenergy,
                }
              : !customTagData?.["t_acte"]
              ? { "Active Energy (kWh)": activeenergy }
              : {}),

            // Reactive Energy
            ...(customTagData?.["t_re"]?.sensorStatus
              ? {
                  [`${customTagData["t_re"]?.sensorAlias} ${
                    customTagData["t_re"]?.sensorUnit || "kVarh"
                  }`]: reactiveenergy,
                }
              : !customTagData?.["t_re"]
              ? { "Reactive Energy (kVarh)": reactiveenergy }
              : {}),

            // Apparent Energy
            ...(customTagData?.["t_apte"]?.sensorStatus
              ? {
                  [`${customTagData["t_apte"]?.sensorAlias} ${
                    customTagData["t_apte"]?.sensorUnit || "kVah"
                  }`]: apparentenergy,
                }
              : !customTagData?.["t_apte"]
              ? { "Apparent Energy (kVah)": apparentenergy }
              : {}),

            // Import Active
            ...(customTagData?.["imp_ae"]?.sensorStatus
              ? {
                  [`${customTagData["imp_ae"]?.sensorAlias} ${
                    customTagData["imp_ae"]?.sensorUnit || "kWh"
                  }`]: importactenrgy,
                }
              : !customTagData?.["imp_ae"]
              ? { "Import Active (kWh)": importactenrgy }
              : {}),

            // Export Active
            ...(customTagData?.["exp_ae"]?.sensorStatus
              ? {
                  [`${customTagData["exp_ae"]?.sensorAlias} ${
                    customTagData["exp_ae"]?.sensorUnit || "kWh"
                  }`]: exportactenrgy,
                }
              : !customTagData?.["exp_ae"]
              ? { "Export Active (kWh)": exportactenrgy }
              : {}),

            // Import Reactive
            ...(customTagData?.["imp_re"]?.sensorStatus
              ? {
                  [`${customTagData["imp_re"]?.sensorAlias} ${
                    customTagData["imp_re"]?.sensorUnit || "kVarh"
                  }`]: importreactenrgy,
                }
              : !customTagData?.["imp_re"]
              ? { "Import Reactive (kVarh)": importreactenrgy }
              : {}),

            // Export Reactive
            ...(customTagData?.["exp_re"]?.sensorStatus
              ? {
                  [`${customTagData["exp_re"]?.sensorAlias} ${
                    customTagData["exp_re"]?.sensorUnit || "kVarh"
                  }`]: exportreactenrgy,
                }
              : !customTagData?.["exp_re"]
              ? { "Export Reactive (kVarh)": exportreactenrgy }
              : {}),
          });

          active_energy_last24hour.push({
            timestamp,
            value:
              activeenergy === undefined || activeenergy === ""
                ? 0
                : activeenergy,
          });
          reactive_energy_last24hour.push({
            timestamp,
            value:
              reactiveenergy === undefined || reactiveenergy === ""
                ? 0
                : reactiveenergy,
          });

          apparent_energy_last24hour.push({
            timestamp,
            value:
              apparentenergy === undefined || apparentenergy === ""
                ? 0
                : apparentenergy,
          });

          import_active_last24hour.push({
            timestamp,
            value:
              importactenrgy === undefined || importactenrgy === ""
                ? 0
                : importactenrgy,
          });

          export_active_last24hour.push({
            timestamp,
            value:
              exportactenrgy === undefined || exportactenrgy === ""
                ? 0
                : exportactenrgy,
          });

          import_reactive_last24hour.push({
            timestamp,
            value:
              importreactenrgy === undefined || importreactenrgy === ""
                ? 0
                : importreactenrgy,
          });

          export_reactive_last24hour.push({
            timestamp,
            value:
              exportreactenrgy === undefined || exportreactenrgy === ""
                ? 0
                : exportreactenrgy,
          });
        }
      );
    }
  } else if (value === 1) {
    if (apiData.length > 0) {
      apiData.forEach(
        ({
          timestamp,
          avgneuvolt,
          rphvtg,
          yphvtg,
          bphvtg,
          avglinevolt,
          ryphvtg,
          ybpgvtg,
          rbphvtg,
          avgcrt,
          rphcrt,
          yphcrt,
          bphcrt,
          totalactpo,
          rphactpo,
          yphactpo,
          bphactpo,
          totalreactpo,
          rphreactpo,
          yphreactpo,
          bphreactpo,
          totalapppo,
          rphapppo,
          yphapppo,
          bphapppo,
          powerfactor,
          rphpf,
          yphpf,
          bphpf,
          frequency,
          mxactpower,
          minactpower,
          mxreactpower,
          minreactpower,
          mxapppopower,
          rvtgphthd,
          yvtgphthd,
          bvtgphthd,
          ryvtgphthd,
          ybvtgphthd,
          rbvtgphthd,
          rcrtphthd,
          ycrtphthd,
          bcrtphthd,
        }: any) => {
          avarage_neutral_data_of_current_month.push({
            timestamp,
            value:
              isNaN(avgneuvolt) || avgneuvolt === undefined ? 0 : avgneuvolt,
          });

          r_phase_Voltage_of_current_month.push({
            timestamp,
            value: isNaN(rphvtg) || rphvtg === undefined ? 0 : rphvtg,
          });

          y_phase_Voltage_of_current_month.push({
            timestamp,
            value: isNaN(yphvtg) || yphvtg === undefined ? 0 : yphvtg,
          });

          b_phase_Voltage_of_current_month.push({
            timestamp,
            value: isNaN(bphvtg) || bphvtg === undefined ? 0 : bphvtg,
          });

          avarage_L_L_data_of_current_month.push({
            timestamp,
            value:
              isNaN(avglinevolt) || avglinevolt === undefined ? 0 : avglinevolt,
          });

          ry_phase_Voltage_of_current_month.push({
            timestamp,
            value: isNaN(ryphvtg) || ryphvtg === undefined ? 0 : ryphvtg,
          });

          yb_phase_Voltage_of_current_month.push({
            timestamp,
            value: isNaN(ybpgvtg) || ybpgvtg === undefined ? 0 : ybpgvtg,
          });

          rb_phase_Voltage_of_current_month.push({
            timestamp,
            value: isNaN(rbphvtg) || rbphvtg === undefined ? 0 : rbphvtg,
          });

          total_Current_of_current_month.push({
            timestamp,
            value: isNaN(avgcrt) || avgcrt === undefined ? 0 : avgcrt,
          });

          r_phase_Current_of_current_month.push({
            timestamp,
            value: isNaN(rphcrt) || rphcrt === undefined ? 0 : rphcrt,
          });

          y_phase_Current_of_current_month.push({
            timestamp,
            value: isNaN(yphcrt) || yphcrt === undefined ? 0 : yphcrt,
          });

          b_phase_Current_of_current_month.push({
            timestamp,
            value: isNaN(bphcrt) || bphcrt === undefined ? 0 : bphcrt,
          });

          total_Active_power_of_current_month.push({
            timestamp,
            value:
              isNaN(totalactpo) || totalactpo === undefined ? 0 : totalactpo,
          });

          r_phase_Active_power_of_current_month.push({
            timestamp,
            value: isNaN(rphactpo) || rphactpo === undefined ? 0 : rphactpo,
          });

          y_phase_Active_power_of_current_month.push({
            timestamp,
            value: isNaN(yphactpo) || yphactpo === undefined ? 0 : yphactpo,
          });

          b_phase_Active_power_of_current_month.push({
            timestamp,
            value: isNaN(bphactpo) || bphactpo === undefined ? 0 : bphactpo,
          });

          total_Reactive_power_of_current_month.push({
            timestamp,
            value:
              isNaN(totalreactpo) || totalreactpo === undefined
                ? 0
                : totalreactpo,
          });

          r_phase_Reactive_power_of_current_month.push({
            timestamp,
            value:
              isNaN(rphreactpo) || rphreactpo === undefined ? 0 : rphreactpo,
          });

          y_phase_Reactive_power_of_current_month.push({
            timestamp,
            value:
              isNaN(yphreactpo) || yphreactpo === undefined ? 0 : yphreactpo,
          });

          b_phase_Reactive_power_of_current_month.push({
            timestamp,
            value:
              isNaN(bphreactpo) || bphreactpo === undefined ? 0 : bphreactpo,
          });

          total_Apparent_power_of_current_month.push({
            timestamp,
            value:
              isNaN(totalapppo) || totalapppo === undefined ? 0 : totalapppo,
          });

          r_phase_Apparent_power_of_current_month.push({
            timestamp,
            value: isNaN(rphapppo) || rphapppo === undefined ? 0 : rphapppo,
          });

          y_phase_Apparent_power_of_current_month.push({
            timestamp,
            value: isNaN(yphapppo) || yphapppo === undefined ? 0 : yphapppo,
          });

          b_phase_Apparent_power_of_current_month.push({
            timestamp,
            value: isNaN(bphapppo) || bphapppo === undefined ? 0 : bphapppo,
          });

          total_Power_Factor_of_current_month.push({
            timestamp,
            value:
              isNaN(powerfactor) || powerfactor === undefined ? 0 : powerfactor,
          });

          r_phase_Power_Factor_of_current_month.push({
            timestamp,
            value: isNaN(rphpf) || rphpf === undefined ? 0 : rphpf,
          });

          y_phase_Power_Factor_of_current_month.push({
            timestamp,
            value: isNaN(yphpf) || yphpf === undefined ? 0 : yphpf,
          });

          b_phase_Power_Factor_of_current_month.push({
            timestamp,
            value: isNaN(bphpf) || bphpf === undefined ? 0 : bphpf,
          });

          frequency_of_current_month.push({
            timestamp,
            value: isNaN(frequency) || frequency === undefined ? 0 : frequency,
          });

          max_active_demand_of_current_month.push({
            timestamp,
            value:
              isNaN(mxactpower) || mxactpower === undefined ? 0 : mxactpower,
          });

          max_reactive_demand_of_current_month.push({
            timestamp,
            value:
              isNaN(mxreactpower) || mxreactpower === undefined
                ? 0
                : mxreactpower,
          });

          max_apparend_demand_of_current_month.push({
            timestamp,
            value:
              isNaN(mxapppopower) || mxapppopower === undefined
                ? 0
                : mxapppopower,
          });

          min_active_demand_of_current_month.push({
            timestamp,
            value:
              isNaN(minactpower) || minactpower === undefined ? 0 : minactpower,
          });

          min_reactive_demand_of_current_month.push({
            timestamp,
            value:
              isNaN(minreactpower) || minreactpower === undefined
                ? 0
                : minreactpower,
          });

          r_phase_THD_Voltage_of_current_month.push({
            timestamp,
            value: isNaN(rvtgphthd) || rvtgphthd === undefined ? 0 : rvtgphthd,
          });

          y_phase_THD_Voltage_of_current_month.push({
            timestamp,
            value: isNaN(yvtgphthd) || yvtgphthd === undefined ? 0 : yvtgphthd,
          });

          b_phase_THD_Voltage_of_current_month.push({
            timestamp,
            value: isNaN(bvtgphthd) || bvtgphthd === undefined ? 0 : bvtgphthd,
          });

          ry_phase_THD_Voltage_of_current_month.push({
            timestamp,
            value:
              isNaN(ryvtgphthd) || ryvtgphthd === undefined ? 0 : ryvtgphthd,
          });

          yb_phase_THD_Voltage_of_current_month.push({
            timestamp,
            value:
              isNaN(ybvtgphthd) || ybvtgphthd === undefined ? 0 : ybvtgphthd,
          });

          rb_phase_THD_Voltage_of_current_month.push({
            timestamp,
            value:
              isNaN(rbvtgphthd) || rbvtgphthd === undefined ? 0 : rbvtgphthd,
          });

          r_phase_THD_Current_of_current_month.push({
            timestamp,
            value: isNaN(rcrtphthd) || rcrtphthd === undefined ? 0 : rcrtphthd,
          });

          y_phase_THD_Current_of_current_month.push({
            timestamp,
            value: isNaN(ycrtphthd) || ycrtphthd === undefined ? 0 : ycrtphthd,
          });

          b_phase_THD_Current_of_current_month.push({
            timestamp,
            value: isNaN(bcrtphthd) || bcrtphthd === undefined ? 0 : bcrtphthd,
          });

          intLNVoltPdfData.push({
            TimeStamp: timestamp,
            ...(customTagData?.["av_ln"]?.sensorStatus
              ? {
                  [`${customTagData["av_ln"]?.sensorAlias} ${customTagData["av_ln"]?.sensorUnit}`]:
                    avgneuvolt,
                }
              : !customTagData?.["av_ln"]
              ? { "Average Neutral": avgneuvolt }
              : {}),

            ...(customTagData?.["v1n"]?.sensorStatus
              ? {
                  [`${customTagData["v1n"]?.sensorAlias} ${customTagData["v1n"]?.sensorUnit}`]:
                    rphvtg,
                }
              : !customTagData?.["v1n"]
              ? { "R Phase": rphvtg }
              : {}),

            ...(customTagData?.["v2n"]?.sensorStatus
              ? {
                  [`${customTagData["v2n"]?.sensorAlias} ${customTagData["v2n"]?.sensorUnit}`]:
                    yphvtg,
                }
              : !customTagData?.["v2n"]
              ? { "Y Phase": yphvtg }
              : {}),

            ...(customTagData?.["v3n"]?.sensorStatus
              ? {
                  [`${customTagData["v3n"]?.sensorAlias} ${customTagData["v3n"]?.sensorUnit}`]:
                    bphvtg,
                }
              : !customTagData?.["v3n"]
              ? { "B Phase": bphvtg }
              : {}),
          });

          intLLVoltPdfData.push({
            TimeStamp: timestamp,
            ...(customTagData?.["av_ll"]?.sensorStatus
              ? {
                  [`${customTagData["av_ll"]?.sensorAlias} ${customTagData["av_ll"]?.sensorUnit}`]:
                    avglinevolt,
                }
              : !customTagData?.["av_ll"]
              ? { "Average Line-Line": avglinevolt }
              : {}),

            ...(customTagData?.["v12"]?.sensorStatus
              ? {
                  [`${customTagData["v12"]?.sensorAlias} ${customTagData["v12"]?.sensorUnit}`]:
                    ryphvtg,
                }
              : !customTagData?.["v12"]
              ? { "RY Phase": ryphvtg }
              : {}),

            ...(customTagData?.["v23"]?.sensorStatus
              ? {
                  [`${customTagData["v23"]?.sensorAlias} ${customTagData["v23"]?.sensorUnit}`]:
                    ybpgvtg,
                }
              : !customTagData?.["v23"]
              ? { "YB Phase": ybpgvtg }
              : {}),

            ...(customTagData?.["v31"]?.sensorStatus
              ? {
                  [`${customTagData["v31"]?.sensorAlias} ${customTagData["v31"]?.sensorUnit}`]:
                    rbphvtg,
                }
              : !customTagData?.["v31"]
              ? { "RB Phase": rbphvtg }
              : {}),
          });

          intCurrPdfData.push({
            TimeStamp: timestamp,
            ...(customTagData?.["av_c"]?.sensorStatus
              ? {
                  [`${customTagData["av_c"]?.sensorAlias} ${customTagData["av_c"]?.sensorUnit}`]:
                    avgcrt,
                }
              : !customTagData?.["av_c"]
              ? { "Avarage Current": avgcrt }
              : {}),

            ...(customTagData?.["cl1"]?.sensorStatus
              ? {
                  [`${customTagData["cl1"]?.sensorAlias} ${customTagData["cl1"]?.sensorUnit}`]:
                    rphcrt,
                }
              : !customTagData?.["cl1"]
              ? { "R Phase": rphcrt }
              : {}),

            ...(customTagData?.["cl2"]?.sensorStatus
              ? {
                  [`${customTagData["cl2"]?.sensorAlias} ${customTagData["cl2"]?.sensorUnit}`]:
                    yphcrt,
                }
              : !customTagData?.["cl2"]
              ? { "Y Phase": yphcrt }
              : {}),

            ...(customTagData?.["cl3"]?.sensorStatus
              ? {
                  [`${customTagData["cl3"]?.sensorAlias} ${customTagData["cl3"]?.sensorUnit}`]:
                    bphcrt,
                }
              : !customTagData?.["cl3"]
              ? { "B Phase": bphcrt }
              : {}),
          });

          getPowerFactorData.push({
            TimeStamp: timestamp,
            ...(customTagData?.["av_pf"]?.sensorStatus
              ? {
                  [`${customTagData["av_pf"]?.sensorAlias} ${customTagData["av_pf"]?.sensorUnit}`]:
                    powerfactor,
                }
              : !customTagData?.["av_pf"]
              ? { "Average PF": powerfactor }
              : {}),

            ...(customTagData?.["pf1"]?.sensorStatus
              ? {
                  [`${customTagData["pf1"]?.sensorAlias} ${customTagData["pf1"]?.sensorUnit}`]:
                    rphpf,
                }
              : !customTagData?.["pf1"]
              ? { "R Phase": rphpf }
              : {}),

            ...(customTagData?.["pf2"]?.sensorStatus
              ? {
                  [`${customTagData["pf2"]?.sensorAlias} ${customTagData["pf2"]?.sensorUnit}`]:
                    yphpf,
                }
              : !customTagData?.["pf2"]
              ? { "Y Phase": yphpf }
              : {}),

            ...(customTagData?.["pf3"]?.sensorStatus
              ? {
                  [`${customTagData["pf3"]?.sensorAlias} ${customTagData["pf3"]?.sensorUnit}`]:
                    bphpf,
                }
              : !customTagData?.["pf3"]
              ? { "B Phase": bphpf }
              : {}),
          });

          if (valueInstTDH === 0) {
            getTDHDataToExport.push({
              TimeStamp: timestamp,
              "R Phase Voltage": rvtgphthd,
              "Y Phase Voltage": yvtgphthd,
              "B Phase Voltage": bvtgphthd,
            });
          } else if (valueInstTDH === 1) {
            getTDHDataToExport.push({
              TimeStamp: timestamp,
              "RY Phase Voltage": ryvtgphthd,
              "YB Phase Voltage": ybvtgphthd,
              "RB Phase Voltage": rbvtgphthd,
            });
          } else {
            getTDHDataToExport.push({
              TimeStamp: timestamp,
              "R Phase Current": rcrtphthd,
              "Y Phase Current": ycrtphthd,
              "B Phase Current": bcrtphthd,
            });
          }

          frequency_for_pdf.push({
            TimeStamp: timestamp,
            Frequency: frequency,
          });
        }
      );
    }
  }

  const linechartEnergyData = [
    ...(customTagData?.["t_acte"]?.sensorStatus === true ||
    !customTagData?.["t_acte"]
      ? [
          {
            name: customTagData?.["t_acte"]?.sensorAlias || "Active Energy",
            color: "#FF0000",
            data: active_energy_last24hour,
            postfix: customTagData?.["t_acte"]?.sensorUnit || " kWh",
          },
        ]
      : []),

    ...(customTagData?.["t_re"]?.sensorStatus === true ||
    !customTagData?.["t_re"]
      ? [
          {
            name: customTagData?.["t_re"]?.sensorAlias || "Reactive Energy",
            color: "#FFFF00",
            data: reactive_energy_last24hour,
            postfix: customTagData?.["t_re"]?.sensorUnit || " kVarh",
          },
        ]
      : []),

    ...(customTagData?.["t_apte"]?.sensorStatus === true ||
    !customTagData?.["t_apte"]
      ? [
          {
            name: customTagData?.["t_apte"]?.sensorAlias || "Apparent Energy",
            color: "#0000FF",
            data: apparent_energy_last24hour,
            postfix: customTagData?.["t_apte"]?.sensorUnit || " kVah",
          },
        ]
      : []),

    ...(customTagData?.["imp_ae"]?.sensorStatus === true ||
    !customTagData?.["imp_ae"]
      ? [
          {
            name: customTagData?.["imp_ae"]?.sensorAlias || "Import Active",
            color: "#228B22",
            data: import_active_last24hour,
            postfix: customTagData?.["imp_ae"]?.sensorUnit || " kWh",
          },
        ]
      : []),

    ...(customTagData?.["exp_ae"]?.sensorStatus === true ||
    !customTagData?.["exp_ae"]
      ? [
          {
            name: customTagData?.["exp_ae"]?.sensorAlias || "Export Active",
            color: "#7CFC00",
            data: export_active_last24hour,
            postfix: customTagData?.["exp_ae"]?.sensorUnit || " kWh",
          },
        ]
      : []),

    ...(customTagData?.["imp_re"]?.sensorStatus === true ||
    !customTagData?.["imp_re"]
      ? [
          {
            name: customTagData?.["imp_re"]?.sensorAlias || "Import Reactive",
            color: "#228B22",
            data: import_reactive_last24hour,
            postfix: customTagData?.["imp_re"]?.sensorUnit || " kVarh",
          },
        ]
      : []),

    ...(customTagData?.["exp_re"]?.sensorStatus === true ||
    !customTagData?.["exp_re"]
      ? [
          {
            name: customTagData?.["exp_re"]?.sensorAlias || "Export Reactive",
            color: "#097969",
            data: export_reactive_last24hour,
            postfix: customTagData?.["exp_re"]?.sensorUnit || " kVarh",
          },
        ]
      : []),
  ];

  const lineChartOfLNVoltageVariations: any = [
    ...(customTagData?.["av_ln"]?.sensorStatus || !customTagData?.["av_ln"]
      ? [
          {
            name: customTagData?.["av_ln"]?.sensorAlias || "Average Neutral",
            color: "#008000",
            data: avarage_neutral_data_of_current_month,
            postfix: customTagData?.["av_ln"]?.sensorUnit || " V",
          },
        ]
      : []),

    ...(customTagData?.["v1n"]?.sensorStatus || !customTagData?.["v1n"]
      ? [
          {
            name: customTagData?.["v1n"]?.sensorAlias || "R Phase Voltage",
            color: "#FF0000",
            data: r_phase_Voltage_of_current_month,
            postfix: customTagData?.["v1n"]?.sensorUnit || " V",
          },
        ]
      : []),

    ...(customTagData?.["v2n"]?.sensorStatus || !customTagData?.["v2n"]
      ? [
          {
            name: customTagData?.["v2n"]?.sensorAlias || "Y Phase Voltage",
            color: "#FFFF00",
            data: y_phase_Voltage_of_current_month,
            postfix: customTagData?.["v2n"]?.sensorUnit || " V",
          },
        ]
      : []),

    ...(customTagData?.["v3n"]?.sensorStatus || !customTagData?.["v3n"]
      ? [
          {
            name: customTagData?.["v3n"]?.sensorAlias || "B Phase Voltage",
            color: "#0000FF",
            data: b_phase_Voltage_of_current_month,
            postfix: customTagData?.["v3n"]?.sensorUnit || " V",
          },
        ]
      : []),
  ];

  const lineChartOfLLVoltageVariations: any = [
    ...(customTagData?.["av_ll"]?.sensorStatus || !customTagData?.["av_ll"]
      ? [
          {
            name: customTagData?.["av_ll"]?.sensorAlias || "Average L-L",
            color: "#008000",
            data: avarage_L_L_data_of_current_month,
            postfix: customTagData?.["av_ll"]?.sensorUnit || " V",
          },
        ]
      : []),

    ...(customTagData?.["v12"]?.sensorStatus || !customTagData?.["v12"]
      ? [
          {
            name: customTagData?.["v12"]?.sensorAlias || "RY Phase Voltage",
            color: "#FF0000",
            data: ry_phase_Voltage_of_current_month,
            postfix: customTagData?.["v12"]?.sensorUnit || " V",
          },
        ]
      : []),

    ...(customTagData?.["v23"]?.sensorStatus || !customTagData?.["v23"]
      ? [
          {
            name: customTagData?.["v23"]?.sensorAlias || "YB Phase Voltage",
            color: "#FFFF00",
            data: yb_phase_Voltage_of_current_month,
            postfix: customTagData?.["v23"]?.sensorUnit || " V",
          },
        ]
      : []),

    ...(customTagData?.["v31"]?.sensorStatus || !customTagData?.["v31"]
      ? [
          {
            name: customTagData?.["v31"]?.sensorAlias || "RB Phase Voltage",
            color: "#0000FF",
            data: rb_phase_Voltage_of_current_month,
            postfix: customTagData?.["v31"]?.sensorUnit || " V",
          },
        ]
      : []),
  ];
  const lineChartOfCurrentVariations: any = [
    ...(customTagData?.["av_c"]?.sensorStatus || !customTagData?.["av_c"]
      ? [
          {
            name: customTagData?.["av_c"]?.sensorAlias || "Average Current",
            color: "#008000",
            data: total_Current_of_current_month,
            postfix: customTagData?.["av_c"]?.sensorUnit || " V",
          },
        ]
      : []),
    ...(customTagData?.["cl1"]?.sensorStatus || !customTagData?.["cl1"]
      ? [
          {
            name: customTagData?.["cl1"]?.sensorAlias || "R Phase Current",
            color: "#FF0000",
            data: r_phase_Current_of_current_month,
            postfix: customTagData?.["cl1"]?.sensorUnit || " A",
          },
        ]
      : []),

    ...(customTagData?.["cl2"]?.sensorStatus || !customTagData?.["cl2"]
      ? [
          {
            name: customTagData?.["cl2"]?.sensorAlias || "Y Phase Current",
            color: "#FFFF00",
            data: y_phase_Current_of_current_month,
            postfix: customTagData?.["cl2"]?.sensorUnit || " A",
          },
        ]
      : []),

    ...(customTagData?.["cl3"]?.sensorStatus || !customTagData?.["cl3"]
      ? [
          {
            name: customTagData?.["cl3"]?.sensorAlias || "B Phase Current",
            color: "#0000FF",
            data: b_phase_Current_of_current_month,
            postfix: customTagData?.["cl3"]?.sensorUnit || " A",
          },
        ]
      : []),
  ];

  const lineChartOfActivePowerVariations: any = [
    ...(customTagData?.["t_kw"]?.sensorStatus || !customTagData?.["t_kw"]
      ? [
          {
            name: customTagData?.["t_kw"]?.sensorAlias || "Total Active Power",
            color: "#008000",
            data: total_Active_power_of_current_month,
            postfix: customTagData?.["t_kw"]?.sensorUnit || " kW",
          },
        ]
      : []),

    ...(customTagData?.["kw1"]?.sensorStatus || !customTagData?.["kw1"]
      ? [
          {
            name: customTagData?.["kw1"]?.sensorAlias || "R Phase Active Power",
            color: "#FF0000",
            data: r_phase_Active_power_of_current_month,
            postfix: customTagData?.["kw1"]?.sensorUnit || " kW",
          },
        ]
      : []),

    ...(customTagData?.["kw2"]?.sensorStatus || !customTagData?.["kw2"]
      ? [
          {
            name: customTagData?.["kw2"]?.sensorAlias || "Y Phase Active Power",
            color: "#FFFF00",
            data: y_phase_Active_power_of_current_month,
            postfix: customTagData?.["kw2"]?.sensorUnit || " kW",
          },
        ]
      : []),

    ...(customTagData?.["kw3"]?.sensorStatus || !customTagData?.["kw3"]
      ? [
          {
            name: customTagData?.["kw3"]?.sensorAlias || "B Phase Active Power",
            color: "#0000FF",
            data: b_phase_Active_power_of_current_month,
            postfix: customTagData?.["kw3"]?.sensorUnit || " kW",
          },
        ]
      : []),
  ];
  const lineChartOfReactivePowerVariations: any = [
    ...(customTagData?.["t_kvar"]?.sensorStatus || !customTagData?.["t_kvar"]
      ? [
          {
            name:
              customTagData?.["t_kvar"]?.sensorAlias || "Total Reactive Power",
            color: "#008000",
            data: total_Reactive_power_of_current_month,
            postfix: customTagData?.["t_kvar"]?.sensorUnit || " kVAr",
          },
        ]
      : []),
    ...(customTagData?.["kvar1"]?.sensorStatus || !customTagData?.["kvar1"]
      ? [
          {
            name:
              customTagData?.["kvar1"]?.sensorAlias || "R Phase Reactive Power",
            color: "#FF0000",
            data: r_phase_Reactive_power_of_current_month,
            postfix: customTagData?.["kvar1"]?.sensorUnit || " kVAr",
          },
        ]
      : []),
    ...(customTagData?.["kvar2"]?.sensorStatus || !customTagData?.["kvar2"]
      ? [
          {
            name:
              customTagData?.["kvar2"]?.sensorAlias || "Y Phase Reactive Power",
            color: "#FFFF00",
            data: y_phase_Reactive_power_of_current_month,
            postfix: customTagData?.["kvar2"]?.sensorUnit || " kVAr",
          },
        ]
      : []),
    ...(customTagData?.["kvar3"]?.sensorStatus || !customTagData?.["kvar3"]
      ? [
          {
            name:
              customTagData?.["kvar3"]?.sensorAlias || "B Phase Reactive Power",
            color: "#0000FF",
            data: b_phase_Reactive_power_of_current_month,
            postfix: customTagData?.["kvar3"]?.sensorUnit || " kVAr",
          },
        ]
      : []),
  ];
  const lineChartOfApparentPowerVariations: any = [
    ...(customTagData?.["t_kva"]?.sensorStatus || !customTagData?.["t_kva"]
      ? [
          {
            name:
              customTagData?.["t_kva"]?.sensorAlias || "Total Apparent Power",
            color: "#008000",
            data: total_Apparent_power_of_current_month,
            postfix: customTagData?.["t_kva"]?.sensorUnit || " kVa",
          },
        ]
      : []),
    ...(customTagData?.["kva1"]?.sensorStatus || !customTagData?.["kva1"]
      ? [
          {
            name:
              customTagData?.["kva1"]?.sensorAlias || "R Phase Apparent Power",
            color: "#FF0000",
            data: r_phase_Apparent_power_of_current_month,
            postfix: customTagData?.["kva1"]?.sensorUnit || " kVa",
          },
        ]
      : []),
    ...(customTagData?.["kva2"]?.sensorStatus || !customTagData?.["kva2"]
      ? [
          {
            name:
              customTagData?.["kva2"]?.sensorAlias || "Y Phase Apparent Power",
            color: "#FFFF00",
            data: y_phase_Apparent_power_of_current_month,
            postfix: customTagData?.["kva2"]?.sensorUnit || " kVa",
          },
        ]
      : []),
    ...(customTagData?.["kva3"]?.sensorStatus || !customTagData?.["kva3"]
      ? [
          {
            name:
              customTagData?.["kva3"]?.sensorAlias || "B Phase Apparent Power",
            color: "#0000FF",
            data: b_phase_Apparent_power_of_current_month,
            postfix: customTagData?.["kva3"]?.sensorUnit || " kVa",
          },
        ]
      : []),
  ];
  const lineChartOfPowerFactorVariations: any = [
    ...(customTagData?.["av_pf"]?.sensorStatus || !customTagData?.["av_pf"]
      ? [
          {
            name: customTagData?.["av_pf"]?.sensorAlias || "Average PF",
            color: "#008000",
            data: total_Power_Factor_of_current_month,
          },
        ]
      : []),

    ...(customTagData?.["pf1"]?.sensorStatus || !customTagData?.["pf1"]
      ? [
          {
            name: customTagData?.["pf1"]?.sensorAlias || "R Phase PF",
            color: "#FF0000",
            data: r_phase_Power_Factor_of_current_month,
          },
        ]
      : []),
    ...(customTagData?.["pf2"]?.sensorStatus || !customTagData?.["pf2"]
      ? [
          {
            name: customTagData?.["pf2"]?.sensorAlias || "Y Phase PF",
            color: "#FFFF00",
            data: y_phase_Power_Factor_of_current_month,
          },
        ]
      : []),
    ...(customTagData?.["pf3"]?.sensorStatus || !customTagData?.["pf3"]
      ? [
          {
            name: customTagData?.["pf3"]?.sensorAlias || "B Phase PF",
            color: "#0000FF",
            data: b_phase_Power_Factor_of_current_month,
          },
        ]
      : []),
  ];

  const lineChartOfFrequencyVariations: any = [
    ...(customTagData?.["freq"]?.sensorStatus || !customTagData?.["freq"]
      ? [
          {
            name: customTagData?.["freq"]?.sensorAlias || "Frequency",
            color: "#008000",
            data: frequency_of_current_month,
            postfix: customTagData?.["freq"]?.sensorUnit || " Hz",
          },
        ]
      : []),
  ];
  //Frequency Data Ends

  //Demand Line chart starts here
  const lineChartOfMaxDemandDataVariations: any = [
    ...(customTagData?.["ap_maxd"]?.sensorStatus || !customTagData?.["ap_maxd"]
      ? [
          {
            name: customTagData?.["ap_maxd"]?.sensorAlias || "Max Active Power",
            color: "#008000",
            data: max_active_demand_of_current_month,
            postfix: customTagData?.["ap_maxd"]?.sensorUnit || " kW",
          },
        ]
      : []),
    ...(customTagData?.["rp_maxd"]?.sensorStatus || !customTagData?.["rp_maxd"]
      ? [
          {
            name:
              customTagData?.["rp_maxd"]?.sensorAlias || "Max Reactive Power",
            color: "#FF0000",
            data: max_reactive_demand_of_current_month,
            postfix: customTagData?.["rp_maxd"]?.sensorUnit || " kVar",
          },
        ]
      : []),
    ...(customTagData?.["apr_maxd"]?.sensorStatus ||
    !customTagData?.["apr_maxd"]
      ? [
          {
            name:
              customTagData?.["apr_maxd"]?.sensorAlias || "Max Apparent Power",
            color: "#FFFF00",
            data: max_apparend_demand_of_current_month,
            postfix: customTagData?.["apr_maxd"]?.sensorUnit || " kVa",
          },
        ]
      : []),
  ];

  const lineChartOfMinDemandDataVariations: any = [
    ...(customTagData?.["ap_mind"]?.sensorStatus || !customTagData?.["ap_mind"]
      ? [
          {
            name: customTagData?.["ap_mind"]?.sensorAlias || "Min Active Power",
            color: "#008000",
            data: min_active_demand_of_current_month,
            postfix: customTagData?.["ap_mind"]?.sensorUnit || " kW",
          },
        ]
      : []),
    ...(customTagData?.["rp_mind"]?.sensorStatus || !customTagData?.["rp_mind"]
      ? [
          {
            name:
              customTagData?.["rp_mind"]?.sensorAlias || "Min Reactive Power",
            color: "#FF0000",
            data: min_reactive_demand_of_current_month,
            postfix: customTagData?.["rp_mind"]?.sensorUnit || " kVar",
          },
        ]
      : []),
  ];

  const lineChartOfTHDVoltageVariations: any = [
    ...(customTagData?.["thd_v1n"]?.sensorStatus || !customTagData?.["thd_v1n"]
      ? [
          {
            name: customTagData?.["thd_v1n"]?.sensorAlias || "R Phase Voltage",
            color: "#008000",
            data: r_phase_THD_Voltage_of_current_month,
            postfix: customTagData?.["thd_v1n"]?.sensorUnit || " %",
          },
        ]
      : []),
    ...(customTagData?.["thd_v2n"]?.sensorStatus || !customTagData?.["thd_v2n"]
      ? [
          {
            name: customTagData?.["thd_v2n"]?.sensorAlias || "Y Phase Voltage",
            color: "#FF0000",
            data: y_phase_THD_Voltage_of_current_month,
            postfix: customTagData?.["thd_v2n"]?.sensorUnit || " %",
          },
        ]
      : []),
    ...(customTagData?.["thd_v3n"]?.sensorStatus || !customTagData?.["thd_v3n"]
      ? [
          {
            name: customTagData?.["thd_v3n"]?.sensorAlias || "B Phase Voltage",
            color: "#FFFF00",
            data: b_phase_THD_Voltage_of_current_month,
            postfix: customTagData?.["thd_v3n"]?.sensorUnit || " %",
          },
        ]
      : []),
  ];

  const lineChartOfTHDLineToLineVoltVariations: any = [
    ...(customTagData?.["thd_v12"]?.sensorStatus || !customTagData?.["thd_v12"]
      ? [
          {
            name: customTagData?.["thd_v12"]?.sensorAlias || "RY Phase Voltage",
            color: "#008000",
            data: ry_phase_THD_Voltage_of_current_month,
            postfix: customTagData?.["thd_v12"]?.sensorUnit || " %",
          },
        ]
      : []),
    ...(customTagData?.["thd_v23"]?.sensorStatus || !customTagData?.["thd_v23"]
      ? [
          {
            name: customTagData?.["thd_v23"]?.sensorAlias || "YB Phase Voltage",
            color: "#FF0000",
            data: yb_phase_THD_Voltage_of_current_month,
            postfix: customTagData?.["thd_v23"]?.sensorUnit || " %",
          },
        ]
      : []),
    ...(customTagData?.["thd_v31"]?.sensorStatus || !customTagData?.["thd_v31"]
      ? [
          {
            name: customTagData?.["thd_v31"]?.sensorAlias || "RB Phase Voltage",
            color: "#FFFF00",
            data: rb_phase_THD_Voltage_of_current_month,
            postfix: customTagData?.["thd_v31"]?.sensorUnit || " %",
          },
        ]
      : []),
  ];

  const lineChartOfTHDCurrentVariations: any = [
    ...(customTagData?.["thd_ci1"]?.sensorStatus || !customTagData?.["thd_ci1"]
      ? [
          {
            name: customTagData?.["thd_ci1"]?.sensorAlias || "R Phase Current",
            color: "#008000",
            data: r_phase_THD_Current_of_current_month,
            postfix: customTagData?.["thd_ci1"]?.sensorUnit || " %",
          },
        ]
      : []),
    ...(customTagData?.["thd_ci2"]?.sensorStatus || !customTagData?.["thd_ci2"]
      ? [
          {
            name: customTagData?.["thd_ci2"]?.sensorAlias || "Y Phase Current",
            color: "#FF0000",
            data: y_phase_THD_Current_of_current_month,
            postfix: customTagData?.["thd_ci2"]?.sensorUnit || " %",
          },
        ]
      : []),
    ...(customTagData?.["thd_ci3"]?.sensorStatus || !customTagData?.["thd_ci3"]
      ? [
          {
            name: customTagData?.["thd_ci3"]?.sensorAlias || "B Phase Current",
            color: "#FFFF00",
            data: b_phase_THD_Current_of_current_month,
            postfix: customTagData?.["thd_ci3"]?.sensorUnit || " %",
          },
        ]
      : []),
  ];
  //THD Data Ends

  const getPowerDataToExport = (valueInstPower: number) => {
    if (valueInstPower === 0) {
      return apiData.map(
        ({ timestamp, totalactpo, rphactpo, yphactpo, bphactpo }: any) => ({
          TimeStamp: timestamp,

          ...(customTagData?.["t_kw"]?.sensorStatus
            ? {
                [`${customTagData["t_kw"]?.sensorAlias} ${
                  customTagData["t_kw"]?.sensorUnit || "kW"
                }`]: totalactpo,
              }
            : !customTagData?.["t_kw"]
            ? { "Total Active (kW)": totalactpo }
            : {}),

          ...(customTagData?.["kw1"]?.sensorStatus
            ? {
                [`${customTagData["kw1"]?.sensorAlias} ${
                  customTagData["kw1"]?.sensorUnit || "kW"
                }`]: rphactpo,
              }
            : !customTagData?.["kw1"]
            ? { "R Phase Active (kW)": rphactpo }
            : {}),

          ...(customTagData?.["kw2"]?.sensorStatus
            ? {
                [`${customTagData["kw2"]?.sensorAlias} ${
                  customTagData["kw2"]?.sensorUnit || "kW"
                }`]: yphactpo,
              }
            : !customTagData?.["kw2"]
            ? { "Y Phase Active (kW)": yphactpo }
            : {}),

          ...(customTagData?.["kw3"]?.sensorStatus
            ? {
                [`${customTagData["kw3"]?.sensorAlias} ${
                  customTagData["kw3"]?.sensorUnit || "kW"
                }`]: bphactpo,
              }
            : !customTagData?.["kw3"]
            ? { "B Phase Active (kW)": bphactpo }
            : {}),
        })
      );
    } else if (valueInstPower === 1) {
      return apiData.map(
        ({
          timestamp,
          totalreactpo,
          rphreactpo,
          yphreactpo,
          bphreactpo,
        }: any) => ({
          TimeStamp: timestamp,

          ...(customTagData?.["t_kvar"]?.sensorStatus
            ? {
                [`${customTagData["t_kvar"]?.sensorAlias} ${
                  customTagData["t_kvar"]?.sensorUnit || "kVAr"
                }`]: totalreactpo,
              }
            : !customTagData?.["t_kvar"]
            ? { "Total Reactive (kVAr)": totalreactpo }
            : {}),

          ...(customTagData?.["kvar1"]?.sensorStatus
            ? {
                [`${customTagData["kvar1"]?.sensorAlias} ${
                  customTagData["kvar1"]?.sensorUnit || "kVAr"
                }`]: rphreactpo,
              }
            : !customTagData?.["kvar1"]
            ? { "R Phase Reactive (kVAr)": rphreactpo }
            : []),

          ...(customTagData?.["kvar2"]?.sensorStatus
            ? {
                [`${customTagData["kvar2"]?.sensorAlias} ${
                  customTagData["kvar2"]?.sensorUnit || "kVAr"
                }`]: yphreactpo,
              }
            : !customTagData?.["kvar2"]
            ? { "Y Phase Reactive (kVAr)": yphreactpo }
            : []),

          ...(customTagData?.["kvar3"]?.sensorStatus
            ? {
                [`${customTagData["kvar3"]?.sensorAlias} ${
                  customTagData["kvar3"]?.sensorUnit || "kVAr"
                }`]: bphreactpo,
              }
            : !customTagData?.["kvar3"]
            ? { "B Phase Reactive (kVAr)": bphreactpo }
            : []),
        })
      );
    } else if (valueInstPower === 2) {
      return apiData.map(
        ({ timestamp, totalapppo, rphapppo, yphapppo, bphapppo }: any) => ({
          TimeStamp: timestamp,

          ...(customTagData?.["t_kva"]?.sensorStatus
            ? {
                [`${customTagData["t_kva"]?.sensorAlias} ${
                  customTagData["t_kva"]?.sensorUnit || "kVa"
                }`]: totalapppo,
              }
            : !customTagData?.["t_kva"]
            ? { "Total Apparent (kVa)": totalapppo }
            : []),

          ...(customTagData?.["kva1"]?.sensorStatus
            ? {
                [`${customTagData["kva1"]?.sensorAlias} ${
                  customTagData["kva1"]?.sensorUnit || "kVa"
                }`]: rphapppo,
              }
            : !customTagData?.["kva1"]
            ? { "R Phase Apparent (kVa)": rphapppo }
            : []),

          ...(customTagData?.["kva2"]?.sensorStatus
            ? {
                [`${customTagData["kva2"]?.sensorAlias} ${
                  customTagData["kva2"]?.sensorUnit || "kVa"
                }`]: yphapppo,
              }
            : !customTagData?.["kva2"]
            ? { "Y Phase Apparent (kVa)": yphapppo }
            : []),

          ...(customTagData?.["kva3"]?.sensorStatus
            ? {
                [`${customTagData["kva3"]?.sensorAlias} ${
                  customTagData["kva3"]?.sensorUnit || "kVa"
                }`]: bphapppo,
              }
            : !customTagData?.["kva3"]
            ? { "B Phase Apparent (kVa)": bphapppo }
            : []),
        })
      );
    }
  };

  const getDemandDataToExport = (valueInstDemand: number) => {
    if (valueInstDemand === 0) {
      return apiData.map(
        ({ timestamp, mxactpower, mxreactpower, mxapppopower }: any) => ({
          TimeStamp: timestamp,

          ...(customTagData?.["ap_maxd"]?.sensorStatus
            ? {
                [`${customTagData["ap_maxd"]?.sensorAlias} ${
                  customTagData["ap_maxd"]?.sensorUnit || "kW"
                }`]: mxactpower,
              }
            : !customTagData?.["ap_maxd"]
            ? { "Max Active Power (kW)": mxactpower }
            : []),

          ...(customTagData?.["rp_maxd"]?.sensorStatus
            ? {
                [`${customTagData["rp_maxd"]?.sensorAlias} ${
                  customTagData["rp_maxd"]?.sensorUnit || "kVar"
                }`]: mxreactpower,
              }
            : !customTagData?.["rp_maxd"]
            ? { "Max Reactive Power (kVar)": mxreactpower }
            : []),

          ...(customTagData?.["apr_maxd"]?.sensorStatus
            ? {
                [`${customTagData["apr_maxd"]?.sensorAlias} ${
                  customTagData["apr_maxd"]?.sensorUnit || "kVa"
                }`]: mxapppopower,
              }
            : !customTagData?.["apr_maxd"]
            ? { "Max Apparent Power (kVa)": mxapppopower }
            : []),
        })
      );
    } else if (valueInstDemand === 1) {
      return apiData.map(({ timestamp, minactpower, minreactpower }: any) => ({
        TimeStamp: timestamp,

        ...(customTagData?.["ap_mind"]?.sensorStatus
          ? {
              [`${customTagData["ap_mind"]?.sensorAlias} ${
                customTagData["ap_mind"]?.sensorUnit || "kW"
              }`]: minactpower,
            }
          : !customTagData?.["ap_mind"]
          ? { "Min Active Power (kW)": minactpower }
          : []),

        ...(customTagData?.["rp_mind"]?.sensorStatus
          ? {
              [`${customTagData["rp_mind"]?.sensorAlias} ${
                customTagData["rp_mind"]?.sensorUnit || "kVar"
              }`]: minreactpower,
            }
          : !customTagData?.["rp_mind"]
          ? { "Min Reactive Power (kVar)": minreactpower }
          : []),
      }));
    }
  };

  const handleExportBarChart = async (
    exportOption: string,
    dataValue: any,
    chart: any,
    label?: string
  ) => {
    switch (exportOption) {
      case "Download as CSV":
        generateCSV1(dataValue);
        break;
      case "Download as PDF":
        if (dataValue && chart) {
          reportDataWithGraph(
            chart,
            dataValue,
            devices?.deviceName,
            devices?.Customer?.name,
            "Energy Summary",
            label ? label : "Realtime - Last 24 Hour | IST"
          );
        } else {
        }
        return;
      default:
        return;
    }
  };

  let emptyBar: any[] | undefined;
  let consumtionData: any[] | undefined;
  if (valueAnalysis === 0) {
    const filterLast24hourDataOfEachHOur: any =
      filterDataFromEachHourEnergy(apiData);
    // console.log("filterLast24hourDataOfEach", filterLast24hourDataOfEachHOur);

    if (filterLast24hourDataOfEachHOur.length > 0) {
      const hourData = processHourlyDataEnergy(filterLast24hourDataOfEachHOur);
      // console.log("hour Data", hourData);

      consumtionData = processConsumptionDataEnergy(hourData);

      emptyBar = consumtionData ? fillMissingHours(consumtionData) : [];
    }
  } else if (valueAnalysis === 1) {
    let t: any = filterDataFromEachDayEnergy(apiData);
    if (t.length > 0) {
      const dailyData = processHourlyDataEnergyDaily(t);
      consumtionData = processMonthlyConsumptionEnergy(dailyData);

      emptyBar = consumtionData ? fillMissingDays(consumtionData) : [];
    }
  }

  const bargraph: any = [
    ...(customTagData?.["t_acte"]?.sensorStatus === true ||
    !customTagData?.["t_acte"]
      ? [
          {
            name: customTagData?.["t_acte"]?.sensorAlias || "Active Energy",
            color: "#101828",
            values: emptyBar
              ? emptyBar.map((item: any) => item.activeconsumption)
              : [],
            postfix: customTagData?.["t_acte"]?.sensorUnit || " kWh",
          },
        ]
      : []),

    ...(customTagData?.["t_re"]?.sensorStatus === true ||
    !customTagData?.["t_re"]
      ? [
          {
            name: customTagData?.["t_re"]?.sensorAlias || "Reactive Energy",
            color: "#0777A7",
            values: emptyBar
              ? emptyBar.map((item: any) => item.reactiveconsumption)
              : [],
            postfix: customTagData?.["t_re"]?.sensorUnit || " kVarh",
          },
        ]
      : []),

    ...(customTagData?.["t_apte"]?.sensorStatus === true ||
    !customTagData?.["t_apte"]
      ? [
          {
            name: customTagData?.["t_apte"]?.sensorAlias || "Apparent Energy",
            color: "#5EAFCF",
            values: emptyBar
              ? emptyBar.map((item: any) => item.apparentconsumption)
              : [],
            postfix: customTagData?.["t_apte"]?.sensorUnit || " kVAh",
          },
        ]
      : []),

    ...(customTagData?.["imp_ae"]?.sensorStatus === true ||
    !customTagData?.["imp_ae"]
      ? [
          {
            name: customTagData?.["imp_ae"]?.sensorAlias || "Import Active",
            color: "#59E659",
            values: emptyBar
              ? emptyBar.map((item: any) => item.importactiveconsumption)
              : [],
            postfix: customTagData?.["imp_ae"]?.sensorUnit || " kWh",
          },
        ]
      : []),

    ...(customTagData?.["exp_ae"]?.sensorStatus === true ||
    !customTagData?.["exp_ae"]
      ? [
          {
            name: customTagData?.["exp_ae"]?.sensorAlias || "Export Active",
            color: "#19A519",
            values: emptyBar
              ? emptyBar.map((item: any) => item.exportactiveconsumption)
              : [],
            postfix: customTagData?.["exp_ae"]?.sensorUnit || " kWh",
          },
        ]
      : []),

    ...(customTagData?.["imp_re"]?.sensorStatus === true ||
    !customTagData?.["imp_re"]
      ? [
          {
            name: customTagData?.["imp_re"]?.sensorAlias || "Import Reactive",
            color: "#FF7074",
            values: emptyBar
              ? emptyBar.map((item: any) => item.importreactiveconsumption)
              : [],
            postfix: customTagData?.["imp_re"]?.sensorUnit || " kVarh",
          },
        ]
      : []),

    ...(customTagData?.["exp_re"]?.sensorStatus === true ||
    !customTagData?.["exp_re"]
      ? [
          {
            name: customTagData?.["exp_re"]?.sensorAlias || "Export Reactive",
            color: "#AA2F33",
            values: emptyBar
              ? emptyBar.map((item: any) => item.exportreactiveconsumption)
              : [],
            postfix: customTagData?.["exp_re"]?.sensorUnit || " kVarh",
          },
        ]
      : []),
  ];

  const xAxisCategories = emptyBar
    ? emptyBar.map((item) =>
        valueAnalysis === 0 ? item.hour + ":00" : item.day
      )
    : [];

  const handleTabClick = useCallback((index: number) => {
    setvalueAnalysis(+index);
    if (index === 1) {
      setLoader(true);
    }
  }, []);

  return (
    <div>
      <div className="p-2 mb-4">
        <section style={{ display: "flex", alignItems: "center" }}>
          <div style={{ flex: 1 }}>
            <div style={{ display: "flex", alignItems: "start" }}>
              <MobileTowerIcon
                levels={dashboardSts?.rssi || 0}
                time={dashboardSts?.timestamp}
              />
              <p className="ml-2">
                {"Last Data Received at " +
                  (dashboardSts?.timestamp || "YYYY-MM-DD HH:MM:SS")}
              </p>
            </div>
          </div>

          <div className="w-[280px] ">
            <ul className="text-sm font-medium text-center text-gray-500 rounded-lg shadow sm:flex dark:divide-gray-700 dark:text-gray-400">
              <li className="w-full focus-within:z-10">
                <button
                  className={`inline-block w-full border border-gray-300 px-2 py-2 text-[1rem] text-gray-900 rounded-s-lg  transition-colors duration-300`}
                  aria-current="page"
                  style={value === 0 ? { backgroundColor: "#dadada" } : {}}
                  onClick={() => setValue(0)}
                >
                  Analysis
                </button>
              </li>

              <li className="w-full focus-within:z-10">
                <button
                  className={`inline-block w-full border border-gray-300 px-2 py-2 text-[1rem] text-gray-900 rounded-e-lg transition-colors duration-300`}
                  style={value === 1 ? { backgroundColor: "#dadada" } : {}}
                  onClick={() => setValue(1)}
                >
                  Instantaneous
                </button>
              </li>
            </ul>
          </div>
        </section>
      </div>
      {value === 0 && (
        <div className="border border-gray-400 p-2 mb-4 mt-5">
          <div className="w-[350px]">
            <ul className="text-sm font-medium text-center text-gray-500 rounded-lg shadow sm:flex dark:divide-gray-700 dark:text-gray-400">
              {["Meter Reading", "Daily Consumption", ,].map((item, index) => (
                <li key={item} className="w-full focus-within:z-10">
                  <button
                    className={`inline-block w-full border border-gray-300 px-1 py-2 text-[1rem] text-gray-900 ${
                      index === 0 ? "rounded-s-lg" : "rounded-e-lg"
                    } transition-colors duration-300`}
                    style={
                      valueAnalysis === index
                        ? { backgroundColor: "#dadada" }
                        : {}
                    }
                    onClick={() => handleTabClick(index)}
                  >
                    {item}
                  </button>
                </li>
              ))}
            </ul>
          </div>

          {value === 0 && valueAnalysis === 0 && (
            <div>
              <div className="flex flex-col justify-between mt-5">
                <div className="border rounded-md border-gray-400 h-1/2 p-4">
                  <div className="grid grid-rows-2 gap-5">
                    <div className="grid grid-cols-4 gap-5 my-cardTwo">
                      {customTagData?.["t_acte"]?.sensorStatus ? (
                        <CardThree
                          title={
                            customTagData?.["t_acte"]?.sensorAlias ||
                            "Active Energy"
                          }
                          amount={
                            active_energy_last24hour.length !== 0
                              ? `${
                                  active_energy_last24hour[
                                    active_energy_last24hour.length - 1
                                  ].value
                                } ${
                                  customTagData?.["t_acte"]?.sensorUnit || "kWh"
                                }`
                              : `N/A kWh`
                          }
                        />
                      ) : !customTagData?.["t_acte"] ? (
                        <CardThree
                          title="Active Energy"
                          amount={
                            active_energy_last24hour.length !== 0
                              ? `${
                                  active_energy_last24hour[
                                    active_energy_last24hour.length - 1
                                  ].value
                                } kWh`
                              : `N/A kWh`
                          }
                        />
                      ) : null}

                      {/* Reactive Energy Card */}
                      {customTagData?.["t_re"]?.sensorStatus ? (
                        <CardThree
                          title={
                            customTagData?.["t_re"]?.sensorAlias ||
                            "Reactive Energy"
                          }
                          amount={
                            reactive_energy_last24hour.length !== 0
                              ? `${
                                  reactive_energy_last24hour[
                                    reactive_energy_last24hour.length - 1
                                  ].value
                                } ${
                                  customTagData?.["t_re"]?.sensorUnit || "kVarh"
                                }`
                              : `N/A kVarh`
                          }
                        />
                      ) : !customTagData?.["t_re"] ? (
                        <CardThree
                          title="Reactive Energy"
                          amount={
                            reactive_energy_last24hour.length !== 0
                              ? `${
                                  reactive_energy_last24hour[
                                    reactive_energy_last24hour.length - 1
                                  ].value
                                } kVarh`
                              : `N/A kVarh`
                          }
                        />
                      ) : null}

                      {/* Apparent Energy Card */}
                      {customTagData?.["t_apte"]?.sensorStatus ? (
                        <CardThree
                          title={
                            customTagData?.["t_apte"]?.sensorAlias ||
                            "Apparent Energy"
                          }
                          amount={
                            apparent_energy_last24hour.length !== 0
                              ? `${
                                  apparent_energy_last24hour[
                                    apparent_energy_last24hour.length - 1
                                  ].value
                                } ${
                                  customTagData?.["t_apte"]?.sensorUnit ||
                                  "kVah"
                                }`
                              : `N/A kVah`
                          }
                        />
                      ) : !customTagData?.["t_apte"] ? (
                        <CardThree
                          title="Apparent Energy"
                          amount={
                            apparent_energy_last24hour.length !== 0
                              ? `${
                                  apparent_energy_last24hour[
                                    apparent_energy_last24hour.length - 1
                                  ].value
                                } kVah`
                              : `N/A kVah`
                          }
                        />
                      ) : null}

                      {/* Placeholder Card */}
                    </div>
                    <div className="grid grid-cols-4 gap-5 my-cardTwo">
                      {/* Import Active Energy Card */}
                      {customTagData?.["imp_ae"]?.sensorStatus ? (
                        <CardThree
                          title={
                            customTagData?.["imp_ae"]?.sensorAlias ||
                            "Import Active"
                          }
                          amount={
                            import_active_last24hour.length !== 0
                              ? `${
                                  import_active_last24hour[
                                    import_active_last24hour.length - 1
                                  ].value
                                } ${
                                  customTagData?.["imp_ae"]?.sensorUnit || "kWh"
                                }`
                              : `N/A kWh`
                          }
                        />
                      ) : !customTagData?.["imp_ae"] ? (
                        <CardThree
                          title="Import Active"
                          amount={
                            import_active_last24hour.length !== 0
                              ? `${
                                  import_active_last24hour[
                                    import_active_last24hour.length - 1
                                  ].value
                                } kWh`
                              : `N/A kWh`
                          }
                        />
                      ) : null}

                      {/* Export Active Energy Card */}
                      {customTagData?.["exp_ae"]?.sensorStatus ? (
                        <CardThree
                          title={
                            customTagData?.["exp_ae"]?.sensorAlias ||
                            "Export Active"
                          }
                          amount={
                            export_active_last24hour.length !== 0
                              ? `${
                                  export_active_last24hour[
                                    export_active_last24hour.length - 1
                                  ].value
                                } ${
                                  customTagData?.["exp_ae"]?.sensorUnit || "kWh"
                                }`
                              : `N/A kWh`
                          }
                        />
                      ) : !customTagData?.["exp_ae"] ? (
                        <CardThree
                          title="Export Active"
                          amount={
                            export_active_last24hour.length !== 0
                              ? `${
                                  export_active_last24hour[
                                    export_active_last24hour.length - 1
                                  ].value
                                } kWh`
                              : `N/A kWh`
                          }
                        />
                      ) : null}

                      {/* Import Reactive Energy Card */}
                      {customTagData?.["imp_re"]?.sensorStatus ? (
                        <CardThree
                          title={
                            customTagData?.["imp_re"]?.sensorAlias ||
                            "Import Reactive"
                          }
                          amount={
                            import_reactive_last24hour.length !== 0
                              ? `${
                                  import_reactive_last24hour[
                                    import_reactive_last24hour.length - 1
                                  ].value
                                } ${
                                  customTagData?.["imp_re"]?.sensorUnit ||
                                  "kVarh"
                                }`
                              : `N/A kVarh`
                          }
                        />
                      ) : !customTagData?.["imp_re"] ? (
                        <CardThree
                          title="Import Reactive"
                          amount={
                            import_reactive_last24hour.length !== 0
                              ? `${
                                  import_reactive_last24hour[
                                    import_reactive_last24hour.length - 1
                                  ].value
                                } kVarh`
                              : `N/A kVarh`
                          }
                        />
                      ) : null}

                      {/* Export Reactive Energy Card */}
                      {customTagData?.["exp_re"]?.sensorStatus ? (
                        <CardThree
                          title={
                            customTagData?.["exp_re"]?.sensorAlias ||
                            "Export Reactive"
                          }
                          amount={
                            export_reactive_last24hour.length !== 0
                              ? `${
                                  export_reactive_last24hour[
                                    export_reactive_last24hour.length - 1
                                  ].value
                                } ${
                                  customTagData?.["exp_re"]?.sensorUnit ||
                                  "kVarh"
                                }`
                              : `N/A kVarh`
                          }
                        />
                      ) : !customTagData?.["exp_re"] ? (
                        <CardThree
                          title="Export Reactive"
                          amount={
                            export_reactive_last24hour.length !== 0
                              ? `${
                                  export_reactive_last24hour[
                                    export_reactive_last24hour.length - 1
                                  ].value
                                } kVarh`
                              : `N/A kVarh`
                          }
                        />
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="h-4"></div>

                <div className="border rounded-md border-gray-400 h-1/2 p-2">
                  <div className="flex justify-center p-2">
                    <div
                      className="my_temp_humidity"
                      style={{
                        padding: "10px",
                        width: "100%",
                      }}
                    >
                      <div className="relative">
                        <p>
                          Energy meter reading data variation with time(between{" "}
                          {apiData.length > 0
                            ? apiData[0].timestamp +
                              " to " +
                              apiData[apiData.length - 1].timestamp
                            : " "}
                          )
                        </p>
                        <div className="absolute top-0 right-0">
                          <LongMenu
                            options={["Download as CSV"]}
                            onOptionClick={(exportOption) =>
                              handleExportBarChart(
                                exportOption,
                                energy_data_pdfData,
                                chartRef
                              )
                            }
                            id={1}
                          />
                        </div>
                        <div>
                          <div
                            ref={chartRef || ""}
                            style={{ marginTop: "20px" }}
                          >
                            <LineChart
                              data={linechartEnergyData}
                              yAxisName="Data"
                              xAxisName="Time"
                              stroke={true}
                            />
                          </div>
                        </div>
                        <div className="mb-4 mt-10">
                          <div className="relative mb-2 mt-1">
                            <p>
                              Hourly energy data variation with time(between{" "}
                              {emptyBar
                                ? emptyBar[0].hour +
                                  ":00 to " +
                                  emptyBar[emptyBar.length - 1].hour +
                                  ":00"
                                : " "}
                              )
                            </p>
                            <div className="absolute top-0 right-0">
                              <LongMenu
                                options={["Download as CSV"]}
                                onOptionClick={(exportOption) =>
                                  handleExportBarChart(
                                    exportOption,
                                    consumtionData?.map(
                                      ({
                                        hour,
                                        activeconsumption,
                                        reactiveconsumption,
                                        apparentconsumption,
                                        importactiveconsumption,
                                        exportactiveconsumption,
                                        importreactiveconsumption,
                                        exportreactiveconsumption,
                                        firstTimeStamp,
                                        lastTimeStamp,
                                      }: any) => {
                                        return {
                                          Hour: hour + ": 00",
                                          "First Time": firstTimeStamp,
                                          "Last Time": lastTimeStamp,
                                          // Active Energy
                                          ...(customTagData?.["t_acte"]
                                            ?.sensorStatus
                                            ? {
                                                [`${customTagData["t_acte"]?.sensorAlias} ${customTagData["t_acte"]?.sensorUnit}`]:
                                                  activeconsumption,
                                              }
                                            : !customTagData?.["t_acte"]
                                            ? {
                                                "Active(kWh)":
                                                  activeconsumption,
                                              }
                                            : {}),

                                          // Reactive Energy
                                          ...(customTagData?.["t_re"]
                                            ?.sensorStatus
                                            ? {
                                                [`${customTagData["t_re"]?.sensorAlias} ${customTagData["t_re"]?.sensorUnit}`]:
                                                  reactiveconsumption,
                                              }
                                            : !customTagData?.["t_re"]
                                            ? {
                                                "Reactive(kVarh)":
                                                  reactiveconsumption,
                                              }
                                            : {}),

                                          // Apparent Energy
                                          ...(customTagData?.["t_apte"]
                                            ?.sensorStatus
                                            ? {
                                                [`${customTagData["t_apte"]?.sensorAlias} ${customTagData["t_apte"]?.sensorUnit}`]:
                                                  apparentconsumption,
                                              }
                                            : !customTagData?.["t_apte"]
                                            ? {
                                                "Apparent(kVah)":
                                                  apparentconsumption,
                                              }
                                            : {}),

                                          // Import Active
                                          ...(customTagData?.["imp_ae"]
                                            ?.sensorStatus
                                            ? {
                                                [`${customTagData["imp_ae"]?.sensorAlias} ${customTagData["imp_ae"]?.sensorUnit}`]:
                                                  importactiveconsumption,
                                              }
                                            : !customTagData?.["imp_ae"]
                                            ? {
                                                "Import Act(kWh)":
                                                  importactiveconsumption,
                                              }
                                            : {}),

                                          // Export Active
                                          ...(customTagData?.["exp_ae"]
                                            ?.sensorStatus
                                            ? {
                                                [`${customTagData["exp_ae"]?.sensorAlias} ${customTagData["exp_ae"]?.sensorUnit}`]:
                                                  exportactiveconsumption,
                                              }
                                            : !customTagData?.["exp_ae"]
                                            ? {
                                                "Export Act(kWh)":
                                                  exportactiveconsumption,
                                              }
                                            : {}),

                                          // Import Reactive
                                          ...(customTagData?.["imp_re"]
                                            ?.sensorStatus
                                            ? {
                                                [`${customTagData["imp_re"]?.sensorAlias} ${customTagData["imp_re"]?.sensorUnit}`]:
                                                  importreactiveconsumption,
                                              }
                                            : !customTagData?.["imp_re"]
                                            ? {
                                                "Import React(kVarh)":
                                                  importreactiveconsumption,
                                              }
                                            : {}),

                                          // Export Reactive
                                          ...(customTagData?.["exp_re"]
                                            ?.sensorStatus
                                            ? {
                                                [`${customTagData["exp_re"]?.sensorAlias} ${customTagData["exp_re"]?.sensorUnit}`]:
                                                  exportreactiveconsumption,
                                              }
                                            : !customTagData?.["exp_re"]
                                            ? {
                                                "Export React(kVarh)":
                                                  exportreactiveconsumption,
                                              }
                                            : {}),
                                        };
                                      }
                                    ),
                                    barchartref1,
                                    "Hourly Data Variations"
                                  )
                                }
                                id={1}
                              />
                            </div>
                          </div>
                          <div ref={barchartref1 || ""}>
                            <DynamicBarChart
                              data={bargraph}
                              xAxisCategories={xAxisCategories}
                              yAxisName="Data"
                              xAxisName="Time"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {value === 0 && valueAnalysis === 1 && (
            <div>
              <div className="flex flex-col justify-between mt-5">
                <div className="border rounded-md border-gray-400 h-1/2 p-4">
                  <div className="grid grid-rows-2 gap-5">
                    <div className="grid grid-cols-4 gap-5 my-cardTwo">
                      {/* Active Energy Card */}
                      {customTagData?.["t_acte"]?.sensorStatus ? (
                        <CardThree
                          title={
                            customTagData?.["t_acte"]?.sensorAlias ||
                            "Active Energy"
                          }
                          amount={
                            active_energy_last24hour.length !== 0
                              ? `${
                                  active_energy_last24hour[
                                    active_energy_last24hour.length - 1
                                  ].value
                                } ${
                                  customTagData?.["t_acte"]?.sensorUnit || "kWh"
                                }`
                              : `N/A kWh`
                          }
                        />
                      ) : !customTagData?.["t_acte"] ? (
                        <CardThree
                          title="Active Energy"
                          amount={
                            active_energy_last24hour.length !== 0
                              ? `${
                                  active_energy_last24hour[
                                    active_energy_last24hour.length - 1
                                  ].value
                                } kWh`
                              : `N/A kWh`
                          }
                        />
                      ) : null}

                      {/* Reactive Energy Card */}
                      {customTagData?.["t_re"]?.sensorStatus ? (
                        <CardThree
                          title={
                            customTagData?.["t_re"]?.sensorAlias ||
                            "Reactive Energy"
                          }
                          amount={
                            reactive_energy_last24hour.length !== 0
                              ? `${
                                  reactive_energy_last24hour[
                                    reactive_energy_last24hour.length - 1
                                  ].value
                                } ${
                                  customTagData?.["t_re"]?.sensorUnit || "kVarh"
                                }`
                              : `N/A kVarh`
                          }
                        />
                      ) : !customTagData?.["t_re"] ? (
                        <CardThree
                          title="Reactive Energy"
                          amount={
                            reactive_energy_last24hour.length !== 0
                              ? `${
                                  reactive_energy_last24hour[
                                    reactive_energy_last24hour.length - 1
                                  ].value
                                } kVarh`
                              : `N/A kVarh`
                          }
                        />
                      ) : null}

                      {/* Apparent Energy Card */}
                      {customTagData?.["t_apte"]?.sensorStatus ? (
                        <CardThree
                          title={
                            customTagData?.["t_apte"]?.sensorAlias ||
                            "Apparent Energy"
                          }
                          amount={
                            apparent_energy_last24hour.length !== 0
                              ? `${
                                  apparent_energy_last24hour[
                                    apparent_energy_last24hour.length - 1
                                  ].value
                                } ${
                                  customTagData?.["t_apte"]?.sensorUnit ||
                                  "kVah"
                                }`
                              : `N/A kVah`
                          }
                        />
                      ) : !customTagData?.["t_apte"] ? (
                        <CardThree
                          title="Apparent Energy"
                          amount={
                            apparent_energy_last24hour.length !== 0
                              ? `${
                                  apparent_energy_last24hour[
                                    apparent_energy_last24hour.length - 1
                                  ].value
                                } kVah`
                              : `N/A kVah`
                          }
                        />
                      ) : null}

                      {/* Placeholder Card */}
                    </div>
                    <div className="grid grid-cols-4 gap-5 my-cardTwo">
                      {/* Import Active Energy Card */}
                      {customTagData?.["imp_ae"]?.sensorStatus ? (
                        <CardThree
                          title={
                            customTagData?.["imp_ae"]?.sensorAlias ||
                            "Import Active"
                          }
                          amount={
                            import_active_last24hour.length !== 0
                              ? `${
                                  import_active_last24hour[
                                    import_active_last24hour.length - 1
                                  ].value
                                } ${
                                  customTagData?.["imp_ae"]?.sensorUnit || "kWh"
                                }`
                              : `N/A kWh`
                          }
                        />
                      ) : !customTagData?.["imp_ae"] ? (
                        <CardThree
                          title="Import Active"
                          amount={
                            import_active_last24hour.length !== 0
                              ? `${
                                  import_active_last24hour[
                                    import_active_last24hour.length - 1
                                  ].value
                                } kWh`
                              : `N/A kWh`
                          }
                        />
                      ) : null}

                      {/* Export Active Energy Card */}
                      {customTagData?.["exp_ae"]?.sensorStatus ? (
                        <CardThree
                          title={
                            customTagData?.["exp_ae"]?.sensorAlias ||
                            "Export Active"
                          }
                          amount={
                            export_active_last24hour.length !== 0
                              ? `${
                                  export_active_last24hour[
                                    export_active_last24hour.length - 1
                                  ].value
                                } ${
                                  customTagData?.["exp_ae"]?.sensorUnit || "kWh"
                                }`
                              : `N/A kWh`
                          }
                        />
                      ) : !customTagData?.["exp_ae"] ? (
                        <CardThree
                          title="Export Active"
                          amount={
                            export_active_last24hour.length !== 0
                              ? `${
                                  export_active_last24hour[
                                    export_active_last24hour.length - 1
                                  ].value
                                } kWh`
                              : `N/A kWh`
                          }
                        />
                      ) : null}

                      {/* Import Reactive Energy Card */}
                      {customTagData?.["imp_re"]?.sensorStatus ? (
                        <CardThree
                          title={
                            customTagData?.["imp_re"]?.sensorAlias ||
                            "Import Reactive"
                          }
                          amount={
                            import_reactive_last24hour.length !== 0
                              ? `${
                                  import_reactive_last24hour[
                                    import_reactive_last24hour.length - 1
                                  ].value
                                } ${
                                  customTagData?.["imp_re"]?.sensorUnit ||
                                  "kVarh"
                                }`
                              : `N/A kVarh`
                          }
                        />
                      ) : !customTagData?.["imp_re"] ? (
                        <CardThree
                          title="Import Reactive"
                          amount={
                            import_reactive_last24hour.length !== 0
                              ? `${
                                  import_reactive_last24hour[
                                    import_reactive_last24hour.length - 1
                                  ].value
                                } kVarh`
                              : `N/A kVarh`
                          }
                        />
                      ) : null}

                      {/* Export Reactive Energy Card */}
                      {customTagData?.["exp_re"]?.sensorStatus ? (
                        <CardThree
                          title={
                            customTagData?.["exp_re"]?.sensorAlias ||
                            "Export Reactive"
                          }
                          amount={
                            export_reactive_last24hour.length !== 0
                              ? `${
                                  export_reactive_last24hour[
                                    export_reactive_last24hour.length - 1
                                  ].value
                                } ${
                                  customTagData?.["exp_re"]?.sensorUnit ||
                                  "kVarh"
                                }`
                              : `N/A kVarh`
                          }
                        />
                      ) : !customTagData?.["exp_re"] ? (
                        <CardThree
                          title="Export Reactive"
                          amount={
                            export_reactive_last24hour.length !== 0
                              ? `${
                                  export_reactive_last24hour[
                                    export_reactive_last24hour.length - 1
                                  ].value
                                } kVarh`
                              : `N/A kVarh`
                          }
                        />
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="h-4"></div>

                <div className="border rounded-md border-gray-400 h-1/2 p-2">
                  <div className="flex justify-center p-2">
                    <div
                      className="my_temp_humidity"
                      style={{
                        padding: "10px",
                        width: "100%",
                      }}
                    >
                      <div className="relative">
                        <div className="mb-4 mt-10">
                          {loader ? (
                            <div className="h-96 w-full flex justify-center items-center">
                              <CircularProgress color="inherit" />
                            </div>
                          ) : (
                            <>
                              <div className="relative mb-2 mt-1">
                                <p>
                                  Daily energy data variation with time(between{" "}
                                  {emptyBar
                                    ? emptyBar[0].day +
                                      " to " +
                                      emptyBar[emptyBar.length - 1].day
                                    : " "}
                                  )
                                </p>
                                <div className="absolute top-0 right-0">
                                  <LongMenu
                                    options={[
                                      "Download as CSV",
                                      "Download as PDF",
                                    ]}
                                    onOptionClick={(exportOption) =>
                                      handleExportBarChart(
                                        exportOption,
                                        consumtionData?.map(
                                          ({
                                            day,
                                            activeconsumption,
                                            reactiveconsumption,
                                            apparentconsumption,
                                            importactiveconsumption,
                                            exportactiveconsumption,
                                            importreactiveconsumption,
                                            exportreactiveconsumption,
                                            firstTimeStamp,
                                            lastTimeStamp,
                                          }: any) => {
                                            return {
                                              Day: day,
                                              "First Timestamp": firstTimeStamp,
                                              "Last Timestamp": lastTimeStamp,
                                              // Active Energy
                                              ...(customTagData?.["t_acte"]
                                                ?.sensorStatus
                                                ? {
                                                    [`${customTagData["t_acte"]?.sensorAlias} ${customTagData["t_acte"]?.sensorUnit}`]:
                                                      activeconsumption,
                                                  }
                                                : !customTagData?.["t_acte"]
                                                ? {
                                                    "Active(kWh)":
                                                      activeconsumption,
                                                  }
                                                : {}),

                                              // Reactive Energy
                                              ...(customTagData?.["t_re"]
                                                ?.sensorStatus
                                                ? {
                                                    [`${customTagData["t_re"]?.sensorAlias} ${customTagData["t_re"]?.sensorUnit}`]:
                                                      reactiveconsumption,
                                                  }
                                                : !customTagData?.["t_re"]
                                                ? {
                                                    "Reactive(kVarh)":
                                                      reactiveconsumption,
                                                  }
                                                : {}),

                                              // Apparent Energy
                                              ...(customTagData?.["t_apte"]
                                                ?.sensorStatus
                                                ? {
                                                    [`${customTagData["t_apte"]?.sensorAlias} ${customTagData["t_apte"]?.sensorUnit}`]:
                                                      apparentconsumption,
                                                  }
                                                : !customTagData?.["t_apte"]
                                                ? {
                                                    "Apparent(kVah)":
                                                      apparentconsumption,
                                                  }
                                                : {}),

                                              // Import Active
                                              ...(customTagData?.["imp_ae"]
                                                ?.sensorStatus
                                                ? {
                                                    [`${customTagData["imp_ae"]?.sensorAlias} ${customTagData["imp_ae"]?.sensorUnit}`]:
                                                      importactiveconsumption,
                                                  }
                                                : !customTagData?.["imp_ae"]
                                                ? {
                                                    "Import Act(kWh)":
                                                      importactiveconsumption,
                                                  }
                                                : {}),

                                              // Export Active
                                              ...(customTagData?.["exp_ae"]
                                                ?.sensorStatus
                                                ? {
                                                    [`${customTagData["exp_ae"]?.sensorAlias} ${customTagData["exp_ae"]?.sensorUnit}`]:
                                                      exportactiveconsumption,
                                                  }
                                                : !customTagData?.["exp_ae"]
                                                ? {
                                                    "Export Act(kWh)":
                                                      exportactiveconsumption,
                                                  }
                                                : {}),

                                              // Import Reactive
                                              ...(customTagData?.["imp_re"]
                                                ?.sensorStatus
                                                ? {
                                                    [`${customTagData["imp_re"]?.sensorAlias} ${customTagData["imp_re"]?.sensorUnit}`]:
                                                      importreactiveconsumption,
                                                  }
                                                : !customTagData?.["imp_re"]
                                                ? {
                                                    "Import React(kVarh)":
                                                      importreactiveconsumption,
                                                  }
                                                : {}),

                                              // Export Reactive
                                              ...(customTagData?.["exp_re"]
                                                ?.sensorStatus
                                                ? {
                                                    [`${customTagData["exp_re"]?.sensorAlias} ${customTagData["exp_re"]?.sensorUnit}`]:
                                                      exportreactiveconsumption,
                                                  }
                                                : !customTagData?.["exp_re"]
                                                ? {
                                                    "Export React(kVarh)":
                                                      exportreactiveconsumption,
                                                  }
                                                : {}),
                                            };
                                          }
                                        ),
                                        barchartref2,
                                        `Daily data variation`
                                      )
                                    }
                                    id={1}
                                  />
                                </div>
                              </div>
                              <div ref={barchartref2 || ""}>
                                <DynamicBarChart
                                  data={bargraph}
                                  xAxisCategories={xAxisCategories}
                                  yAxisName="Data"
                                  xAxisName="Time"
                                />
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {value === 1 && (
        <div>
          <div className="border border-gray-400 p-2 mb-4 mt-5">
            <div className="w-[800px] mb-5">
              <ul className="text-sm font-medium text-center text-gray-500 rounded-lg sm:flex dark:divide-gray-700 dark:text-gray-400">
                <li className="focus-within:z-10">
                  <button
                    className={`inline-block border border-gray-300 px-4 py-2 text-[1rem] text-gray-900 rounded-s-lg  transition-colors duration-300`}
                    aria-current="page"
                    style={
                      valueInst === 0 ? { backgroundColor: "#dadada" } : {}
                    }
                    onClick={() => setInst(0)}
                  >
                    Voltage
                  </button>
                </li>

                <li className="focus-within:z-10">
                  <button
                    className={`inline-block border border-gray-300 px-4 py-2 text-[1rem] text-gray-900  transition-colors duration-300`}
                    style={
                      valueInst === 1 ? { backgroundColor: "#dadada" } : {}
                    }
                    onClick={() => setInst(1)}
                  >
                    Current
                  </button>
                </li>
                <li className="focus-within:z-10">
                  <button
                    className={`inline-block border border-gray-300 px-4 py-2 text-[1rem] text-gray-900   transition-colors duration-300`}
                    aria-current="page"
                    style={
                      valueInst === 2 ? { backgroundColor: "#dadada" } : {}
                    }
                    onClick={() => setInst(2)}
                  >
                    Power
                  </button>
                </li>
                <li className="focus-within:z-10">
                  <button
                    className={`inline-block border border-gray-300 px-4 py-2 text-[1rem] text-gray-900  transition-colors duration-300`}
                    aria-current="page"
                    style={
                      valueInst === 3 ? { backgroundColor: "#dadada" } : {}
                    }
                    onClick={() => setInst(3)}
                  >
                    Power Factor
                  </button>
                </li>
                <li className="focus-within:z-10">
                  <button
                    className={`inline-block border border-gray-300 px-4 py-2 text-[1rem] text-gray-900  transition-colors duration-300`}
                    aria-current="page"
                    style={
                      valueInst === 4 ? { backgroundColor: "#dadada" } : {}
                    }
                    onClick={() => setInst(4)}
                  >
                    Frequency
                  </button>
                </li>
                <li className="focus-within:z-10">
                  <button
                    className={`inline-block border border-gray-300 px-4 py-2 text-[1rem] text-gray-900 transition-colors duration-300`}
                    aria-current="page"
                    style={
                      valueInst === 5 ? { backgroundColor: "#dadada" } : {}
                    }
                    onClick={() => setInst(5)}
                  >
                    Demand
                  </button>
                </li>
                <li className="focus-within:z-10">
                  <button
                    className={`inline-block border border-gray-300 px-4 py-2 text-[1rem] text-gray-900 transition-colors duration-300`}
                    aria-current="page"
                    style={
                      valueInst === 6 ? { backgroundColor: "#dadada" } : {}
                    }
                    onClick={() => setInst(6)}
                  >
                    Threshold
                  </button>
                </li>
                <li className="focus-within:z-10">
                  <button
                    className={`inline-block border border-gray-300 px-4 py-2 text-[1rem] text-gray-900 rounded-e-lg transition-colors duration-300`}
                    aria-current="page"
                    style={
                      valueInst === 7 ? { backgroundColor: "#dadada" } : {}
                    }
                    onClick={() => setInst(7)}
                  >
                    Others
                  </button>
                </li>
              </ul>
            </div>

            {value === 1 && valueInst === 0 && (
              <>
                <div className="grid grid-rows-2 gap-5 border rounded-md border-gray-400 ">
                  <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5 mb-2 mt-2 p-2 my-cardTwo">
                    {/* Average Neutral Card */}
                    {customTagData?.["av_ln"]?.sensorStatus ? (
                      <CardThree
                        title={
                          customTagData?.["av_ln"]?.sensorAlias ||
                          "Average Neutral"
                        }
                        amount={`${
                          dashboardSts.avgneuvolt
                            ? dashboardSts.avgneuvolt
                            : "0"
                        } ${customTagData?.["av_ln"]?.sensorUnit || "V"}`}
                      />
                    ) : !customTagData?.["av_ln"] ? (
                      <CardThree
                        title="Average Neutral"
                        amount={`${
                          dashboardSts.avgneuvolt
                            ? dashboardSts.avgneuvolt
                            : "0"
                        } V`}
                      />
                    ) : null}

                    {/* R Phase Voltage Card */}
                    {customTagData?.["v1n"]?.sensorStatus ? (
                      <CardThree
                        title={customTagData?.["v1n"]?.sensorAlias || "R Phase"}
                        amount={`${
                          dashboardSts.rphvtg ? dashboardSts.rphvtg : "0"
                        } ${customTagData?.["v1n"]?.sensorUnit || "V"}`}
                      />
                    ) : !customTagData?.["v1n"] ? (
                      <CardThree
                        title="R Phase"
                        amount={`${
                          dashboardSts.rphvtg ? dashboardSts.rphvtg : "0"
                        } V`}
                      />
                    ) : null}

                    {/* Y Phase Voltage Card */}
                    {customTagData?.["v2n"]?.sensorStatus ? (
                      <CardThree
                        title={customTagData?.["v2n"]?.sensorAlias || "Y Phase"}
                        amount={`${
                          dashboardSts.yphvtg ? dashboardSts.yphvtg : "0"
                        } ${customTagData?.["v2n"]?.sensorUnit || "V"}`}
                      />
                    ) : !customTagData?.["v2n"] ? (
                      <CardThree
                        title="Y Phase"
                        amount={`${
                          dashboardSts.yphvtg ? dashboardSts.yphvtg : "0"
                        } V`}
                      />
                    ) : null}

                    {/* B Phase Voltage Card */}
                    {customTagData?.["v3n"]?.sensorStatus ? (
                      <CardThree
                        title={customTagData?.["v3n"]?.sensorAlias || "B Phase"}
                        amount={`${
                          dashboardSts.bphvtg ? dashboardSts.bphvtg : "0"
                        } ${customTagData?.["v3n"]?.sensorUnit || "V"}`}
                      />
                    ) : !customTagData?.["v3n"] ? (
                      <CardThree
                        title="B Phase"
                        amount={`${
                          dashboardSts.bphvtg ? dashboardSts.bphvtg : "0"
                        } V`}
                      />
                    ) : null}
                  </div>
                  <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5 mb-2 mt-2 p-2 my-cardTwo">
                    {/* Average Line-Line Voltage Card */}
                    {customTagData?.["av_ll"]?.sensorStatus ? (
                      <CardThree
                        title={
                          customTagData?.["av_ll"]?.sensorAlias ||
                          "Average Line-Line"
                        }
                        amount={`${
                          dashboardSts.avglinevolt
                            ? dashboardSts.avglinevolt
                            : "0"
                        } ${customTagData?.["av_ll"]?.sensorUnit || "V"}`}
                      />
                    ) : !customTagData?.["av_ll"] ? (
                      <CardThree
                        title="Average Line-Line"
                        amount={`${
                          dashboardSts.avglinevolt
                            ? dashboardSts.avglinevolt
                            : "0"
                        } V`}
                      />
                    ) : null}

                    {/* RY Phase Voltage Card */}
                    {customTagData?.["v12"]?.sensorStatus ? (
                      <CardThree
                        title={
                          customTagData?.["v12"]?.sensorAlias || "RY Phase"
                        }
                        amount={`${
                          dashboardSts.ryphvtg ? dashboardSts.ryphvtg : "0"
                        } ${customTagData?.["v12"]?.sensorUnit || "V"}`}
                      />
                    ) : !customTagData?.["v12"] ? (
                      <CardThree
                        title="RY Phase"
                        amount={`${
                          dashboardSts.ryphvtg ? dashboardSts.ryphvtg : "0"
                        } V`}
                      />
                    ) : null}

                    {/* YB Phase Voltage Card */}
                    {customTagData?.["v23"]?.sensorStatus ? (
                      <CardThree
                        title={
                          customTagData?.["v23"]?.sensorAlias || "YB Phase"
                        }
                        amount={`${
                          dashboardSts.ybpgvtg ? dashboardSts.ybpgvtg : "0"
                        } ${customTagData?.["v23"]?.sensorUnit || "V"}`}
                      />
                    ) : !customTagData?.["v23"] ? (
                      <CardThree
                        title="YB Phase"
                        amount={`${
                          dashboardSts.ybpgvtg ? dashboardSts.ybpgvtg : "0"
                        } V`}
                      />
                    ) : null}

                    {/* RB Phase Voltage Card */}
                    {customTagData?.["v31"]?.sensorStatus ? (
                      <CardThree
                        title={
                          customTagData?.["v31"]?.sensorAlias || "RB Phase"
                        }
                        amount={`${
                          dashboardSts.rbphvtg ? dashboardSts.rbphvtg : "0"
                        } ${customTagData?.["v31"]?.sensorUnit || "V"}`}
                      />
                    ) : !customTagData?.["v31"] ? (
                      <CardThree
                        title="RB Phase"
                        amount={`${
                          dashboardSts.rbphvtg ? dashboardSts.rbphvtg : "0"
                        } V`}
                      />
                    ) : null}
                  </div>
                </div>

                <div className="pb-[10px] border border-gray-400 rounded-md p-2">
                  <div className="relative  mt-2 mb-5">
                    <p>
                      Voltage data variation with time(between{" "}
                      {apiData.length > 0
                        ? apiData[0].timestamp +
                          " to " +
                          apiData[apiData.length - 1].timestamp
                        : " "}
                      )
                    </p>

                    <div className="w-[600px]">
                      <ul className="text-sm font-medium text-center text-gray-500 rounded-lg sm:flex">
                        <li className="focus-within:z-10">
                          <button
                            className={`inline-block border border-gray-300 px-4 py-2 text-[1rem] text-gray-900 rounded-s-lg  transition-colors duration-300`}
                            aria-current="page"
                            style={
                              valueInstVolt === 0
                                ? { backgroundColor: "#dadada" }
                                : {}
                            }
                            onClick={() => setValueInstVolt(0)}
                          >
                            Line Neutral
                          </button>
                        </li>

                        <li className="focus-within:z-10">
                          <button
                            className={`inline-block border border-gray-300 px-4 py-2 text-[1rem] text-gray-900 rounded-e-lg transition-colors duration-300`}
                            style={
                              valueInstVolt === 1
                                ? { backgroundColor: "#dadada" }
                                : {}
                            }
                            onClick={() => setValueInstVolt(1)}
                          >
                            Line to line
                          </button>
                        </li>
                      </ul>
                    </div>

                    {valueInst === 0 && valueInstVolt === 0 && (
                      <div className="absolute top-0 right-0">
                        <LongMenu
                          options={["Download as CSV", "Download as PDF"]}
                          onOptionClick={(exportOption) =>
                            handleExportBarChart(
                              exportOption,
                              intLNVoltPdfData,
                              chartRef1
                            )
                          }
                          id={1}
                        />
                      </div>
                    )}
                    {valueInst === 0 && valueInstVolt === 1 && (
                      <div className="absolute top-0 right-0">
                        <LongMenu
                          options={["Download as CSV", "Download as PDF"]}
                          onOptionClick={(exportOption) =>
                            handleExportBarChart(
                              exportOption,
                              intLLVoltPdfData,
                              chartRef1
                            )
                          }
                          id={1}
                        />
                      </div>
                    )}
                  </div>
                  {valueInst === 0 && valueInstVolt === 0 && (
                    <div ref={chartRef1}>
                      <LineChart
                        data={lineChartOfLNVoltageVariations}
                        stroke={true}
                        xAxisName="Time"
                        yAxisName="Data"
                      />
                    </div>
                  )}
                  {valueInst === 0 && valueInstVolt === 1 && (
                    <div ref={chartRef1}>
                      <LineChart
                        data={lineChartOfLLVoltageVariations}
                        stroke={true}
                        xAxisName="Time"
                        yAxisName="Data"
                      />
                    </div>
                  )}
                </div>
              </>
            )}

            {value === 1 && valueInst === 1 && (
              <div>
                <div className=" border rounded-md border-gray-400 grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5 mb-2 mt-2 p-2 my-cardTwo">
                  {/* Average Current Card */}
                  {customTagData?.["av_c"]?.sensorStatus ? (
                    <CardThree
                      title={
                        customTagData?.["av_c"]?.sensorAlias ||
                        "Average Current"
                      }
                      amount={`${
                        dashboardSts.avgcrt ? dashboardSts.avgcrt : "0"
                      } ${customTagData?.["av_c"]?.sensorUnit || "A"}`}
                    />
                  ) : !customTagData?.["av_c"] ? (
                    <CardThree
                      title="Average Current"
                      amount={`${
                        dashboardSts.avgcrt ? dashboardSts.avgcrt : "0"
                      } A`}
                    />
                  ) : null}

                  {/* R Phase Current Card */}
                  {customTagData?.["cl1"]?.sensorStatus ? (
                    <CardThree
                      title={customTagData?.["cl1"]?.sensorAlias || "R Phase"}
                      amount={`${
                        dashboardSts.rphcrt ? dashboardSts.rphcrt : "0"
                      } ${customTagData?.["cl1"]?.sensorUnit || "A"}`}
                    />
                  ) : !customTagData?.["cl1"] ? (
                    <CardThree
                      title="R Phase"
                      amount={`${
                        dashboardSts.rphcrt ? dashboardSts.rphcrt : "0"
                      } A`}
                    />
                  ) : null}

                  {/* Y Phase Current Card */}
                  {customTagData?.["cl2"]?.sensorStatus ? (
                    <CardThree
                      title={customTagData?.["cl2"]?.sensorAlias || "Y Phase"}
                      amount={`${
                        dashboardSts.yphcrt ? dashboardSts.yphcrt : "0"
                      } ${customTagData?.["cl2"]?.sensorUnit || "A"}`}
                    />
                  ) : !customTagData?.["cl2"] ? (
                    <CardThree
                      title="Y Phase"
                      amount={`${
                        dashboardSts.yphcrt ? dashboardSts.yphcrt : "0"
                      } A`}
                    />
                  ) : null}

                  {/* B Phase Current Card */}
                  {customTagData?.["cl3"]?.sensorStatus ? (
                    <CardThree
                      title={customTagData?.["cl3"]?.sensorAlias || "B Phase"}
                      amount={`${
                        dashboardSts.bphcrt ? dashboardSts.bphcrt : "0"
                      } ${customTagData?.["cl3"]?.sensorUnit || "A"}`}
                    />
                  ) : !customTagData?.["cl3"] ? (
                    <CardThree
                      title="B Phase"
                      amount={`${
                        dashboardSts.bphcrt ? dashboardSts.bphcrt : "0"
                      } A`}
                    />
                  ) : null}
                </div>

                <div className="pb-[20px]">
                  <div className="relative mt-2 mb-5">
                    <p>
                      Current data variation with time(between{" "}
                      {apiData.length > 0
                        ? apiData[0].timestamp +
                          " to " +
                          apiData[apiData.length - 1].timestamp
                        : " "}
                      )
                    </p>
                    <div className="absolute top-0 right-0">
                      <LongMenu
                        options={["Download as CSV", "Download as PDF"]}
                        onOptionClick={(exportOption) =>
                          handleExportBarChart(
                            exportOption,
                            intCurrPdfData,
                            chartRef4
                          )
                        }
                        id={1}
                      />
                    </div>
                    <div ref={chartRef4}>
                      <LineChart
                        data={lineChartOfCurrentVariations}
                        stroke={true}
                        xAxisName="Time"
                        yAxisName="Data"
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}

            {value === 1 && valueInst === 2 && (
              <>
                <div className="grid grid-rows-2 border rounded-md border-gray-400 ">
                <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5 mb-2 mt-2 p-2 my-cardTwo">
                  {/* Total Active Card */}
                  {customTagData?.["t_kw"]?.sensorStatus ? (
                    <CardThree
                      title={
                        customTagData?.["t_kw"]?.sensorAlias || "Total Active"
                      }
                      amount={`${
                        dashboardSts.totalactpo ? dashboardSts.totalactpo : "0"
                      } ${customTagData?.["t_kw"]?.sensorUnit || "kW"}`}
                    />
                  ) : !customTagData?.["t_kw"] ? (
                    <CardThree
                      title="Total Active"
                      amount={`${
                        dashboardSts.totalactpo ? dashboardSts.totalactpo : "0"
                      } kW`}
                    />
                  ) : null}

                  {/* R Phase Active Power Card */}
                  {customTagData?.["kw1"]?.sensorStatus ? (
                    <CardThree
                      title={customTagData?.["kw1"]?.sensorAlias || "R Phase"}
                      amount={`${
                        dashboardSts.rphactpo ? dashboardSts.rphactpo : "0"
                      } ${customTagData?.["kw1"]?.sensorUnit || "kW"}`}
                    />
                  ) : !customTagData?.["kw1"] ? (
                    <CardThree
                      title="R Phase"
                      amount={`${
                        dashboardSts.rphactpo ? dashboardSts.rphactpo : "0"
                      } kW`}
                    />
                  ) : null}

                  {/* Y Phase Active Power Card */}
                  {customTagData?.["kw2"]?.sensorStatus ? (
                    <CardThree
                      title={customTagData?.["kw2"]?.sensorAlias || "Y Phase"}
                      amount={`${
                        dashboardSts.yphactpo ? dashboardSts.yphactpo : "0"
                      } ${customTagData?.["kw2"]?.sensorUnit || "kW"}`}
                    />
                  ) : !customTagData?.["kw2"] ? (
                    <CardThree
                      title="Y Phase"
                      amount={`${
                        dashboardSts.yphactpo ? dashboardSts.yphactpo : "0"
                      } kW`}
                    />
                  ) : null}

                  {/* B Phase Active Power Card */}
                  {customTagData?.["kw3"]?.sensorStatus ? (
                    <CardThree
                      title={customTagData?.["kw3"]?.sensorAlias || "B Phase"}
                      amount={`${
                        dashboardSts.bphactpo ? dashboardSts.bphactpo : "0"
                      } ${customTagData?.["kw3"]?.sensorUnit || "kW"}`}
                    />
                  ) : !customTagData?.["kw3"] ? (
                    <CardThree
                      title="B Phase"
                      amount={`${
                        dashboardSts.bphactpo ? dashboardSts.bphactpo : "0"
                      } kW`}
                    />
                  ) : null}
                  </div>
                  <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5 mb-2 mt-2 p-2 my-cardTwo">
                  {/* Total Reactive Power Card */}
                  {customTagData?.["t_kvar"]?.sensorStatus ? (
                    <CardThree
                      title={
                        customTagData?.["t_kvar"]?.sensorAlias ||
                        "Total Reactive"
                      }
                      amount={`${
                        dashboardSts.totalreactpo
                          ? dashboardSts.totalreactpo
                          : "0"
                      } ${customTagData?.["t_kvar"]?.sensorUnit || "kVAr"}`}
                    />
                  ) : !customTagData?.["t_kvar"] ? (
                    <CardThree
                      title="Total Reactive"
                      amount={`${
                        dashboardSts.totalreactpo
                          ? dashboardSts.totalreactpo
                          : "0"
                      } kVAr`}
                    />
                  ) : null}

                  {/* R Phase Reactive Power Card */}
                  {customTagData?.["kvar1"]?.sensorStatus ? (
                    <CardThree
                      title={customTagData?.["kvar1"]?.sensorAlias || "R Phase"}
                      amount={`${
                        dashboardSts.rphreactpo ? dashboardSts.rphreactpo : "0"
                      } ${customTagData?.["kvar1"]?.sensorUnit || "kVAr"}`}
                    />
                  ) : !customTagData?.["kvar1"] ? (
                    <CardThree
                      title="R Phase"
                      amount={`${
                        dashboardSts.rphreactpo ? dashboardSts.rphreactpo : "0"
                      } kVAr`}
                    />
                  ) : null}

                  {/* Y Phase Reactive Power Card */}
                  {customTagData?.["kvar2"]?.sensorStatus ? (
                    <CardThree
                      title={customTagData?.["kvar2"]?.sensorAlias || "Y Phase"}
                      amount={`${
                        dashboardSts.yphreactpo ? dashboardSts.yphreactpo : "0"
                      } ${customTagData?.["kvar2"]?.sensorUnit || "kVAr"}`}
                    />
                  ) : !customTagData?.["kvar2"] ? (
                    <CardThree
                      title="Y Phase"
                      amount={`${
                        dashboardSts.yphreactpo ? dashboardSts.yphreactpo : "0"
                      } kVAr`}
                    />
                  ) : null}

                  {/* B Phase Reactive Power Card */}
                  {customTagData?.["kvar3"]?.sensorStatus ? (
                    <CardThree
                      title={customTagData?.["kvar3"]?.sensorAlias || "B Phase"}
                      amount={`${
                        dashboardSts.bphreactpo ? dashboardSts.bphreactpo : "0"
                      } ${customTagData?.["kvar3"]?.sensorUnit || "kVAr"}`}
                    />
                  ) : !customTagData?.["kvar3"] ? (
                    <CardThree
                      title="B Phase"
                      amount={`${
                        dashboardSts.bphreactpo ? dashboardSts.bphreactpo : "0"
                      } kVAr`}
                    />
                  ) : null}
                  </div>
                  <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5 mb-2 mt-2 p-2 my-cardTwo">
                  {/* Total Apparent Power Card */}
                  {customTagData?.["t_kva"]?.sensorStatus ? (
                    <CardThree
                      title={
                        customTagData?.["t_kva"]?.sensorAlias ||
                        "Total Apparent"
                      }
                      amount={`${
                        dashboardSts.totalapppo ? dashboardSts.totalapppo : "0"
                      } ${customTagData?.["t_kva"]?.sensorUnit || "kVa"}`}
                    />
                  ) : !customTagData?.["t_kva"] ? (
                    <CardThree
                      title="Total Apparent"
                      amount={`${
                        dashboardSts.totalapppo ? dashboardSts.totalapppo : "0"
                      } kVa`}
                    />
                  ) : null}

                  {/* R Phase Apparent Power Card */}
                  {customTagData?.["kva1"]?.sensorStatus ? (
                    <CardThree
                      title={customTagData?.["kva1"]?.sensorAlias || "R Phase"}
                      amount={`${
                        dashboardSts.rphapppo ? dashboardSts.rphapppo : "0"
                      } ${customTagData?.["kva1"]?.sensorUnit || "kVa"}`}
                    />
                  ) : !customTagData?.["kva1"] ? (
                    <CardThree
                      title="R Phase"
                      amount={`${
                        dashboardSts.rphapppo ? dashboardSts.rphapppo : "0"
                      } kVa`}
                    />
                  ) : null}

                  {/* Y Phase Apparent Power Card */}
                  {customTagData?.["kva2"]?.sensorStatus ? (
                    <CardThree
                      title={customTagData?.["kva2"]?.sensorAlias || "Y Phase"}
                      amount={`${
                        dashboardSts.yphapppo ? dashboardSts.yphapppo : "0"
                      } ${customTagData?.["kva2"]?.sensorUnit || "kVa"}`}
                    />
                  ) : !customTagData?.["kva2"] ? (
                    <CardThree
                      title="Y Phase"
                      amount={`${
                        dashboardSts.yphapppo ? dashboardSts.yphapppo : "0"
                      } kVa`}
                    />
                  ) : null}

                  {/* B Phase Apparent Power Card */}
                  {customTagData?.["kva3"]?.sensorStatus ? (
                    <CardThree
                      title={customTagData?.["kva3"]?.sensorAlias || "B Phase"}
                      amount={`${
                        dashboardSts.bphapppo ? dashboardSts.bphapppo : "0"
                      } ${customTagData?.["kva3"]?.sensorUnit || "kVa"}`}
                    />
                  ) : !customTagData?.["kva3"] ? (
                    <CardThree
                      title="B Phase"
                      amount={`${
                        dashboardSts.bphapppo ? dashboardSts.bphapppo : "0"
                      } kVa`}
                    />
                  ) : null}
                  </div>
                </div>

                <div className="border border-gray-400 rounded-md p-2">
                  <div className="pb-[20px]">
                    <div className="relative mt-2 mb-5">
                      <p>
                        Power data variation with time(between{" "}
                        {apiData.length > 0
                          ? apiData[0].timestamp +
                            " to " +
                            apiData[apiData.length - 1].timestamp
                          : " "}
                        )
                      </p>
                      <div className="absolute top-0 right-0">
                        <LongMenu
                          options={["Download as CSV", "Download as PDF"]}
                          onOptionClick={(exportOption) =>
                            handleExportBarChart(
                              exportOption,
                              getPowerDataToExport(valueInstPower),
                              chartRef5
                            )
                          }
                          id={1}
                        />
                      </div>
                    </div>
                    <div className="w-[270px]">
                      <ul className="text-sm font-medium text-center text-gray-500 rounded-lg shadow sm:flex dark:divide-gray-700 dark:text-gray-400">
                        <li className="w-full focus-within:z-10">
                          <button
                            className={`inline-block w-full border border-gray-300 px-1 py-2 text-[1rem] text-gray-900 rounded-s-lg  transition-colors duration-300`}
                            aria-current="page"
                            style={
                              valueInstPower === 0
                                ? { backgroundColor: "#dadada" }
                                : {}
                            }
                            onClick={() => setvalueInstPower(0)}
                          >
                            Active
                          </button>
                        </li>

                        <li className="w-full focus-within:z-10">
                          <button
                            className={`inline-block w-full border border-gray-300 px-1 py-2 text-[1rem] text-gray-900  transition-colors duration-300`}
                            style={
                              valueInstPower === 1
                                ? { backgroundColor: "#dadada" }
                                : {}
                            }
                            onClick={() => setvalueInstPower(1)}
                          >
                            Reactive
                          </button>
                        </li>
                        <li className="w-full focus-within:z-10">
                          <button
                            className={`inline-block w-full border border-gray-300 px-1 py-2 text-[1rem] text-gray-900 rounded-e-lg  transition-colors duration-300`}
                            aria-current="page"
                            style={
                              valueInstPower === 2
                                ? { backgroundColor: "#dadada" }
                                : {}
                            }
                            onClick={() => setvalueInstPower(2)}
                          >
                            Apparent
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div ref={chartRef5}>
                    {valueInst === 2 && valueInstPower === 0 && (
                      <LineChart
                        data={lineChartOfActivePowerVariations}
                        stroke={true}
                        xAxisName="Time"
                        yAxisName="Data"
                      />
                    )}
                    {valueInst === 2 && valueInstPower === 1 && (
                      <LineChart
                        data={lineChartOfReactivePowerVariations}
                        stroke={true}
                        xAxisName="Time"
                        yAxisName="Data"
                      />
                    )}
                    {valueInst === 2 && valueInstPower === 2 && (
                      <LineChart
                        data={lineChartOfApparentPowerVariations}
                        stroke={true}
                        xAxisName="Time"
                        yAxisName="Data"
                      />
                    )}
                  </div>
                </div>
              </>
            )}

            {value === 1 && valueInst === 3 && (
              <>
                <div className="border rounded-md border-gray-400 grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5 mb-2 mt-2 p-2 my-cardTwo">
                  {/* Average PF Card */}
                  {customTagData?.["av_pf"]?.sensorStatus ? (
                    <CardThree
                      title={
                        customTagData?.["av_pf"]?.sensorAlias || "Average PF"
                      }
                      amount={`${
                        dashboardSts.powerfactor
                          ? dashboardSts.powerfactor
                          : "0"
                      } ${customTagData?.["av_pf"]?.sensorUnit || ""}`}
                    />
                  ) : !customTagData?.["av_pf"] ? (
                    <CardThree
                      title="Average PF"
                      amount={`${
                        dashboardSts.powerfactor
                          ? dashboardSts.powerfactor
                          : "0"
                      } `}
                    />
                  ) : null}

                  {/* R Phase PF Card */}
                  {customTagData?.["pf1"]?.sensorStatus ? (
                    <CardThree
                      title={customTagData?.["pf1"]?.sensorAlias || "R Phase"}
                      amount={`${
                        dashboardSts.rphpf ? dashboardSts.rphpf : "0"
                      } ${customTagData?.["pf1"]?.sensorUnit || ""}`}
                    />
                  ) : !customTagData?.["pf1"] ? (
                    <CardThree
                      title="R Phase"
                      amount={`${
                        dashboardSts.rphpf ? dashboardSts.rphpf : "0"
                      } `}
                    />
                  ) : null}

                  {/* Y Phase PF Card */}
                  {customTagData?.["pf2"]?.sensorStatus ? (
                    <CardThree
                      title={customTagData?.["pf2"]?.sensorAlias || "Y Phase"}
                      amount={`${
                        dashboardSts.yphpf ? dashboardSts.yphpf : "0"
                      } ${customTagData?.["pf2"]?.sensorUnit || ""}`}
                    />
                  ) : !customTagData?.["pf2"] ? (
                    <CardThree
                      title="Y Phase"
                      amount={`${
                        dashboardSts.yphpf ? dashboardSts.yphpf : "0"
                      } `}
                    />
                  ) : null}

                  {/* B Phase PF Card */}
                  {customTagData?.["pf3"]?.sensorStatus ? (
                    <CardThree
                      title={customTagData?.["pf3"]?.sensorAlias || "B Phase"}
                      amount={`${
                        dashboardSts.bphpf ? dashboardSts.bphpf : "0"
                      } ${customTagData?.["pf3"]?.sensorUnit || ""}`}
                    />
                  ) : !customTagData?.["pf3"] ? (
                    <CardThree
                      title="B Phase"
                      amount={`${
                        dashboardSts.bphpf ? dashboardSts.bphpf : "0"
                      } `}
                    />
                  ) : null}
                </div>

                <div className="border border-gray-400 rounded-md p-2">
                  <div className="pb-[20px]">
                    <div className="relative mt-2 mb-5">
                      <p>
                        Power Factor data variation with time(between{" "}
                        {apiData.length > 0
                          ? apiData[0].timestamp +
                            " to " +
                            apiData[apiData.length - 1].timestamp
                          : " "}
                        )
                      </p>
                      <div className="absolute top-0 right-0">
                        <LongMenu
                          options={["Download as CSV", "Download as PDF"]}
                          onOptionClick={(exportOption) =>
                            handleExportBarChart(
                              exportOption,
                              getPowerFactorData,
                              chartRef6
                            )
                          }
                          id={1}
                        />
                      </div>
                    </div>
                  </div>
                  <div ref={chartRef6}>
                    <LineChart
                      data={lineChartOfPowerFactorVariations}
                      stroke={true}
                      xAxisName="Time"
                      yAxisName="Data"
                    />
                  </div>
                </div>
              </>
            )}

            {value === 1 && valueInst === 4 && (
              <>
                <div className="border rounded-md border-gray-400 grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5 mb-2 mt-2 p-2 my-cardTwo">
                  {/* Frequency Card */}
                  {customTagData?.["freq"]?.sensorStatus ? (
                    <CardThree
                      title={
                        customTagData?.["freq"]?.sensorAlias || "Frequency"
                      }
                      amount={`${
                        dashboardSts.frequency ? dashboardSts.frequency : "0"
                      } ${customTagData?.["freq"]?.sensorUnit || "Hz"}`}
                    />
                  ) : !customTagData?.["freq"] ? (
                    <CardThree
                      title="Frequency"
                      amount={`${
                        dashboardSts.frequency ? dashboardSts.frequency : "0"
                      } Hz`}
                    />
                  ) : null}
                </div>

                <div className="border border-gray-400 rounded-md p-2">
                  <div className="pb-[20px]">
                    <div className="relative mt-2 mb-5">
                      <p>
                        Frequency data variation with time(between{" "}
                        {apiData.length > 0
                          ? apiData[0].timestamp +
                            " to " +
                            apiData[apiData.length - 1].timestamp
                          : " "}
                        )
                      </p>
                      <div className="absolute top-0 right-0">
                        <LongMenu
                          options={["Download as CSV", "Download as PDF"]}
                          onOptionClick={(exportOption) =>
                            handleExportBarChart(
                              exportOption,
                              frequency_for_pdf,
                              chartRef7
                            )
                          }
                          id={1}
                        />
                      </div>
                    </div>
                  </div>
                  <div ref={chartRef7}>
                    <LineChart
                      data={lineChartOfFrequencyVariations}
                      stroke={true}
                      xAxisName="Time"
                      yAxisName="Data"
                    />
                  </div>
                </div>
              </>
            )}

            {value === 1 && valueInst === 5 && (
              <>
                <div className="grid grid-rows-2 border rounded-md border-gray-400 ">
                <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5 mb-2 mt-2 p-2 my-cardTwo">
                  {/* Max Active Power Card */}
                  {customTagData?.["ap_maxd"]?.sensorStatus ? (
                    <CardThree
                      title={
                        customTagData?.["ap_maxd"]?.sensorAlias ||
                        "Max Active Power"
                      }
                      amount={`${
                        dashboardSts.mxactpower ? dashboardSts.mxactpower : "0"
                      } ${customTagData?.["ap_maxd"]?.sensorUnit || "kW"}`}
                    />
                  ) : !customTagData?.["ap_maxd"] ? (
                    <CardThree
                      title="Max Active Power"
                      amount={`${
                        dashboardSts.mxactpower ? dashboardSts.mxactpower : "0"
                      } kW`}
                    />
                  ) : null}

                  {/* Max Reactive Power Card */}
                  {customTagData?.["rp_maxd"]?.sensorStatus ? (
                    <CardThree
                      title={
                        customTagData?.["rp_maxd"]?.sensorAlias ||
                        "Max Reactive Power"
                      }
                      amount={`${
                        dashboardSts.mxreactpower
                          ? dashboardSts.mxreactpower
                          : "0"
                      } ${customTagData?.["rp_maxd"]?.sensorUnit || "kVar"}`}
                    />
                  ) : !customTagData?.["rp_maxd"] ? (
                    <CardThree
                      title="Max Reactive Power"
                      amount={`${
                        dashboardSts.mxreactpower
                          ? dashboardSts.mxreactpower
                          : "0"
                      } kVar`}
                    />
                  ) : null}

                  {/* Max Apparent Power Card */}
                  {customTagData?.["apr_maxd"]?.sensorStatus ? (
                    <CardThree
                      title={
                        customTagData?.["apr_maxd"]?.sensorAlias ||
                        "Max Apparent Power"
                      }
                      amount={`${
                        dashboardSts.mxapppopower
                          ? dashboardSts.mxapppopower
                          : "0"
                      } ${customTagData?.["apr_maxd"]?.sensorUnit || "kVa"}`}
                    />
                  ) : !customTagData?.["apr_maxd"] ? (
                    <CardThree
                      title="Max Apparent Power"
                      amount={`${
                        dashboardSts.mxapppopower
                          ? dashboardSts.mxapppopower
                          : "0"
                      } kVa`}
                    />
                  ) : null}
                  </div>
                  <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5 mb-2 mt-2 p-2 my-cardTwo">
                  {/* Min Active Power Card */}
                  {customTagData?.["ap_mind"]?.sensorStatus ? (
                    <CardThree
                      title={
                        customTagData?.["ap_mind"]?.sensorAlias ||
                        "Min Active Power"
                      }
                      amount={`${
                        dashboardSts.minactpower
                          ? dashboardSts.minactpower
                          : "0"
                      } ${customTagData?.["ap_mind"]?.sensorUnit || "kW"}`}
                    />
                  ) : !customTagData?.["ap_mind"] ? (
                    <CardThree
                      title="Min Active Power"
                      amount={`${
                        dashboardSts.minactpower
                          ? dashboardSts.minactpower
                          : "0"
                      } kW`}
                    />
                  ) : null}

                  {/* Min Reactive Power Card */}
                  {customTagData?.["rp_mind"]?.sensorStatus ? (
                    <CardThree
                      title={
                        customTagData?.["rp_mind"]?.sensorAlias ||
                        "Min Reactive Power"
                      }
                      amount={`${
                        dashboardSts.minreactpower
                          ? dashboardSts.minreactpower
                          : "0"
                      } ${customTagData?.["rp_mind"]?.sensorUnit || "kVar"}`}
                    />
                  ) : !customTagData?.["rp_mind"] ? (
                    <CardThree
                      title="Min Reactive Power"
                      amount={`${
                        dashboardSts.minreactpower
                          ? dashboardSts.minreactpower
                          : "0"
                      } kVar`}
                    />
                  ) : null}
                  </div>
                </div>

                <div className="border border-gray-400 rounded-md p-2">
                  <div className="pb-[20px]">
                    <div className="relative mt-2 mb-5">
                      <p>
                        Power data variation with time(between{" "}
                        {apiData.length > 0
                          ? apiData[0].timestamp +
                            " to " +
                            apiData[apiData.length - 1].timestamp
                          : " "}
                        )
                      </p>
                      <div className="absolute top-0 right-0">
                        <LongMenu
                          options={["Download as CSV", "Download as PDF"]}
                          onOptionClick={(exportOption) =>
                            handleExportBarChart(
                              exportOption,
                              getDemandDataToExport(valueInstDemand),
                              chartRef5
                            )
                          }
                          id={1}
                        />
                      </div>
                    </div>
                    <div className="w-[270px]">
                      <ul className="text-sm font-medium text-center text-gray-500 rounded-lg shadow sm:flex dark:divide-gray-700 dark:text-gray-400">
                        <li className="w-full focus-within:z-10">
                          <button
                            className={`inline-block w-full border border-gray-300 px-1 py-2 text-[1rem] text-gray-900 rounded-s-lg  transition-colors duration-300`}
                            aria-current="page"
                            style={
                              valueInstDemand === 0
                                ? { backgroundColor: "#dadada" }
                                : {}
                            }
                            onClick={() => setvalueInstDemand(0)}
                          >
                            Max Demand
                          </button>
                        </li>

                        <li className="w-full focus-within:z-10">
                          <button
                            className={`inline-block w-full border border-gray-300 px-1 py-2 text-[1rem] text-gray-900 rounded-e-lg transition-colors duration-300`}
                            style={
                              valueInstDemand === 1
                                ? { backgroundColor: "#dadada" }
                                : {}
                            }
                            onClick={() => setvalueInstDemand(1)}
                          >
                            Min Demand
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div ref={chartRef5}>
                    {valueInst === 5 && valueInstDemand === 0 && (
                      <LineChart
                        data={lineChartOfMaxDemandDataVariations}
                        stroke={true}
                        xAxisName="Time"
                        yAxisName="Data"
                      />
                    )}
                    {valueInst === 5 && valueInstDemand === 1 && (
                      <LineChart
                        data={lineChartOfMinDemandDataVariations}
                        stroke={true}
                        xAxisName="Time"
                        yAxisName="Data"
                      />
                    )}
                  </div>
                </div>
              </>
            )}

            {value === 1 && valueInst === 6 && (
              <>
                <div className="grid grid-rows-2border rounded-md border-gray-400 ">
                <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5 mb-2 mt-2 p-2 my-cardTwo">
                  {/* R Phase Voltage THD */}
                  {customTagData?.["thd_v1n"]?.sensorStatus ? (
                    <CardThree
                      title={
                        customTagData?.["thd_v1n"]?.sensorAlias ||
                        "R Phase Voltage"
                      }
                      amount={`${
                        dashboardSts.rvtgphthd ? dashboardSts.rvtgphthd : "0"
                      } ${customTagData?.["thd_v1n"]?.sensorUnit || "%"}`}
                    />
                  ) : !customTagData?.["thd_v1n"] ? (
                    <CardThree
                      title="R Phase Voltage"
                      amount={`${
                        dashboardSts.rvtgphthd ? dashboardSts.rvtgphthd : "0"
                      } %`}
                    />
                  ) : null}

                  {/* Y Phase Voltage THD */}
                  {customTagData?.["thd_v2n"]?.sensorStatus ? (
                    <CardThree
                      title={
                        customTagData?.["thd_v2n"]?.sensorAlias ||
                        "Y Phase Voltage"
                      }
                      amount={`${
                        dashboardSts.yvtgphthd ? dashboardSts.yvtgphthd : "0"
                      } ${customTagData?.["thd_v2n"]?.sensorUnit || "%"}`}
                    />
                  ) : !customTagData?.["thd_v2n"] ? (
                    <CardThree
                      title="Y Phase Voltage"
                      amount={`${
                        dashboardSts.yvtgphthd ? dashboardSts.yvtgphthd : "0"
                      } %`}
                    />
                  ) : null}

                  {/* B Phase Voltage THD */}
                  {customTagData?.["thd_v3n"]?.sensorStatus ? (
                    <CardThree
                      title={
                        customTagData?.["thd_v3n"]?.sensorAlias ||
                        "B Phase Voltage"
                      }
                      amount={`${
                        dashboardSts.bvtgphthd ? dashboardSts.bvtgphthd : "0"
                      } ${customTagData?.["thd_v3n"]?.sensorUnit || "%"}`}
                    />
                  ) : !customTagData?.["thd_v3n"] ? (
                    <CardThree
                      title="B Phase Voltage"
                      amount={`${
                        dashboardSts.bvtgphthd ? dashboardSts.bvtgphthd : "0"
                      } %`}
                    />
                  ) : null}

                  {/* Empty Div */}
                  </div>
                  <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5 mb-2 mt-2 p-2 my-cardTwo">
                  {/* RY Phase Voltage THD */}
                  {customTagData?.["thd_v12"]?.sensorStatus ? (
                    <CardThree
                      title={
                        customTagData?.["thd_v12"]?.sensorAlias ||
                        "RY Phase Voltage"
                      }
                      amount={`${
                        dashboardSts.ryvtgphthd ? dashboardSts.ryvtgphthd : "0"
                      } ${customTagData?.["thd_v12"]?.sensorUnit || "%"}`}
                    />
                  ) : !customTagData?.["thd_v12"] ? (
                    <CardThree
                      title="RY Phase Voltage"
                      amount={`${
                        dashboardSts.ryvtgphthd ? dashboardSts.ryvtgphthd : "0"
                      } %`}
                    />
                  ) : null}

                  {/* YB Phase Voltage THD */}
                  {customTagData?.["thd_v23"]?.sensorStatus ? (
                    <CardThree
                      title={
                        customTagData?.["thd_v23"]?.sensorAlias ||
                        "YB Phase Voltage"
                      }
                      amount={`${
                        dashboardSts.ybvtgphthd ? dashboardSts.ybvtgphthd : "0"
                      } ${customTagData?.["thd_v23"]?.sensorUnit || "%"}`}
                    />
                  ) : !customTagData?.["thd_v23"] ? (
                    <CardThree
                      title="YB Phase Voltage"
                      amount={`${
                        dashboardSts.ybvtgphthd ? dashboardSts.ybvtgphthd : "0"
                      } %`}
                    />
                  ) : null}

                  {/* RB Phase Voltage THD */}
                  {customTagData?.["thd_v31"]?.sensorStatus ? (
                    <CardThree
                      title={
                        customTagData?.["thd_v31"]?.sensorAlias ||
                        "RB Phase Voltage"
                      }
                      amount={`${
                        dashboardSts.rbvtgphthd ? dashboardSts.rbvtgphthd : "0"
                      } ${customTagData?.["thd_v31"]?.sensorUnit || "%"}`}
                    />
                  ) : !customTagData?.["thd_v31"] ? (
                    <CardThree
                      title="RB Phase Voltage"
                      amount={`${
                        dashboardSts.rbvtgphthd ? dashboardSts.rbvtgphthd : "0"
                      } %`}
                    />
                  ) : null}

                  {/* Empty Div */}
                  </div>
                  <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5 mb-2 mt-2 p-2 my-cardTwo">
                  {/* R Phase Current THD */}
                  {customTagData?.["thd_ci1"]?.sensorStatus ? (
                    <CardThree
                      title={
                        customTagData?.["thd_ci1"]?.sensorAlias ||
                        "R Phase Current"
                      }
                      amount={`${
                        dashboardSts.rcrtphthd ? dashboardSts.rcrtphthd : "0"
                      } ${customTagData?.["thd_ci1"]?.sensorUnit || "%"}`}
                    />
                  ) : !customTagData?.["thd_ci1"] ? (
                    <CardThree
                      title="R Phase Current"
                      amount={`${
                        dashboardSts.rcrtphthd ? dashboardSts.rcrtphthd : "0"
                      } %`}
                    />
                  ) : null}

                  {/* Y Phase Current THD */}
                  {customTagData?.["thd_ci2"]?.sensorStatus ? (
                    <CardThree
                      title={
                        customTagData?.["thd_ci2"]?.sensorAlias ||
                        "Y Phase Current"
                      }
                      amount={`${
                        dashboardSts.ycrtphthd ? dashboardSts.ycrtphthd : "0"
                      } ${customTagData?.["thd_ci2"]?.sensorUnit || "%"}`}
                    />
                  ) : !customTagData?.["thd_ci2"] ? (
                    <CardThree
                      title="Y Phase Current"
                      amount={`${
                        dashboardSts.ycrtphthd ? dashboardSts.ycrtphthd : "0"
                      } %`}
                    />
                  ) : null}

                  {/* B Phase Current THD */}
                  {customTagData?.["thd_ci3"]?.sensorStatus ? (
                    <CardThree
                      title={
                        customTagData?.["thd_ci3"]?.sensorAlias ||
                        "B Phase Current"
                      }
                      amount={`${
                        dashboardSts.bcrtphthd ? dashboardSts.bcrtphthd : "0"
                      } ${customTagData?.["thd_ci3"]?.sensorUnit || "%"}`}
                    />
                  ) : !customTagData?.["thd_ci3"] ? (
                    <CardThree
                      title="B Phase Current"
                      amount={`${
                        dashboardSts.bcrtphthd ? dashboardSts.bcrtphthd : "0"
                      } %`}
                    />
                  ) : null}

                  {/* Empty Div */}
                  </div>
                </div>

                <div className="border border-gray-400 rounded-md p-2">
                  <div className="pb-[20px]">
                    <div className="relative mt-2 mb-5">
                      <p>
                        Threshold data variation with time(between{" "}
                        {apiData.length > 0
                          ? apiData[0].timestamp +
                            " to " +
                            apiData[apiData.length - 1].timestamp
                          : " "}
                        )
                      </p>
                      <div className="absolute top-0 right-0">
                        <LongMenu
                          options={["Download as CSV", "Download as PDF"]}
                          onOptionClick={(exportOption) =>
                            handleExportBarChart(
                              exportOption,
                              getTDHDataToExport,
                              chartRef8
                            )
                          }
                          id={1}
                        />
                      </div>
                    </div>
                    <div className="w-[500px]">
                      <ul className="text-sm font-medium text-center text-gray-500 rounded-lg shadow sm:flex">
                        <li className="w-full focus-within:z-10">
                          <button
                            className={`inline-block w-full border border-gray-300 px-1 py-2 text-[1rem] text-gray-900 rounded-s-lg  transition-colors duration-300`}
                            aria-current="page"
                            style={
                              valueInstTDH === 0
                                ? { backgroundColor: "#dadada" }
                                : {}
                            }
                            onClick={() => setvalueInstTDH(0)}
                          >
                            Neutral Voltage
                          </button>
                        </li>

                        <li className="w-full focus-within:z-10">
                          <button
                            className={`inline-block w-full border border-gray-300 px-1 py-2 text-[1rem] text-gray-900 transition-colors duration-300`}
                            style={
                              valueInstTDH === 1
                                ? { backgroundColor: "#dadada" }
                                : {}
                            }
                            onClick={() => setvalueInstTDH(1)}
                          >
                            Voltage Line-Line
                          </button>
                        </li>

                        <li className="w-full focus-within:z-10">
                          <button
                            className={`inline-block w-full border border-gray-300 px-1 py-2 text-[1rem] text-gray-900 rounded-e-lg transition-colors duration-300`}
                            style={
                              valueInstTDH === 2
                                ? { backgroundColor: "#dadada" }
                                : {}
                            }
                            onClick={() => setvalueInstTDH(2)}
                          >
                            Current
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div ref={chartRef8}>
                    {valueInst === 6 && valueInstTDH === 0 && (
                      <LineChart
                        data={lineChartOfTHDVoltageVariations}
                        stroke={true}
                        xAxisName="Time"
                        yAxisName="Data"
                      />
                    )}
                    {valueInst === 6 && valueInstTDH === 1 && (
                      <LineChart
                        data={lineChartOfTHDLineToLineVoltVariations}
                        stroke={true}
                        xAxisName="Time"
                        yAxisName="Data"
                      />
                    )}
                    {valueInst === 6 && valueInstTDH === 2 && (
                      <LineChart
                        data={lineChartOfTHDCurrentVariations}
                        stroke={true}
                        xAxisName="Time"
                        yAxisName="Data"
                      />
                    )}
                  </div>
                </div>
              </>
            )}

            {value === 1 && valueInst === 7 && (
              <>
                <div className="grid grid-rows-2 border rounded-md border-gray-400 ">
                <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5 mb-2 mt-2 p-2 my-cardTwo">
                  {/* Run Hours Card */}
                  {customTagData?.["r_hour"]?.sensorStatus ? (
                    <CardThree
                      title={
                        customTagData?.["r_hour"]?.sensorAlias || "Run Hours"
                      }
                      amount={`${
                        dashboardSts.runhours ? dashboardSts.runhours : "0"
                      } ${customTagData?.["r_hour"]?.sensorUnit || "Hr."}`}
                    />
                  ) : !customTagData?.["r_hour"] ? (
                    <CardThree
                      title="Run Hours"
                      amount={`${
                        dashboardSts.runhours ? dashboardSts.runhours : "0"
                      } Hr.`}
                    />
                  ) : null}

                  {/* Current Interrupt Card */}
                  {customTagData?.["a_intrpt"]?.sensorStatus ? (
                    <CardThree
                      title={
                        customTagData?.["a_intrpt"]?.sensorAlias ||
                        "Current Interrupt"
                      }
                      amount={`${
                        dashboardSts.currintrrpt
                          ? dashboardSts.currintrrpt
                          : "0"
                      } ${customTagData?.["a_intrpt"]?.sensorUnit || ""}`}
                    />
                  ) : !customTagData?.["a_intrpt"] ? (
                    <CardThree
                      title="Current Interrupt"
                      amount={`${
                        dashboardSts.currintrrpt
                          ? dashboardSts.currintrrpt
                          : "0"
                      }`}
                    />
                  ) : null}
                  </div>
                  <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5 mb-2 mt-2 p-2 my-cardTwo">
                  {/* R Phase Voltage Card */}
                  {customTagData?.["max_iv1n"]?.sensorStatus ? (
                    <CardThree
                      title={
                        customTagData?.["max_iv1n"]?.sensorAlias ||
                        "R Phase Voltage"
                      }
                      amount={`${
                        dashboardSts.rphvoltage ? dashboardSts.rphvoltage : "0"
                      } ${
                        customTagData?.["max_iv1n"]?.sensorUnit || "(max A)"
                      }`}
                    />
                  ) : !customTagData?.["max_iv1n"] ? (
                    <CardThree
                      title="R Phase Voltage"
                      amount={`${
                        dashboardSts.rphvoltage ? dashboardSts.rphvoltage : "0"
                      } (max A)`}
                    />
                  ) : null}

                  {/* Y Phase Voltage Card */}
                  {customTagData?.["max_iv2n"]?.sensorStatus ? (
                    <CardThree
                      title={
                        customTagData?.["max_iv2n"]?.sensorAlias ||
                        "Y Phase Voltage"
                      }
                      amount={`${
                        dashboardSts.yphvoltage ? dashboardSts.yphvoltage : "0"
                      } ${
                        customTagData?.["max_iv2n"]?.sensorUnit || "(max A)"
                      }`}
                    />
                  ) : !customTagData?.["max_iv2n"] ? (
                    <CardThree
                      title="Y Phase Voltage"
                      amount={`${
                        dashboardSts.yphvoltage ? dashboardSts.yphvoltage : "0"
                      } (max A)`}
                    />
                  ) : null}

                  {/* B Phase Voltage Card */}
                  {customTagData?.["max_iv3n"]?.sensorStatus ? (
                    <CardThree
                      title={
                        customTagData?.["max_iv3n"]?.sensorAlias ||
                        "B Phase Voltage"
                      }
                      amount={`${
                        dashboardSts.bphvoltage ? dashboardSts.bphvoltage : "0"
                      } ${
                        customTagData?.["max_iv3n"]?.sensorUnit || "(max A)"
                      }`}
                    />
                  ) : !customTagData?.["max_iv3n"] ? (
                    <CardThree
                      title="B Phase Voltage"
                      amount={`${
                        dashboardSts.bphvoltage ? dashboardSts.bphvoltage : "0"
                      } (max A)`}
                    />
                  ) : null}
                  </div>
                  <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5 mb-2 mt-2 p-2 my-cardTwo">
                  {/* RY Phase Voltage Card */}
                  {customTagData?.["max_iv12"]?.sensorStatus ? (
                    <CardThree
                      title={
                        customTagData?.["max_iv12"]?.sensorAlias ||
                        "RY Phase Voltage"
                      }
                      amount={`${
                        dashboardSts.ryphvoltage
                          ? dashboardSts.ryphvoltage
                          : "0"
                      } ${
                        customTagData?.["max_iv12"]?.sensorUnit || "(max A)"
                      }`}
                    />
                  ) : !customTagData?.["max_iv12"] ? (
                    <CardThree
                      title="RY Phase Voltage"
                      amount={`${
                        dashboardSts.ryphvoltage
                          ? dashboardSts.ryphvoltage
                          : "0"
                      } (max A)`}
                    />
                  ) : null}

                  {/* YB Phase Voltage Card */}
                  {customTagData?.["max_iv23"]?.sensorStatus ? (
                    <CardThree
                      title={
                        customTagData?.["max_iv23"]?.sensorAlias ||
                        "YB Phase Voltage"
                      }
                      amount={`${
                        dashboardSts.ybphvoltage
                          ? dashboardSts.ybphvoltage
                          : "0"
                      } ${
                        customTagData?.["max_iv23"]?.sensorUnit || "(max A)"
                      }`}
                    />
                  ) : !customTagData?.["max_iv23"] ? (
                    <CardThree
                      title="YB Phase Voltage"
                      amount={`${
                        dashboardSts.ybphvoltage
                          ? dashboardSts.ybphvoltage
                          : "0"
                      } (max A)`}
                    />
                  ) : null}

                  {/* RB Phase Voltage Card */}
                  {customTagData?.["max_iv31"]?.sensorStatus ? (
                    <CardThree
                      title={
                        customTagData?.["max_iv31"]?.sensorAlias ||
                        "RB Phase Voltage"
                      }
                      amount={`${
                        dashboardSts.rbphvoltage
                          ? dashboardSts.rbphvoltage
                          : "0"
                      } ${
                        customTagData?.["max_iv31"]?.sensorUnit || "(max A)"
                      }`}
                    />
                  ) : !customTagData?.["max_iv31"] ? (
                    <CardThree
                      title="RB Phase Voltage"
                      amount={`${
                        dashboardSts.rbphvoltage
                          ? dashboardSts.rbphvoltage
                          : "0"
                      } (max A)`}
                    />
                  ) : null}

                  </div>
                  <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5 mb-2 mt-2 p-2 my-cardTwo">
                  {/* R Phase Current Card */}
                  {customTagData?.["max_ici1"]?.sensorStatus ? (
                    <CardThree
                      title={
                        customTagData?.["max_ici1"]?.sensorAlias ||
                        "R Phase Current"
                      }
                      amount={`${
                        dashboardSts.rphcuurent ? dashboardSts.rphcuurent : "0"
                      } ${
                        customTagData?.["max_ici1"]?.sensorUnit || "(max A)"
                      }`}
                    />
                  ) : !customTagData?.["max_ici1"] ? (
                    <CardThree
                      title="R Phase Current"
                      amount={`${
                        dashboardSts.rphcuurent ? dashboardSts.rphcuurent : "0"
                      } (max A)`}
                    />
                  ) : null}

                  {/* Y Phase Current Card */}
                  {customTagData?.["max_ici2"]?.sensorStatus ? (
                    <CardThree
                      title={
                        customTagData?.["max_ici2"]?.sensorAlias ||
                        "Y Phase Current"
                      }
                      amount={`${
                        dashboardSts.yphcurrent ? dashboardSts.yphcurrent : "0"
                      } ${
                        customTagData?.["max_ici2"]?.sensorUnit || "(max A)"
                      }`}
                    />
                  ) : !customTagData?.["max_ici2"] ? (
                    <CardThree
                      title="Y Phase Current"
                      amount={`${
                        dashboardSts.yphcurrent ? dashboardSts.yphcurrent : "0"
                      } (max A)`}
                    />
                  ) : null}

                  {/* B Phase Current Card */}
                  {customTagData?.["max_ici3"]?.sensorStatus ? (
                    <CardThree
                      title={
                        customTagData?.["max_ici3"]?.sensorAlias ||
                        "B Phase Current"
                      }
                      amount={`${
                        dashboardSts.bphcurrent ? dashboardSts.bphcurrent : "0"
                      } ${
                        customTagData?.["max_ici3"]?.sensorUnit || "(max A)"
                      }`}
                    />
                  ) : !customTagData?.["max_ici3"] ? (
                    <CardThree
                      title="B Phase Current"
                      amount={`${
                        dashboardSts.bphcurrent ? dashboardSts.bphcurrent : "0"
                      } (max A)`}
                    />
                  ) : null}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default DashboardEnergy2;
