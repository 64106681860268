import { TableCell, TableHead, TableRow } from "@mui/material";

function EnhancedTableHead() {
    const tableCellsData = [
      { label: "Id", id: "id" },
      { label: "Template Name", id: "templatename" },
      { label: "Customer", id: "customer" },
      // { label: "Status", id: "status" },
      { label: "Action", id: "action" },

    ];
  
    return (
      <TableHead className="bg-gray-800">
        <TableRow className="border-b">
          {tableCellsData.map((cellData) => (
            <TableCell
              key={cellData.id}
              padding="normal"
              className="p-2"
              sx={{ color: "white", fontWeight: 400, textAlign: "center" }}
            >
              {cellData.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
  
export default EnhancedTableHead