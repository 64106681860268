import React, { ReactNode } from "react";
import { createContext, useContext, useEffect, useReducer } from "react";
import UserReducer, { UserAction, UserState } from "./AuthReducer";
import { logout, setUser } from "./AuthAction";
// import { logout, setUser } from "./AuthAction";




const initialState: UserState = {
  users: {
    user: {
      id: 0, // some default value
      email: '', // some default value
      role: 0,
      customerId: 0,
      name: ""
    },
    role: {
      role: 0
    }
  }
}

interface UserContextProps {
  state: UserState;
  dispatch: React.Dispatch<UserAction>;
}

export const UserContext = createContext<UserContextProps>({
  state: initialState,
  dispatch: () => {},
});

interface UserProviderProps {
  children: ReactNode;
}

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(UserReducer, initialState);

  useEffect(() => {
    // setUser(dispatch);
    if (window.localStorage.getItem("token")) {
      setUser(dispatch);
    } else {
      logout(dispatch);
    }
  }, []);

  return (
    <UserContext.Provider value={{ state, dispatch }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUserState = () => {
  const { state, dispatch } = useContext(UserContext);
  return { ...state, dispatch };
};
