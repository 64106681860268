import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

export default function LinearIndeterminate() {
  return (
    <Box sx={{ width: '100%'}}>
      <LinearProgress sx={{  backgroundColor: '#9ee8e0',
      '& .MuiLinearProgress-bar': {
        backgroundColor: '#14B8A6'
      } }} />
    </Box>
  );
}