// import React from 'react';
// import { LineChart } from '@mui/x-charts/LineChart';

// interface CustomLineChartProps {
//   xAxis: { data: number[] }[];
//   series: { data: number[] }[];
//   width: number;
//   height: number;
// }

// const CustomLineChart: React.FC<CustomLineChartProps> = ({ xAxis, series, width, height }) => {
//   return <LineChart xAxis={xAxis} series={series} width={width} height={height} />;
// };

// export default CustomLineChart;


import React from 'react';
import { LineChart } from '@mui/x-charts/LineChart';

interface CustomLineChartProps {
  xAxis?: { data: number[] }[];
  series?: { data: number[] }[];
  width: number;
  height: number;
}

const CustomLineChart: React.FC<CustomLineChartProps> = ({ xAxis, series, width, height }) => {
  if (!xAxis || !series || xAxis.length === 0 || series.length === 0) {
    return <p>No Data Available</p>;
  }

  return <LineChart xAxis={xAxis} series={series} width={width} height={height} />;
};

export default CustomLineChart;
