export interface Customer {
  id: number;
  name: string;
  email: string;
  status: boolean;
  createdBy?: string;
  updatedBy?: string;
}

export interface PaginatedCustomers {
  [x: string]: any;
  customers: Customer[];
  currentPage: number;
  totalPages: number;
  nextPage?: number;
  previousPage?: number;
  totalItems: number;
}


export const createDefaultCustomer = (): Customer => ({
  id: 0,
  name: "",
  email: "",
  status: false,
  createdBy: "",
  updatedBy: "",
});


export interface CustomerApiResponse {
  message: string;
  customers: Customer; // Replace YourCustomerType with the actual type of your customers
}

export interface ApiResponseGetAllCustomer {
  message: string;
  customers: Customer[]
}