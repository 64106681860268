import { http } from '../../helper/http';
import {   ApiResponseForCreateTagGroup, ApiResponseTagsGroupAttributes } from '../../types/TagGroup';

export interface PaginatedTagGroupWithTagTemplate {
  tagGroups: ApiResponseTagsGroupAttributes[];
  currentPage: number;
  totalPages: number;
  nextPage?: number;
  previousPage?: number;
  totalItems: number;
}

export interface SetTagGroupAction {
  type: "SET_TAG_GROUP";
  payload: PaginatedTagGroupWithTagTemplate;
}

export interface AddNewTagGroup {
  type: "ADD_TAG_GROUP";
  payload: {totalitems: number; taggroup: ApiResponseForCreateTagGroup};
}

export interface SetSearchTemplateTagGroupAction {
  type: "SET_TAG_GROUP_TEMPLATE";
  payload: PaginatedTagGroupWithTagTemplate;
}


export interface UpdateTagGroupAction {
  type: "UPDATE_TAG_GROUP";
  payload: { id: number; tagname: string, templatename: string  };
}

export interface SetTagGroupPageAction {
  type: "SET_TAG_GROUP_WITH_PAGE";
  payload: PaginatedTagGroupWithTagTemplate;
}


export type TagGroupAction = 
  | SetTagGroupAction
  | AddNewTagGroup
  | UpdateTagGroupAction
  | SetSearchTemplateTagGroupAction
  | SetTagGroupPageAction

interface ApiResponse {
  message: string;
  tagsGroup: PaginatedTagGroupWithTagTemplate;
}



const baseUrl = process.env.REACT_APP_BASE_URL;

export async function setTagGroup(dispatch: React.Dispatch<TagGroupAction>, payload: number) {
  try {
    const page = payload ?? 1;
    const response = (await http(baseUrl + `/tag-groups?page=${page}`)) as ApiResponse;
    const responseData = response;
    
    dispatch({
      type: "SET_TAG_GROUP",
      payload: responseData.tagsGroup,
    });
  } catch (err) {
    console.error(err);
  }
}


export function addNewTagGroup(
  dispatch: React.Dispatch<TagGroupAction>,
  payload: { totalitems: number; taggroup: ApiResponseForCreateTagGroup}
) {
  dispatch({
    type: "ADD_TAG_GROUP",
    payload,
  });
}

export function updateTagGroup(
  dispatch: React.Dispatch<TagGroupAction>,
  payload: { id: number; tagname: string, templatename: string  }
) {
  dispatch({
    type: "UPDATE_TAG_GROUP",
    payload,
  });
}


export function searchTemplate(
  dispatch: React.Dispatch<TagGroupAction>,
  payload: PaginatedTagGroupWithTagTemplate
) {
  dispatch({
    type: "SET_TAG_GROUP_TEMPLATE",
    payload,
  });
}






export function getTagGroupPageClick(
  dispatch: React.Dispatch<TagGroupAction>,
  payload: PaginatedTagGroupWithTagTemplate
) {
  dispatch({
    type: "SET_TAG_GROUP_WITH_PAGE",
    payload,
  });
}