import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          padding: "5px",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          "@media (max-width: 700px)": {
            padding: "9px 16px",
          },
          "@media (min-width: 701px)": {
            padding: "5px 16px",
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          "@media (max-width: 800px)": {
            width: "100%",
          },
          "@media (min-width: 701px)": {
            width: "100%",
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          padding: "0px",
          paddingLeft: "4px",
          marginBottom: "0px"
        },
      },
    },
  },
});

export default theme;
