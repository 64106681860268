import React, {
    createContext,
    useContext,
    useEffect,
    useReducer,
    ReactNode,
    Dispatch,
} from "react";
import UserReducer, { UserAction, UserState } from "./UserReducer";
import { setUserOnbaord } from "./UserAction";



interface UserContextProps {
    state: UserState;
    dispatch: Dispatch<UserAction>;
}

const initialState: UserState = {
    userOnBoard: {
        users: [],
        currentPage: 0,
        totalPages: 0,
        nextPage: 0,
        previousPage: 0,
        totalItems: 0
    }
  };

export const UserContext = createContext<UserContextProps>({
    state: initialState,
    dispatch: () => { },
});

interface UserProviderProps {
    children: ReactNode;
}

export const UserOnBoardProvider: React.FC<UserProviderProps> = ({ children }) => {
    const [state, dispatch] = useReducer(UserReducer, initialState);

    // useEffect(() => {
    //     setUserOnbaord(dispatch, 1);
    // }, []);

    return (
        <UserContext.Provider
            value={{
                state,
                dispatch,
            }}
        >
            {children}
        </UserContext.Provider>
    );
};

export const useUserOnBoardState = () => {
    const { state, dispatch } = useContext(UserContext);
    return { ...state, dispatch };
};
