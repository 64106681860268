import { http } from "../../helper/http";
import { PaginatedTags, Tag } from "../../types/TagTypes";

interface SetTagAction {
  type: "SET_TAGS";
  payload: PaginatedTags;
}

interface AddTagAction {
  type: "ADD_TAGS";
  payload: { tags: Tag, totalItems: number };
}

export interface SetTagGroupPageAction {
  type: "SET_TAG_WITH_PAGE";
  payload: PaginatedTags;
}

interface UpdateTagAction {
  type: "UPDATE_TAGS";
  payload: { id: number; tags: Tag };
}

interface UpdateTagsStatusAction {
  type: "UPDATE_TAGS_STATUS";
  payload: { id: number; status: boolean };
}

interface SetTagPageAction {
  type: "SET_TAGS_WITH_PAGE";
  payload: PaginatedTags;
}

interface SetAllTagAction {
  type: "SET_TAGS_ALL";
  payload: Tag[];
}






export type TagAction =
  | SetTagAction
  | AddTagAction
  | UpdateTagAction
  | UpdateTagsStatusAction
  | SetTagPageAction
  | SetAllTagAction


interface ApiResponse {
  message: string;
  tags: PaginatedTags;
}

const baseUrl = process.env.REACT_APP_BASE_URL;
export async function setTag(dispatch: React.Dispatch<TagAction>, payload: number) {
  try {
    const page = payload ?? 1;
    const response = (await http(baseUrl + `/tags?page=${page}`)) as ApiResponse;
    const responseData = response;

    

    dispatch({
      type: "SET_TAGS",
      payload: responseData.tags,
    });
  } catch (err) {
    console.error(err);
  }
}



export function addNewTags(
  dispatch: React.Dispatch<TagAction>,
  payload: { tags: Tag, totalItems: number }
) {
  dispatch({
    type: "ADD_TAGS",
    payload,
  });
}

export function updateTag(
  dispatch: React.Dispatch<TagAction>,
  payload: { id: number; tags: Tag }
) {
  dispatch({
    type: "UPDATE_TAGS",
    payload,
  });
}

export function updateTagStatus(
  dispatch: React.Dispatch<TagAction>,
  payload: { id: number; status: boolean }
) {
  dispatch({
    type: "UPDATE_TAGS_STATUS",
    payload,
  });
}

export function getTagPageClick(
  dispatch: React.Dispatch<TagAction>,
  payload: PaginatedTags
) {
  dispatch({
    type: "SET_TAGS_WITH_PAGE",
    payload,
  });
}


export function setAllTags(
  dispatch: React.Dispatch<TagAction>,
  payload: Tag[]
) {
  dispatch({
    type: "SET_TAGS_ALL",
    payload,
  });
}
