import { TableCell, TableHead, TableRow } from "@mui/material";

function EnhancedTableHeader() {
  const tableCellsData = [
    { label: "Report Header", id: "reportHeader" },
    { label: "Periodicity", id: "reportPeriodicity" },
    { label: "Report Format", id: "reportFormat" },
    { label: "Report Received on", id: "reportReceivedOn" },
    { label: "Received Time", id: "reportReceivedTime" },
    { label: "Email", id: "email" },
    { label: "Status", id: "status" },
    { label: "Action", id: "action" },
  ];

  return (
    <TableHead className="bg-gray-800">
      <TableRow className="border-b">
        {tableCellsData.map((cellData) => (
          <TableCell
            key={cellData.id}
            padding="normal"
            className="p-2"
            sx={{ color: "white", fontWeight: 400, textAlign: "center" }}
          >
            {cellData.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default EnhancedTableHeader;
