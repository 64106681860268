import { TableCell, TableHead, TableRow } from "@mui/material";

interface Data {
  id: number;
  email: string;
  name: string;
  action: string;
}

// function createData(
//   id: number,
//   name: string,
//   email: string,
//   status: string
// ): Data {
//   return {
//     id,
//     name,
//     email,
//     action: "view",
//   };
// }

const headCells: { id: keyof Data; disablePadding: boolean; label: string }[] =
  [
    {
      id: "name",
      disablePadding: true,
      label: "Name",
    },
    {
      id: "email",
      disablePadding: false,
      label: "Email",
    },
    {
      id: "action",
      disablePadding: false,
      label: "Action",
    },
  ];

export function EnhancedTableHead() {
  return (
    <TableHead className="bg-gray-800">
      <TableRow className="border-b">
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="center"
            padding="normal"
            sx={{ fontWeight: 600, color: "white" }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
