import { ApiResponse } from "../../types/UserTypes";

export interface UserState {
  users: ApiResponse;
}



export type UserAction =
  | { type: "SET_USERS"; payload: ApiResponse }


export default function UserReducer(
  state: UserState,
  action: UserAction
): UserState {
  const { type, payload } = action;

  switch (type) {
    case "SET_USERS":
      {
        return { 
          ...state, 
          users: {
            ...state.users,
            user: {
              ...state.users.user,
              id: payload.user.id,
              email: payload.user.email,
              customerId: payload.user.customerId,
              name: payload.user.name,
              role: payload.user.role
            },
          },
        };
      }
    default:
      return state;
  }
}
