import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import ApexCharts from "apexcharts";

interface MotorStatusData {
  x: string;
  y: number;
}

interface MotorStatusChartProps {
  apiData: { timestamp: string; flow: string }[];
}

const MotorFlowChart: React.FC<MotorStatusChartProps> = ({ apiData }) => {
  const [motorStatusData, setMotorStatusData] = useState<MotorStatusData[]>([]);

  const prepareMotorStatusData = () => {
    const currentDate = new Date();
    const last24HoursDate = new Date(currentDate.getTime() - 24 * 60 * 60 * 1000);

    const last24HoursData = apiData.filter((entry) => {
      const entryDate = new Date(entry.timestamp);
      return entryDate >= last24HoursDate && entryDate <= currentDate;
    });
    //console.log('last24HoursData1',last24HoursData)
    const uniqueData = last24HoursData.reduce((acc: MotorStatusData[], entry) => {
      const entryDate = new Date(entry.timestamp);
      const formattedTime = `${entryDate.getHours().toString().padStart(2, "0")}:${entryDate.getMinutes().toString().padStart(2, "0")}:${entryDate.getSeconds().toString().padStart(2, "0")}`;
      const flow = parseFloat(entry.flow);
       //console.log('formattedTime',formattedTime)
      const status = flow > 0 ? 1 : 0;
      if (!acc.find(data => data.x === formattedTime)) {
        acc.push({ x: formattedTime, y: status });
      }
      return acc;
    }, []);
    // console.log('uniqueData',uniqueData)
   
    return uniqueData;
  };

  useEffect(() => {
    const motorData = prepareMotorStatusData();
    setMotorStatusData(motorData);
  }, [apiData]);

  useEffect(() => {
    if (motorStatusData.length === 0) {
      return;
    }
    
    const uniqueHours = new Set(motorStatusData.map(dataPoint => dataPoint.x.split(":")[0]));
    const numberOfUniqueHours = uniqueHours.size;
    const tickAmount = numberOfUniqueHours > 1 ? numberOfUniqueHours : 2;

    const options = {
      series: [
        {
          name: "Motor Status",
          data: motorStatusData.map((dataPoint) => ({ x: dataPoint.x, y: dataPoint.y })),
        },
      ],
      chart: {
        type: "line",
        height: "200px",
        toolbar: {
          show: false,
        },
      },
      xaxis: {
        type: "category",
        labels: {
          rotate: -45,
          rotateAlways: true,
          formatter: function (val: any) {
            if (val && typeof val === 'string' && val.includes(":")) {
              const [hours, minutes] = val.split(":");
              return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;
            }
            return val;
          },
        },
        tickAmount: 8,
      },
      yaxis: {
        max: 1,
        min: 0,
        tickAmount: 1,
        labels: {
          formatter: (value: number) => (value === 1 ? "On" : "Off"),
        },
      },
      stroke: {
        curve: "stepline",
        width: 2
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        custom: ({ series, seriesIndex, dataPointIndex, w }:{ series:any, seriesIndex:any, dataPointIndex:any, w:any }) => {
          const data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
          const formattedTime = data.x.replace(":00", ":00:00");
          
          return `<div class="custom-tooltip p-2"><p>Time: ${formattedTime}<br/>Status: ${data.y === 1 ? "On" : "Off"}</p></div>`;
        },
      },
    };

    const chart = new ApexCharts(document.querySelector("#motor-flow-chart"), options);
    chart.render();

    return () => {
      chart.destroy();
    };
  }, [motorStatusData]);

  return (
    <div style={{ width: "100%"}}>
      <Box sx={{ Width: "100%" }}>
        {motorStatusData.length === 0 ? (
          <div className="flex items-center h-[200px] justify-center">No data available in last 24 hours</div>
        ) : (
          <div className="relative h-fit overflow-y-hidden" id="motor-flow-chart"></div>
        )}
      </Box>
    </div>
  );
};

export default MotorFlowChart;
