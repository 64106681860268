import React, {
    createContext,
    useContext,
    useEffect,
    useReducer,
    ReactNode,
    Dispatch,
  } from "react";
import TemplateReducer, { TemplateState } from "./TemplateReducer";
import { TemplateAction, setTemplate } from "./TemplateAction";

  
  
  
  interface TemplateContextProps {
    state: TemplateState;
    dispatch: Dispatch<TemplateAction>;
  }
  
  const initialState: TemplateState = {
    templates: {
      templates: [],
      currentPage: 0,
      totalPages: 0,
      previousPage: 0,
      totalItems: 0
    },
  };
  
  export const TemplateContext = createContext<TemplateContextProps>({
    state: initialState,
    dispatch: () => {},
  });
  
  interface TemplateProviderProps {
    children: ReactNode;
  }
  
  export const TemplateProvider: React.FC<TemplateProviderProps> = ({ children }) => {
    const [state, dispatch] = useReducer(TemplateReducer, initialState);
  
    // useEffect(() => {
    //   setTemplate(dispatch, 1);
    // }, []);
  
    return (
      <TemplateContext.Provider
        value={{
          state,
          dispatch,
        }}
      >
        {children}
      </TemplateContext.Provider>
    );
  };
  
  export const useTemplateState = () => {
    const { state, dispatch } = useContext(TemplateContext);
    return { ...state, dispatch };
  };
  