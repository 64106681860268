import { UserOnboard, UserOnboardPagination } from "../../types/UserOnboardTypes";
import { http } from "../../helper/http";

interface SetUserOnboardAction {
    type: "SET_USER_ONBOARD";
    payload: UserOnboardPagination;
}

interface SetUserOnbaordPageAction {
    type: "SET_USER_ONBOARD_PAGE";
    payload: UserOnboardPagination;
}

interface AddUserOnboardAction {
    type: "ADD_USER_ONBOARD";
    payload: { userOnboards: UserOnboard, totalItems: number };
}

interface UpdateUserOnboardAction {
    type: "UPDATE_USER_ONBOARD";
    payload: { id: number, userOnboards: UserOnboard };
}

// interface SetAllUserOnboardAction {
//     type: "SET_ALL_USER_ONBOARD";
//     payload: UserOnboard;
// }

export type UserOnboardAction =
    | SetUserOnboardAction
    | AddUserOnboardAction
    | UpdateUserOnboardAction
    | SetUserOnbaordPageAction



interface ApiResponse {
    message: string;
    users: UserOnboardPagination;
}

const baseUrl = process.env.REACT_APP_BASE_URL;
export async function setUserOnbaord(dispatch: React.Dispatch<UserOnboardAction>, payload: number) {
    try {
        const page = payload ?? 1;
        const response = (await http(baseUrl + `/user?page=${page}`)) as ApiResponse;        
        dispatch({
            type: "SET_USER_ONBOARD",
            payload: response.users
        });
    } catch (error) {
        console.error(error);
    }
}


export function addNewUserOnBoard(
    dispatch: React.Dispatch<UserOnboardAction>,
    payload: { userOnboards: UserOnboard, totalItems: number }
  ) {
    dispatch({
      type: "ADD_USER_ONBOARD",
      payload,
    });
  }
  

  export function updateUserOnBoard(
    dispatch: React.Dispatch<UserOnboardAction>,
    payload: { id: number, userOnboards: UserOnboard }
  ) {
    dispatch({
      type: "UPDATE_USER_ONBOARD",
      payload,
    });
  }


  export function getUserPageClick(
    dispatch: React.Dispatch<UserOnboardAction>,
    payload: any
  ) {
    dispatch({
      type: "SET_USER_ONBOARD_PAGE",
      payload,
    });
  }
  
  