

import * as React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { useParams } from "react-router-dom";
import { http } from "../../helper/http";
import { getReportAll1, getReportAll2 } from "../../common-function/Report";
import { useUserState } from "../../app-context/auth-context/AuthState";
import { toast } from "react-toastify";
const baseUrl = process.env.REACT_APP_BASE_URL;

const Report1 = () => {
  const [startDate, setStartDate] = React.useState<any>(null);
  const [endDate, setEndDate] = React.useState<any>(null);
  const [apiData, setApiData] = React.useState<any>(null);
  const { customerId } = useParams();
  const { users } = useUserState();

  const fetchData = async (startD: Date, endD: Date) => {
    try {
      const response = await http(
        baseUrl +
          `/report?startDate=${startD}&endDate=${endD}&customerId=${customerId}`
      );
      setApiData(response);
    } catch (error) {}
  };

  React.useEffect(() => {
    if (startDate && endDate) {
      const startD = startDate["$d"]; // Convert to JavaScript Date object
      const endD = endDate["$d"];
      fetchData(startD, endD);
    }
  }, [startDate, endDate]);

  const handleClick = () => {
    // getReportAll1(apiData, users?.user?.name || "N/A", "Report", startDate["$d"]+" - "+endDate["$d"]);
    if (startDate && endDate) {
      getReportAll2(
        apiData,
        users?.user?.name || "N/A",
        "Report",
        `${new Date(startDate["$d"]).toLocaleString()} - ${new Date(
          endDate["$d"]
        ).toLocaleString()}`
      );
    } else {
      toast.info("Select the startdate and enddate");
    }
  };
  return (
    <div>
      <div className="m-6 space-y-4 md:flex md:items-center md:space-x-4 md:space-y-0">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div className="space-y-3 md:space-y-0 md:flex md:space-x-3">
            <DateTimePicker
              label="Start Date & Time"
              value={startDate}
              onChange={(e: any) => {
                setStartDate(e);
              }}
            />
            <DateTimePicker
              label="End Date & Time"
              value={endDate}
              onChange={(e: any) => {
                setEndDate(e);
              }}
            />
          </div>
        </LocalizationProvider>
        <button
          onClick={handleClick}
          className="bg-blue-500  text-white px-4 py-4 rounded-md hover:bg-blue-600"
        >
          Download
        </button>
      </div>
    </div>
  );
};

export default Report1;



