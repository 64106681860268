export interface Device {
  id: number;
  deviceId: string;
  deviceName: string;
  template: number;
  status: boolean;
  latitude: number;
  longitude: number;
  createdBy?: string;
  updatedBy?: string;
  customerId?: string;
  createdAt?: string;
  updatedAt?: string;
  modbusConfig:boolean;
  deviceAlias: string;
  state: string;
  city: string;
  area: string;
  // customerName?: string;
  customTag: boolean;
  Customer?: {
    name: string
  } 
  ,templateId?:Number,
  deviceCategory: any,
  batteryConfigStatus: boolean,
  slaveId: any,
}



export interface PaginatedDevice {
  slice(arg0: number, arg1: number): any;
  length: number;
  devices: Device[];
  currentPage: number;
  totalPages: number;
  nextPage?: number;
  previousPage?: number;
  totalItems: number;
}


export const createDefaultDevice = (): Device => ({
  id: 0,
  deviceId: "",
  deviceName: "",
  template: 0,
  status: false,
  latitude: 0,
  longitude: 0,
  createdBy: "",
  updatedBy: "",
  customerId: "",
  modbusConfig:false,
  customTag: false,
  deviceCategory: "",
  batteryConfigStatus: false,
  slaveId: "",
  deviceAlias: "",
  state: "",
  city: "",
  area: "",
});


export  interface DeviceApiResponse {
  message: string;
  devices: Device; // Replace YourCustomerType with the actual type of your customers
}


export interface ApiResponseForCreateDevice {
  status: number;
  data: {
      message: string;
      devices: Device
  };
}