import React, {
  createContext,
  useContext,
  useEffect,
  useReducer,
  ReactNode,
  Dispatch,
} from "react";
import { setDevice } from "./DeviceAction";
import DeviceReducer, { DeviceAction, DeviceState } from "./DeviceReducer";

interface DeviceContextProps {
  state: DeviceState;
  dispatch: Dispatch<DeviceAction>;
}

const initialState: DeviceState = {
  devices: {
    devices: [],
    currentPage: 0,
    totalPages: 0,
    previousPage: 0,
    totalItems: 0,
    slice: function (arg0: number, arg1: number) {
      throw new Error("Function not implemented.");
    },
    length: 0
  },
};

export const DeviceContext = createContext<DeviceContextProps>({
  state: initialState,
  dispatch: () => {},
});

interface DeviceProviderProps {
  children: ReactNode;
}

export const DeviceProvider: React.FC<DeviceProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(DeviceReducer, initialState);

  useEffect(() => {
    setDevice(dispatch, 1);
  }, []);

  return (
    <DeviceContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </DeviceContext.Provider>
  );
};

export const useDeviceState = () => {
  const { state, dispatch } = useContext(DeviceContext);
  return { ...state, dispatch };
};
