import { useEffect, useRef, useState } from "react";
import CardTwo from "../../components/dashBoardComponents/CardTwo";
import axios from "axios";
import {
  filterDataFromEachHourLast24HoursTotalizer,
  formatTimestamp,
  generateCSV1,
  processConsumptionDataTotalizer,
  processHourlyDataTotalizer,
  reportDataWithGraph,
} from "../../common-function/CommonFunction";
import LongMenu from "../../utils/longMenu/LongMenu";
import LineChart from "../../components/apex-chart/LineChart";
import flow from "../../images/flow.jpeg";
import totalizer from "../../images/totalizer.jpeg";
import MobileTowerIcon from "../../components/apex-chart/HeatMapChart";
import CustomBarChart from "../../components/dashBoardComponents/BarChart2";
const baseUrl = process.env.REACT_APP_BASE_URL;
const DashboardPulseWaterTemplate = ({ devices, selectedDevice }: any) => {
  const [apiData, setApiData] = useState<any[]>([]);
  const [dashboardSts, setDashboardSts] = useState<any>([]);
  const chartRef = useRef<HTMLDivElement>(null);
  const chartRef1 = useRef<HTMLDivElement>(null);
  const chartRef2 = useRef<HTMLDivElement>(null);
  const [customTagData, setCustomTagData] = useState<any>([]);
  const [value, setValue] = useState(0);

  const fetchData = async () => {
    const accessToken = localStorage.getItem("token");

    let response: any = null;
    try {
      response = await axios.get(baseUrl + `/device-data-log?last24=last24`, {
        params: {
          deviceId: selectedDevice,
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const _data = response.data
        .map((d: any, i: any) => {
          return {
            timestamp: formatTimestamp(d.timeStamp),
            // flow: d.Flow != 'ERROR' ? +d.Flow * 0.001 : 'ERROR',
            rssi: d["rssi"],
            unit: "m³",
            totalizer: d["Totalizer"] || 0,
            flow: d["Flow"] || 0,
            batteryValue: d["batteryValue"],
          };
        })
        .filter((d: any) => {
          return !Object.values(d).some(
            (val: any) =>
              typeof val === "string" && val.toLowerCase() === "error"
          );
        });

      setApiData(_data);

      setDashboardSts(_data.length ? _data[0] : "");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
    if (devices?.DeviceTemplate?.tagJson) {
      const transformedTagJson = devices.DeviceTemplate.tagJson.reduce(
        (acc: any, tag: any) => {
          acc[tag.tagName] = tag;
          return acc;
        },
        {}
      );
      setCustomTagData(transformedTagJson);
    }
    const intervalId = setInterval(fetchData, 120000);
    return () => clearInterval(intervalId);
  }, [selectedDevice]);

  const flowrate =
    apiData.length > 0
      ? apiData.map(({ timestamp, flow }: any) => ({
          timestamp,
          value: flow,
        }))
      : [];
  const battery =
    apiData.length > 0
      ? apiData.map(({ timestamp, batteryValue }: any) => ({
          timestamp,
          value: batteryValue ? batteryValue : "0",
        }))
      : [];

  let consumtionData: any[] | undefined;
  const filterLast24hourDataOfEachHOur =
    filterDataFromEachHourLast24HoursTotalizer(apiData);

  if (filterLast24hourDataOfEachHOur.length > 0) {
    const hourData = processHourlyDataTotalizer(filterLast24hourDataOfEachHOur);
    consumtionData = processConsumptionDataTotalizer(hourData);
  }

  const dummyData: any = [
    {
      name: customTagData?.["Flow"]?.sensorAlias || "Flowrate",
      color: "#1f79a7",
      data: flowrate,
      postfix: customTagData?.["Flow"]?.sensorUnit || " m³/hr",
    }
  ];

  const batteryData: any = [
    {
      name: "Battery",
      color: "#1f79a7",
      data: battery,
      postfix: " %",
    },
  ];

  const handleExportLineChart = async (exportOption: string) => {
    let filterData = flowrate.map(({ timestamp, value }: any) => {
      return {
        TIMESTAMP: timestamp,
        [customTagData?.["Flow"].sensorAlias || "FLOW_RATE"]: value,
        UNIT: `${customTagData?.["Flow"].sensorUnit || "m³/hr"}`,
      };
    });
    switch (exportOption) {
      case "Download as CSV":
        generateCSV1(filterData);
        break;

      case "Download as PDF":
        if (filterData && chartRef1) {
          reportDataWithGraph(
            chartRef1,
            filterData,
            devices?.deviceName,
            devices?.Customer?.name,
            `${customTagData?.["Flow"].sensorAlias ? customTagData?.["Flow"].sensorAlias : "Flowrate" } Summary`,
            apiData?.length > 0
              ? apiData[0].timestamp +
                  "-" +
                  apiData[apiData?.length - 1].timestamp
              : ""
          );
        }
        return;
      default:
        return;
    }
  };

  const handleExportBarChart = async (exportOption: string) => {
    const filterData =
      consumtionData && consumtionData.length > 0
        ? consumtionData.map(
            ({ hour, firstTimeStamp, lastTimeStamp, consumption }: any) => {
              return {
                hour: hour + ":00",
                firstTimeStamp,
                lastTimeStamp,
                consumption,
              };
            }
          )
        : [];
    switch (exportOption) {
      case "Download as CSV":
        if (filterData) {
          generateCSV1(filterData);
        }
        break;

      case "Download as PDF":
        if (filterData && chartRef) {
          reportDataWithGraph(
            chartRef,
            filterData,
            devices?.deviceName,
            devices?.Customer?.name,
            "Water Consumption Summary",
            apiData?.length > 0
              ? apiData[0].timestamp +
                  "-" +
                  apiData[apiData?.length - 1].timestamp
              : ""
          );
        }
        return;
      default:
        return;
    }
  };

  const handleExportLineChart2 = async (exportOption: string) => {
    let filterData = battery.map(({ timestamp, value }: any) => {
      return {
        TIMESTAMP: timestamp,
        BATTERY: value ? value : "0",
        UNIT: "%",
      };
    });
    switch (exportOption) {
      case "Download as CSV":
        generateCSV1(filterData);
        break;

      case "Download as PDF":
        if (filterData && chartRef2) {
          reportDataWithGraph(
            chartRef2,
            filterData,
            devices?.deviceName,
            devices?.Customer?.name,
            "Battery Summary",
            apiData?.length > 0
              ? apiData[0].timestamp +
                  "-" +
                  apiData[apiData?.length - 1].timestamp
              : ""
          );
        }
        return;
      default:
        return; // Unknown export option
    }
  };
  // console.log("devcieea", devices);
  
    
  return (
    <div>
      <div className=" p-2 mb-4">
        <section>
          <p className="flex">
            <MobileTowerIcon
              levels={dashboardSts?.rssi || 0}
              time={dashboardSts?.timestamp}
            />
            {"Last Data Received at  " + dashboardSts?.timestamp ||
              "YYYY-MM-DD HH:MM:SS"}
          </p>
        </section>
      </div>
      <div className="border border-gray-400 rounded-md  grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5  p-2">
        {customTagData?.["Flow"]?.sensorStatus ? (
          <CardTwo
            icon={flow}
            title={customTagData?.["Flow"]?.sensorAlias || "Flowrate"}
            amount={`${dashboardSts.flow ? dashboardSts.flow : "N/A"} ${
              customTagData?.["Flow"]?.sensorUnit || "m³/hr"
            }`}
          />
        ) : !customTagData?.["Flow"] ? (
          <CardTwo
            icon={flow}
            title="Flowrate"
            amount={
              dashboardSts && dashboardSts.flow
                ? `${dashboardSts.flow} m³/hr`
                : "N/A m³/hr"
            }
          />
        ) : null}
        {devices.batteryConfigStatus &&(
          <CardTwo
          title="Battery"
          amount={
            dashboardSts && dashboardSts.batteryValue
            ? `${dashboardSts.batteryValue}  %`
            : "0 %"
          }
          />
        )}

        {customTagData?.["Totalizer"]?.sensorStatus ? (
          <CardTwo
            icon={totalizer}
            title={customTagData?.["Totalizer"]?.sensorAlias || "Totalizer"}
            amount={`${
              dashboardSts.totalizer ? dashboardSts.totalizer : "N/A"
            } ${customTagData?.["Totalizer"]?.sensorUnit || "m³/hr"}`}
          />
        ) : !customTagData?.["Totalizer"] ? (
          <CardTwo
            icon={totalizer}
            title="Totalizer"
            amount={
              dashboardSts && dashboardSts.totalizer
                ? `${dashboardSts.totalizer} m³`
                : "N/A m³"
            }
          />
        ) : null}
      </div>
      <div className="pt-[5px]"></div>
      <div className="my_temp_humidity">
        <div
          className="border border-gray-400 p-2 rounded-md"
          style={{ height: "100%" }}
        >
          <div className="relative">
            <p>
              {value === 0 ? (customTagData?.["Flow"] ? customTagData?.["Flow"]?.sensorAlias : "Flowrate" ) : value === 1 ? (customTagData?.["Totalizer"] ? customTagData?.["Totalizer"]?.sensorAlias : "Totalizer" ) : "Battery"}{" "}
              data variation with time (Between{" "}
              {apiData.length > 0
                ? apiData[0].timestamp +
                  " to " +
                  apiData[apiData?.length - 1].timestamp
                : " "}
              )
            </p>
            <div className="pb-[20px] pt-[10px]">
              <div className="w-[600px]">
                <ul className="text-sm font-medium text-center text-gray-500 rounded-lg sm:flex">
                  {customTagData?.["Flow"]?.sensorStatus ? (
                    <li className="focus-within:z-10">
                    <button
                      className={`inline-block border border-gray-300 px-4 py-2 text-[1rem] text-gray-900 rounded-s-lg  transition-colors duration-300`}
                      aria-current="page"
                      style={value === 0 ? { backgroundColor: "#dadada" } : {}}
                      onClick={() => setValue(0)}
                    >
                      {customTagData?.["Flow"]?.sensorAlias}
                    </button>
                  </li>
                  ) : !customTagData?.["Flow"] ? (
                  <li className="focus-within:z-10">
                    <button
                      className={`inline-block border border-gray-300 px-4 py-2 text-[1rem] text-gray-900 rounded-s-lg  transition-colors duration-300`}
                      aria-current="page"
                      style={value === 0 ? { backgroundColor: "#dadada" } : {}}
                      onClick={() => setValue(0)}
                    >
                      Flowrate
                    </button>
                  </li>
                  ) : null}
                  {devices.batteryConfigStatus &&(
                    <li className="focus-within:z-10">
                    <button
                      className={`inline-block border border-gray-300 px-5 py-2 text-[1rem] text-gray-900 transition-colors duration-300`}
                      style={value === 2 ? { backgroundColor: "#dadada" } : {}}
                      onClick={() => setValue(2)}
                      >
                      Battery
                    </button>
                  </li>
                    )}
                  {customTagData?.["Totalizer"]?.sensorStatus ? (
                    <li className="focus-within:z-10">
                    <button
                      className={`inline-block border border-gray-300 px-4 py-2 text-[1rem] text-gray-900 rounded-e-lg  transition-colors duration-300`}
                      aria-current="page"
                      style={value === 1 ? { backgroundColor: "#dadada" } : {}}
                      onClick={() => setValue(1)}
                    >
                      {customTagData?.["Totalizer"]?.sensorAlias}
                    </button>
                  </li>
                  ) : !customTagData?.["Totalizer"] ? (
                  <li className="focus-within:z-10">
                    <button
                      className={`inline-block border border-gray-300 px-4 py-2 text-[1rem] text-gray-900 rounded-e-lg  transition-colors duration-300`}
                      aria-current="page"
                      style={value === 1 ? { backgroundColor: "#dadada" } : {}}
                      onClick={() => setValue(1)}
                    >
                      Totalizer
                    </button>
                  </li>
                  ) : null}
                </ul>
              </div>
            </div>
            <div className="absolute top-0 right-0">
              <LongMenu
                options={["Download as CSV", "Download as PDF"]}
                onOptionClick={
                  value === 0
                    ? handleExportLineChart
                    : value === 1
                    ? handleExportBarChart
                    : handleExportLineChart2
                }
                id={1}
              />
            </div>
          </div>

          <div>
            {value === 0 ? (
              <div ref={chartRef1}>
                <LineChart
                  stroke={true}
                  yAxisName="Data"
                  data={dummyData}
                  xAxisName="Time"
                />
              </div>
            ) : value === 1 ? (
              <div ref={chartRef}>
                <CustomBarChart
                  data={consumtionData}
                  yAxisName="Water Consumption"
                  xAxisName="Time"
                />
              </div>
            ) : (
              <div ref={chartRef2}>
                <LineChart
                  stroke={true}
                  yAxisName="Battery (%)"
                  data={batteryData}
                  xAxisName="Time"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardPulseWaterTemplate;
