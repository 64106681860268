import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { IoIosArrowDown } from "react-icons/io";

import { BiLogOut } from "react-icons/bi";

import { useUserState } from "../../app-context/auth-context/AuthState";
import { logout } from "../../app-context/auth-context/AuthAction";
import LockResetIcon from "@mui/icons-material/LockReset";
import { Dialog } from "@mui/material";
import ChangePassword from "../../modules/change-password/ChangePassword";
import ProtectedComponent from "../../utils/protected-route/ProtectedComponent";

const UserProfile = () => {
  // this is to make the div responsive if tailwind not working
  const isSmallScreen = useMediaQuery({ maxWidth: 700 });
  const { dispatch } = useUserState();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const trigger = useRef<any>(null);
  const dropdown = useRef<any>(null);
  const [toggleChangePasswordModal, setToggleChangePasswordModal] =
    useState(false);

  const { users }: any = useUserState();
  

  useEffect(() => {}, [users.user.email]);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }: MouseEvent) => {
      if (!dropdown.current || !trigger.current) return;
      if (
        !dropdownOpen ||
        dropdown.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setDropdownOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  const logoutHandler = () => {
    logout(dispatch);
  };

  const togglePasswordModal = () => {
    setToggleChangePasswordModal((prevVal) => !prevVal);
  };

  return (
    <>
      <Dialog
        open={toggleChangePasswordModal}
        onClose={() => {
          setToggleChangePasswordModal(!toggleChangePasswordModal);
        }}
      >
        <ChangePassword togglePasswordModal={togglePasswordModal} />
      </Dialog>
      <div className="relative lg:pl-10 sm:pl-1">
        <button
          ref={trigger}
          onClick={() => setDropdownOpen(!dropdownOpen)}
          className="flex items-center gap-4"
          // to="#"
        >
          <span className="hidden text-right lg:block">
            <span className="block text-sm font-medium text-black">
              {users.user.name}
            </span>
            <span className="block text-xs">{users.user.email}</span>
          </span>

          {/* <span className="h-12 w-12 rounded-full overflow-hidden">
            <img src={User1} alt="User" className="h-full w-full object-cover"/>
          </span> */}

          <IoIosArrowDown
            className={`hidden fill-current sm:block ${
              dropdownOpen ? "rotate-180" : ""
            }`}
          />
        </button>

        {/* <!-- Dropdown Start --> */}
        <div
          ref={dropdown}
          onFocus={() => setDropdownOpen(true)}
          onBlur={() => setDropdownOpen(false)}
          className={`absolute right-0 mt-4 flex p-2 flex-col rounded-sm border border-stroke bg-white shadow-default ${
            dropdownOpen === true ? "block" : "hidden"
          }  ${isSmallScreen ? "w-[50vw]" : "w-[19vw]"}`}
        >
          <ProtectedComponent>
            <button
              onClick={togglePasswordModal}
              className="flex items-center gap-3.5 py-4 px-6 text-sm font-medium duration-300 ease-in-out hover:text-primary lg:text-base"
            >
              <LockResetIcon />
              Change Password
            </button>
          </ProtectedComponent>
          <button
            onClick={logoutHandler}
            className="flex items-center gap-3.5 py-4 px-6 text-sm font-medium duration-300 ease-in-out hover:text-primary lg:text-base"
          >
            <BiLogOut />
            Log Out
          </button>
        </div>
        {/* <!-- Dropdown End --> */}
      </div>
    </>
  );
};

export default UserProfile;
