import React from 'react';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

interface BarChartDataItem {
  timestamp: string;
  value: number;
}

interface BarChartProps {
  data: BarChartDataItem[];
  title: string;
  postfix?: string;
}

const SingleLineBarChart: React.FC<BarChartProps> = ({ data, title, postfix }: BarChartProps) => {
  const labels = data.map(dataItem => dataItem.timestamp);
  const dataset1 = data.map(dataItem => dataItem.value);

 
  const series = [
    { name: title, data: dataset1 }, // Use the title passed from the calling function
  ];

 
  const options: ApexOptions = {
    chart: {
      type: 'bar',
      height: 350,
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: labels,
      title: {
        text: title, // Add your x-axis title here
      },
      labels: {
        rotate: -45,
      },
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
        y: {
            formatter: function (value: number) {
              return `${value} ${postfix|| ''}`;
            },
          }
      },
  
  };

  return (
      <Chart options={options} series={series} type="bar" height={350} />
  );
};

export default SingleLineBarChart;
