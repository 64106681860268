import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { http } from "../../helper/http";
import { useUserState } from "../../app-context/auth-context/AuthState";

const baseUrl = process.env.REACT_APP_BASE_URL;

const Report = () => {
  const navigate = useNavigate();
  const { users } = useUserState();
  const [showConsumptionSummary, setShowConsumptionSummary] = useState(false);

  useEffect(() => {
    const fetchDataOverview = async () => {
      try {
        const [response1, response2] = await Promise.all([
          http(`${baseUrl}/device?alldevice=1`),
          http(`${baseUrl}/assign-device?userId=${users.user.id}`)
        ]) as any[];

        const allDevices = Array.isArray(response1.devices) ? response1.devices : [];
        const assignedDevices = Array.isArray(response2.devices) ? response2.devices : 
                                (Array.isArray(response2.devices?.devices) ? response2.devices.devices : []);

        const devices = [...allDevices, ...assignedDevices];
        //console.log('devices', devices);

        const requiredTemplates = [5, 31, 24, 14];
        const hasAnyRequiredTemplate = devices.some((device: any) => {
          const template = device.Device?.template || device.template;
          return requiredTemplates.includes(template);
        });

        setShowConsumptionSummary(hasAnyRequiredTemplate);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchDataOverview();
  }, [users.user.id]);

  const handleMeterReadingClick = () => {
    navigate(`/meter-reading-data1/${users.user.customerId}`);
  };

  const handleConsumptionSummaryClick = () => {
    navigate(`/consumption-summary1/${users.user.customerId}`);
  };

  const handleReportTableClick = () => {
    navigate(`/report-table`);
  };

  return (
    <div className='flex w-full'>
    <div className='flex gap-4 md:w-[50%] w-full mt-4'>
      <div 
        className="bg-white p-4 
        shadow-sm mb-4 w-[calc(50%-8px)] border border-solid
         border-gray-300 cursor-pointer rounded-md"
        onClick={handleMeterReadingClick}
      >
        <h2 className="text-xl mb-2 font-sans-serif text-center">Meter Reading Data</h2>
      </div>
        <div 
          className="bg-white p-4 
          shadow-sm mb-4 w-[calc(50%-8px)] border border-solid 
          border-gray-300 cursor-pointer rounded-md"
          onClick={handleConsumptionSummaryClick}
        >
          <h2 className="text-xl font-sans-serif mb-2 text-center">Consumption Summary</h2>
        </div>

        <div 
        className="bg-white p-4 
        shadow-sm mb-4 w-[calc(50%-8px)] border border-solid
         border-gray-300 cursor-pointer rounded-md"
        onClick={handleReportTableClick}
      >
        <h2 className="text-xl mb-2 font-sans-serif text-center">Report Table</h2>
      </div>
    </div>
  </div>
  );
};

export default Report;

