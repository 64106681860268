import { TagGroupAction, setTagGroup } from "./TagGroupAction";
import TagGroupReducer, { TagGroupState } from "./TagGroupReducer";

import { Dispatch, ReactNode, createContext, useContext, useEffect, useReducer } from "react";

interface TagGroupContextProps {
  state: TagGroupState;
  dispatch: Dispatch<TagGroupAction>;
}

const initialTagGroupState: TagGroupState = {
  tagsGroups: {
    tagGroups: [],
    currentPage: 0,
    totalPages: 0,
    totalItems: 0,
  },
};

export const TagGroupContext = createContext<TagGroupContextProps>({
  state: initialTagGroupState,
  dispatch: () => { },
});

interface TagGroupProviderProps {
  children: ReactNode;
}

export const TagGroupProvider: React.FC<TagGroupProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(TagGroupReducer, initialTagGroupState);

  // useEffect(() => {
  //   setTagGroup(dispatch, 1);
  // }, []);

  return (
    <TagGroupContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </TagGroupContext.Provider>
  );
};

export const useTagGroupState = () => {
  const { state, dispatch } = useContext(TagGroupContext);
  return { ...state, dispatch };
};




// import { setTagGroup } from "./TagGroupAction";
// import TagGroupReducer, { TagGroupAction, TagGroupState } from "./TagGroupReducer";

// import { Dispatch, ReactNode, createContext, useContext, useEffect, useReducer } from "react";

// interface TagGroupContextProps {
//   state: TagGroupState;
//   dispatch: Dispatch<TagGroupAction>;
// }

// const initialTagGroupState: TagGroupState = {
//   tagGroups: {
//     tagsGroup: {
//       tagGroups: [],
//       currentPage: 0,
//       totalPages: 0,
//       nextPage: undefined,
//       previousPage: undefined,
//       totalItems: 0,
//     }
//   },
// };

// export const TagGroupContext = createContext<TagGroupContextProps>({
//   state: initialTagGroupState,
//   dispatch: () => {},
// });

// interface TagGroupProviderProps {
//   children: ReactNode;
// }

// export const TagGroupProvider: React.FC<TagGroupProviderProps> = ({ children }) => {
//   const [state, dispatch] = useReducer(TagGroupReducer, initialTagGroupState);

//   useEffect(() => {
//     setTagGroup(dispatch, 1);
//   }, []);

//   return (
//     <TagGroupContext.Provider
//       value={{
//         state,
//         dispatch,
//       }}
//     >
//       {children}
//     </TagGroupContext.Provider>
//   );
// };

// export const useTagGroupState = () => {
//   const { state, dispatch } = useContext(TagGroupContext);
//   return { ...state, dispatch };
// };
