import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";

interface DataPoint {
    status: string;
    timestamp: string;
  }
  
  interface ChartProps {
    data: DataPoint[];
    yAxisLabel?: string;
    xAxisLabel?: string;
  }

const ApexChart: React.FC<ChartProps> = ({ data, yAxisLabel, xAxisLabel}) => {
  const [options] = useState<ApexCharts.ApexOptions>({
    chart: {
      type: "line",
      height: 350,
      toolbar: {
        show: false,
      },
    },
    stroke: {
      curve: "stepline",
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 6,
      hover: {
        sizeOffset: 4,
      },
    },
    xaxis: {
      type: "category",
      categories: data.map((point) => point.timestamp),
      title: {
        text: xAxisLabel, 
      },
      labels: {
        rotate: -45, 
        formatter: function (val: any) {
          return val;
        },
      },
    },
    yaxis: {
      title: {
        text: yAxisLabel,
      },
      labels: {
        formatter: function (val: any) {
          return val === 1 ? "On" : "Off"; 
        },
      },
      tickAmount: 1,
    },
  });

  // Process data for chart series
  const seriesData = data.map((point) => ({
    x: point.timestamp, 
    y: point.status.toLowerCase() === "on" ? 1 : 0, 
  }));

  const series = [
    {
      name: "Status",
      data: seriesData,
    },
  ];

  return (
    <div>
      <div id="chart">
        <ReactApexChart
          options={options}
          series={series}
          type="line"
          height={350}
        />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default ApexChart;
