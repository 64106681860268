const indianStateCityData = {
  states: [
    {
      name: "Assam",
      cities: ["Guwahati"]
    },
    {
      name: "Chandigarh",
      cities: ["Chandigarh"]
    },
    {
      name: "Gujarat",
      cities: ["Ahmedabad"]
    },
    {
      name: "Haryana",
      cities: ["Gurugram", "Noida", "Delhi"]
    },
    {
      name: "Karnataka",
      cities: ["Bangalore"]
    },
    {
      name: "Kerala",
      cities: ["Kochi"]
    },
    {
      name: "Madhya Pradesh",
      cities: ["Indore"]
    },
    {
      name: "Maharashtra",
      cities: ["Mumbai", "Pune", "Nagpur"]
    },
    {
      name: "NCR",
      cities: ["Delhi", "Noida", "Gurgram"]
    },
    {
      name: "Odisha",
      cities: ["Bhubaneswar"]
    },
    {
      name: "Rajasthan",
      cities: ["Jaipur"]
    },
    {
      name: "Tamil Nadu",
      cities: ["Chennai"]
    },
    {
      name: "Telangana",
      cities: ["Hyderabad"]
    },
    {
      name: "Uttar Pradesh",
      cities: ["Lucknow"]
    },
    {
      name: "West Bengal",
      cities: ["Kolkata"]
    }
  ]
};

export default indianStateCityData;
