import { Button, Toolbar, Typography } from "@mui/material";
import React, { useState } from "react";
import AddTagForm from "./AddTagFrom";
import { http } from "../../../helper/http";
import { ApiResponseTag } from "../../../types/TagTypes";
import { getTagPageClick } from "../../../app-context/tags-context/TagAction";
import { useTagState } from "../../../app-context/tags-context/TagState";
import AddIcon from "@mui/icons-material/Add";
import { ApiResponseGetAllCustomer } from "../../../types/CustomerTypes";
import { setAllCustomer } from "../../../app-context/customer-context/CustomerAction";
import { useCustomerState } from "../../../app-context/customer-context/CustomerState";
import { fetchAllCustomer } from "../../../common-function/CommonFunction";



interface EnhancedTableToolbarProps {
  onFilter: (value: string, filterOption: string) => void;
}
const baseUrl = process.env.REACT_APP_BASE_URL;

function EnhancedTableToolbar({ onFilter }: EnhancedTableToolbarProps) {
  const [filterValue] = React.useState("");
  const [filterOption] = React.useState<string>("name");
  const [formModalIsOpen, setFormModalIsOpen] = useState(false);

  const { dispatch } = useTagState()
  const { dispatch: customerDispatch } = useCustomerState()

  const toggleForm = async () => {
    setFormModalIsOpen((prevVal) => !prevVal);
    try {
      const resp = (await fetchAllCustomer()) as any;
      setAllCustomer(customerDispatch, resp.customers);
    } catch (error) {
      console.error(error);
    }

  };

  const handleFilterChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    try {
      let resp;

      if (value === "") {
        resp = (await http(baseUrl + "/tags?page=1")) as ApiResponseTag;
      } else {
        resp = (await http(
          baseUrl + "/tags?searchTag=" + value
        )) as ApiResponseTag;
      }
      getTagPageClick(dispatch, resp.tags);
    } catch (error) {
      console.error("no device found");
    }
  };

  const handleFilterSubmit = () => {
    onFilter(filterValue, filterOption);
  };


  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      <Typography
        sx={{
          flex: "1 1 100%",
          fontWeight: 700,
          fontSize: [
            "text-sm",
            "text-md",
            "text-lg",
            "text-xl",
            "text-2xl",
            "text-3xl",
          ],
        }}
        variant="h6"
        id="tableTitle"
        component="div"
        className="drop-shadow-[2px_2px_var(--tw-shadow-color)] "
      >
        Tags
      </Typography>

      <div className="flex flex-col sm:flex-row items-center space-y-2 sm:space-y-0 sm:space-x-2">
        <Button variant="contained" sx={{
          backgroundColor: "teal",
          color: "white",
          height: 32,
          fontWeight: "bold",
          fontSize: "1rem",
          transition: "background-color 200ms",
          "&:hover": {
            backgroundColor: "teal",
          },
        }} onClick={toggleForm}>
          <AddIcon />
          ADD
        </Button>
        <input
          type="text"
          placeholder="Search"
          onChange={handleFilterChange}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleFilterSubmit();
            }
          }}
          className="border border-black rounded p-1 w-full sm:w-auto"
        />
      </div>
      {formModalIsOpen &&
        <AddTagForm toggleForm={formModalIsOpen} toggleFormFun={toggleForm} data={[]} uniqueId="ADD" />
      }
    </Toolbar>
  );
}

export default EnhancedTableToolbar;