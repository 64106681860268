import React, {
    createContext,
    useContext,
    useEffect,
    useReducer,
    ReactNode,
    Dispatch,
  } from "react";
import AlertReducer, {  AlertState } from "./AlertReducer";
import { AlertAction, setAlert } from "./AlertAction";

  
  
  
  interface AlertContextProps {
    state: AlertState;
    dispatch: Dispatch<AlertAction>;
  }
  
  const initialState: AlertState = {
    alert: {
      alert: [],
      currentPage: 0,
      totalPages: 0,
      previousPage: 0,
      totalItems: 0
    },
  };
  
  export const AlertContext = createContext<AlertContextProps>({
    state: initialState,
    dispatch: () => {},
  });
  
  interface AlertProviderProps {
    children: ReactNode;
  }
  
  export const AlertProvider: React.FC<AlertProviderProps> = ({ children }) => {
    const [state, dispatch] = useReducer(AlertReducer, initialState);
  
    // useEffect(() => {
    //   setAlert(dispatch, 1);
    // }, []);
  
    return (
      <AlertContext.Provider
        value={{
          state,
          dispatch,
        }}
      >
        {children}
      </AlertContext.Provider>
    );
  };
  
  export const useAlertState = () => {
    const { state, dispatch } = useContext(AlertContext);
    return { ...state, dispatch };
  };
  