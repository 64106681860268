import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import ApexCharts from "apexcharts";
import customRestIcon from "../../images/refresh.svg";

interface FlowData {
  timestamp: string;
  flow: string;
}

interface LineChart3Props {
  height?: string;
  apiData?: FlowData[];
}

const LineChart3 = ({ apiData = [] }: LineChart3Props) => {
  const [flowData, setFlowData] = useState<{ x: string; y: number }[]>([]);

  useEffect(() => {
    const currentDate = new Date();
    const last24HoursDate = new Date(currentDate.getTime() - 24 * 60 * 60 * 1000);

    const last24HoursData = apiData.filter((entry) => {
      const entryDate = new Date(entry.timestamp);
      return entryDate >= last24HoursDate && entryDate <= currentDate;
    });

    const transformedFlowData = last24HoursData.map((entry) => {
      const flow = parseFloat(entry.flow);
      return { x: entry.timestamp, y: flow };
    });

    setFlowData(transformedFlowData);
  }, [apiData]);

  useEffect(() => {
    if (flowData.length === 0) {
      return;
    }

    const maxFlowValue = Math.max(...flowData.map((dataPoint) => dataPoint.y));
    const options = {
      series: [
        {
          name: "Totalizer",
          data: flowData.map((dataPoint) => ({ x: dataPoint.x, y: dataPoint.y })),
        },
      ],
      chart: {
        id: "flow",
        type: "line",
        height: "300px",
        zoom: {
          enabled: true,
          type: "xy", 
          autoScaleYaxis: true,
        },
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: false,
            selection: false,
            zoom: true,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: `<img src=${customRestIcon} width="20" alt="Reset Zoom">`,
          },
        },
      },
      xaxis: {
        type: "datetime",
        labels: {
          rotate: -45,
          rotateAlways: true,
          formatter: function (val: any) {
            const entryDate = new Date(val);
            const hours = entryDate.getHours().toString().padStart(2, '0');
            const minutes = entryDate.getMinutes().toString().padStart(2, '0');
            return `${hours}:${minutes}`;
          },
        },
        tickAmount: 10,
      },
      yaxis: {
        min: 0,
        max: maxFlowValue,
        labels: {
          formatter: (value: number) => value.toFixed(2),
        },
      },
      stroke: {
        curve: "smooth",
        width: 1.5,
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }: { series: any, seriesIndex: any, dataPointIndex: any, w: any }) {
          const flowValue = series[seriesIndex][dataPointIndex];
          const timestamp = w.config.series[seriesIndex].data[dataPointIndex].x;
          return `<div class="apexcharts-tooltip-custom p-2">${timestamp}<br/>Flow: ${flowValue}</div>`;
        },
      },
    };

    const chart = new ApexCharts(document.querySelector("#flow"), options);
    chart.render();

    return () => {
      chart.destroy();
    };
  }, [flowData]);

  return (
    <div style={{ width: "100%" }}>
      <Box sx={{ Width: "100%" }}>
        {flowData.length === 0 ? (
         <div className="flex items-center justify-center h-[300px]">
           No data available in the last 24 hours
         </div>
        ) : (
          <div className="mt-2 relative h-[300px] overflow-y-hidden" id="flow"></div>
        )}
      </Box>
    </div>
  );
};

export default LineChart3;