import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TablePagination, TableSortLabel } from "@mui/material";
import { styled } from "@mui/material/styles";

interface TabularComponentsProps {
  headers: string[];
  rows: any[];
  sorting?: boolean;
  pagination?: boolean;
  sortableColumns?: string[];
}

const StylesTableCell = styled(TableCell)(({ theme }) => ({
  "&.MuiTableCell-root": {
    padding: theme.spacing(2),
  },
}));

const StyledTableSortLabel = styled(TableSortLabel)(({ theme }) => ({
  '& .MuiTableSortLabel-icon': {
    opacity: 1,
  },
}));

const TabularComponents: React.FC<TabularComponentsProps> = ({
  headers,
  rows,
  sorting = true,
  pagination = true,
  sortableColumns = [],
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState<string>("");

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedRows = sorting
    ? rows.slice().sort((a, b) => {
        const aValue = a[orderBy];
        const bValue = b[orderBy];

        if (typeof aValue === "string" && typeof bValue === "string") {
          return order === "asc"
            ? aValue.localeCompare(bValue)
            : bValue.localeCompare(aValue);
        }

        return order === "asc" ? aValue - bValue : bValue - aValue;
      })
    : rows;

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div>
      <TableContainer component={Paper} className="h-[600px]">
        <Table  sx={{ minWidth: 750}} aria-label="simple table">
          <TableHead>
            <TableRow>
              {headers.map((header, index) => (
                <StylesTableCell key={index}>
                  {sortableColumns.includes(header.toUpperCase()) ? (
                    <StyledTableSortLabel
                      active={orderBy === header.toLowerCase()}
                      direction={orderBy === header.toLowerCase() ? order : "desc"}
                      onClick={() => handleRequestSort(header.toLowerCase())}
                    >
                      {header}
                    </StyledTableSortLabel>
                  ) : (
                    header
                  )}
                </StylesTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedRows.length > 0 ? (
              sortedRows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, rowIndex) => {
                  const normalizedRow = Object.keys(row).reduce((acc, key) => {
                    acc[key.toLowerCase()] = row[key];
                    return acc;
                  }, {} as { [key: string]: any });

                  return (
                    <TableRow
                      key={rowIndex}
                      sx={{
                        "&:last-child td, &:last-child th": { borderBottom: 0 },
                      }}
                    >
                      {headers.map((header, headerIndex) => (
                        <StylesTableCell
                          key={headerIndex}
                          align="left"
                          sx={{ padding: "8px" }}
                        >
                          {normalizedRow[header.toLowerCase()]}
                        </StylesTableCell>
                      ))}
                    </TableRow>
                  );
                })
            ) : (
              <TableRow>
                <StylesTableCell 
                className="flex items-center h-[600px]"
                colSpan={headers.length} align="center">
                  No data available
                </StylesTableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <div >
      {pagination && sortedRows.length > 0 && (
        <TablePagination
          rowsPerPageOptions={[10, 50, 100, 500]}
          component="div"
          count={sortedRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
      </div>
    </div>
  );
};

export default TabularComponents;
