import { Button, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { toast } from "react-toastify";
import { http } from '../../../helper/http';


const MqttPublish = ({ deviceId }: any) => {

    const [message, setMessage]: any = useState('');
    const [publishButtonStatus, setPublishButtonStatus]: any = useState(false);

    function handleInputChange(event: any) {
        setMessage(event.target.value);
    }

    useEffect(() => {
        if (!message) {
            setPublishButtonStatus(false);
            return;
        }

        try {
            JSON.parse(message);
            setPublishButtonStatus(true);
        } catch(err) {
            setPublishButtonStatus(false);
        }
    }, [message]);

    async function publishMessage() {
        try {
            // console.log(message, deviceId);
            
            const resp: any = await http('/mqtt-publish', 'POST', { deviceId, message });
            toast.success(resp.message);
            setMessage('');
        } catch(err: any) {
            toast.error(err);
        }
    }

  return (
    <>
        <p className="mb-2">IMEI No: {deviceId}</p>
        <div className="mb-2">
            <TextField multiline rows={4} variant='outlined' className="w-full" value={message} onChange={handleInputChange} />
        </div>
        <Button variant='contained' color='primary' size='large' disabled={!publishButtonStatus} className="w-full" onClick={publishMessage}>Publish</Button>
    </>
  )
}

export default MqttPublish