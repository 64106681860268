import Box from "@mui/material/Box";
import  { useEffect } from "react";
import ApexCharts from "apexcharts";

interface LineChart3Props {
  height?: string;
  flow?: any[];
}

const LineChart3 = ({ height = "350px", flow = [] }: LineChart3Props) => {

  
  useEffect(() => {
    if (flow.length === 0) {
      return;
    }
    const options = {
      series: [{
        data: flow,
      }],
      chart: {
        type: 'line',
        height: height,
        toolbar: {
          show: false, // Hide the toolbar
        },
      },
      xaxis: {
        type: 'category',
        categories: flow.map((dataPoint: any) => dataPoint.hour),
        reverseCategories: true,
      },
      stroke: {
        curve: 'stepline',
      },
      dataLabels: {
        enabled: false,
      },
      title: {
        align: 'left',
      },
      markers: {
        hover: {
          sizeOffset: 4,
        },
      },
    };

    var chart1 = new ApexCharts(document.querySelector("#chart1"), options);
    chart1.render();

    return () => {
      chart1.destroy();
    };
  }, [flow]);

  return (
    <div style={{ width: "100%", marginBottom: "40px" }}>
      <Box sx={{ Width: "100%" }}>
      {flow.length === 0 ? (
          <div className="pl-[9%]">No data available in last 24 hour</div>
        ) : (
        <div className="relative h-fit overflow-y-hidden" id="chart1"></div>
        )}
      </Box>
    </div>
  );
};

export default LineChart3;


