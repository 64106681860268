import React from 'react';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

interface BarChart2DataItem {
  timestamp: string;
  value1: number;
  value2: number,
}

interface BarChart2Props {
  data: BarChart2DataItem[];
}

const BarChart2Component: React.FC<BarChart2Props> = ({ data }: BarChart2Props) => {
  // Extract data for labels and datasets
  const labels = data.map(dataItem => dataItem.timestamp);
  const dataset1 = data.map(dataItem => dataItem.value1);
  const dataset2 = data.map(dataItem => dataItem.value2);

  // Define custom colors for the bars
  const colors = ['#1a2e4f', '#4b80d6']; // Specify your custom colors here

  // Construct the series data with custom colors
  const series = [
    { name: 'Energy Generation', data: dataset1, color: colors[0] },
    { name: 'Energy Consumption', data: dataset2, color: colors[1] },
  ];

  // Define the chart options
  const options: ApexOptions = {
    chart: {
      type: 'bar',
      height: 350,
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: labels,
      labels: {
        rotate: -45
      }
    },
    dataLabels: {
      enabled: false // Hide data values on bars
    }
  };

  return (
    <div>
      {/* Render the ApexCharts component */}
      <Chart options={options} series={series} type="bar" height={350} />
    </div>
  );
};

export default BarChart2Component;
