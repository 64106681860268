import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { http } from "../../../helper/http";
import { toast } from "react-toastify";
import { Customer } from "../../../types/CustomerTypes";
import { useCustomerState } from "../../../app-context/customer-context/CustomerState";
import { addNewTemplates, updateTemplate } from "../../../app-context/template-context/TemplateAction";
import { useTemplateState } from "../../../app-context/template-context/TemplateState";
import { Template, TemplateApiResponse } from "../../../types/TemplateType";
import ProtectedComponent from "../../../utils/protected-route/ProtectedComponent";
import { fetchAllCustomer } from "../../../common-function/CommonFunction";

interface AddTagFormModalProps {
  toggleForm: boolean;
  toggleFormFun: () => void;
  data: Template[];
  uniqueId: string;
}

let id: number | 0;
const baseUrl = process.env.REACT_APP_BASE_URL;

const AddTemplateForm: React.FC<AddTagFormModalProps> = ({
  toggleForm,
  toggleFormFun,
  data,
  uniqueId,
}) => {

  const [templatename, setTemplateName] = useState("")
  const [customer, setCustomer] = useState<Customer[]>([]);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const { customers } = useCustomerState();
  const { templates, dispatch } = useTemplateState()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const customerResp = (await fetchAllCustomer()) as any;
        setCustomer(customerResp.customers || []);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
    if (uniqueId === "EDIT" && data) {
      const tagToUse = Array.isArray(data) ? data[0] : data;
      const customer = customers.customers.find((c) => tagToUse.customerId === c.id)
      id = tagToUse?.id ;
      setSelectedCustomer(customer?.id.toString() || "")
      setTemplateName(tagToUse.templatename)
    }
  }, [uniqueId, data, templates]);

  const handleSubmit = async () => {
    const data = {
      templatename,
      customerId: selectedCustomer,
    };
    try {
      if (uniqueId === "ADD") {
        const resp = (await http(
          baseUrl + `/templates`,
          "POST",
          data
        )) as TemplateApiResponse;        
        addNewTemplates(dispatch, { templates: resp.templates, totalItems: templates.totalItems });
        toast.success(resp.message);
      } else {
        const resp = (await http(
          baseUrl + `/templates?id=${id}`,
          "PUT",
          data
        )) as TemplateApiResponse;        
        updateTemplate(dispatch, { id: id, templates: resp.templates })
        toast.success(resp.message);
      }
    } catch (err: any) {
      toast.error(err)
    }
    toggleFormFun();

  }


  return (
    <>
      {/* Popup onclick of Add button */}
      <Dialog open={toggleForm} onClose={toggleFormFun}>
        <DialogTitle>
          {" "}
          {uniqueId === "ADD" ? "Add Template" : "Update Template"}
        </DialogTitle>
        <DialogContent>
          {/* Input fields for adding a new device */}
          <TextField
            label="Template Name"
            placeholder="Enter Template Name"
            onChange={e => setTemplateName(e.target.value)}
            value={templatename}
            fullWidth
            margin="normal"
          />
          <ProtectedComponent>
          <InputLabel id="selectunit">Select the Customer</InputLabel>
          <Select
            labelId="selectcustmer"
            id="select-customer"
            value={selectedCustomer}
            label="Select Customer"
            onChange={(e) => setSelectedCustomer(e.target.value)}
            sx={{ width: "100%" }}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 300, // Adjust the maxHeight as needed
                },
              },
            }}
          >
            {customer.map((customer) => (
              <MenuItem key={customer.id} value={customer.id} style={{paddingLeft: "20px", display: "block", marginBottom: "5px"}}>
                {customer.name}
              </MenuItem>
            ))}
          </Select>
          </ProtectedComponent>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleFormFun}>Cancel</Button>
          <Button onClick={handleSubmit} color="primary">
            {uniqueId === "ADD" ? "Save" : "Update"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddTemplateForm;
