import React, { useState, useEffect, useRef } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { http } from "../../helper/http";
import { useUserState } from "../../app-context/auth-context/AuthState";
import { toast } from "react-toastify";
import { TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";

const baseUrl = process.env.REACT_APP_BASE_URL;

const ConsumptionSummary1 = () => {
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const [reportFormat, setReportFormat] = useState("excel");
  const [reportPeriodicity, setReportPeriodicity] = useState("");
  const [method, setMethod] = useState("manually");
  const [selectedDevices, setSelectedDevices] = useState<any[]>([]);
  const { users } = useUserState();
  const [devices, setDevices] = useState<any[]>([]);
  const [allDevices, setAllDevices] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredDevices, setFilteredDevices] = useState<string[]>([]);
  const [showDevices, setShowDevices] = useState(false);
  const [selectAllDevices, setSelectAllDevices] = useState(false);
  const [receivedTime, setReceivedTime] = useState("");
  const [email, setEmail] = useState<string[]>([]);
  const [emailError, setEmailError] = useState<string | null>(null);
  const [allTemplate, setAllTemplate] = useState<any[]>([]);
  const [allTags, setAllTags] = useState<any[]>([]);
  const [reportHeader, setReportHeader] = useState<string>("");
  const [selectedDays, setSelectedDays] = useState<number[]>([]);
  const [reportSelectedTag, setSelectedTags] = useState<number[]>([]);
  const [showTags, setShowTags] = useState<boolean>(false);
  const [searchTagTerm, setSearchTagTerm] = useState<string>("");
  const [selectAllTags, setSelectAllTags] = useState<boolean>(false);
  const [filteredTags, setFilteredTags] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showReportReceivedOn, setShowReportReceivedOn] = useState(false);
  const [reportSelectedTemplate, setSelectedTemplateId] = useState<
    number | null
  >(null);

  const dropdownRef = useRef<HTMLDivElement>(null);
  const dropdownRef1 = useRef<HTMLDivElement>(null);
  const dropdownRef2 = useRef<HTMLDivElement>(null);

  const reportGeneratedType = "daily";
  const reportSelected = "consumption_summary";
  const status = false;
  const reportGeneratedBy = users.user.name;
  const daysOfWeek = [
    { id: 1, name: "Monday" },
    { id: 2, name: "Tuesday" },
    { id: 3, name: "Wednesday" },
    { id: 4, name: "Thursday" },
    { id: 5, name: "Friday" },
    { id: 6, name: "Saturday" },
    { id: 7, name: "Sunday" },
  ];
  const navigate = useNavigate();


  useEffect(() => {
    const filtered = devices.filter((device) =>
      device.deviceName.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredDevices(filtered);
  }, [searchTerm, devices]);

  useEffect(() => {
    const fetchDataOverview = async () => {
      try {
        const response1 = (await http(`${baseUrl}/device?alldevice=1`)) as any;
        const devices1 = response1.devices || [];
        const deviceList1 = devices1
          .map((device: any) => ({
            template: device.template,
            deviceId: device.id,
            deviceName: device.deviceName,
          }))
          .filter((device: any) => device.deviceId && device.deviceName);
        const response2 = (await http(
          `${baseUrl}/assign-device?userId=${users.user.id}`
        )) as any;
        const devices2 = Array.isArray(response2.devices)
          ? response2.devices
          : Array.isArray(response2.devices?.devices)
          ? response2.devices.devices
          : [];
          

          
        const deviceList2 = devices2
          .map((device: any) => ({
            template: device.Device.template,
            deviceId: device.Device.id,
            deviceName: device.Device.deviceName,
          }))
          .filter((device: any) => device.deviceId && device.deviceName);
        const allDeviceNames = [...deviceList1, ...deviceList2];

        const uniqueDeviceNames = Array.from(new Set(allDeviceNames));
        // console.log("devices", uniqueDeviceNames);

        setAllDevices(uniqueDeviceNames);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    const fetchAllTemplates = async () => {
      try {
        const response = (await http(
          `${baseUrl}/templates?alltemplates=all`
        )) as any;
        const templates = response.template;
        const templateData = templates.map((template: any) => ({
          id: template.id,
          templatename: template.templatename,
        }));
        setAllTemplate(templateData);
      } catch (error) {
        console.error(error);
      }
    };

    fetchDataOverview();
    fetchAllTemplates();
  }, [users.user.id]);
  useEffect(() => {
    const fetchTagNames = async () => {
      try {
        const response = (await http(
          `${baseUrl}/tag-groups?templateid=${reportSelectedTemplate}`
        )) as any;
        // console.log("response Tag", response);
        const tags = response.tagsGroup.tagGroups;

        const tagData = tags.map((tag: any) => ({
          tagId: tag.tag.id,
          tagName: tag.tag.tagname,
        }));
        setAllTags(tagData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    if (reportSelectedTemplate !== null) {
      const filtered = allDevices.filter(
        (device) => device.template === reportSelectedTemplate
      );

      setDevices(filtered);
      fetchTagNames();
    }
  }, [reportSelectedTemplate]);

  useEffect(() => {
    if (reportSelectedTemplate !== null) {
      const filtered = allTags.filter((tag) =>
        tag.tagName.toLowerCase().includes(searchTagTerm.toLowerCase())
      );
      setFilteredTags(filtered);
    }
  }, [searchTagTerm, reportSelectedTemplate, allTags]);
  const handleToggleTags = () => {
    setShowTags(!showTags);
  };

  const handleTagSelection = (tagId: number) => {
    if (reportSelectedTag.includes(tagId)) {
      setSelectedTags(reportSelectedTag.filter((id) => id !== tagId));
    } else {
      setSelectedTags([...reportSelectedTag, tagId]);
    }
  };

  const handleSelectAllTags = (checked: boolean) => {
    if (checked) {
      setSelectedTags(allTags.map((tag) => tag.tagId));
    } else {
      setSelectedTags([]);
    }
    setSelectAllTags(checked);
  };

  const handleDaySelection = (dayId: number) => {
    if (selectedDays.includes(dayId)) {
      setSelectedDays(selectedDays.filter((id) => id !== dayId));
    } else {
      setSelectedDays([...selectedDays, dayId]);
    }
  };

  const handleSelectAll = () => {
    if (selectedDays.length === daysOfWeek.length) {
      setSelectedDays([]);
    } else {
      setSelectedDays(daysOfWeek.map((day) => day.id));
    }
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setShowDevices(false);
      }
      if (
        dropdownRef1.current &&
        !dropdownRef1.current.contains(event.target as Node)
      ) {
        setShowTags(false);
      }
      if (
        dropdownRef2.current &&
        !dropdownRef2.current.contains(event.target as Node)
      ) {
        setShowReportReceivedOn(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleToggleDevices = () => {
    setShowDevices(!showDevices);
  };

  const handleTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReceivedTime(event.target.value);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const emailValue = event.target.value;
    const emailArray = emailValue.replace(/\s+/g, "").split(",").map(email => email.trim());
    setEmail(emailArray);
    validateEmail(emailArray);
  };

  const validateEmail = (emailArray: string[]) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const invalidEmails = emailArray.filter(email => !emailRegex.test(email));
    if (invalidEmails.length > 0) {
      setEmailError(`Invalid email(s): ${invalidEmails.join(", ")}`);
    } else {
      setEmailError("");
    }
  };

  const handleDeviceSelection = (device: any) => {
    if (selectedDevices.includes(device.deviceId)) {
      setSelectedDevices(
        selectedDevices.filter((id) => id !== device.deviceId)
      );
    } else {
      setSelectedDevices([...selectedDevices, device.deviceId]);
    }
  };

  const handleSelectAllDevices = (checked: boolean) => {
    if (checked) {
      setSelectedDevices(devices.map((device) => device.deviceId));
    } else {
      setSelectedDevices([]);
    }
    setSelectAllDevices(checked);
  };

  const handleReset = () => {
    setSelectedTemplateId(null);
    setReportHeader("");
    setStartDate(null);
    setEndDate(null);
    setSelectedDays([]);
    setReportFormat("excel");
    setMethod("manually");
    setSelectedDevices([]);
    setSelectedTags([]);
    setEmail([]);
    setReceivedTime("");
  };

  const validateForm = () => {
    if (!reportSelectedTemplate) {
      toast.error("Please select a template");
      return false;
    }
    if (!reportFormat) {
      toast.error("Please select a report format");
      return false;
    }
    if(!reportHeader){
      toast.error("Please Enter Report Header");
      return false;
    }
    if(!reportPeriodicity){
      toast.error("Please Enter Report Periodicity");
      return false;
    }
    if (method === "autogenerated") {
      if (!Array.isArray(selectedDays) || selectedDays.length === 0) {
        toast.error("Please select a report received on day");
        return false;
      }
      if (!receivedTime) {
        toast.error("Please select a received time");
        return false;
      }
      if (email.length === 0 || emailError) {
        toast.error("Please provide a valid email");
        return false;
      }
    }
    if (method === "manually") {
      if (!startDate) {
        toast.error("Please select a start date");
        return false;
      }
      if (!endDate) {
        toast.error("Please select an end date");
        return false;
      }
      if(endDate && endDate<= startDate){
        toast.error("End date should be greater than start date");
        return false;
      }
    }
    if (selectedDevices.length === 0) {
      toast.error("Please select at least one device");
      return false;
    }
    if (reportSelectedTag.length === 0) {
      toast.error("Please select at least one tag");
      return false;
    }
    return true;
  };

  const handleDesable = () => {
    toast.error("Wait Until Schedule is done...");
  };

  const handleClick = async () => {
    if (!validateForm()) return;
    setIsLoading(true);
    const data = {
      customerId: users.user.customerId,
      reportGeneratedBy,
      reportHeader: reportHeader ? reportHeader : "Summary",
      reportMethod: method,
      reportFormat,
      reportGeneratedType,
      reportSelected,
      reportReceivedOn: selectedDays,
      reportReceivedTime: receivedTime,
      reportSelectedDevice: selectedDevices,
      email,
      status,
      startDate,
      endDate,
      reportPeriodicity,
      reportSelectedTag,
      reportSelectedTemplate,
    };

    try {
      if (data.reportMethod === "autogenerated") {
        try {
          if (users.user.role === 1) {
            toast.error("Your Role can't Schedule Reports");
          } else {
            const response: any = await http(
              baseUrl + "/report-schedule",
              "POST",
              data
            );
            // addNewReport(dispatch, { report: response, totalItems: report.totalItems })
            // navigate("/home/report-list")
            toast.success("Report scheduled successfully");
            handleReset();
            navigate(`/report-table`);
          }
        } catch (error) {
          console.error(error);
        }
      } else {
        const response = await fetch(baseUrl + "/report-download" || "", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });
        if (data.reportFormat !== "pdf") {
          if (response.ok) {
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.style.display = "none";
            a.href = url;
            a.download = `${reportHeader ? reportHeader : "report"}.xlsx`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
          } else {
            console.error("Failed to download file");
          }
        } else {
          if (response.ok) {
            const blob = await response.blob();
            const downloadUrl = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = downloadUrl;
            a.download = `${reportHeader ? reportHeader : "report"}.pdf`;
            document.body.appendChild(a);
            a.click();
            a.remove();
          } else {
            console.error("Error generating PDF");
          }
        }
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // useEffect(()=>{

  // },[devices])

  return (
    <div className="px-4 mt-6">
      <div className="border w-full px-2 py-4 rounded-md mb-[200px]">
        <div className="font-inter p-2 text-xl font-bold leading-5 tracking-wider text-left text-gray-800">
          Consumption Summary Report Config
        </div>
        <div className="mx-2 mt-2">
          <div className="grid grid-cols-5 justify-items-start mt-4 gap-4">
            <div className="w-full col-span-2">
              <p className="text-[#6F7482] font-plex-sans text-base font-normal leading-6 tracking-tight text-left">
                Select Template
              </p>
              <div className="mt-2">
                <select
                  className="block w-full px-4 py-4 pr-8 leading-tight bg-[#F8FAFC] border border-gray-300 rounded-lg"
                  value={reportSelectedTemplate ?? ""}
                  onChange={(e) => {
                    setSelectedTemplateId(parseInt(e.target.value));
                    setSelectedDevices([]);
                    setSelectedTags([]);
                    setSelectedDays([]);
                  }}
                >
                  <option value="" disabled>
                    Select a template
                  </option>
                  {allTemplate.map((template) => (
                    <option key={template.id} value={template.id}>
                      {template.templatename}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="w-full">
              <p className="text-[#6F7482] font-plex-sans text-base font-normal leading-6 tracking-tight text-left">
                Select Report Format
              </p>
              <div className="mt-2">
                <select
                  className="block w-full px-4 py-4 pr-8 leading-tight bg-[#F8FAFC] border border-gray-300 rounded-lg"
                  value={reportFormat}
                  onChange={(e) => setReportFormat(e.target.value)}
                >
                  <option value="selectReportFormat">
                    Select Report Format
                  </option>
                  <option value="excel">Excel</option>
                  <option value="pdf">PDF</option>
                </select>
              </div>
            </div>

            <div className="w-full">
              <p className="text-[#6F7482] font-plex-sans text-base font-normal leading-6 tracking-tight text-left">
                Method
              </p>
              <div className="mt-2">
                <select
                  className="block w-full px-4 py-4 pr-8 leading-tight bg-[#F8FAFC] border border-gray-300 rounded-lg"
                  value={method}
                  onChange={(e) => setMethod(e.target.value)}
                >
                  <option value="manually">Manual</option>
                  <option value="autogenerated">Auto-Generated</option>
                </select>
              </div>
            </div>
            <div className="w-full col-span-2">
              <p className="text-[#6F7482] font-plex-sans text-base font-normal leading-6 tracking-tight text-left">
                Report Header
              </p>
              <div className="mt-2">
                <input
                  type="text"
                  value={reportHeader}
                  onChange={(e) => setReportHeader(e.target.value)}
                  className="block w-full px-4 py-4 pr-8 leading-tight bg-[#F8FAFC] border border-gray-300 rounded-md"
                  placeholder="Report Header"
                />
              </div>
            </div>
            {method === "autogenerated" && (
              <>
                <div className="w-full col-span-2" ref={dropdownRef2}>
                  <p className="text-[#6F7482] font-plex-sans text-base font-normal leading-6 tracking-tight text-left">
                    Report Received On
                  </p>
                  <div className="mt-2 relative">
                    <button
                      className="bg-[#F8FAFC] hover:bg-gray-100 text-gray-800 py-4 px-4 w-full text-left border border-gray-300 rounded-lg dropdown-arrow"
                      onClick={() =>
                        setShowReportReceivedOn(!showReportReceivedOn)
                      }
                    >
                      {selectedDays.length > 0
                        ? selectedDays
                            .map(
                              (dayId) =>
                                daysOfWeek.find((day) => day.id === dayId)?.name
                            )
                            .join(", ")
                        : "Select Days"}
                    </button>
                    {showReportReceivedOn && (
                      <div className="absolute z-10 bg-white border border-gray-400 rounded shadow w-full max-h-60 overflow-y-auto">
                        <ul>
                          <li className="py-2 px-4 hover:bg-gray-100">
                            <input
                              type="checkbox"
                              checked={
                                selectedDays.length === daysOfWeek.length
                              }
                              onChange={handleSelectAll}
                            />
                            <span className="ml-2">Select All</span>
                          </li>
                          {daysOfWeek.map((day) => (
                            <li
                              key={day.id}
                              className="py-2 px-4 hover:bg-gray-100"
                            >
                              <input
                                type="checkbox"
                                checked={selectedDays.includes(day.id)}
                                onChange={() => handleDaySelection(day.id)}
                              />
                              <span className="ml-2">{day.name}</span>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
                <div className="w-full">
                  <label
                    className="text-[#6F7482] font-plex-sans text-base font-normal leading-6 tracking-tight text-left"
                    htmlFor="r-time"
                  >
                    Received Time
                  </label>
                  <TextField
                    type="time"
                    id="r-time"
                    name="receivedTime"
                    value={receivedTime}
                    onChange={handleTimeChange}
                    variant="outlined"
                    className="border border-gray-300 rounded-md p-[0.4rem] w-full"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div className="w-full col-span-2">
                  <div>
                    <label
                      className="text-[#6F7482] font-plex-sans text-base font-normal leading-6 tracking-tight text-left"
                      htmlFor="email"
                    >
                      Email
                    </label>
                    <input
                      type="text"
                      id="email"
                      value={email.join(", ")}
                      onChange={handleEmailChange}
                      className="border border-gray-300 rounded-md p-[0.4rem] py-[15px] w-full"
                    />
                    {emailError && (
                      <p className="text-red-500 text-sm mt-1">{emailError}</p>
                    )}
                  </div>
                </div>
              </>
            )}
            <div className="w-full col-span-2">
              <p className="text-[#6F7482] font-plex-sans text-base font-normal leading-6 tracking-tight text-left">
                Select Report Periodicity
              </p>
              <div className="mt-2">
                <select
                  className="block w-full px-4 py-4 pr-8 leading-tight bg-[#F8FAFC] border border-gray-300 rounded-lg"
                  value={reportPeriodicity}
                  onChange={(e) => setReportPeriodicity(e.target.value)}
                >
                  <option value="">Select Report Periodicity</option>
                  <option value="daily">Daily</option>
                  <option value="hourly">Hourly</option>
                </select>
              </div>
            </div>
          </div>

          <div className="mt-4">
            {method === "manually" && (
              <>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <div className="grid grid-cols-2 justify-items-start mt-4 gap-4 w-full">
                    <div className="w-full">
                      <p className="text-[#6F7482] mb-2 font-plex-sans text-base font-normal leading-6 tracking-tight text-left">
                        Start Date
                      </p>
                      <DateTimePicker
                        value={startDate}
                        onChange={(e: any) => setStartDate(e)}
                        className="w-[100%] bg-[#F8FAFC] "
                      />
                    </div>
                    <div className="w-full">
                      <p className="text-[#6F7482] mb-2 font-plex-sans text-base font-normal leading-6 tracking-tight text-left">
                        End Date
                      </p>
                      <DateTimePicker
                        value={endDate}
                        onChange={(e: any) => setEndDate(e)}
                        className="w-[100%] bg-[#F8FAFC] mt-2"
                      />
                    </div>
                  </div>
                </LocalizationProvider>
              </>
            )}
          </div>
          <div className="grid grid-cols-2 gap-4 mt-4">
            <div className="w-full mt-4" ref={dropdownRef}>
              <p className="text-[#6F7482] font-plex-sans text-base font-normal leading-6 tracking-tight text-left">
                Select Devices
              </p>
              <div className="mt-2">
                <div className="relative">
                  <button
                    className="bg-[#F8FAFC] hover:bg-gray-100 text-gray-800 py-4 px-4 w-full text-left border border-gray-300 rounded-lg dropdown-arrow"
                    onClick={handleToggleDevices}
                  >
                    {selectedDevices.length > 0
                      ? selectedDevices
                          .map(
                            (id) =>
                              devices.find((device) => device.deviceId === id)
                                ?.deviceName
                          )
                          .join(", ")
                      : "Select Devices"}
                  </button>
                  {showDevices && (
                    <div className="absolute z-10 bg-white border border-gray-400 rounded shadow w-full max-h-60 overflow-y-auto">
                      <div className="sticky top-0 bg-white p-2">
                        <input
                          type="text"
                          placeholder="Search devices..."
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                          className="w-full p-2 border rounded"
                        />
                      </div>
                      <ul>
                        <li className="py-2 px-4 hover:bg-gray-100 sticky top-10 bg-white">
                          <input
                            type="checkbox"
                            checked={selectAllDevices}
                            onChange={(e) =>
                              handleSelectAllDevices(e.target.checked)
                            }
                          />
                          <span className="ml-2">Select All Devices</span>
                        </li>
                        {filteredDevices.map((device: any, index: number) => (
                          <li
                            key={index}
                            className="py-2 px-4 hover:bg-gray-100"
                          >
                            <input
                              type="checkbox"
                              checked={selectedDevices.includes(
                                device.deviceId
                              )}
                              onChange={() => handleDeviceSelection(device)}
                            />
                            <span className="ml-2">{device.deviceName}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="w-full mt-4" ref={dropdownRef1}>
              <p className="text-[#6F7482] font-plex-sans text-base font-normal leading-6 tracking-tight text-left">
                Select Tag Names
              </p>
              <div className="mt-2">
                <div className="relative">
                  <button
                    className="bg-[#F8FAFC] hover:bg-gray-100 text-gray-800 py-4 px-4 w-full text-left border border-gray-300 rounded-lg dropdown-arrow"
                    onClick={handleToggleTags}
                  >
                    {reportSelectedTag.length > 0
                      ? reportSelectedTag
                          .map(
                            (id) =>
                              allTags.find((tag) => tag.tagId === id)?.tagName
                          )
                          .join(", ")
                      : "Select Tag Names"}
                  </button>
                  {showTags && (
                    <div className="absolute z-10 bg-white border border-gray-400 rounded shadow w-full max-h-60 overflow-y-auto">
                      <div className="sticky top-0 bg-white p-2">
                        <input
                          type="text"
                          placeholder="Search tags..."
                          value={searchTagTerm}
                          onChange={(e) => setSearchTagTerm(e.target.value)}
                          className="w-full p-2 border rounded"
                        />
                      </div>
                      <ul>
                        <li className="py-2 px-4 hover:bg-gray-100 sticky top-10 bg-white">
                          <input
                            type="checkbox"
                            checked={selectAllTags}
                            onChange={(e) =>
                              handleSelectAllTags(e.target.checked)
                            }
                          />
                          <span className="ml-2">Select All Tags</span>
                        </li>
                        {filteredTags.map((tag: any, index: number) => (
                          <li
                            key={index}
                            className="py-2 px-4 hover:bg-gray-100"
                          >
                            <input
                              type="checkbox"
                              checked={reportSelectedTag.includes(tag.tagId)}
                              onChange={() => handleTagSelection(tag.tagId)}
                            />
                            <span className="ml-2">{tag.tagName}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-end mt-20 gap-4">
            <div
              className="relative p-2 gap-8 rounded-md bg-[#E0E0E0] cursor-pointer text-black"
              onClick={handleReset}
            >
              <span className="text-black px-2">Reset</span>
            </div>
            <div
              className={`relative p-2 gap-8 rounded-md ${
                isLoading ? "bg-gray-400 cursor-default" : "bg-[green] cursor-pointer"
              }`}
              onClick={isLoading ? handleDesable : handleClick}
            >
              <span className="text-white">
                {isLoading
                  ? method === "autogenerated"
                    ? "Scheduling..."
                    : "Downloading..."
                  : method === "autogenerated"
                  ? "Schedule"
                  : "Download"}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConsumptionSummary1;
