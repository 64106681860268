import { ScheduleAction } from "./SchedulingAction";


export interface PaginatedSchedule {
    schedulings: any[];
    currentPage: number;
    totalPages: number;
    nextPage?: number;
    previousPage?: number;
    totalItems: number;
}

export interface ScheduleState {
    schedulings: PaginatedSchedule;
}


export default function scheduleReducer(
    state: ScheduleState,
    action: ScheduleAction
): ScheduleState {
    const {  payload , type} = action;
    switch (type) {
        case "SET_SCHEDULE":
            return {
                ...state,
                schedulings: payload,
            };
        case "UPDATE_SCHEDULE":{
            const { id, schedule } = payload;
            const s = state.schedulings.schedulings.map(s => s.id === id? schedule : s);
            return {
               ...state,
                schedulings: {
                   ...state.schedulings,
                    schedulings:s,
                },
            };
        }
        case "ADD_SCHEDULE":{
            const { schedule, totalItems } = payload;
            return {
               ...state,
                schedulings: {
                   ...state.schedulings,
                    schedulings: [...state.schedulings.schedulings, schedule],
                    totalItems,
                },
            };
        }
        default:
            return state;
    }
}