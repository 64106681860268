import { PaginatedTags, Tag } from "../../types/TagTypes";

export interface TagState {
  tags: PaginatedTags;
}

export type TagAction =
  | { type: "SET_TAGS"; payload: PaginatedTags }
  | { type: "ADD_TAGS"; payload: { tags: Tag, totalItems: number } }
  | { type: "SET_TAGS_ALL"; payload: Tag[] }
  | { type: "UPDATE_TAGS"; payload: { id: number; tags: Tag } }
  | {
    type: "UPDATE_TAGS_STATUS";
    payload: { id: number; status: boolean };
  }
  | { type: "SET_TAGS_WITH_PAGE"; payload: PaginatedTags }





export default function TagReducer(
  state: TagState,
  action: TagAction
): TagState {
  const { type, payload } = action;

  switch (type) {
    case "SET_TAGS":
      return { ...state, tags: payload };
    case "SET_TAGS_ALL":
      return {
        ...state,
        tags: {
          tags: action.payload,
          currentPage: 1, // Assuming some default values for pagination
          totalPages: 1,
          totalItems: action.payload.length
        }
      };
    case "SET_TAGS_WITH_PAGE":
      return { ...state, tags: payload };
    case "ADD_TAGS":
      const { tags: newTag, totalItems } = payload;
      return {
        ...state,
        tags: {
          ...state.tags,
          tags: [newTag, ...state.tags.tags],
          totalItems: totalItems + 1
        },
      };
    case "UPDATE_TAGS":
      const { id: updateId, tags } = payload;
      const updatedTags = state.tags.tags.map((tag) =>
        tag.id === updateId ? { ...tag, ...tags } : tag
      );
      return {
        ...state,
        tags: {
          ...state.tags,
          tags: updatedTags,
        },
      };
    case "UPDATE_TAGS_STATUS":
      const { id, status } = payload;
      const tagToUpdate = state.tags.tags.find(
        (tag) => tag.id === id
      );
      if (tagToUpdate) {
        // Update the status of the found device
        tagToUpdate.status = status;
        // Clone the devices array to trigger a state update
        const updatedTagsAfterStatusUpdate = [...state.tags.tags];
        // Update the state with the new devices array
        return {
          ...state,
          tags: {
            ...state.tags,
            tags: updatedTagsAfterStatusUpdate,
          },
        };
      }
      return state;
    default:
      return state;
  }
}
