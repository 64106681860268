import axios, { AxiosInstance, AxiosError, InternalAxiosRequestConfig } from "axios";

interface HandleHttpProps {
  baseUrl: string;
  token: string;
}

const HandleHttp = ({ baseUrl, token }: HandleHttpProps): AxiosInstance => {
  const http = axios.create({ baseURL: baseUrl, timeout: 300000 });

  const requestHandler = (request: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {

    
    request.headers = request.headers || {};
    request.headers.Authorization = `Bearer ${token}`;
    return request;
  };

  const errorResponseHandler = (error: AxiosError): Promise<never> => {
    return Promise.reject(error);
  };

  const errorHandler = (error: AxiosError): Promise<never> => {
    return Promise.reject(error);
  };

  http.interceptors.request.use(
    (request) => requestHandler(request),
    (error) => errorHandler(error)
  );

  http.interceptors.response.use(
    (response) => response,
    (error) => errorResponseHandler(error)
  );

  return http;
};

export default HandleHttp;
