import { useEffect, useMemo, useState } from "react";
import { http } from "../../helper/http";
import { ConfirmModal } from "../../utils/alertui/ConfirmModal";
import {
  Box,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from "@mui/material";
import EnhancedTableHeader from "./EnhancedTableHeader";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";

const baseUrl = process.env.REACT_APP_BASE_URL;
const ReportTable = () => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [rows, setRows] = useState<any[]>([]);
  const [totalItems, setTotalItems] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [rowToDelete, setRowToDelete] = useState<string | null>(null); // Store the ID of the row to delete
  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const fetchData = async () => {
    try {
      const response: any = await http(baseUrl + "/report-schedule");
      // console.log("response data", response.reports);
      setRows(response.reports);
      setTotalItems(response.reports.length);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleConfirmDeletePopup = (id: string) => {
    setRowToDelete(id);
    setShowConfirmModal(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await http(baseUrl + `/report-schedule?id=${rowToDelete}`, "DELETE");
      toast.success("Report deleted successfully");
      setShowConfirmModal(false);
      fetchData();
    } catch (error) {
      toast.error("Unable to delete the Report");
    }
  };

  const handleSwitchToggle = async (id: any, status: any) => {
    const statusSwitch = !status;
    try {
      await http(
        baseUrl + `/report-schedule?id=${id} &status=${statusSwitch}`,
        "PATCH"
      );
      toast.success("Status updated successfully");
      fetchData();
    } catch (error) {
      toast.error("Unable to update Status");
    }
  };

  const visibleRows = useMemo(
    () => rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [page, rowsPerPage, rows]
  );

  return (
    <>
      <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Paper sx={{ width: "99%" }}>
          <div className="h-[72vh] overflow-y-auto">
            <TableContainer>
              <Table
                className="min-w-750 bg-white"
                aria-labelledby="tableTitle"
                size="medium"
              >
                <EnhancedTableHeader />
                <TableBody>
                  {visibleRows.map((row: any) => (
                    <TableRow
                      hover
                      className="cursor-pointer transition-all hover:bg-gray-100"
                      key={row.id}
                    >
                      <TableCell align="center">
                        {row?.reportHeader || "NA"}
                      </TableCell>
                      <TableCell align="center">
                        {row?.reportPeriodicity || "NA"}
                      </TableCell>
                      <TableCell align="center">
                        {row?.reportFormat || "NA"}
                      </TableCell>
                      <TableCell align="center">
                        {row?.reportReceivedOn
                          ? row.reportReceivedOn
                              .map((day: any) => daysOfWeek[day])
                              .join(", ")
                          : "NA"}
                      </TableCell>
                      <TableCell align="center">
                        {row?.reportReceivedTime || "NA"}
                      </TableCell>
                      <TableCell align="center">
                        {Array.isArray(row?.email)
                          ? row.email.map((email: any, index: number) => (
                              <div key={index}>
                                {email}
                                {index < row.email.length - 1 && <br />}
                              </div>
                            ))
                          : row?.email || "NA"}
                      </TableCell>
                      <TableCell align="center">
                        <Switch
                          checked={row.status !== undefined ? row.status : false}
                          color="primary"
                          onChange={() => handleSwitchToggle(row.id, row.status)}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <div onClick={() => handleConfirmDeletePopup(row.id)}>
                          <DeleteIcon />
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <TablePagination
            component="div"
            rowsPerPageOptions={[5, 10, 50, 100]}
            count={totalItems}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleChangeRowsPerPage}
            className="bg-gray-100"
          />
        </Paper>
      </Box>

      {showConfirmModal && (
        <ConfirmModal
          title="Confirmation"
          message="Are you sure you want to delete?"
          onConfirm={handleConfirmDelete}
          onCancel={() => setShowConfirmModal(false)}
          isVisible={showConfirmModal}
        />
      )}
    </>
  );
};

export default ReportTable;

