import React, { useEffect, useRef, useState } from 'react'
import { http } from '../../../helper/http';
import { formatTimestamp, generateCSV1, reportDataWithGraph } from '../../../common-function/CommonFunction';
import MobileTowerIcon from '../../../components/apex-chart/HeatMapChart';
import CardTwo from '../../../components/dashBoardComponents/CardTwo';
import LineChart from '../../../components/apex-chart/LineChart';
import LongMenu from '../../../utils/longMenu/LongMenu';


const baseUrl = process.env.REACT_APP_BASE_URL;
const CustomDashboardTemplate = ({ selectedDevice, devices }: any) => {
    const [apiData, setApiData] = useState<any[]>([]);
    const [value, setValue] = useState(0);
    const [dashboardSts, setDashboardSts] = useState<any>([]);
    const [customTagData, setCustomTagData] = useState<any>([]);
    const chartRef = useRef<HTMLDivElement>(null);




    const fetchData = async () => {
        try {
            const response = (await http(
                baseUrl + `/device-data-log?deviceId=${selectedDevice}?last24=last24`
            )) as any;
            const _data = response
                .map((d: any) => ({
                    timestamp: formatTimestamp(d.timeStamp),
                    tag1: d["tag1"],
                    tag2: d["tag2"],
                    tag3: d["tag3"],
                    tag4: d["tag4"],
                    rssi: d["rssi"],
                }))
                .filter((d: any) => {
                    return !Object.values(d).some(
                        (val: any) =>
                            typeof val === "string" && val.toLowerCase() === "error"
                    );
                });

            setApiData(_data);
            setDashboardSts(_data.length ? _data[0] : "");
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    let tagOneLineChartData: any = [];
    let tagTwoLineChartData: any = [];
    let tagThreeLineChartData: any = [];
    let tagFourLineChartData: any = [];

    if (apiData.length > 0) {
        apiData.forEach(
            ({
                timestamp,
                tag1,
                tag2,
                tag3,
                tag4,
            }: any) => {
                tagOneLineChartData.push({
                    timestamp,
                    value: tag1
                })
                tagTwoLineChartData.push({
                    timestamp,
                    value: tag2
                })
                tagThreeLineChartData.push({
                    timestamp,
                    value: tag3
                })
                tagFourLineChartData.push({
                    timestamp,
                    value: tag4
                })
            });
    }

    const tagOne: any = [
        {
            name: customTagData?.["tag1"]?.sensorAlias || "Tag 1",
            color: "#0066CC",
            data: tagOneLineChartData,
            postfix: customTagData?.["tag1"]?.sensorUnit || "",
        }
    ];
    const tagTwo: any = [
        {
            name: customTagData?.["tag2"]?.sensorAlias || "Tag 2",
            color: "#63993D",
            data: tagTwoLineChartData,
            postfix: customTagData?.["tag2"]?.sensorUnit || "",
        }
    ];
    const tagThree: any = [
        {
            name: customTagData?.["tag3"]?.sensorAlias || "Tag 3",
            color: "#876FD4",
            data: tagThreeLineChartData,
            postfix: customTagData?.["tag3"]?.sensorUnit || "",
        }
    ];
    const tagFour: any = [
        {
            name: customTagData?.["tag4"]?.sensorAlias || "Tag 4",
            color: "#CA6C0F",
            data: tagFourLineChartData,
            postfix: customTagData?.["tag4"]?.sensorUnit || "",
        }
    ];

    const handleExportLineChart = async (exportOption: string) => {
        const filterData: any = [];
        if(value === 0){
            filterData.push(tagOne.map(({ timestamp, value} : any) =>{
                return {
                    TIMESTAMP : timestamp,
                    [customTagData?.["tag1"].sensorAlias || "Tag 1"]: value,
                    UNIT: `${customTagData?.["tag1"].sensorUnit || ""}`
                }
            }))
        }else if( value === 1){
            filterData.push(tagTwo.map(({ timestamp, value} : any) =>{
                return {
                    TIMESTAMP : timestamp,
                    [customTagData?.["tag2"].sensorAlias || "Tag 2"]: value,
                    UNIT: `${customTagData?.["tag2"].sensorUnit || ""}`
                }
            }))
        } else if(value === 2){
            filterData.push(tagThree.map(({ timestamp, value} : any) =>{
                return {
                    TIMESTAMP : timestamp,
                    [customTagData?.["tag3"].sensorAlias || "Tag 3"]: value,
                    UNIT: `${customTagData?.["tag3"].sensorUnit || ""}`
                }
            }))
        }else{
            filterData.push(tagFour.map(({ timestamp, value} : any) =>{
                return {
                    TIMESTAMP : timestamp,
                    [customTagData?.["tag4"].sensorAlias || "Tag 4"]: value,
                    UNIT: `${customTagData?.["tag4"].sensorUnit || ""}`
                }
            }))
        }
        switch (exportOption) {
          case "Download as CSV":
            generateCSV1(filterData);
            break;
    
          case "Download as PDF":
            if (filterData && chartRef) {
              reportDataWithGraph(
                chartRef,
                filterData,
                devices?.deviceName,
                devices?.Customer?.name,
                `${value === 0 ? customTagData?.["tag1"].sensorAlias || 'Tag 1' :
                    value === 1 ? customTagData?.["tag2"].sensorAlias || 'Tag 2' :
                    value === 2 ? customTagData?.["tag3"].sensorAlias || 'Tag 3' :
                     customTagData?.["tag4"].sensorAlias || 'Tag 4'
                } Summary`,
                apiData?.length > 0
                  ? apiData[0].timestamp +
                      "-" +
                      apiData[apiData?.length - 1].timestamp
                  : ""
              );
            }
            return;
          default:
            return;
        }
      };

    useEffect(() => {
        fetchData();
        if (devices?.DeviceTemplate?.tagJson) {
            const transformedTagJson = devices.DeviceTemplate.tagJson.reduce(
                (acc: any, tag: any) => {
                    acc[tag.tagName] = tag;
                    return acc;
                },
                {}
            );
            setCustomTagData(transformedTagJson);
        }
        const intervalId = setInterval(fetchData, 120000);
        return () => clearInterval(intervalId);
    }, [selectedDevice]);
    return (
        <div>
            <p className="flex p-[10px] mb-[20px]">
                <MobileTowerIcon levels={dashboardSts.rssi || 0} time={dashboardSts?.timestamp} />
                <p className="ml-2">
                    {"    Last Data Received at  " + dashboardSts?.timestamp ||
                        "  YYYY-MM-DD HH:MM:SS"}
                </p>
            </p>
            <div className="border border-gray-400 rounded-md grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5 mb-2 p-2">
                {customTagData?.["tag1"]?.sensorStatus ? (
                    <CardTwo
                        title={customTagData?.["tag1"]?.sensorAlias || "Tag 1"}
                        amount={`${dashboardSts.tag1 ? dashboardSts.tag1 : "N/A"} ${customTagData?.["tag1"]?.sensorUnit || ""
                            }`}
                    />
                ) : !customTagData?.["tag1"] ? (
                    <CardTwo
                        title="Tag 1"
                        amount={
                            dashboardSts && dashboardSts.tag1
                                ? `${dashboardSts.tag1}`
                                : "N/A"
                        }
                    />
                ) : null}
                {customTagData?.["tag2"]?.sensorStatus ? (
                    <CardTwo
                        title={customTagData?.["tag2"]?.sensorAlias || "Tag 2"}
                        amount={`${dashboardSts.tag2 ? dashboardSts.tag2 : "N/A"} ${customTagData?.["tag2"]?.sensorUnit || ""
                            }`}
                    />
                ) : !customTagData?.["tag2"] ? (
                    <CardTwo
                        title="Tag 2"
                        amount={
                            dashboardSts && dashboardSts.tag2
                                ? `${dashboardSts.tag2}`
                                : "N/A"
                        }
                    />
                ) : null}
                {customTagData?.["tag3"]?.sensorStatus ? (
                    <CardTwo
                        title={customTagData?.["tag3"]?.sensorAlias || "Tag 3"}
                        amount={`${dashboardSts.tag3 ? dashboardSts.tag3 : "N/A"} ${customTagData?.["tag3"]?.sensorUnit || ""
                            }`}
                    />
                ) : !customTagData?.["tag3"] ? (
                    <CardTwo
                        title="Tag 3"
                        amount={
                            dashboardSts && dashboardSts.tag3
                                ? `${dashboardSts.tag3}`
                                : "N/A"
                        }
                    />
                ) : null}
                {customTagData?.["tag4"]?.sensorStatus ? (
                    <CardTwo
                        title={customTagData?.["tag4"]?.sensorAlias || "Tag 4"}
                        amount={`${dashboardSts.tag4 ? dashboardSts.tag4 : "N/A"} ${customTagData?.["tag4"]?.sensorUnit || ""
                            }`}
                    />
                ) : !customTagData?.["tag4"] ? (
                    <CardTwo
                        title="Tag 4"
                        amount={
                            dashboardSts && dashboardSts.tag4
                                ? `${dashboardSts.tag4}`
                                : "N/A"
                        }
                    />
                ) : null}

            </div>

            <div className="pt-[5px]"></div>

            <div
                className="border border-gray-400 rounded-md p-2"
            // style={{ border: "2px solid #EAECF0", padding: "10px" }}
            >
                <div className="relative">
                    <div className="absolute top-0 right-0">
                        <LongMenu
                            options={[
                                "Download as CSV",
                                "Download as PDF",
                            ]}
                            onOptionClick={handleExportLineChart}
                            id={1}
                        />
                    </div>
                </div>
                <p>
                    Data Variation with time
                    (between{" "}
                    {apiData.length > 0
                        ? apiData[apiData.length - 1].timestamp +
                        " to " +
                        apiData[0].timestamp
                        : " "}
                    )
                </p>
                <div className="pb-[20px] pt-[10px]">
                    <div className="w-[250px]">
                        <ul className="text-sm font-medium text-center text-gray-500 rounded-lg sm:flex">
                            {customTagData?.["tag1"]?.sensorStatus ? (
                                <li className="focus-within:z-10">
                                    <button

                                        className={`inline-block border border-gray-300 px-4 py-2 text-[1rem] text-gray-900 rounded-sm  transition-colors duration-300`}
                                        aria-current="page"
                                        style={value === 0 ? { backgroundColor: "#dadada" } : {}}
                                        onClick={() => setValue(0)}
                                    >
                                        {customTagData?.["tag1"]?.sensorAlias || "Tag 1"}
                                    </button>
                                </li>
                            ) : !customTagData?.["tag1"] ? (
                                <li className="focus-within:z-10">
                                    <button

                                        className={`inline-block border border-gray-300 px-4 py-2 text-[1rem] text-gray-900 rounded-sm  transition-colors duration-300`}
                                        aria-current="page"
                                        style={value === 0 ? { backgroundColor: "#dadada" } : {}}
                                        onClick={() => setValue(0)}
                                    >
                                        Tag 1
                                    </button>
                                </li>
                            ) : null}

                            {customTagData?.["tag2"]?.sensorStatus ? (
                                <li className="focus-within:z-10">
                                    <button

                                        className={`inline-block border border-gray-300 px-4 py-2 text-[1rem] text-gray-900 rounded-sm  transition-colors duration-300`}
                                        aria-current="page"
                                        style={value === 1 ? { backgroundColor: "#dadada" } : {}}
                                        onClick={() => setValue(1)}
                                    >
                                        {customTagData?.["tag2"]?.sensorAlias || "Tag 2"}
                                    </button>
                                </li>
                            ) : !customTagData?.["tag2"] ? (
                                <li className="focus-within:z-10">
                                    <button

                                        className={`inline-block border border-gray-300 px-4 py-2 text-[1rem] text-gray-900 rounded-sm  transition-colors duration-300`}
                                        aria-current="page"
                                        style={value === 1 ? { backgroundColor: "#dadada" } : {}}
                                        onClick={() => setValue(1)}
                                    >
                                        Tag 2
                                    </button>
                                </li>
                            ) : null}

                            {customTagData?.["tag3"]?.sensorStatus ? (
                                <li className="focus-within:z-10">
                                    <button

                                        className={`inline-block border border-gray-300 px-4 py-2 text-[1rem] text-gray-900 rounded-sm  transition-colors duration-300`}
                                        aria-current="page"
                                        style={value === 2 ? { backgroundColor: "#dadada" } : {}}
                                        onClick={() => setValue(2)}
                                    >
                                        {customTagData?.["tag3"]?.sensorAlias || "Tag 3"}
                                    </button>
                                </li>
                            ) : !customTagData?.["tag3"] ? (
                                <li className="focus-within:z-10">
                                    <button

                                        className={`inline-block border border-gray-300 px-4 py-2 text-[1rem] text-gray-900 rounded-sm  transition-colors duration-300`}
                                        aria-current="page"
                                        style={value === 2 ? { backgroundColor: "#dadada" } : {}}
                                        onClick={() => setValue(2)}
                                    >
                                        Tag 3
                                    </button>
                                </li>
                            ) : null}

                            {customTagData?.["tag4"]?.sensorStatus ? (
                                <li className="focus-within:z-10">
                                    <button

                                        className={`inline-block border border-gray-300 px-4 py-2 text-[1rem] text-gray-900 rounded-sm  transition-colors duration-300`}
                                        aria-current="page"
                                        style={value === 3 ? { backgroundColor: "#dadada" } : {}}
                                        onClick={() => setValue(3)}
                                    >
                                        {customTagData?.["tag4"]?.sensorAlias || "Tag 4"}
                                    </button>
                                </li>
                            ) : !customTagData?.["tag4"] ? (
                                <li className="focus-within:z-10">
                                    <button

                                        className={`inline-block border border-gray-300 px-4 py-2 text-[1rem] text-gray-900 rounded-sm  transition-colors duration-300`}
                                        aria-current="page"
                                        style={value === 3 ? { backgroundColor: "#dadada" } : {}}
                                        onClick={() => setValue(3)}
                                    >
                                        Tag 4
                                    </button>
                                </li>
                            ) : null}
                        </ul>
                    </div>
                </div>
                <div ref={chartRef}>
                    <LineChart
                        yAxisName="Data"
                        stroke={true}
                        data={value === 0 ? tagOne : value === 1 ? tagTwo : value === 2 ? tagThree : tagFour}
                        xAxisName="Time"
                    />
                </div>
            </div>
        </div>
    )
}

export default CustomDashboardTemplate