import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import {
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Box,
  Typography,
  SelectChangeEvent,
  styled,
} from "@mui/material";
import "./LineChart.css";
import customRestIcon from "../../images/refresh.svg";

interface LineData {
  name?: string;
  color: string;
  showDots?: number;
  data: { timestamp: string; value: number }[];
  showHoverTooltip?: boolean;
  postfix?: string;
}

interface LineChartProps {
  data: LineData[];
  yAxisName?: string;
  stroke?: boolean;
  xAxisName?: string;
}

// Define a styled component for the hint text
const HintText = styled(Typography)(({ theme }) => ({
  padding: '4px 26px 4px 15px',
  position: 'absolute',
  top: -20,
  left: 0,
  width: '100%',
  backgroundColor: theme.palette.background.paper,
  zIndex: 1,
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

// Define a styled component for the dropdown menu item with optional margin
const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  marginTop: '10px',
}));

const DynamicLineChart: React.FC<LineChartProps> = ({ data, yAxisName, xAxisName, stroke }) => {
  const [visibleData, setVisibleData] = useState<string[]>(data.slice(0, 3).map(d => d.name || ""));

  // Handle the change in selected items
  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const selectedValues = event.target.value as string[];
    if (selectedValues.length <= 3) {
      setVisibleData(selectedValues);
    }
  };

  // Filter data based on visibleData
  const filteredData = data.filter(d => visibleData.includes(d.name || ""));

  const now = new Date();
  const thirtyMinutes = 30 * 60 * 1000;
  const twentyFourHoursAgo = new Date(now.getTime() - 24 * 60 * 60 * 1000);

  const series = filteredData.map((line) => {
    let lastTimestamp: number | null = null;

    const processedData = line.data.flatMap((point) => {
      const pointTime = new Date(point.timestamp).getTime();
      const result = [];
      if (lastTimestamp !== null && pointTime - lastTimestamp > thirtyMinutes) {
        result.push({ x: pointTime - thirtyMinutes, y: null });
      }
      result.push({ x: pointTime, y: point.value });
      lastTimestamp = pointTime;
      return result;
    });

    return {
      name: line.name || "",
      data: processedData,
      color: line.color,
      showDots: line.showDots,
      postfix: line.postfix,
    };
  });

  const options: ApexOptions = {
    chart: {
      type: "line",
      height: 350,
      animations: {
        enabled: false,
      },
      zoom: {
        enabled: true,
        type: 'xy',
        autoScaleYaxis: true,
      },
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: false,
          selection: false,
          zoom: true,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: `<img src=${customRestIcon} width="20" alt="Reset Zoom">`,
        },
      },
    },
    xaxis: {
      type: 'datetime',
      min: twentyFourHoursAgo.getTime(),
      max: now.getTime(),
      title: {
        text: xAxisName,
      },
      labels: {
        datetimeUTC: false,
        format: 'HH:mm',
      },
      tickAmount: 6,
    },
    yaxis: {
      title: {
        text: yAxisName,
      },
      labels: {
        formatter: function (value: any) {
          return value;
        }
      }
    },
    tooltip: {
      x: {
        formatter: function(val: any) {
          return new Date(val).toLocaleString();
        }
      },
      y: {
        formatter: function (value, { seriesIndex }) {
          const postfix = filteredData[seriesIndex].postfix || "";
          return value === null ? "N/A" : value + " " + postfix;
        },
      }
    },
    markers: {
      size: series.map((line) => (line.showDots ? 4 : 0)),
      strokeWidth: 0,
      hover: {
        size: 8,
      },
    },
    colors: series.map((line) => line.color),
    ...(stroke
      ? {
          stroke: {
            width: 2,
            curve: "smooth",
          },
        }
      : {}),
  };

  return (
    <Box position="relative">
      <FormControl variant="outlined" sx={{ position: 'relative', width: 250 }}>
        <Select
          multiple
          value={visibleData}
          onChange={handleChange}
          renderValue={(selected) => selected.join(", ")}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 224,
                width: 250,
                paddingTop: '24px',
              },
            },
          }}
        >
          <HintText variant="caption">
            Maximum 3 can be selected
          </HintText>
          {data.map((line, index) => (
            <StyledMenuItem key={line.name || index} value={line.name || ""}>
              <Checkbox checked={visibleData.includes(line.name || "")} />
              <ListItemText primary={line.name || "Unnamed"} />
            </StyledMenuItem>
          ))}
        </Select>
      </FormControl>
      <ReactApexChart
        options={options}
        series={series}
        type="line"
        height={350}
        key={JSON.stringify(visibleData)}
      />
    </Box>
  );
};

export default DynamicLineChart