import { Outlet } from "react-router-dom";
import Sidebar2 from "../Sidebar/Sidebar";
import React,{ useState } from "react";
import Navbar2 from "../navbar/Navbar";





const Homepage = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  return (
    <>
      <div className="flex h-screen overflow-hidden">
        {/* <!-- ===== Sidebar Start ===== --> */}
        <Sidebar2 sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        {/* <!-- ===== Sidebar End ===== --> */}

        {/* <!-- ===== Content Area Start ===== --> */}
        <div className="relative flex flex-1 flex-col overflow-hidden">
          {/* <!-- ===== Header Start ===== --> */}
          <Navbar2 sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

          {/* <!-- ===== Main outlet Content Start ===== --> */}
          <main className="flex-1 overflow-y-auto z-10">
            <div className="mx-auto max-w-screen-2xl p-2 z-0">
              <Outlet />
            </div>
          </main>
          {/* <!-- ===== Main outlet Content End ===== --> */}
        </div>
        {/* <!-- ===== Content Area End ===== --> */}
      </div>
    </>
  );
};

export default Homepage;
