import React from "react";

interface CardFourProps {
  title?: string;
  amount1?: string;
  amount2?: string;
  color?: string;
  background?: string;
  style?: React.CSSProperties;
}

const CardFour: React.FC<CardFourProps> = ({
  title,
  amount1,
  amount2,
  color,
  background,
  style,
}) => {
  return (
    <div
      className="rounded-lg border border-stroke p-3 bg-white"
      style={{ background, ...style, overflow: "hidden" }}
    >
      <div className="flex">
        <div className="w-full">
          <div className="mt-2">
            <div className="flex flex-col">
              <h2
                className="text-[20px] font-bold text-black overflow-hidden whitespace-nowrap text-ellipsis"
              >
                {title}
              </h2>
              <div className="flex justify-center h-[70px]">
                <div className="flex items-center">
                  <span className="text-title-md font-bold text-gray-700 text-[18px] py-1">
                    {amount1}
                  </span>
                  <div className="w-[1px] h-[35px] bg-gray-500 mx-2"></div>
                  <span className="text-title-md font-bold text-gray-700 text-[18px] py-1">
                    {amount2}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardFour;
