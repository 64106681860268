import Table from "@mui/material/Table";
import Box from "@mui/material/Box";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useAlertState } from "../../app-context/alert-context/AlertState";
import { useEffect, useMemo, useState } from "react";
import { Alert as AlertModel } from "../../types/AlertTypes";
import { Switch } from "@mui/material";
import LongMenu from "../../utils/longMenu/LongMenu";
import { EnhancedTableHead } from "./EnhancedTableHead";
import { EnhancedTableToolbar } from "./EnhancedTableToolBar";
import AddAlertForm from "./AddAlertForm";
import {
  setAlert,
  updateAlertStatus,
} from "../../app-context/alert-context/AlertAction";
import { http } from "../../helper/http";
import { toast } from "react-toastify";
import { ConfirmModal } from "../../utils/alertui/ConfirmModal";
const baseUrl = process.env.REACT_APP_BASE_URL;
let __editAlertData: AlertModel | undefined;
let deleteId: number;
const Alert = () => {
  const [updatedRows, setUpdatedRows] = useState<AlertModel[]>([]);
  const [editAlertModal, setEditAlertModal] = useState(false);
  const { alert, dispatch } = useAlertState();
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  useEffect(() => {
    setUpdatedRows(alert.alert);
  }, [alert]);

  const emptyRows = 10 - updatedRows.length;
  const visibleRows = useMemo(
    () => updatedRows.slice(0 * 10, 0 * 10 + 10),
    [updatedRows, alert]
  );

  const handleMenuOptionClick = async (option: string, id: number) => {
    try {
      switch (option) {
        case "Delete":
          deleteId = id;
          setShowConfirmModal(true);
          break;
        case "Edit":
          const editAlert = alert.alert.find((alert) => alert.id === id);
          // setCustomerData([customers]);
          __editAlertData = editAlert;
          setEditAlertModal(true);
          break;
        case "Report":
          break;
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleConfirmDelete = async () => {
    if (deleteId !== null) {
      try {
        const deleteResp = (await http(
          baseUrl + `/alert?id=${deleteId}`,
          "DELETE"
        )) as any;
        toast.success(deleteResp.message);
        setAlert(dispatch, alert.currentPage);
      } catch (err) {
        console.error(err);
      }
      setShowConfirmModal(false);
    }
  };

  const handlePageChange = async (newPage: number) => {
    const direction = newPage > alert.currentPage - 1 ? "next" : "previous";
    const page = +alert.currentPage;
    setAlert(dispatch, direction === "next" ? page + 1 : page - 1);
  };

  const handleSwitchToggle = async (id: number, status: any, key: string) => {
    try {
      const response = await http(
        baseUrl + `/alert?id=${id}&${key === "SMS" ? "smsStatus=" : "emailStatus="}${!status}&key=${key}`,
        "PATCH"
      );
      updateAlertStatus(dispatch, { id: id, status: !status, key: key });
      toast.success("Status updated successfully");
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Paper sx={{ width: "99%" }}>
          <EnhancedTableToolbar />
          <div className="h-[72vh] overflow-y-auto">
            <TableContainer>
              {showConfirmModal && (
                <ConfirmModal
                  title="Confirmation"
                  message="Are you sure you want to delete?"
                  onConfirm={handleConfirmDelete}
                  onCancel={() => {
                    setShowConfirmModal(false);
                  }}
                  isVisible={showConfirmModal}
                />
              )}
              <Table
                className="min-w-750 bg-white"
                aria-labelledby="tableTitle"
                size="medium"
              >
                {editAlertModal && (
                  <AddAlertForm
                    uniqueId="EDIT"
                    data={__editAlertData as any}
                    toggleForm={editAlertModal}
                    toggleFormFun={() => {
                      setEditAlertModal(false);
                    }}
                  />
                )}
                <EnhancedTableHead />
                <TableBody>
                  {visibleRows.map((row: any) => (
                    <TableRow
                      key={row?.id || null}
                      hover
                      className="cursor-pointer"
                    >
                      {row && (
                        <>
                          <TableCell align="center" className="py-3">
                            {row?.Device?.deviceName || "N/A"}
                          </TableCell>
                          <TableCell align="center" className="py-3">
                            {row?.Tag?.tagname || "N/A"}
                          </TableCell>
                          <TableCell align="center" className="py-3">
                            {row?.level?.level1 ? (
                              <>
                                {row.level.level1.value || "N/A"}
                                <br />
                                {row.level.level1.email || "N/A"}
                              </>
                            ) : (
                              "N/A"
                            )}
                          </TableCell>

                          <TableCell align="center" className="py-3">
                            {row?.level?.level2 ? (
                              <>
                                {row.level.level2.value || "N/A"}
                                <br />
                                {row.level.level2.email || "N/A"}
                              </>
                            ) : (
                              "N/A"
                            )}
                          </TableCell>

                          <TableCell align="center" className="py-3">
                            {row?.level?.level3 ? (
                              <>
                                {row.level.level3.value || "N/A"}
                                <br />
                                {row.level.level3.email || "N/A"}
                              </>
                            ) : (
                              "N/A"
                            )}
                          </TableCell>

                          <TableCell align="center" className="py-3">
                            <Switch
                              checked={
                                row.smsStatus !== undefined ? row.smsStatus : false
                              }
                              color="primary"
                              onChange={() =>
                                handleSwitchToggle(row.id, row.smsStatus, "SMS")
                              }
                            />
                          </TableCell>

                          <TableCell align="center" className="py-3">
                            <Switch
                              checked={
                                row.emailStatus !== undefined ? row.emailStatus : false
                              }
                              color="primary"
                              onChange={() =>
                                handleSwitchToggle(row.id, row.emailStatus, "EMAIL")
                              }
                            />
                          </TableCell>

                          <TableCell
                            className="text-center"
                            style={{ textAlign: "center" }}
                          >
                            <LongMenu
                              options={["Edit", "Delete"]}
                              onOptionClick={handleMenuOptionClick}
                              id={row.id}
                            />
                          </TableCell>
                        </>
                      )}
                    </TableRow>
                  ))}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: 45 * emptyRows,
                      }}
                    >
                      <TableCell colSpan={4} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={alert?.totalItems || 0}
            rowsPerPage={10}
            page={Math.max(
              0,
              Math.min(alert?.currentPage - 1, alert?.totalPages - 1)
            )}
            onPageChange={(event, newPage) => handlePageChange(newPage)}
            className="bg-gray-100"
          />
        </Paper>
      </Box>
    </>
  );
};

export default Alert;
