import { jwtDecode } from 'jwt-decode'
import ProtectedComponent from '../../utils/protected-route/ProtectedComponent'
import AdminDashboard1 from './AdminDashboard1'
import Overviewpage from './Overviewpage'
import UserDashboard from './DynamicDashboard'
import ACUserDashboard from './ACUserEndDashboard/ACUserDashboard'
const DashBoard = () => {
  const token: any = window.localStorage.getItem('token')
  const decodedToken = jwtDecode<any>(token);

  return (
    <>
      <ProtectedComponent>
        <AdminDashboard1 />
      </ProtectedComponent>
      <ProtectedComponent roleids={[2]}>
        {
          (decodedToken.loginRedirectPage === "dashboard" || decodedToken.loginRedirectPage === "ambientDashboard") ? <UserDashboard /> : (decodedToken.loginRedirectPage === "acDashboard") ? <ACUserDashboard /> : <Overviewpage />
        }

      </ProtectedComponent>
    </>
  )
}

export default DashBoard