import React, { useEffect, useRef, useState } from "react";
import { http } from "../../../helper/http";
import CardFour from "../../../components/dashBoardComponents/CardFour";
import LineChart from "../../../components/apex-chart/LineChart";
import {
  generateCSV1,
  generateCSVForMultipledeviceData,
  reportDataWithGraph,
  reportMultipleDeviceDataWithGraph,
} from "../../../common-function/CommonFunction";
import LongMenu from "../../../utils/longMenu/LongMenu";
import { useUserState } from "../../../app-context/auth-context/AuthState";

interface MainDashboardTotalizerHumidityProps {
  selectedTemplateId: number;
}

const baseUrl = process.env.REACT_APP_BASE_URL;

const MainDashboardTotalizerHumidity: React.FC<
  MainDashboardTotalizerHumidityProps
> = ({ selectedTemplateId }) => {
  const [selectedTempData, setSelectedTempData] = useState<any>([]);
  const chartRef = useRef<HTMLDivElement>(null);
  const chartRef1 = useRef<HTMLDivElement>(null);
  const { users } = useUserState();
  // console.log(users, "000000");
  

  const fetchTemplateData = async () => {
    let response: any = null;
    try {
      response = await http(
        baseUrl + `/dashboard-overall?template=${selectedTemplateId}&last24=30`
      );
      setSelectedTempData(response);
    } catch (error) {
      console.error(error);
    }
  };

  const dataArray: any = Object.keys(selectedTempData).map((key) => ({
    id: key,
    items: selectedTempData[key],
  }));

  // console.log("data Array", dataArray);

  const colorsForChart = [
    "#FF0000",
    "#FFB800",
    "#027A48",
    "#FFFF00",
    "#9447AD",
    "#393932",
    "#0000FF",
  ];

  function restructureTempData(dataArray: any) {
    const newDataArray: any = {};

    // Iterate over each entry in the input array
    dataArray.forEach((entry: any) => {
      // Iterate over each item in the current entry's items array
      entry.items.forEach((item: any) => {
        const deviceName = item.device.deviceName;

        // If the deviceId is not yet in newDataArray, initialize it
        if (!newDataArray[deviceName]) {
          newDataArray[deviceName] = [];
        }

        // Add the relevant data to the device's data array
        newDataArray[deviceName].push({
          timeStamp: item.timeStamp,
          Temperature: item.Temperature,
        });
      });
    });

    return newDataArray;
  }
  function restructureHumData(dataArray: any) {
    const newDataArray: any = {};

    // Iterate over each entry in the input array
    dataArray.forEach((entry: any) => {
      // Iterate over each item in the current entry's items array
      entry.items.forEach((item: any) => {
        const deviceName = item.device.deviceName;

        // If the deviceId is not yet in newDataArray, initialize it
        if (!newDataArray[deviceName]) {
          newDataArray[deviceName] = [];
        }

        // Add the relevant data to the device's data array
        newDataArray[deviceName].push({
          timeStamp: item.timeStamp,
          Humadity: item.Humidity,
        });
      });
    });

    return newDataArray;
  }

  const dataForTempDownload = restructureTempData(dataArray);
  const dataForHumDownload = restructureHumData(dataArray);


  const linechartTempData: any[] = dataArray.map(
    (dataObj: any, index: number) => ({
      name: dataObj.items[0]?.device.deviceName,
      color: colorsForChart[index % colorsForChart.length],
      data: dataObj.items.map((item: any) => ({
        timestamp: item.timeStamp,
        value: item.Temperature ? parseFloat(item.Temperature) : 0,
      })),
      postfix: " °C",
    })
  );

  const linechartHumadityData: any[] = dataArray.map(
    (dataObj: any, index: number) => ({
      name: dataObj.items[0]?.device.deviceName,
      color: colorsForChart[index % colorsForChart.length],
      data: dataObj.items.map((item: any) => ({
        timestamp: item.timeStamp,
        value: item.Humidity ? parseFloat(item.Humidity) : 0,
      })),
      postfix: " %",
    })
  );

  // console.log("data Araay", dataArray);

  const handleExportBarChart = async (
    exportOption: string,
    dataValue: any,
    chart: any,
    reportHeader?: string,
  ) => {
    switch (exportOption) {
      case "Download as CSV":
        generateCSVForMultipledeviceData(dataValue);
        break;
      case "Download as PDF":
        if (dataValue && chart) {
          reportMultipleDeviceDataWithGraph(
            chart,
            dataValue,
            users.user.name || "N/A",
            reportHeader? reportHeader : "Water Quality Summary",
            "Last 24 hour",
            "Report_Summary.pdf"
          );
        } else {
        }
        return;
      default:
        return;
    }
  };

  useEffect(() => {
    fetchTemplateData();
    const intervalId = setInterval(fetchTemplateData, 120000);
    return () => clearInterval(intervalId);
  }, [selectedTemplateId]);

  return (
    <div>
      <div className="border p-2 border-gray-400 rounded-md bp-[10px] grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5">
        {dataArray.length > 0 ? (
          dataArray.map((data: any) => {
            const firstItem = data.items[0];
            const deviceName = firstItem.device.deviceName;
            const humidity = firstItem?.Humidity || "N/A";
            const temperature = firstItem?.Temperature || "N/A";

            return (
              <CardFour
                title={deviceName}
                amount1={`${temperature} °C`}
                amount2={`${humidity} %`}
              />
            );
          })
        ) : (
          <h3>No data Available within last 24 hour</h3>
        )}
      </div>
      {dataArray.length > 0 && (
        <>
          <div className="relative mt-5 border p-5 border-gray-400 rounded-md">
            <p>Temperature reading data variation of last 24 hours</p>
            <div className="absolute top-3 right-3">
              <LongMenu
                options={["Download as CSV", "Download as PDF"]}
                onOptionClick={(exportOption) =>
                  handleExportBarChart(
                    exportOption,
                    dataForTempDownload,
                    chartRef,
                    "Temperature data summary"
                  )
                }
                id={1}
              />
            </div>
            <div>
              <div ref={chartRef} style={{ marginTop: "20px" }}>
                <LineChart
                  data={linechartTempData}
                  yAxisName="Data"
                  xAxisName="Time"
                  stroke={true}
                />
              </div>
            </div>
          </div>
          <div className="relative mt-5 border p-5 border-gray-400 rounded-md">
            <p>Humidity reading data variation of last 24 hours</p>
            <div className="absolute top-3 right-3">
              <LongMenu
                options={["Download as CSV", "Download as PDF"]}
                onOptionClick={(exportOption) =>
                  handleExportBarChart(
                    exportOption,
                    dataForHumDownload,
                    chartRef1,
                    "Humidity data summary"
                  )
                }
                id={1}
              />
            </div>
            <div>
              <div ref={chartRef1} style={{ marginTop: "20px" }}>
                <LineChart
                  data={linechartHumadityData}
                  yAxisName="Data"
                  xAxisName="Time"
                  stroke={true}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default MainDashboardTotalizerHumidity;
