import { TableCell, TableHead, TableRow } from '@mui/material';
import React from 'react'

interface Data {
    id: number;
    schedulingName: string;
    deviceName: string;
    email: any;
    phone: any;
    smsStatus: boolean;
    emailStatus: boolean;
    workingDay: any;
    offCmdIntervalMin: number;
    schedulerStatus: boolean;
    triggerStatus: boolean;
    action: string;
}

const headCells: { id: keyof Data; disablePadding: boolean; label: string }[] =
    [
        {
            id: "schedulingName",
            disablePadding: true,
            label: "Scheduling Name",
        },
        {
            id: "deviceName",
            disablePadding: true,
            label: "DeviceName",
        },
        {
            id: "email",
            disablePadding: false,
            label: "Email ID",
        },
        {
            id: "phone",
            disablePadding: false,
            label: "Phone No",
        },
        {
            id: "smsStatus",
            disablePadding: false,
            label: "SMS Status",
        },
        {
            id: "emailStatus",
            disablePadding: false,
            label: "Email Status",
        },
        {
            id: "workingDay",
            disablePadding: false,
            label: "Working Days",
        },
        {
            id: "offCmdIntervalMin",
            disablePadding: false,
            label: "Cooldown Duration",
        },
        {
            id: "schedulerStatus",
            disablePadding: false,
            label: "Status",
        },
        {
            id: "action",
            disablePadding: false,
            label: "Action",
        },
    ];

function EnhancedTableHead() {
    return (
        <TableHead className="bg-gray-800">
            <TableRow className="border-b">
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align="center"
                        padding="normal"
                        sx={{ fontWeight: 600, color: "white" }}
                    >
                        {headCell.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

export default EnhancedTableHead