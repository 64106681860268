import React, { useState, useEffect } from 'react';
import {
    Box,
    Table,
    Button,
    Modal,
    TextField,
    MenuItem,
    Select,
    InputLabel,
    FormControl,
    Chip,
    OutlinedInput,
    Autocomplete,
} from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import { Add as AddIcon } from '@mui/icons-material';
import { http } from '../../helper/http';
import { toast } from "react-toastify";

interface User {
    id: number;
    name: string;
  }


const baseUrl = process.env.REACT_APP_BASE_URL;
function AssignDevices() {
    const [open, setOpen] = useState(false);
    const [selectedImei, setSelectedImei] = useState(null);
    const [users, setUsers] = useState<User[]>([]);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setSelectedDevices([]);
        setSelectedImei(null);
        setOpen(false);
    };

    const [devices, setDevices] = useState([]);

    const [selectedDevices, setSelectedDevices] = useState<User[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(1);
    const [totalItems, setTotalItems] = useState<number>(0);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [deviceToDelete, setDeviceToDelete] = useState<any>(null);

    const [AllDevice, setAllDevices] = useState([]);





    // const handleDeviceChange = (event: any) => {
    //     setSelectedDevices(event.target.value);
    // };
    const handleDeviceChange = (event: React.ChangeEvent<{}>, value: User[]) => {
        setSelectedDevices(value);
    };

    const fetchUsers = async (deviceId: any) => {
        try {
            const resp: any = await http(`/filter-user-device?deviceId=${deviceId}`);
            const newCustomers = resp.filterUsers;
            setUsers(newCustomers);
        } catch (err) {
            console.error("Failed to fetch users:", err);
        }
    };

    const handleImeiChange = (event: any, value: any) => {
        setSelectedImei(value.id);  
        fetchUsers(value.id);      
    };


    const handleDeleteClick = (device: any) => {
        setDeviceToDelete(device);
        setDeleteOpen(true);
    };


    const getAllDevices = async () => {
        try {
            const resp: any = await http(`${baseUrl}/device?alldevice=4`);
            const newTable: any = resp.devices;
            setAllDevices(newTable);
            // console.log(newTable);

        } catch (err) {
            console.error("Failed to fetch devices:", err);
        }
    };

    const fetchDevices = async (page: number) => {
        try {
            const resp: any = await http(`${baseUrl}/assign-device?page=${page}&limit=10`);
            const newTable: any = resp.devices.devices;
            setDevices(newTable);
            setTotalPages(resp.devices.totalPages);
            setTotalItems(resp.devices.totalItems);
            setCurrentPage(resp.devices.currentPage);
        } catch (err) {
            console.error("Failed to fetch devices:", err);
        }
    };


    useEffect(() => {
        fetchDevices(currentPage);
        getAllDevices();
    }, [currentPage]);

    const deviceData = AllDevice.map((device: any) => ({
        id: device?.id,
        deviceName: device?.deviceId,
    }));

    const handlePageChange = async (newPage: number) => {
        await fetchDevices(newPage + 1);
    };

    const handleDeleteConfirm = async () => {
        try {
            await http(`${baseUrl}/assign-device?id=${deviceToDelete.id}`, "DELETE");
            setDevices(devices.filter((d: any) => d.id !== deviceToDelete.id));
            toast.success("Device deleted successfully!");
        } catch (err: any) {
            console.error("Failed to delete device:", err);
            toast.error("Failed to delete device");
        } finally {
            setDeleteOpen(false);
            setDeviceToDelete(null);
        }
    };

    const handleAddForm = async () => {
        const selectedUserIds = selectedDevices.map(user => user.id);
        const payload = {
            userId: selectedUserIds,
            deviceId: selectedImei,
        };
        try {
            await http(`${baseUrl}/assign-device`, "POST", payload);
            fetchDevices(currentPage); // Refresh the device list
            toast.success("Device assigned successfully!");
        } catch (err: any) {
            console.error("Failed to assign device:", err);
            toast.error("Failed to assign device");
        } finally {
            handleClose();
        }
    };


    function getUserNameById(id: number) {
        // return (users.find((d: any) => d.id == id) as any).name;
        const user = users.find((user) => user.id === id);
        return user ? user.name : '';
    }

    return (
        <Box >
            <Box sx={{ display: 'flex', gap: 1, justifyContent: 'end', marginBottom: '15px' }}>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    sx={{ textTransform: 'none', backgroundColor: 'blue', '&:hover': { backgroundColor: 'darkblue' } }}
                    onClick={handleOpen}
                >
                    Add
                </Button>
            </Box>
            <Table sx={{ width: '100%', border: '1px solid black', borderRadius: '8px', }}>
                <thead>
                    <tr style={{ backgroundColor: '#0A083B', color: 'white', textAlign: 'left' }}>
                        <th style={{ width: 'var(--Table-firstColumnWidth)', color: 'white', textAlign: 'left' }}>id</th>
                        <th style={{ width: 200, color: 'white', textAlign: 'left' }}>User Name</th>
                        <th style={{ width: 200, color: 'white', textAlign: 'left' }}>IMEI&nbsp;</th>
                        <th style={{ width: 200, color: 'white', textAlign: 'left' }}>Email Id&nbsp;</th>

                        <th style={{ width: 200, color: 'white', textAlign: 'left' }}>Action&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    {devices.map((row: any) => (
                        <tr style={{ border: '1px solid black' }} key={row.id}>
                            <td>{row.id}</td>
                            <td>{row.User.name}</td>
                            <td>{row.Device?.deviceId}</td>
                            <td>{row.User.email}</td>
                            <td>
                                <Box sx={{ display: 'flex', gap: 1 }}>
                                    <button onClick={() => handleDeleteClick(row)}>Delete</button>
                                </Box>
                            </td>
                        </tr>
                    ))}
                </tbody>

            </Table>

            <TablePagination
                rowsPerPageOptions={[]}
                component="div"
                count={totalItems}
                rowsPerPage={10}
                page={Math.max(
                    0,
                    Math.min(currentPage - 1, totalPages - 1)
                )}
                onPageChange={(event, newPage) => handlePageChange(newPage)}
                className="bg-gray-100"
            />
            {/* //model pop up for adding data *****/}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>
                    <h2 id="modal-modal-title" className='mb-3'>Assign Device</h2>
                    <Autocomplete
                        id="search-imei"
                        options={deviceData}
                        getOptionLabel={(option) => option?.deviceName}
                        onChange={handleImeiChange}
                        renderInput={(params) => <TextField {...params} label="Device" variant="outlined" />}
                        sx={{ m: 1, width: 300 }}
                    />
                    <FormControl sx={{ m: 1, width: 300 }}>
                        
                        <Autocomplete
                            multiple
                            id="device-select"
                            options={users}
                            getOptionLabel={(option) => option.name}
                            value={selectedDevices}
                            onChange={handleDeviceChange}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Users"
                                    placeholder="Select users"
                                />
                            )}
                            renderTags={(tagValue, getTagProps) =>
                                tagValue.map((option: User, index: number) => (
                                  <Chip
                                    // key={i} // Ensure key is unique for each chip
                                    label={getUserNameById(option.id)}
                                    {...getTagProps({ index })}
                                  />
                                ))
                              }
                            style={{ maxHeight: 200 }}
                            disableCloseOnSelect
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                        />
                    </FormControl>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                        <Button onClick={handleClose} variant="outlined" sx={{ mr: 2 }}>Cancel</Button>
                        <Button onClick={handleAddForm} variant="contained" color="primary">Save</Button>
                    </Box>
                </Box>
            </Modal>

            {/* Modal for delete confirmation */}
            <Modal
                open={deleteOpen}
                onClose={handleDeleteClick}
                aria-labelledby="delete-modal-title"
                aria-describedby="delete-modal-description"
            >
                <Box sx={modalStyle}>
                    <h2 id="delete-modal-title">Are you sure you want to delete this device?</h2>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                        <Button onClick={handleDeleteClick} variant="outlined" sx={{ mr: 2 }}>Cancel</Button>
                        <Button onClick={handleDeleteConfirm} variant="contained" color="primary">Delete</Button>
                    </Box>
                </Box>
            </Modal>
        </Box>
    );
}
const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
export default AssignDevices;
