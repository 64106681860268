import { useEffect, useRef, useState } from "react";
import { http } from "../../../helper/http";
import {
  formatTimestamp,
  generateCSV1,
  reportDataWithGraph,
} from "../../../common-function/CommonFunction";
import LineChart from "../../../components/apex-chart/LineChart";
import LongMenu from "../../../utils/longMenu/LongMenu";
import CardTwo from "../../../components/dashBoardComponents/CardTwo";
import MobileTowerIcon from "../../../components/apex-chart/HeatMapChart";
import temp from "../../../images/temp2.jpeg";

const baseUrl = process.env.REACT_APP_BASE_URL;

const DashboardTemperature = ({ devices, selectedDevice }: any) => {
  const [dashboardSts, setDashboardSts] = useState<any>({});
  //console.log('dashboardSts',dashboardSts)
  const [apiData, setApiData] = useState<any[]>([]);
  const chartRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    fetchData();
    const intervalId = setInterval(fetchData, 120000);
    return () => clearInterval(intervalId);
  }, [selectedDevice]);

  const fetchData = async () => {
    let response: any = null;
    try {
      response = await http(
        baseUrl + `/device-data-log?deviceId=${selectedDevice}?last24=last24`
      );
      const _data = response
        .map((d: any, i: any) => {
          return {
            timestamp: formatTimestamp(d.timeStamp),
            rssi: d["rssi"] || 0,
            temperature: d["Temperature"] || 0,
          };
        })
      
        .filter((d: any) => {
          return !Object.values(d).some(
            (val: any) =>
              typeof val === "string" && val.toLowerCase() === "error"
          );
        });

      setDashboardSts(_data.length ? _data[0] : "");
      setApiData(_data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const currentDate = new Date();
  const last24HoursData = apiData.filter((entry: any) => {
    const entryDate = new Date(entry.timestamp);
    const timeDifference = currentDate.getTime() - entryDate.getTime();
    return timeDifference <= 24 * 60 * 60 * 1000; // Include entries up to the current timestamp
  });
  //console.log('last24HoursData',last24HoursData)

  const temperaturedatalast24hour =
    last24HoursData.length > 0
      ? last24HoursData.map(({ timestamp, temperature }: any) => ({
          timestamp,
          value: temperature,
        }))
      : [];

  const templinegraph: any = [
    {
      name: "Temprature",
      color: "#34bf4b",
      // showDots: true,
      postfix: " \u2103",
      data: temperaturedatalast24hour.reverse(),
    },
  ];
 // console.log('templinegraph',templinegraph)

  const handleExportBarChart = async (exportOption: string) => {
    let lineChartData = temperaturedatalast24hour?.map(
      ({ timestamp, value }) => {
        return {
          TIMESTAMP: timestamp,
          TEMPERATURE: value,
          UNIT: " °C",
        };
      }
    );

    switch (exportOption) {
      case "Download as CSV":
        generateCSV1(lineChartData);
        break;
      case "Download as PDF":
        if (lineChartData && chartRef) {
          reportDataWithGraph(
            chartRef,
            lineChartData,
            devices?.deviceName,
            devices?.Customer?.name,
            "Temperature Summary",
            last24HoursData.length > 0
              ? last24HoursData[last24HoursData.length - 1].timestamp +
                  " - " +
                  last24HoursData[0].timestamp
              : ""
          );
        } else {
        }
        return;
      default:
        return; // Unknown export option
    }
  };
  
  return (
    <div>
      <p className="flex  p-[10px] mb-[20px] ">
        <MobileTowerIcon levels={dashboardSts ? dashboardSts.rssi : 0 || 0} time={dashboardSts?.timestamp}/>
        <p className="ml-2">
          {"    Last Data Received at  " + dashboardSts?.timestamp ||
            "  YYYY-MM-DD HH:MM:SS"}
        </p>
      </p>
      <div className="border border-gray-400 rounded-md p-2 grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5 mb-2">
        <CardTwo
          title="Temperature"
          temperature={`${dashboardSts ? dashboardSts.temperature : 0 + " "}\u2103` }
        
          icon={temp}
          // background="#ebbcc4"
        />
      </div>
      <div className="pt-[5px]"></div>
      <div
        className="border border-gray-400 rounded-md p-2"
        // style={{ border: "2px solid #EAECF0", padding: "10px" }}
      >
        <div className="relative">
          <div className="absolute top-0 right-0">
            <LongMenu
              options={[
                "Download as CSV",
                "Download as PDF",
              ]}
              onOptionClick={handleExportBarChart}
              id={1}
            />
          </div>
        </div>
        <p>
          Temperature variation with time (between{" "}
          {last24HoursData.length > 0
            ? last24HoursData[last24HoursData.length - 1].timestamp +
              " to " +
              last24HoursData[0].timestamp
            : " "}
          )
        </p>

        <div ref={chartRef} style={{marginTop: "15px"}}>
          <LineChart
            data={templinegraph}
            stroke={true}
            xAxisName="Time"
            yAxisName="Temperature ℃"
          />
        </div>
      </div>
    </div>
  );
};

export default DashboardTemperature;
