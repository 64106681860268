interface DataItem1 {
  timestamp: string;
  totalizer: string;
}

interface HourlyData1 {
  firstTimeStamp: string;
  lastTimeStamp: string;
  firstTotalizer: string;
  lastTotalizer: string;
  firstPositiveCumulative: string;
  lastPositiveCumulative: string;
  firstNegativeCumulative: string;
  lastNegativeCumulative: string;
  hour: string;
}
export function filterDataFromEachHourLast24HourAtlanta(
  data: DataItem1[]
): HourlyData1[] {
  data.sort(
    (a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
  );

  // Calculate the timestamp for 24 hours ago from the current time
  const twentyFourHoursAgo = new Date(Date.now() - 24 * 60 * 60 * 1000);
  // Filter the array to include only items from the last 24 hours from the current hour
  const last24HoursData = data.filter(
    (item) => new Date(item.timestamp) >= twentyFourHoursAgo
  );
  // Initialize an array to store the results
  const result: HourlyData1[] = [];

  // Process the data
  // console.log(last24HoursData, "89");

  last24HoursData.forEach((item: any) => {
    const hourTimestamp = item.timestamp.slice(0, 13); // Adjust the timestamp format here

    const index = result.findIndex(
      (hourlyData) => hourlyData.hour === hourTimestamp
    );

    if (index === -1) {
      // If the hour doesn't exist in the result array, initialize it
      result.push({
        firstTimeStamp: item.timestamp,
        lastTimeStamp: item.timestamp,
        firstTotalizer: item.totalizer,
        lastTotalizer: item.totalizer,
        firstPositiveCumulative: item["forward totalizer"],
        lastPositiveCumulative: item["forward totalizer"],
        firstNegativeCumulative: item["reverse totalizer"],
        lastNegativeCumulative: item["reverse totalizer"],
        hour: hourTimestamp,
      });
    } else {
      // Update the lastTimeStamp and lastPositivecumulative for the current hour
      result[index].lastTimeStamp = item.timestamp;
      result[index].lastTotalizer = item.totalizer;
      result[index].lastPositiveCumulative = item["forward totalizer"];
      result[index].lastNegativeCumulative = item["reverse totalizer"];
    }
  });

  return result;
}

export function processHourlyDataAtlanta(
  inputData: HourlyData1[]
): HourlyData1[] {
  const processedData: HourlyData1[] = [];

  // Process the first object separately

  processedData.push({
    firstTimeStamp: inputData[0].firstTimeStamp,
    lastTimeStamp: inputData[0].lastTimeStamp,
    firstTotalizer: inputData[0].firstTotalizer,
    lastTotalizer: inputData[0].lastTotalizer,
    firstPositiveCumulative: inputData[0].firstPositiveCumulative,
    lastPositiveCumulative: inputData[0].lastPositiveCumulative,
    firstNegativeCumulative: inputData[0].firstNegativeCumulative,
    lastNegativeCumulative: inputData[0].lastNegativeCumulative,
    hour: inputData[0].hour,
  });
  // console.log(processedData, "proces");

  // Process the rest of the objects
  for (let i = 1; i < inputData.length; i++) {
    const currentHour = inputData[i];
    const previousHour = inputData[i - 1];

    processedData.push({
      firstTimeStamp: previousHour.lastTimeStamp,
      lastTimeStamp: currentHour.lastTimeStamp,
      firstTotalizer: previousHour.lastTotalizer,
      lastTotalizer: currentHour.lastTotalizer,

      firstPositiveCumulative: previousHour.lastPositiveCumulative,
      lastPositiveCumulative: currentHour.lastPositiveCumulative,

      firstNegativeCumulative: previousHour.lastNegativeCumulative,
      lastNegativeCumulative: currentHour.lastNegativeCumulative,

      hour: currentHour.hour,
    });
  }

  return processedData;
}

interface ConsumptionData1 {
  hour: string;
  totalizerconsumption: number;
  positivecumuconsumption: number;
  negativecumuconsumption: number;
  firstTimeStamp: string;
  lastTimeStamp: string;
}
// this will return ths consumtion data
export function processConsumptionDataAtlanta(inputData: any[]): any[] {
  const consumptionData: ConsumptionData1[] = [];
  // console.log("inputData", inputData);

  for (let i = 0; i < inputData.length; i++) {
    const currentHour = inputData[i];

    let c = +currentHour.firstTotalizer - +currentHour.lastTotalizer;
    let pc =
      +currentHour?.firstPositiveCumulative -
      +currentHour.lastPositiveCumulative;
    let nc =
      +currentHour?.firstNegativeCumulative -
      +currentHour.lastNegativeCumulative;

    if (c < 0) {
      c = c * -1;
    }
    if (pc < 0) {
      pc = pc * -1;
    }
    if (nc < 0) {
      nc = nc * -1;
    }

    const consumption: any = {
      hour: currentHour.hour,
      totalizerconsumption: c.toFixed(3),
      positivecumuconsumption: pc.toFixed(3),
      negativecumuconsumption: nc.toFixed(3),
      firstTimeStamp: currentHour.firstTimeStamp,
      lastTimeStamp: currentHour.lastTimeStamp,
    };

    consumptionData.push(consumption);
  }

  return consumptionData;
}

export function fillMissingHours(data: any) {
  const result: {
    hour: string;
    totalizerconsumption: number;
    negativecumuconsumption: number;
    positivecumuconsumption: number;
    firstTimeStamp: null;
    lastTimeStamp: null;
  }[] = [];
  const currentDate = new Date(); // Get current date and time

  // Adjust current date and time to IST
  const currentISTDate = new Date(currentDate.getTime() + 5.5 * 60 * 60 * 1000);

  // Generate the last 24 hours in IST format 'YYYY-MM-DD HH'
  const last24Hours = [];
  for (let i = 0; i < 24; i++) {
    const hourAgo = new Date(currentISTDate.getTime() - i * 60 * 60 * 1000);
    const formattedHour = hourAgo.toISOString().slice(0, 13).replace("T", " ");
    last24Hours.push(formattedHour);
  }

  // Iterate over the generated hours and check if each hour is present in the data
  last24Hours.reverse().forEach((hour) => {
    const foundData = data.find((item: { hour: string }) => item.hour === hour);

    // If data for the hour is found, add it to the result, otherwise add default values
    if (foundData) {
      result.push(foundData);
    } else {
      result.push({
        hour: hour,
        totalizerconsumption: 0.0,
        negativecumuconsumption: 0.0,
        positivecumuconsumption: 0.0,
        firstTimeStamp: null,
        lastTimeStamp: null,
      });
    }
  });

  return result;
}
