import React, { forwardRef, useEffect, useRef } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { fillMissingHours } from "../../common-function/CommonFunction";

interface HourData {
  hour: string;
  consumption?: number;
  firstTimeStamp?: string;
  lastTimeStamp?: string;
  firstPositivecumulative?: number;
  lastPositivecumulative?: number;
}

interface BarchartProps {
  data?: HourData[];
  forwardedRef?: React.MutableRefObject<HTMLDivElement | null>;
  yAxisName?: string;
  xAxisName?: string;
}

const CustomBarChart = forwardRef<HTMLDivElement, BarchartProps>(
  (props, ref) => {
    const { 
      data = [], 
      forwardedRef, 
      yAxisName = "Value",
      xAxisName = "Timestamp"
    } = props;
    
    const chartRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (chartRef.current && forwardedRef) {
        forwardedRef.current = chartRef.current;
      }
    }, [chartRef.current, forwardedRef]);

    if (data.length === 0) {
      return <div className="flex items-center justify-center h-[300px]">No data available in last 24 hour</div>;
    }

    const emptyBar = fillMissingHours(data);
    const seriesData: any[] = emptyBar.map((entry) => ({
      hour: entry.hour,
      value: entry?.consumption ?? 0,
      firstTimeStamp: entry.firstTimeStamp,
      lastTimeStamp: entry.lastTimeStamp,
    }));
   
    return (
      <div ref={chartRef} className="ml-[-25px]">
        <ResponsiveContainer width="100%" height={350}>
          <BarChart
            data={seriesData}
            margin={{ top: 20, right: 30, left: 40, bottom: 40 }}
            height={350}
          >
            <XAxis
              dataKey="hour"
              tickFormatter={(hour) => `${hour.split(" ")[1]}:00`}
              axisLine={{ stroke: '#E5E5E5' }}
              tick={{ fontSize: 14, fill: '#000' }}
              label={{ value: xAxisName, 
                position: 'bottom', offset: 10, fontSize: 16, fill: '#000',fontWeight: 'bold'}}
            />
            <YAxis 
              axisLine={{ stroke: '#E5E5E5' }}
              tick={{ fontSize: 14, fill: '#000' }}
              tickFormatter={(value) => {
                if (value >= 1000000) return `${(value / 1000000).toFixed(1)}M`;
                if (value >= 1000) return `${(value / 1000).toFixed(1)}K`;
                return value;
              }}
              label={{ value: yAxisName, angle: -90, 
                position: 'center', offset: 0, dx: -30,
                fontSize: 16, fill: '#000', fontWeight: 'bold'}}
            />
            <Tooltip
              content={(props: any) => {
                const { payload } = props;
                if (payload && payload[0]) {
                  const dataPoint = payload[0].payload;
                  return (
                    <div
                      style={{
                        backgroundColor: "#fff",
                        border: "1px solid #ccc",
                        padding: "10px",
                      }}
                    >
                      <p>{`Hour: ${dataPoint.hour}:00`}</p>
                      <p>{`Consumption: ${dataPoint.value.toFixed(3)}`}</p>
                    </div>
                  );
                }
                return null;
              }}
            />
            <Bar dataKey="value" fill="#4A90E2" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  }
);

export default CustomBarChart;