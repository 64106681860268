import { http } from "../../helper/http";
import { Alert, PaginatedAlert } from "../../types/AlertTypes";

interface SetAlertAction {
  type: "SET_ALERT";
  payload: PaginatedAlert;
}

interface AddAlertsAction {
  type: "ADD_ALERT";
  payload: { alert: Alert, totalItems: number };
}

interface UpdateAlertStatusAction {
  type: "UPDATE_ALERT_STATUS";
  payload: { id: number, status: boolean, key: string };
}

interface UpdateAlertAction {
  type: "UPDATE_ALERT";
  payload: { id: number; alert: Alert };
}




export type AlertAction =
  | SetAlertAction
  | AddAlertsAction
  | UpdateAlertStatusAction
  | UpdateAlertAction


interface ApiResponse {
  message: string;
  alert: PaginatedAlert;
}

const baseUrl = process.env.REACT_APP_BASE_URL;
export async function setAlert(dispatch: React.Dispatch<AlertAction>, payload: number) {
  try {
    const page = payload ?? 1;
    const response = (await http(baseUrl + `/alert?page=${page}`)) as ApiResponse;
    const responseData = response;  
    // console.log(responseData);
      
    dispatch({
      type: "SET_ALERT",
      payload: responseData.alert,
    });
  } catch (err) {
    console.error(err);
  }
}

export function addNewAlert(
  dispatch: React.Dispatch<AlertAction>,
  payload: { alert: Alert, totalItems: number }
) {
  dispatch({
    type: "ADD_ALERT",
    payload,
  });
}


export function updateAlertStatus(
  dispatch: React.Dispatch<AlertAction>,
  payload:  { id: number, status: boolean, key: string }
) {
  dispatch({
    type: "UPDATE_ALERT_STATUS",
    payload,
  });
}

export function updateAlerts(
  dispatch: React.Dispatch<AlertAction>,
  payload: { id: number; alert: Alert }
) {
  dispatch({
    type: "UPDATE_ALERT",
    payload,
  });
}




