import { useState } from "react";
import { InputLabel } from "@mui/material";
import { http } from "../../helper/http";
import csvtojson from "csvtojson";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

const baseUrl = process.env.REACT_APP_BASE_URL;

const CsvUpload = () => {
  const [csvData, setCsvData] = useState<any>([]);
  const { id } = useParams();

  const handleFileChange = async (event: any) => {
    try {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = async (e: any) => {
        const csvData = e.target.result;

        const jsonArray = await csvtojson().fromString(csvData);

        setCsvData(jsonArray);
      };
      reader.readAsText(file);
    } catch (error) {
      console.error(error);
    }
  };

  const handlePost = async () => {
    try {
      if (csvData.length > 0) {
        const response: any = await http(
          baseUrl + "/csv-upload?deviceId=" + id,

          "POST",
          csvData
        );
        toast.success(response?.message || "Data uploaded successfully");
      } else {
        toast.info("Please select the file to upload");
      }
    } catch (error) {
      toast.error("Error uploading data");
    }
  };

  return (
    <div className="max-w-lg mx-auto p-6 bg-white rounded-lg shadow-lg">
      <h1 className="text-2xl font-semibold mb-4">CSV Upload</h1>

      <div className="mb-4">
        <InputLabel htmlFor="csv-file" className="block mb-2">
          Upload CSV File
        </InputLabel>
        <input
          type="file"
          id="csv-file"
          accept=".csv"
          onChange={handleFileChange}
          className="border border-gray-300 rounded w-full py-2 px-3 focus:outline-none focus:border-blue-500"
        />
      </div>

      <button
        onClick={handlePost}
        className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition duration-300"
      >
        POST
      </button>
    </div>
  );
};

export default CsvUpload;
