import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { http } from "../../helper/http";
import { ScheduleApiResponseData } from "../../types/Schedule";
import { useScheduleState } from "../../app-context/scheduling-context/SchedulingState";
import { addSchedules, updateSchedules } from "../../app-context/scheduling-context/SchedulingAction";
import TimePicker from 'react-time-picker';
// import {
//   addSchedule,
//   updateSchedule,
// } from "../../app-context/scheduling-context/SchedulingAction";

interface AddUserModalProps {
  toggleForm: boolean;
  toggleFormFun: () => void;
  data: ScheduleApiResponseData;
  uniqueId: string;
}

const baseUrl = process.env.REACT_APP_BASE_URL;

const AddSchedulingForm: React.FC<AddUserModalProps> = ({
  toggleForm,
  toggleFormFun,
  data,
  uniqueId,
}) => {
  const [devices, setDevices] = useState<any[]>([]);
  const { dispatch, schedulings } = useScheduleState();
  const [selectedDays, setSelectedDays] = useState<number[]>([]);
  const [allTags, setAllTags] = useState<any[]>([]);
  const [sensorValue, setSensorValue] = useState<string>("");
  const [formData, setFormData] = useState({
    schedulingName: "",
    customerId: "",
    deviceId: "",
    workingDay: [],
    schedulerOn: "",
    schedulerOff: "",
    offCmdIntervalMin: 0,
    cooldownInterval: 0,
    sensor: { tagId: "", tagName: "" },
    operator: "",
    email: [],
    phone: [],
    eventMessage: "",
  });


  const daysOfWeek = [
    { id: 0, name: "Select All" },
    { id: 1, name: "Monday" },
    { id: 2, name: "Tuesday" },
    { id: 3, name: "Wednesday" },
    { id: 4, name: "Thursday" },
    { id: 5, name: "Friday" },
    { id: 6, name: "Saturday" },
    { id: 7, name: "Sunday" },
  ];

  const conditions = [
    { label: "Greater than >", value: ">" },
    { label: "Greater than equal >=", value: ">=" },
    { label: "Less than <", value: "<" },
    { label: "Less than equal <=", value: "<=" },
    { label: "Equal ==", value: "===" },
    { label: "Not equal !=", value: "!==" },
  ];

  const fetchData = async () => {
    try {
      const response = (await http(`${baseUrl}/device?alldevice=1`)) as any;
      const filteredDevice = response.devices.filter(
        (device: any) => device.template === 42
      );
      setDevices(filteredDevice);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  const fetchTagNames = async (selectedDeviceForTemp: any) => {

    try {
      const response = (await http(
        `${baseUrl}/tag-groups?templateid=${selectedDeviceForTemp}`
      )) as any;
      // console.log("response Tag", response);
      const tags = response.tagsGroup.tagGroups;

      const tagData = tags.map((tag: any) => ({
        tagId: tag.tag.id,
        tagName: tag.tag.tagname,
      }));
      setAllTags(tagData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  const handleSelectChange = (event: SelectChangeEvent<number[]>) => {
    const value = event.target.value as number[];

    if (value.includes(0)) {
      if (selectedDays.length === daysOfWeek.length - 1) {
        setSelectedDays([]);
      } else {
        setSelectedDays(daysOfWeek.slice(1).map(day => day.id));
      }
    } else {
      setSelectedDays(value as number[]);
    }

    setFormData((prevFormData: any) => ({
      ...prevFormData,
      workingDay: value.includes(0) ?
        (selectedDays.length === daysOfWeek.length - 1 ? [] : daysOfWeek.slice(1).map(day => day.id)) :
        (value as number[]),
    }));
  };


  const handleChange = (
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<string>
  ) => {
    const { name, value } = event.target;

    if (name === "deviceId") {
      const selectedDevice = devices.find(
        (device: any) => device.id === event.target.value
      );
      if (selectedDevice) {
        fetchTagNames(selectedDevice.templateid);
        setFormData((prevFormData) => ({
          ...prevFormData,
          customerId: selectedDevice.customerId,
          deviceId: selectedDevice.id,
        }));
      }
    } else if (name === "offCmdIntervalMin") {
      const intValue = parseInt(value, 10);
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: isNaN(intValue) ? 0 : intValue,
      }));
    } else if (name === "tagId") {
      const selectedTag = allTags.find((tag) => tag.tagId === value);
      if (selectedTag) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          sensor: {
            tagId: selectedTag.tagId,
            tagName: selectedTag.tagName,
          },
        }));
      }
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name as string]: value,
      }));
    }
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const emailValue = event.target.value;
    const emailArray = emailValue
      .replace(/\s+/g, "")
      .split(",")
      .map((email) => email.trim());
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      email: emailArray,
    }));
  };

  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const phoneValue = event.target.value;
    const phoneArray = phoneValue
      .replace(/\s+/g, "")
      .split(",")
      .map((phone) => phone.trim());
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      phone: phoneArray,
    }));
  };

  const handleSubmit = async () => {
    const updatedFormData = {
      ...formData,
      sensor: {
        ...formData.sensor,
        value: sensorValue,
      },
    };
    if (uniqueId === "ADD") {
      try {
        const selectedDevice = devices.find(
          (device: any) => device.id === formData.deviceId
        );
        const resp = (await http(
          baseUrl + `/scheduling`,
          "POST",
          updatedFormData
        )) as any;
        const data = resp.scheduling
        const sch = { ...data, device: { deviceName: selectedDevice.deviceName } }
        addSchedules(dispatch, {
          schedule: sch,
          totalItems: schedulings.totalItems + 1,
        });
        toggleFormFun();
      } catch (error) {
        console.error(error);
      }
    } else {
      try {
        const resp = (await http(
          baseUrl + `/scheduling?id=${data.id}`,
          "PUT",
          updatedFormData
        )) as any;
        toggleFormFun();
        const scheduling = resp.scheduling;

        updateSchedules(dispatch, {
          id: data.id,
          schedule: { ...scheduling, device: { deviceName: data.device.deviceName } },
        });
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    if (uniqueId === "EDIT" && data) {
      fetchTagNames(`${data.device.template}`);

      setFormData((prevFormData: any) => ({
        ...prevFormData,
        schedulingName: data.schedulingName,
        customerId: data.customerId,
        deviceId: data.deviceId,
        workingDay: data.workingDay || [],
        schedulerOn: data.schedulerOn,
        schedulerOff: data.schedulerOff,
        offCmdIntervalMin: data.offCmdIntervalMin,
        cooldownInterval: data.cooldownInterval,
        sensor: {
          tagId: data.sensor?.tagId || "",
          tagName: data.sensor?.tagName || "",
        },
        operator: data.operator,
        email: data.email || [],
        phone: data.phone || [],
        eventMessage: data.eventMessage || "",
      }));
      setSelectedDays(data.workingDay || []);
      setSensorValue(data.sensor.value || "");
    }
    fetchData();
  }, []);


  return (
    <>
      <Dialog open={toggleForm} onClose={toggleFormFun}>
        <DialogTitle>
          {uniqueId === "ADD" ? "Add Schedule" : "Update Schedule"}
        </DialogTitle>
        <DialogContent>
          <div className="w-full">
            <TextField
              label="Scheduling Name"
              placeholder="Scheduling Name"
              fullWidth
              margin="normal"
              name="schedulingName"
              value={formData.schedulingName}
              onChange={handleChange}
              inputProps={{
                style: {
                  height: "20px",
                  padding: "16px 12px",
                },
              }}
            />
          </div>
          <FormControl sx={{ marginTop: "10px" }} fullWidth>
            <InputLabel id="selectdevice">Select Device</InputLabel>
            <Select
              labelId="selectdevice"
              id="select-device"
              value={formData.deviceId}
              name="deviceId"
              label="Select Device"
              onChange={handleChange}
              sx={{ width: "100%", height: "48px" }}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 300,
                  },
                },
              }}
            >
              {devices.length > 0 ? (
                devices.map((d: any) => (
                  <MenuItem key={d.id} value={d.id}>
                    {d.deviceName}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>No devices available</MenuItem>
              )}
            </Select>
          </FormControl>

          <FormControl sx={{ marginTop: "10px" }} fullWidth>
            <InputLabel id="select-days">Working Days</InputLabel>
            <Select
              labelId="select-days"
              id="select-days"
              multiple
              value={selectedDays}
              onChange={handleSelectChange}
              renderValue={(selected) =>
                selected
                  .map((dayId) => daysOfWeek.find((day) => day.id === dayId)?.name)
                  .join(", ")
              }
            >
              <MenuItem value={0}>
                <Checkbox checked={selectedDays.length === daysOfWeek.length - 1} />
                <ListItemText primary="Select All" />
              </MenuItem>
              {daysOfWeek.slice(1).map((day) => (
                <MenuItem key={day.id} value={day.id}>
                  <Checkbox checked={selectedDays.indexOf(day.id) > -1} />
                  <ListItemText primary={day.name} />
                </MenuItem>
              ))}
            </Select>

          </FormControl>

          {/* <div className="w-full col-span-2" ref={dropdownRef2}>
            <p className="text-[#6F7482] font-plex-sans text-base font-normal leading-6 tracking-tight text-left">
              Working Days
            </p>
            <div className="mt-2 relative">
              <button
                className="bg-[#F8FAFC] hover:bg-gray-100 text-gray-800 py-4 px-4 w-full text-left border border-gray-300 rounded-lg dropdown-arrow"
                onClick={() => setShowWorkingDays(!showWorkingDays)}
              >
                {selectedDays.length > 0
                  ? selectedDays
                    .map(
                      (dayId) =>
                        daysOfWeek.find((day) => day.id === dayId)?.name
                    )
                    .join(", ")
                  : "Select Days"}
              </button>
              {showWorkingDays && (
                <div className="absolute z-10 bg-white border border-gray-400 rounded shadow w-full max-h-60 overflow-y-auto">
                  <ul>
                    <li className="py-2 px-4 hover:bg-gray-100">
                      <input
                        type="checkbox"
                        checked={selectedDays.length === daysOfWeek.length}
                        onChange={handleSelectAllDays}
                      />
                      <span className="ml-2">Select All</span>
                    </li>
                    {daysOfWeek.map((day) => (
                      <li key={day.id} className="py-2 px-4 hover:bg-gray-100">
                        <input
                          type="checkbox"
                          checked={selectedDays.includes(day.id)}
                          onChange={() => handleDaySelection(day.id)}
                        />
                        <span className="ml-2">{day.name}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div> */}

          <div className="w-full">
            <label
              className="text-[#6F7482] font-plex-sans text-base font-normal leading-6 tracking-tight text-left"
              htmlFor="schedulerOn"
            >
              Schedule On
            </label>
            <TextField
              type="time"
              id="schedulerOn"
              name="schedulerOn"
              value={formData.schedulerOn}
              onChange={handleChange}
              variant="outlined"
              className="border border-gray-300 rounded-md p-[0.4rem] w-full"
              inputProps={{ 
                step: 60
              }}
              InputLabelProps={{
                shrink: false,
              }}
            />
          </div>
          

          <div className="w-full">
            <label
              className="text-[#6F7482] font-plex-sans text-base font-normal leading-6 tracking-tight text-left"
              htmlFor="schedulerOff"
            >
              Schedule Off
            </label>
            <TextField
              type="time"
              id="schedulerOff"
              name="schedulerOff"
              value={formData.schedulerOff}
              onChange={handleChange}
              variant="outlined"
              className="border border-gray-300 rounded-md p-[0.4rem] w-full"
              inputProps={{ step: 60 }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>

          <div className="w-full">
            <TextField
              label="Cool Down Interval (in minutes)"
              placeholder="In Minutes"
              fullWidth
              margin="normal"
              name="cooldownInterval"
              value={formData.cooldownInterval}
              onChange={handleChange}
              inputProps={{
                style: {
                  height: "20px",
                  padding: "16px 12px",
                },
              }}
            />
          </div>
          <FormControl className="flex-grow" sx={{ width: "100%" }}>
            <InputLabel
              sx={{ display: "flex", alignItems: "center", height: "20px" }}
              id="selecttag"
            >
              Select Sensor
            </InputLabel>
            <Select
              labelId="selecttag"
              id="select-tag"
              name="tagId"
              label="Select Sensor"
              value={formData.sensor.tagId}
              onChange={handleChange}
              sx={{ width: "100%", height: "48px" }}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 300,
                  },
                },
              }}
            >
              {allTags &&
                allTags.map((d: any) => (
                  <MenuItem
                    key={d.tagId}
                    value={d.tagId}
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      paddingLeft: "10px",
                    }}
                  >
                    {d.tagName}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>

          <div className="w-full">
            <TextField
              label="Set Value"
              placeholder="Set Value"
              fullWidth
              margin="normal"
              name="setValue"
              value={sensorValue}
              onChange={(e) => setSensorValue(e.target.value)}
              inputProps={{
                style: {
                  height: "20px",
                  padding: "16px 12px",
                },
              }}
            />
          </div>

          <FormControl
            className="flex-grow"
            sx={{ width: "100%", marginTop: "1rem" }}
          >
            <InputLabel
              sx={{ display: "flex", alignItems: "center", height: "20px" }}
              id="selectoperator"
            >
              Select Operator
            </InputLabel>
            <Select
              labelId="selectoperator"
              id="select-operator"
              value={formData.operator}
              name="operator"
              label="Select Operator"
              onChange={handleChange}
              sx={{ width: "100%", height: "48px" }}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 300,
                  },
                },
              }}
            >
              {conditions.map((condition, index) => (
                <MenuItem
                  key={index}
                  value={condition.value}
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    paddingLeft: "10px",
                  }}
                >
                  {condition.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <div className="w-full col-span-2">
            <div>
              <label
                className="text-[#6F7482] font-plex-sans text-base font-normal leading-6 tracking-tight text-left"
                htmlFor="email"
              >
                Email
              </label>
              <input
                type="text"
                id="email"
                value={formData.email.join(", ")}
                onChange={handleEmailChange}
                className="border border-gray-300 rounded-md p-[0.4rem] py-[15px] w-full"
              />
            </div>
          </div>

          <div className="w-full col-span-2">
            <div>
              <label
                className="text-[#6F7482] font-plex-sans text-base font-normal leading-6 tracking-tight text-left"
                htmlFor="phone"
              >
                Phone No
              </label>
              <input
                type="text"
                id="phone"
                value={formData.phone.join(", ")}
                onChange={handlePhoneChange}
                className="border border-gray-300 rounded-md p-[0.4rem] py-[15px] w-full"
              />
            </div>
          </div>
          <TextField
            label="Event Message"
            placeholder="Enter the Message"
            fullWidth
            margin="normal"
            name="eventMessage"
            value={formData.eventMessage}
            onChange={handleChange}
            inputProps={{
              style: {
                height: "20px",
                padding: "16px 12px",
              },
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleFormFun}>Cancel</Button>
          <Button onClick={handleSubmit} color="primary">
            {uniqueId === "ADD" ? "Save" : "Update"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddSchedulingForm;
