import { TableCell, TableHead, TableRow } from "@mui/material";
import ProtectedComponent from "../../../utils/protected-route/ProtectedComponent";
// import { useState } from "react";
// import { JwtPayload, jwtDecode } from "jwt-decode";

// Table Header
const tableCellsData = [
  { label: "IMEI No", id: "serialnum" },
  { label: "Device Name", id: "deviceName" },
  { label: "Device Alias", id: "deviceAlias" },
  { label: "Customer Name", id: "customerName" },
  { label: "Location(lat,long)", id: "location" },
  { label: "Status", id: "status" },
  { label: "Action", id: "action" },
];
export function EnhancedTableHead() {
  // const [token] = useState(window.localStorage.getItem("token"));
  // function readRoles(token: any) {
  //   try {
  //     const decodedToken: any = jwtDecode<JwtPayload>(token);
  //     return decodedToken;
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }
  // const role = readRoles(token);
  // if (role.role === 1) {
  //   tableCellsData.splice(4, 0, { label: "Status", id: "status" });
  // }

  return (
    <TableHead className="bg-gray-800">
      <TableRow className="border-b">
        {tableCellsData.map((cellData) => (
          <>
            {cellData.label === "Customer Name" ? (
              <ProtectedComponent>
                <TableCell
                  key={cellData.id}
                  padding="normal"
                  className="p-2"
                  sx={{ color: "white", fontWeight: 600, textAlign: "center" }}
                >
                  {cellData.label}
                </TableCell>
              </ProtectedComponent>
            ) : (
              <TableCell
                key={cellData.id}
                padding="normal"
                className="p-2"
                sx={{ color: "white", fontWeight: 600, textAlign: "center" }}
              >
                {cellData.label}
              </TableCell>
            )}
          </>
        ))}
      </TableRow>
    </TableHead>
  );
}
