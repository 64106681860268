import { Visibility, VisibilityOff } from "@mui/icons-material";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { http } from "../../helper/http";
import { JwtPayload, jwtDecode } from "jwt-decode";

const ChangePassword = ({ togglePasswordModal }: any) => {
  const [passwords, setPasswords] = useState({
    oldPassword: "",
    password1: "",
    password2: "",
  });

  const [showPasswords, setShowPasswords] = useState({
    oldPassword: false,
    password1: false,
    password2: false,
  });
  const [submitting, setSubmitting] = useState(false);
  const [token] = useState(window.localStorage.getItem("token"));

  const handlePasswordVisibility = (field: keyof typeof showPasswords) => {
    setShowPasswords({
      ...showPasswords,
      [field]: !showPasswords[field],
    });
  };

  const handlePasswordChange = (
    field: keyof typeof passwords,
    value: string
  ) => {
    setPasswords({
      ...passwords,
      [field]: value,
    });
  };

  const handleUpdate = async () => {
    if (token) {
      const decodedToken: any = jwtDecode<JwtPayload>(token);
      setSubmitting(true);
      if (passwords.password1 !== passwords.password2) {
        toast.error("Passwords do not match");
        setSubmitting(false);
        return;
      }
      if (passwords.oldPassword === passwords.password2) {
        toast.error("Your Old Password does should not same as your new Password");
        setSubmitting(false);
        return;
      }
      const data = {
        oldPassword: passwords.oldPassword,
        password: passwords.password2,
        email: decodedToken.email
      };
      try {
        const response = (await http("/user", "PATCH", data)as any);
        toast.success(response?.message);
        window.localStorage.removeItem("token");
        window.location.reload();
      } catch (error) {
        toast.error("Error updating passwords");
      }

      // console.log(passwords);

      setSubmitting(false);
      togglePasswordModal();
    }
  };

  return (
    <div className="p-10">
      <h2 className="mb-2 font-bold">Change Password</h2>
      <input
        type={showPasswords.oldPassword ? "text" : "password"}
        placeholder="Old Password"
        required
        value={passwords.oldPassword}
        onChange={(e) => handlePasswordChange("oldPassword", e.target.value)}
        className="h-10 w-full border border-gray-300 rounded mb-4 px-4 text-base focus:outline-none focus:border-blue-500"
      />
      {showPasswords.oldPassword ? (
        <VisibilityOff
          onClick={() => handlePasswordVisibility("oldPassword")}
          style={{
            marginLeft: "-10%",
            marginBottom: "2%",
            color: "#000",
          }}
        />
      ) : (
        <Visibility
          onClick={() => handlePasswordVisibility("oldPassword")}
          style={{
            marginLeft: "-10%",
            marginBottom: "2%",
            color: "#000",
          }}
        />
      )}

      <input
        type={showPasswords.password1 ? "text" : "password"}
        placeholder="New Password"
        required
        value={passwords.password1}
        onChange={(e) => handlePasswordChange("password1", e.target.value)}
        className="h-10 w-full border border-gray-300 rounded mb-4 px-4 text-base focus:outline-none focus:border-blue-500"
      />
      {showPasswords.password1 ? (
        <VisibilityOff
          onClick={() => handlePasswordVisibility("password1")}
          style={{
            marginLeft: "-10%",
            marginBottom: "2%",
            color: "#000",
          }}
        />
      ) : (
        <Visibility
          onClick={() => handlePasswordVisibility("password1")}
          style={{
            marginLeft: "-10%",
            marginBottom: "2%",
            color: "#000",
          }}
        />
      )}

      <input
        type={showPasswords.password2 ? "text" : "password"}
        placeholder="Confirm New Password"
        required
        value={passwords.password2}
        onChange={(e) => handlePasswordChange("password2", e.target.value)}
        className="h-10 w-full border border-gray-300 rounded mb-4 px-4 text-base focus:outline-none focus:border-blue-500"
      />
      {showPasswords.password2 ? (
        <VisibilityOff
          onClick={() => handlePasswordVisibility("password2")}
          style={{
            marginLeft: "-10%",
            marginBottom: "2%",
            color: "#000",
          }}
        />
      ) : (
        <Visibility
          onClick={() => handlePasswordVisibility("password2")}
          style={{
            marginLeft: "-10%",
            marginBottom: "2%",
            color: "#000",
          }}
        />
      )}
      <div className="flex flex-col text-center gap-4">
        <button
          disabled={submitting}
          onClick={handleUpdate}
          type="submit"
          className="bg-teal-500 text-white h-12 rounded font-semibold text-lg transition duration-200 hover:bg-teal-600 focus:outline-none"
        >
          Update
        </button>
        {/* <Link to="/forgot-password" className="text-teal-500 text-sm hover:underline">
                Forgot password?
              </Link> */}
      </div>
    </div>
  );
};

export default ChangePassword;
